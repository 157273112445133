import axios from "axios";
import { toast } from "react-toastify";
import { LIST_OF_COURSE, LIST_OF_COURSE_ERROR } from "../ActionTypes";
import {
  ADD_COURSE_API,
  LIMIT,
  PAGE,
  COURSE_LIST_API,
  UPDATE_COURSE_API,
} from "../constants";

const token = sessionStorage.getItem("token");
const header = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization",
  },
};

export const addCourse = async (userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  const body = JSON.stringify(userObj);
  return await axios
    .post(ADD_COURSE_API, body, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
      return error.response.data;
    });
};


export const updateCourse = async (
  courseId: string,
  userObj: any,
  token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  const body = JSON.stringify(userObj);
  return await axios
    .put(`${UPDATE_COURSE_API}/${courseId}`, body, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
      return error.response.data;
    });
};


export const CourseList = (page = PAGE, programId: any, limit = LIMIT, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${COURSE_LIST_API}?page=${page}&program_id=${programId}&limit=${limit}`, header)
      .then((res) => {
        dispatch({
          type: LIST_OF_COURSE,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_COURSE_ERROR,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};

export const addTopic = async (userObj: any) => {
  const body = JSON.stringify(userObj);
  return await axios
    .post(ADD_COURSE_API, body, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
      return error.response.data;
    });
};