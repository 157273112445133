import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid } from "@material-ui/core";
import OtpInput from "react-otp-input";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ROUTES } from "../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as authAction from "../../../reducer-store/actions/authActions";
import { OTP_VERIFY, RESEND_OTP } from "../../../reducer-store/ActionTypes";
import ToastMessage from "../../../common-components/ToastMessage";
import { toast } from "react-toastify";
import {
  ACCOUNT_VERIFIED_SUCCESSFULLY,
  ALL_ERROR_MESSAGE,
  RESEND_OTP_SUCCESSFULLY,
} from "../../../reducer-store/messages/actionMessages";
import { isRequiredField, renderRequiredError } from "../../FormComponent/FormValidator";

const useStyles = makeStyles((theme) => ({
  sectionTop: {
    marginTop: "80px",
    minHeight: "80vh",
  },
  loginTitle: {
    fontSize: "44px",
    textAlign: "center",
    marginBottom: "40px",
    fontWeight: 700,
    color: "#000000",
  },
  forgotpass: {
    fontSize: "20px",
    textAlign: "right",
    marginBottom: "40px",
    fontWeight: 600,
    color: "#000000",
  },
  forgotpass1: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: "#000000",
      textDecoration: "none",
    },
  },
  createacc: {
    textDecoration: "none",
    fontSize: "20px",
    fontWeight: 600,
    color: "black",
    "&:hover": {
      color: "#000000",
      textDecoration: "none",
    },
  },
  timerResend: {
    textAlign: 'center',
    font: 'normal normal normal 16px/21px Arial',
  },
  resend: {
    cursor: 'pointer',
    marginBottom: '4px',
    color: '#00A651',
    textAlign: 'center',
    backgroundColor: '#FFFFFF',
    border: 'none',
    font: 'normal normal normal 16px / 21px Arial',
    "&:hover": {
      color: '#00A651',
      backgroundColor: '#FFFFFF',
      textDecoration: "none",
    },
  },
  resend1: {
    textAlign: 'center',
    font: 'normal normal normal 16px / 21px Arial',
    letterSpacing: ' 0px',
    color: '#7E7E7E',
  },
  saveEmailButton1: {
    fontSize: '15px',
    textTransform: 'capitalize',
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
  },
  saveEmailButton: {
    fontSize: '15px',
    textTransform: 'capitalize',
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #393939 !important",
    color: "#393939 !important",
    padding: "0 35px !important",
    height: "40px !important",
  },
}));

type StateProps = {
  auth: AuthProps;
};

type AuthProps = {
  user: any;
  selectedValue: string | null;
};

const Onetime_otp: React.FC = () => {
  const classes = useStyles();
  const auth: AuthProps = useSelector((state: StateProps) => state.auth);
  let { user, selectedValue } = auth;
  const [otp, setOtp] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const isCheckValidEmailValue = false;
  const isCheckValidNoValue = false;
  const isCheckValidPhoneValue = false;
  const isCheckValidRID = false;
  const isCheckValidAddress = false;
  const [isValidValues, setIsValidValues] = useState(true);
  const [loaded, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { resendVerificationOTPCode, verifyOTPCode, verifyForgotPasswordOTPCode } = bindActionCreators(
    authAction,
    dispatch
  );
  const [timer, setTimer] = useState(59);
  const [show, setShow] = useState(false);
  const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);
  let type = selectedValue ? selectedValue : "email";
  const history = useHistory();

  useEffect(() => {
    setShow(false);
    if (timer == 0) {
      setShow(true);
    } else {
      timer > 0 && setTimeout(timeOutCallback, 1000);
    }
    // return () => { clearInterval(timer); setShow(true) }
  }, [timer, timeOutCallback]);

  const resetTimer = function () {
    if (!timer) {
      setTimer(59);
    }
  };

  let userObj;
  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (isRequiredField(otp)) {
      setIsSubmit(false);
      setLoader(true);
      if (type === "email") {
        userObj = {
          email: user.email,
          otp: otp,

        };
      } else {
        userObj = {
          mobile: user.mobile,
          otp: otp,
        };
      }
      let resData: any = await verifyForgotPasswordOTPCode(userObj);
      if (!resData) {
        setLoader(false);
        return;
      }
      setOtp("");
      toast.success("OTP Verified.");
      setTimeout(() => {
        setLoader(false);
        history.push(ROUTES.changeNewPassword);
      }, 2000);
    }
    setIsValidValues(false);
  };

  let resendOTPObj;
  const ResendOtp = async (e: React.MouseEvent) => {
    resetTimer();
    e.preventDefault();
    setLoader(true);
    if (type === "email") {
      resendOTPObj = {
        email: user.email,
      };
    } else {
      resendOTPObj = {
        phone: user.mobile,
      };
    }
    let resData: any = await resendVerificationOTPCode(resendOTPObj, type);
    if (!resData) {
      setLoader(false);
      return;
    }
    toast.success(RESEND_OTP_SUCCESSFULLY);
    setLoader(false);
  };

  return (
    <>
      <ToastMessage />
      <section className={classes.sectionTop}>
        <Container>
          <Row className="stap-form">
            <Typography
              className={classes.loginTitle}
              variant="h5"
              component="div"
            >
              Enter OTP
            </Typography>
          </Row>
          <Row className="stap-form">
            <Col lg="6" sm="12" className=" offset-lg-3">
              <Box>
                <Card className="Main_Card" variant="outlined">
                  {loaded && <LinearProgress />}
                  <Form onSubmit={SubmitHandler}>
                    <CardContent>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        item
                        xs
                      >
                        <OtpInput
                          value={otp}
                          onChange={(otp: any) => { setOtp(otp) }}
                          numInputs={6}
                          inputStyle={{
                            borderBottomColor: "#000",
                            fontSize: "24px",
                            width: "40px",
                            height: "40px",
                            margin: "auto 10px",
                            borderTop: "0px",
                            borderLeft: "0px",
                            borderRight: "0px",
                            outline: "none",
                          }}
                          containerStyle={{ margin: "20px auto", padding: "auto" }}
                          isInputNum
                        />
                        {!isValidValues &&
                          renderRequiredError(otp, isSubmit, "Enter valid OTP", isCheckValidEmailValue, isCheckValidNoValue, isCheckValidPhoneValue, isCheckValidRID, isCheckValidAddress)}
                      </Grid>
                    </CardContent>
                    <CardContent>
                      <div className="text-center">
                        <Link to={ROUTES.changePassword}>
                          <Button
                            type="button"
                            variant="contained"
                            className={classes.saveEmailButton}
                          >
                            Back
                          </Button>
                        </Link>{" "}
                        <Button
                          type="submit"
                          variant="contained"
                          className={classes.saveEmailButton1}
                        >
                          Verify OTP
                        </Button>
                      </div>
                    </CardContent>
                  </Form>

                  <CardContent>
                    <Grid item xs direction="row" className={classes.timerResend}>
                      {show == true ?
                        (
                          <div style={{ alignSelf: 'center' }}>
                            <Typography
                              variant="subtitle1"
                              display="inline"
                              style={{ marginRight: '10px' }}
                            >
                              00:00
                            </Typography>

                            <span onClick={ResendOtp}>
                              <Typography
                                variant="caption"
                                className={classes.resend}
                                display="inline"
                              >
                                Resend Code
                              </Typography>
                            </span>
                          </div>
                        )
                        :
                        (
                          <div style={{ alignSelf: 'center' }}>
                            <Typography
                              variant="subtitle1"
                              display="inline"
                              style={{ marginRight: '10px' }}
                            >
                              00:{timer}
                            </Typography>

                            <Typography
                              variant="caption"
                              display="inline"
                              className={classes.resend1}
                            >
                              Resend Code
                            </Typography>
                          </div>)
                      }
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Onetime_otp;
