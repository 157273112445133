import { ActionProps } from "../../ts";
import {
  LIST_OF_SCHOOL,
  LIST_OF_SCHOOL_ERROR,
  SINGLE_SCHOOL_INFO,
  SINGLE_SCHOOL_ERROR,
  SINGLE_SCHOOL_COMMUNICATION,
  SINGLE_SCHOOL_COMMUNICATION_ERROR,
  LIST_OF_SCHOOL_COUNT,
  LIST_OF_SCHOOL_COUNT_ERROR,
} from "../ActionTypes";

const initialState = { schools: null,studentCount:null };

const schoolStore = (state: any = initialState, action: ActionProps) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_OF_SCHOOL: {
      return {
        ...state,
        schools: payload,
        school_data: null,
      };
    }
    case LIST_OF_SCHOOL_ERROR: {
      return {
        ...state,
        schools: null,
        school_data: null,
      };
    }
    case SINGLE_SCHOOL_INFO: {
      return {
        ...state,
        school_data: payload,
      };
    }
    case SINGLE_SCHOOL_ERROR: {
      return {
        ...state,
        school_data: null,
      };
    }
    case SINGLE_SCHOOL_COMMUNICATION: {
      return {
        ...state,
        communication: payload,
      };
    }
    case SINGLE_SCHOOL_COMMUNICATION_ERROR: {
      return {
        ...state,
        communication: null,
      };
    }
    case LIST_OF_SCHOOL_COUNT:{
      return{
        ...state,
        studentCount:payload,
      }
    }
    case LIST_OF_SCHOOL_COUNT_ERROR:{
      return{
        ...state,
        studentCount:null,
      }
    }
    default:
      return state;
  }
};

export default schoolStore;
