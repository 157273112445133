import React from "react";
import { useState } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Box, } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@mui/material/FormControl";
import * as authAction from "../../../reducer-store/actions/authActions";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ToastMessage from "../../../common-components/ToastMessage";
import { isRequiredField } from "../../FormComponent/FormValidator";
import { PASSWORD_UPDATED_SUCCESSFULLY } from "../../../reducer-store/messages/actionMessages";
import { InputField, InputPasswordField } from "../../FormComponent/FormFieldsComponent";
import { USER_LOGOUT } from "../../../reducer-store/ActionTypes";

const useStyles = makeStyles((theme) => ({
  sectionTop: {
    marginTop: "80px",
    minHeight: "80vh",
  },
  loginTitle: {
    fontSize: "44px",
    textAlign: "center",
    marginBottom: "40px",
    fontWeight: 700,
    color: "#000000",
  },
  forgotpass: {
    fontSize: "20px",
    textAlign: "right",
    marginBottom: "40px",
    fontWeight: 600,
    color: "#000000",
  },
  forgotpass1: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: "#000000",
      textDecoration: "none",
    },
  },
  createacc: {
    textDecoration: "none",
    fontSize: "20px",
    fontWeight: 600,
    color: "black",
    "&:hover": {
      color: "#000000",
      textDecoration: "none",
    },
  },
  timerResend: {
    textAlign: 'center',
    font: 'normal normal normal 16px/21px Arial',
  },
  resend: {
    padding: '0px',
    textTransform: 'capitalize',
    paddingBottom: '4px',
    color: '#00A651',
    textAlign: 'center',
    backgroundColor: '#FFFFFF !important',
    border: 'none',
    font: 'normal normal normal 16px / 21px Arial',
    boxShadow: 'none !important',
    "&:hover": {
      color: '#00A651',
      backgroundColor: '#FFFFFF !important',
      textDecoration: "none",
    },
  },
  resend1: {
    textAlign: 'center',
    font: 'normal normal normal 16px / 21px Arial',
    letterSpacing: ' 0px',
    color: '#7E7E7E',
    backgroundColor: '#FFFFFF !important',
    boxShadow: 'none !important',
  },
  saveEmailButton: {
    fontSize: '15px',
    textTransform: 'capitalize',
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
  },
}));

interface StateProps {
  auth: {
    user: UsedInfoProps
    token: string
  }
}

interface UsedInfoProps {
  email: string;
  mobile: string;
}

const Newpassword: React.FC = () => {
  const Auth = useSelector((state: StateProps) => state.auth)
  const { user, token } = Auth;
  const { email, mobile } = user;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { updateForgotPassword } = bindActionCreators(authAction, dispatch);
  const [loaded, setLoader] = useState(false);

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setIsShowConfirmPassword(!isShowConfirmPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (isRequiredField(password) && isRequiredField(confirmPassword)) {
      setIsSubmit(false);
      let userObj = {
        email: email,
        password: password,
        confirm_password: confirmPassword
      }
      let resData: any = await updateForgotPassword(userObj);
      if (!resData.data.error) {
        toast.success(PASSWORD_UPDATED_SUCCESSFULLY);
        setTimeout(async () => {
          setLoader(false);
          await authAction.logoutUser(token);
          sessionStorage.clear();
          dispatch({ type: USER_LOGOUT });
        }, 2000);
      }
      return;
    }
    setIsValidValues(false);
  };

  return (
    <>
      <ToastMessage />
      <section className={classes.sectionTop}>
        <Container>
          <Row className="stap-form">
            <Typography
              className={classes.loginTitle}
              variant="h5"
              component="div"
            >
              Enter new password!
            </Typography>
          </Row>
          <Row className="stap-form">
            <Col lg="6" sm="12" className=" offset-lg-3">
              <Box>
                <Card variant="outlined">
                  <div className="Progress-bar"></div>
                  <Form onSubmit={SubmitHandler}>
                    <CardContent>
                      <FormControl variant="standard" fullWidth>
                        <InputPasswordField
                          type="password"
                          lable="New Password"
                          name={password}
                          rows={10}
                          multiline={false}
                          placeHolder="Enter password"
                          className="form-textfield-styles"
                          value={password}
                          handleChange={(e) => setPassword(e.target.value)}
                          clickToShowPassowrd={handleClickShowPassword}
                          hoverToPassword={handleMouseDownPassword}
                          showPassword={isShowPassword}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="Password field is required"
                        />
                      </FormControl>
                    </CardContent>
                    <CardContent>
                      <InputPasswordField
                        type="password"
                        lable="Confirm Password"
                        name={confirmPassword}
                        rows={10}
                        multiline={false}
                        placeHolder="Enter confirm password"
                        className="form-textfield-styles"
                        value={confirmPassword}
                        handleChange={(e) => setConfirmPassword(e.target.value)}
                        clickToShowPassowrd={handleClickShowConfirmPassword}
                        hoverToPassword={handleMouseDownPassword}
                        showPassword={isShowConfirmPassword}
                        isValidValue={isValidValues}
                        isSubmit={isSubmit}
                        requiredMessage="Confirm password field is required"
                      />
                    </CardContent>
                    <CardContent>
                      <FormControlLabel
                        name="checkbox3"
                        control={<Checkbox style={{ color: '#23a751' }} />}
                        label="Remember me"
                      />
                    </CardContent>
                    <CardContent>
                      <div className="logbutton">
                        <Button type="submit" className={classes.saveEmailButton}>
                          Submit
                        </Button>
                      </div>
                    </CardContent>
                  </Form>
                </Card>
              </Box>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Newpassword;
