import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormFieldProps } from "../ts/index";

const useStyles = makeStyles((theme) => ({
    titleStyle: {
        fontWeight: "bold",
    },
    valueStyle: {
        color: "#8B8B8B",
    },
}));



const FormFieldComponent: React.FC<FormFieldProps> = ({
    FormFieldLable,
    FormFieldValue,
}): JSX.Element => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="subtitle1" className={classes.titleStyle} >
                {FormFieldLable}
            </Typography>
            <Typography variant="body1" className={classes.valueStyle}>
                {FormFieldValue ? FormFieldValue : "------"}
            </Typography>

        </>
    );
};

export default FormFieldComponent;
