import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@mui/icons-material/Edit";
import TaxIcon from "../../svg_icons/tax.svg";
import "../Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ROUTES } from "../../constants/routes";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as Actions from "../../reducer-store/actions/provinceTax";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ToastMessage from "../../common-components/ToastMessage";
import { SearchOutlined } from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { InputField } from "../../common-components/FormComponent/FormFieldsComponent";
import { Button, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Modal from '@mui/material/Modal';
import CommonHeadPart from "../../common-components/CommonHeadPart";

const useStyles = makeStyles(() => ({
    divInsideMain: {
        boxShadow: "0 0 5px #8e8e8e",
        borderRadius: "3px",
    },
    searchBar: {
        top: 0,
        borderColor: "black",
        "& hover": {
            borderColor: "black"
        },
        "& focused": {
            borderColor: "black"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "black"
        }
    },
    saveEmailButton1: {
        borderRadius: "40px !important",
        backgroundColor: "#ffffff !important",
        border: "1px solid #000000 !important",
        color: "#000000 !important",
        padding: "0 35px !important",
        height: "40px !important",
        textTransform: 'capitalize',
        fontSize: "0.875rem",
    },
    updateButton: {
        borderRadius: "40px !important",
        backgroundColor: "#00A651 !important",
        border: "1px solid #00A651 !important",
        color: "#ffffff !important",
        padding: "0 35px !important",
        height: "40px !important",
        fontFamily: 'Arial !important',
        fontSize: '15px',
        textTransform: 'capitalize'
    },
}));

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    borderRadius: '4px'
};

const theme = createTheme({
    components: {
        MuiTableHead: {
            styleOverrides: {
                root: {
                    "& .css-1ygcj2i-MuiTableCell-root": {
                        fontweight: "bold",
                        backgroundColor: '#FFFFFF'
                    },
                    "& .css-14e01q-MuiTableCell-root": {
                        borderBottomColor: '#000',
                        borderBottomWidth: 'thin',
                    },
                    "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
                        display: "-webkit-box"
                    },
                    "& .tss-1fbujeu-MUIDataTableHeadCell-toolButton": {
                        backgroundColor: '#FFFFFF'
                    },
                    "& .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader": {
                        borderBottomColor: '#000',
                        borderBottomWidth: 'thin'
                    }
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial",
                    fontSize: "15px",
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial",
                    fontSize: "15px",
                },
                head: {
                    backgroundColor: "#FFFFFF"
                },
                footer: {
                    backgroundColor: "#FFFFFF"
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                colorInherit: {
                    backgroundColor: '#E53D12',
                    borderRadius: '4px',
                    color: 'white',
                    marginRight: '25px',
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-child(odd)": {
                        backgroundColor: "#F4F4F4"
                    },
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    "& .MuiTablePagination-displayedRows": {
                        marginTop: "9%"
                    },
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    "&.css-1uat52z-MuiTypography-root": {
                        fontFamily: "Arial",
                        fontWeight: 900,
                        letterSpacing: '0.06px',
                        color: '#3A3A3A',
                    }
                }
            }
        },
    }
});

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const Tax: React.FC = () => {
    const classes = useStyles();
    const AuthToken = useSelector((state: any) => state.auth);
    const { token } = AuthToken;
    const taxList = useSelector((state: any) => state.tax);
    const { taxes } = taxList;
    const [filterVal, setFilterVal] = useState('');
    const [searchApiData, setSearchApiData] = useState([]);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const { ProvinceTaxList } = bindActionCreators(Actions, dispatch);
    const { ProvinceTaxUpdate } = Actions;
    const [provinceId, setProvinceId] = useState("");
    const [province, setProvince] = useState("");
    const [countyTax, setCountyTax] = useState("");
    const [provincialTax, setProvincialTax] = useState("");
    const [openView, setOpenView] = React.useState(false);
    const [updated, setUpdated] = React.useState(false);
    const [searchData, setSearchData] = useState(false);

    const BreadCrumbTax = [
        {
            labelText: "Home",
            linkUrl: ROUTES.home,
        },
        {
            labelText: "Province Tax Details",
            linkUrl: "",
        },
    ];

    const BootstrapDialogTitle = (props: DialogTitleProps) => {
        const { children, onClose, ...other } = props;
        return (
            <DialogTitle sx={{ m: 0, p: 2, borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }} style={{ color: "#ffffff", backgroundColor: "#646464" }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={() => onClose()}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[100],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    useEffect(() => {
        (async () => {
            const provinceTaxList: any = await ProvinceTaxList(token);
            renderDataTable(provinceTaxList);
        })();
    }, [updated]);

    const renderDataTable = (provinceTaxList: any) => {
        const rowsDataTable = provinceTaxList && provinceTaxList.data.data.map((row: any) => {
            return {
                id: row?.id,
                province: row.Province?.name,
                total_tax: row?.total_tax,
                county_tax: row?.tax_slab_2,
                provincial_tax: row?.tax_slab_1,
                edit: <EditIcon style={{ cursor: 'pointer' }} onClick={() => handleEditTax(row)} />,
            };
        });
        setData(rowsDataTable);
        setSearchApiData(rowsDataTable);
    };

    const HandleFilter = (e: any) => {
        if (e.target.value == "") {
            setData(searchApiData);
        } else {
            const filterData = searchApiData.filter((items: any) =>
                items.id.toString().includes(e.target.value.toString())
                || items.province.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
                || items.provincial_tax.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
                || items.county_tax.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
                || items.total_tax.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
            );
            if (filterData.length > 0) {
                setData(filterData);
                setSearchData(false);
            } else {
                setData([]);
                setSearchData(true);
            }
        }
        setFilterVal(e.target.value);
    };

    const columns = [
        {
            label: 'Sr No',
            name: "id",
        },
        {
            label: 'Province',
            name: "province",
        },
        {
            label: 'Combined Rate(%)',
            name: "total_tax",
        },
        {
            label: 'GST/HST(%)',
            name: "county_tax",
        },
        {
            label: 'Provincial/Territorial Sales Tax(%)',
            name: "provincial_tax",
        },
        {
            label: 'Edit',
            name: "edit",
            options: {
                sort: false
            }
        },
    ];

    const options = {
        responsive: "vertical",
        search: false,
        print: false,
        viewColumns: false,
        filter: false,
        download: false,
        expandableRows: false,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        selectableRows: "none",
        rowsPerPageOptions: [],
        fixedHeader: false,
        textLabels: {
            body: {
                noMatch: searchData || data?.length == 0 && taxes?.data?.length == 0 ?
                    'Sorry, there is no matching data to display' :
                    <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
                        <CircularProgress sx={{ color: "#707070" }} />
                    </Box >
                ,
            },
        },
        customToolbar: () => {
            return (<>
                <TextField
                    variant="standard"
                    placeholder="Search"
                    onInput={(e: any) => HandleFilter(e)}
                    value={filterVal}
                    className={classes.searchBar}
                    InputProps={{
                        style: { color: "black" },
                        endAdornment: (
                            <IconButton>
                                <SearchOutlined />
                            </IconButton>
                        ),
                    }}
                />
            </>)
        },
    };

    const allowOnlyNumber = (value: string, setMethod: any) => {
        const re = /^[.10-9\b]+$/;
        if (value === '' || re.test(value))
            setMethod(value);
    }

    const handleEditTax = (row: any) => {
        setOpenView(true);
        setProvinceId(row?.id);
        setProvince(row?.Province.name);
        setCountyTax(row?.tax_slab_2);
        setProvincialTax(row?.tax_slab_1)
    };

    const handleCloseView = () => {
        setProvinceId("");
        setProvince("");
        setCountyTax("");
        setProvincialTax("")
        setOpenView(false);
    };

    const SubmitHandler = async () => {
        let obj = {
            "tax_slab_2": countyTax,
            "tax_slab_1": provincialTax,
            "total_tax": +countyTax + +provincialTax,
        }
        const resData: any = await ProvinceTaxUpdate(provinceId, token, obj);
        if (!resData.data.error) {
            toast.success("Province Tax Updated Successfully");
            setUpdated(!updated);
            setTimeout(() => {
                handleCloseView();
            }, 2000)
        }
    }

    return (
        <>
            <ToastMessage />
            <CommonHeadPart
                componentText="Tax"
                Icon={TaxIcon}
                buttonText=""
                BreadCrumbs={BreadCrumbTax}
                urlLink={"/"}
            />
            <div className={classes.divInsideMain}>

                <ThemeProvider theme={theme}>
                    <MUIDataTable
                        title={"Province Tax Details"}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>

                <Modal open={openView} onClose={handleCloseView}>
                    <Box sx={style}>
                        <BootstrapDialogTitle
                            id="customized-dialog-title"
                            onClose={handleCloseView}
                        >
                            <FaRegEdit color="#FFFFFF" size={22} style={{ marginRight: 10, marginBottom: 6 }} />
                            Edit Province Tax Details
                        </BootstrapDialogTitle>

                        <form onSubmit={SubmitHandler}>
                            <DialogContent dividers>
                                <Col lg="12" sm="12">
                                    <InputField
                                        disabled
                                        type="text"
                                        lable="Province"
                                        name="province"
                                        rows={1}
                                        multiline={false}
                                        placeHolder="Province"
                                        className="form-textfield-styles"
                                        value={province}
                                        // handleChange={(e) => { }}
                                        isValidValue={false}
                                        isSubmit={false}
                                        requiredMessage="Total Fee Amount field is required"
                                    />
                                </Col>
                                <Col lg="12" sm="12">
                                    <InputField
                                        type="text"
                                        lable="GST/HST Tax(%)"
                                        name="county_tax"
                                        rows={1}
                                        multiline={false}
                                        placeHolder="Enter GST/HST Tax"
                                        className="form-textfield-styles"
                                        value={countyTax}
                                        handleChange={(e) => allowOnlyNumber(e.target.value, setCountyTax)}
                                        isValidValue={false}
                                        isSubmit={false}
                                        requiredMessage="GST/HST Tax field is required"
                                        max={6}
                                    />
                                </Col>
                                <Col lg="12" sm="12">
                                    <InputField
                                        type="text"
                                        lable="Provincial/Territorial Sales Tax(%)"
                                        name="provincial_tax"
                                        rows={1}
                                        multiline={false}
                                        placeHolder="Enter Provincial/Territorial Sales Tax"
                                        className="form-textfield-styles"
                                        value={provincialTax}
                                        handleChange={(e) => allowOnlyNumber(e.target.value, setProvincialTax)}
                                        isValidValue={false}
                                        isSubmit={false}
                                        requiredMessage="Provincial/Territorial Sales Tax field is required"
                                        max={6}
                                    />
                                </Col>
                            </DialogContent>

                            <DialogActions>
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={handleCloseView}
                                    className={classes.saveEmailButton1}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="button"
                                    variant="contained"
                                    className={classes.updateButton}
                                    onClick={SubmitHandler}
                                >
                                    Update
                                </Button>
                            </DialogActions>
                        </form>
                    </Box>
                </Modal>
            </div>
        </>
    );
};

export default Tax;
