import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import CommonHeadPart from "../../common-components/CommonHeadPart";
import LicenseIcon from "../../svg_icons/add_new_account.svg";
import { ROUTES } from "../../constants/routes";
import {
    CancelButtonComponent,
    SaveButtonComponent,
} from "../../common-components/ButtonComponent";
import {
    InputField, InputPasswordField
} from "../../common-components/FormComponent/FormFieldsComponent";
import { isRequiredField, isValidEmail, isValidNo, isValidPassword, isValidPhone, isValidRegisterID } from "../../common-components/FormComponent/FormValidator";
import { ADD_NEW_USER_SUCCESSFULLY } from "../../reducer-store/ToastMessage";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ToastMessage from "../../common-components/ToastMessage";
import Typography from "@material-ui/core/Typography";
import * as authAction from "../../reducer-store/actions/authActions";
import { Button } from "@mui/material";

const useStyles = makeStyles(() => ({
    button_save: {
        textAlign: "right",
    },
    backButton: {
        borderRadius: "40px !important",
        backgroundColor: "#ffffff !important",
        border: "1px solid #393939 !important",
        color: "#393939 !important",
        padding: "0 35px !important",
        height: "40px !important",
        fontWeight: 500,
        lineHeight: 1.75,
        letterSpacing: "0.02857em",
        textTransform: "capitalize",
        fontSize: "0.875rem",
        minWidth: "64px",
        fontFamily: "Arial !important",
    },
}));

const BreadCrumbComposeMessage = [
    {
        labelText: "Home",
        linkUrl: ROUTES.dashboard,
    },
    {
        labelText: "Add Another Account",
        linkUrl: "",
    },
];

const AddNewUser: React.FC = () => {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [userName, setUserName] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [isValidValues, setIsValidValues] = useState(true);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
    const dispatch = useDispatch();
    const { addNewUser } = bindActionCreators(authAction, dispatch);
    const history = useHistory();
    const classes = useStyles();
    const [show, setShow] = useState(false);


    const handleClickShowPassword = () => {
        setIsShowPassword(!isShowPassword);
    };

    const handleClickShowConfirmPassowrd = () => {
        setIsShowConfirmPassword(!isShowConfirmPassword);
    };

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const resetData = () => {
        setFullName("");
        setEmail("");
        setMobile("");
        setPassword("");
        setConfirmPassword("");
        setUserName("");
    };

    const handleCancel = () => {
        resetData();
        setIsSubmit(false);
    };


    const checkPasswordFieldSave = (newPass: string, conPass: string) => {
        if (newPass !== conPass) {
            setShow(true)
            return false;
        } else {
            setShow(false)
            return true;
        };
    };

    const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmit(true);
        if (
            isRequiredField(fullName) &&
            isValidRegisterID(fullName) &&
            isRequiredField(email) &&
            isValidEmail(email) &&
            isRequiredField(password) &&
            isRequiredField(confirmPassword) &&
            isRequiredField(userName) &&
            isValidRegisterID(userName) &&
            isRequiredField(mobile) &&
            isValidPhone(mobile) &&
            checkPasswordFieldSave(password, confirmPassword)
        ) {
            setIsSubmit(false);
            let userObj = {
                full_name: fullName,
                email: email,
                mobile: mobile,
                password: password,
                confirm_password: confirmPassword,
                username: userName,
            };

            let resData: any = await addNewUser(userObj);
            if (!resData.error) {
                toast.success(ADD_NEW_USER_SUCCESSFULLY);
                setTimeout(() => {
                    resetData();
                }, 2000);
                return;
            }
        }
        setIsValidValues(false);
    };

    return (
        <>
            <ToastMessage />
            <CommonHeadPart
                componentText="Add Another Account"
                Icon={LicenseIcon}
                buttonText=""
                urlLink=""
                BreadCrumbs={BreadCrumbComposeMessage}
            />
            <Card>
                <CardContent>
                    <form onSubmit={SubmitHandler}>
                        <Row>
                            <Col lg="6" sm="12">
                                <InputField
                                    type="text"
                                    lable="Full Name"
                                    name={fullName}
                                    rows={1}
                                    multiline={false}
                                    placeHolder="Enter Full Name"
                                    className="form-textfield-styles"
                                    value={fullName}
                                    handleChange={(e) => setFullName(e.target.value)}
                                    isValidValue={isValidValues}
                                    isSubmit={isSubmit}
                                    requiredMessage="Full Name field is required"
                                    isCheckValidRID={true}
                                />
                            </Col>
                            <Col lg="6" sm="12">
                                <InputField
                                    type="email"
                                    lable="Email"
                                    name={email}
                                    rows={1}
                                    multiline={false}
                                    placeHolder="Enter Valid Email Address"
                                    className="form-textfield-styles"
                                    value={email}
                                    handleChange={(e) => setEmail(e.target.value)}
                                    isValidValue={isValidValues}
                                    isSubmit={isSubmit}
                                    requiredMessage="Email Address field is required"
                                    isCheckValidEmailValue={true}
                                />
                            </Col>
                            <Col lg="6" sm="12">
                                <InputField
                                    type="text"
                                    lable="Mobile No"
                                    name={mobile}
                                    rows={1}
                                    multiline={false}
                                    placeHolder="Enter Mobile No"
                                    className="form-textfield-styles"
                                    value={mobile}
                                    handleChange={(e) => setMobile(e.target.value)}
                                    isValidValue={isValidValues}
                                    isSubmit={isSubmit}
                                    max={10}
                                    requiredMessage="Mobile No field is required"
                                    isCheckValidPhoneValue={true}
                                />
                            </Col>
                            <Col lg="6" sm="12">
                                <InputField
                                    type="text"
                                    lable="User Name"
                                    name={userName}
                                    rows={1}
                                    multiline={false}
                                    placeHolder="Enter User Name"
                                    className="form-textfield-styles"
                                    value={userName}
                                    handleChange={(e) => setUserName(e.target.value)}
                                    isValidValue={isValidValues}
                                    isCheckValidRID={true}
                                    isSubmit={isSubmit}
                                    requiredMessage="User Name field is required"
                                />
                            </Col>
                            <Col lg="6" sm="12">
                                <InputPasswordField
                                    type="password"
                                    lable="Password"
                                    name={password}
                                    rows={1}
                                    multiline={false}
                                    placeHolder="Enter Password"
                                    className="form-textfield-styles"
                                    value={password}
                                    handleChange={(e) => setPassword(e.target.value)}
                                    clickToShowPassowrd={handleClickShowPassword}
                                    hoverToPassword={handleMouseDownPassword}
                                    showPassword={isShowPassword}
                                    isValidValue={isValidValues}
                                    isSubmit={isSubmit}
                                    requiredMessage="Password field is required"
                                />
                                {
                                    show ? <Typography style={{ color: 'red' }}>New Password and Confirm password must be same!</Typography> : null
                                }
                            </Col>
                            <Col lg="6" sm="12">
                                <InputPasswordField
                                    type="password"
                                    lable="Confirm Password"
                                    name={confirmPassword}
                                    rows={1}
                                    multiline={false}
                                    placeHolder="Enter Confirm Password"
                                    className="form-textfield-styles"
                                    value={confirmPassword}
                                    handleChange={(e) => setConfirmPassword(e.target.value)}
                                    clickToShowPassowrd={handleClickShowConfirmPassowrd}
                                    hoverToPassword={handleMouseDownPassword}
                                    showPassword={isShowConfirmPassword}
                                    isValidValue={isValidValues}
                                    isSubmit={isSubmit}
                                    requiredMessage="Confirm Password field is required"
                                />
                                {
                                    show ? <Typography style={{ color: 'red' }}>New Password and Confirm password must be same!</Typography> : null
                                }
                            </Col>
                        </Row>
                        <br />
                        <Row className="mt-4">
                            <Col lg="12" sm="12">
                                <div className={classes.button_save}>
                                    <Button
                                        variant="outlined"
                                        onClick={handleCancel}
                                        className={classes.backButton}
                                    >
                                        Cancel
                                    </Button>
                                    {" "}
                                    <SaveButtonComponent ButtonType="submit" ButtonLabel="Save" />
                                </div>
                            </Col>
                        </Row>
                    </form>
                </CardContent>
            </Card>
        </>
    );
};

export default AddNewUser;
