import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import CommonHeadPart from "../../../common-components/CommonHeadPart";
import LicenseIcon from "../../../svg_icons/licence.svg";
import { ROUTES } from "../../../constants/routes";
import {
  CancelButtonComponent,
  SaveButtonComponent,
} from "../../../common-components/ButtonComponent";
import {
  InputDatePicker,
  InputField
} from "../../../common-components/FormComponent/FormFieldsComponent";
import { isRequiredField, isValidRegisterID } from "../../../common-components/FormComponent/FormValidator";
import * as Actions from "../../../reducer-store/actions/licenseAction";
import { LICENSE_SUCCESSFULLY_CREATED, LICENSE_SUCCESSFULLY_UPDATED } from "../../../reducer-store/ToastMessage";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import ToastMessage from "../../../common-components/ToastMessage";

const useStyles = makeStyles(() => ({
  button_save: {
    textAlign: "right",
  },
}));

const BreadCrumbComposeMessage = [
  {
    labelText: "Home",
    linkUrl: ROUTES.dashboard,
  },
  {
    labelText: "Manage platform licenses",
    linkUrl: ROUTES.manageLicence,
  },
  {
    labelText: "Add new platform license",
    linkUrl: "",
  },
];

const AddNewPlatformLicense: React.FC = () => {
  const [licenseName, setLicenseName] = useState("");
  const [startDate, setStartDate] = useState<any>(null);
  const [description, setDescription] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const { addLicence, updateLicence } = Actions;
  const history = useHistory();
  const queryPara: any = useParams();
  const dispatch = useDispatch();
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const { SingleLicenseData } = bindActionCreators(Actions, dispatch);

  useEffect(() => {
    (async () => {
      if (queryPara && queryPara.id) {
        const response: any = await SingleLicenseData(queryPara.id, token);
        const { data } = response.data;
        setLicenseName(data.name);
        setStartDate(moment(data.start_timestamp).format("YYYY-MM-DD"));
        setDescription(data.description);
      }
    })();
  }, [queryPara]);

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      isRequiredField(licenseName) &&
      isValidRegisterID(licenseName) &&
      // isRequiredField(startDate) &&
      isRequiredField(description) &&
      isValidRegisterID(description)
    ) {
      setIsSubmit(false);
      let userObj = {
        name: licenseName,
        start_timestamp: moment(startDate).format("DD-MM-YYYY"),
        description: description,
      };

      let resData: any = await addLicence(userObj);
      if (!resData.error) {
        toast.success(LICENSE_SUCCESSFULLY_CREATED);
        setTimeout(() => {
          return history.push(ROUTES.manageLicence);
        }, 2000);
        return;
      }
    }
    setIsValidValues(false);
  };

  const SubmitUpdateHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    let userObj = {
      name: licenseName,
      start_timestamp: startDate,
      description: description,
    };
    let resData: any = await updateLicence(queryPara.id, userObj);
    if (!resData.error) {
      toast.success(LICENSE_SUCCESSFULLY_UPDATED);
      setLicenseName("");
      setStartDate("");
      setDescription("");
      setTimeout(() => {
        return history.push(ROUTES.manageLicence);
      }, 2000);
      return;
    }
  }

  const classes = useStyles();

  const handleStartDate = (date: any) => {
    setStartDate(date);
  };


  return (
    <>
      <ToastMessage />
      <CommonHeadPart
        componentText="Manage Platform Licenses"
        Icon={LicenseIcon}
        buttonText=""
        urlLink=""
        BreadCrumbs={BreadCrumbComposeMessage}
      />
      <Card>
        <CardContent>
          <form onSubmit={queryPara.id === undefined ? SubmitHandler : SubmitUpdateHandler}>
            <Row>
              <Col lg="6" sm="12">
                <InputField
                  type="text"
                  lable="License name"
                  name={licenseName}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter license name"
                  className="form-textfield-styles"
                  value={licenseName}
                  handleChange={(e) => setLicenseName(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="License name field is required"
                  isCheckValidRID={true}
                />
              </Col>
              <Col lg="6" sm="12">
                {/* <InputField
                  type="date"
                  lable="Start Date"
                  name={startDate}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter start date"
                  className="form-textfield-styles"
                  value={startDate}
                  handleChange={(e) => setStartDate(e.target.value)}
                  isValidValue={false}
                  isSubmit={false}
                  requiredMessage="Start date field is required"
                  disableUpcoming={moment(new Date("9999-01-01")).format("YYYY-MM-DD")}
                /> */}
                <InputDatePicker
                  className="form-textfield-styles errorBottom"
                  value={startDate}
                  lable="Start Date"
                  handleChange={(e) => handleStartDate(e)}
                  isValidValue={false}
                  isSubmit={false}
                  requiredMessage="Start Date field is required"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg="12" sm="12">
                <InputField
                  type="text"
                  lable="Description"
                  name={description}
                  rows={6}
                  multiline={true}
                  placeHolder="Enter message"
                  className="form-textfield-styles"
                  value={description}
                  handleChange={(e) => setDescription(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Description field is required"
                />
              </Col>
            </Row>
            <br />
            <Row className="mt-4">
              <Col lg="12" sm="12">
                <div className={classes.button_save}>
                  <Link to={ROUTES.manageLicence}>
                    <CancelButtonComponent ButtonLabel="Cancel" />
                  </Link>
                  {" "}

                  {queryPara.id === undefined ? <SaveButtonComponent ButtonType="submit" ButtonLabel="Save" /> : <SaveButtonComponent ButtonType="submit" ButtonLabel="Update" />}
                </div>
              </Col>
            </Row>
          </form>
        </CardContent>
      </Card>
    </>
  );
};

export default AddNewPlatformLicense;
