import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Typography } from '@mui/material';
import { Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";
import { fileSignedURL } from "../../../../../reducer-store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";

const LineStyle = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.lineVertical}`]: {
      borderColor: '#23a751',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.lineVertical}`]: {
      borderColor: '#23a751',

    },
  },
  [`& .${stepConnectorClasses.lineVertical}`]: {
    borderRadius: 2,
    borderLeftWidth: 5,
    height: 100,
    marginLeft: -2
  },
}));

const Icon = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ ownerState }) => ({
    display: 'flex',
    height: 20,
    alignItems: 'center',
    ...(ownerState.active && {
      color: "#23a751",
    }),
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
      marginLeft: 9
    },
  }),
);

type onBoardingProps = {
  onBoarding: any;
};


function StepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <Icon ownerState={{ active }} className={className} >
      {completed ? (<CheckCircleRoundedIcon style={{ color: "#23a751" }} />) : (<div className="QontoStepIcon-circle" />)}
    </Icon>
  );
}

const steps = ['Registration Form  and Payment Status', 'Cloud Setup Status', 'User Credentials Creation', 'User Credentials Mail Status'];

const CustomizedSteppers: React.FC<onBoardingProps> = ({ onBoarding }) => {
  const [next, setNext] = useState(0)
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;

  useEffect(() => {
    if (onBoarding.registration_status === true && onBoarding.payment_status === true) {
      setNext(1);
      if (onBoarding.cloudsetup_s3 === true && onBoarding.cloudsetup_subDomain === true) {
        setNext(2);
        if (onBoarding.credential_status === true) {
          setNext(3);
          if (onBoarding.credential_mail_status === true) {
            setNext(4);
          }
        }
      }
    } else {
      setNext(0);
    }
  }, []);

  const navigateToExternalUrl = async (urlName: string, shouldOpenNewTab: boolean = true) => {
    const newData: { url: string, msg: string, error: boolean } = await fileSignedURL(urlName && urlName, token);
    const { url, msg, error } = newData;
    if (error) {
      toast.error(msg);
      return;
    }
    shouldOpenNewTab ? window.open(url, "_blank") : window.location.href = url;
    return;
  }

  return (
    <Row className="justify-content-md-center">
      <Col lg="3" sm="12">
        <Typography><b>Subdomain URL :</b></Typography><a href={`http://${onBoarding.domain}`} target="_blank"><b>{onBoarding.domain}</b></a>
        <br />
        <Typography style={{ marginTop: 10 }}><b>S3 Bucket :</b></Typography><a href={onBoarding.school_bucket} target="_blank" ><b>Bucket URL (Click on it)</b></a>
      </Col>
      <Col lg="9" sm="12">

        <Stack sx={{ width: '100%' }} alignItems="center">
          <Stepper activeStep={next} connector={<LineStyle />} orientation="vertical"  >
            {steps.map((label) => (
              <Step key={label} className="mainFont">
                {label === "Registration form  and Payment Status"
                  ? <StepLabel StepIconComponent={StepIcon}>{label}<br />{onBoarding.registration_timestamp ? `${moment(onBoarding.registration_timestamp).format("MMM D, YYYY | h:mm:ss A")} | ${moment(onBoarding.payment_timestamp).format("MMM D, YYYY | h:mm:ss A")}` : moment(onBoarding.created_at).format("MMM D, YYYY | h:mm:ss A")}</StepLabel>
                  : label === "Cloud Setup Status" ?
                    <StepLabel StepIconComponent={StepIcon}>{label}<br />{onBoarding.cloudsetup_timestamp ? `${moment(onBoarding.cloudsetup_timestamp).format("MMM D, YYYY | h:mm:ss A")}` : moment(onBoarding.last_updated).format("MMM D, YYYY | h:mm:ss A")}</StepLabel>
                    : label === "User credentials creation" ?
                      <StepLabel StepIconComponent={StepIcon}>{label}<br />{onBoarding.credential_timestamp ? `${moment(onBoarding.credential_timestamp).format("MMM D, YYYY | h:mm:ss A")}` : moment(onBoarding.created_at).format("MMM D, YYYY | h:mm:ss A")}</StepLabel>
                      :
                      <StepLabel StepIconComponent={StepIcon}>{label}<br />{onBoarding.credential_mail_timestamp ? `${moment(onBoarding.credential_mail_timestamp).format("MMM D, YYYY | h:mm:ss A")}` : moment(onBoarding.last_updated).format("MMM D, YYYY | h:mm:ss A")}</StepLabel>
                }
              </Step>
            ))}
          </Stepper>
          {/* Check for All Steps Complete or not */}
          {next === steps.length ? (
            <>
              <Typography sx={{ mt: 2, mb: 1, mr: 10, fontWeight: 'bold', color: '#23a751' }}>
                School OnBording Completed
              </Typography>
            </>
          ) : null}

        </Stack>
      </Col>
    </Row>
  );
}
export default CustomizedSteppers