import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CommonHeadPart from "../../../common-components/CommonHeadPart";
import { ROUTES } from "../../../constants/routes";
import ProgramIcon from "../../../svg_icons/program.svg";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepIconProps,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import AddProgramModules from "./AddProgramModules";
import AddCourses from "./AddCourses";
import AddTopics from "./AddTopics";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../../reducer-store/actions/programsAction";
import { LIMIT, PAGE } from "../../../reducer-store/constants";

const stepsAdd = ["Add Program / Modules", "Add Courses", "Add Topics"];

const stepsEdit = ["Edit Program / Modules", "Edit Courses", "Edit Topics"];

const AddNewProgram: React.FC = () => {
  const params: any = useParams();
  const { id } = params;
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const programList = useSelector((state: any) => state.program);
  const { programs } = programList;
  const [editProgram, setEditProgram] = useState(null);
  const [steps, setSteps] = useState<any>([]);
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("step");
  urlParams.set("redirect_status", "");
  const [activeStep, setActiveStep] = useState(myParam ? parseInt(myParam) : 1);
  const dispatch = useDispatch();
  const { ProgramList } = bindActionCreators(Actions, dispatch);
  const [textValue, setTextValue] = useState("Add new Program")

  useEffect(() => {
    (async () => {
      await ProgramList("", PAGE, LIMIT, token, "desc", "");
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (id && programs) {
        setTextValue("Edit Program")
        const editProgramData = await programs?.data.filter((program: any) => {
          return program.id === parseInt(id);
        });
        setEditProgram(editProgramData[0]);
      }
    })();
  }, [id]);

  useEffect(() => {
    if (editProgram === null) {
      setSteps(stepsAdd)
    } else {
      setSteps(stepsEdit)
    }
  }, [editProgram])

  const BreadCrumbProgram = [
    {
      labelText: "Home",
      linkUrl: ROUTES.dashboard,
    },
    {
      labelText: "Program",
      linkUrl: ROUTES.programs,
    },
    {
      labelText: textValue,
      linkUrl: "",
    },
  ];

  function QontoStepIcon(props: StepIconProps) {
    const { completed } = props;

    return (
      <>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </>
    );
  }

  return (
    <>
      <CommonHeadPart
        componentText="Programs"
        Icon={ProgramIcon}
        buttonText=""
        urlLink=""
        BreadCrumbs={BreadCrumbProgram}
      />
      <Card>
        <CardContent>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label: any) => (
                <Step className="stepper-css" key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === 1 ? (
              <AddProgramModules
                handleStep={setActiveStep}
                stepValue={activeStep}
                editProgramInfo={editProgram}
              />
            ) : activeStep === 2 ? (
              <AddCourses handleStep={setActiveStep} stepValue={activeStep} editProgramInfo={editProgram} />
            ) : activeStep === 3 ? (
              <AddTopics handleStep={setActiveStep} stepValue={activeStep} />
            ) : (
              ""
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default AddNewProgram;
