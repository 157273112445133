import axios from "axios";
import { toast } from "react-toastify";
import {
  LIST_OF_SCHOOL_PAYMENT,
  LIST_OF_SCHOOL_PAYMENT_ERROR,
  SINGLE_SCHOOL_PAYMENT,
  SINGLE_SCHOOL_PAYMENT_ERROR
} from "../ActionTypes";
import { SCHOOLPAYMENT_LIST_API, ADD_SCHOOLPAYMENTREFUND_API, PAGE, LIMIT, SINGLE_SCHOOLPAYMENT_API } from "../constants";

const token = sessionStorage.getItem("token");
const header = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization",
  },
};

type AddRefundProps = {
  amount: string;
  created: string;
  refund_id: string;
  type: string;
  remark: string;
};


export const SchoolPaymentList = (
  status = "",
  page = PAGE,
  limit = LIMIT,
  token: string,
  order: string,
  search: string,
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(
        `${SCHOOLPAYMENT_LIST_API}?status=${status}&page=${page}&limit=${limit}&orderBy=${order}&search=${search}`,
        header
      )
      .then((res) => {
        dispatch({
          type: LIST_OF_SCHOOL_PAYMENT,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_SCHOOL_PAYMENT_ERROR,
        });
        toast.error(error.response.data.errorMsg);
        return error.response;
      });
  };
};

export const PaymentListBySchoolId = async (
  page = PAGE,
  limit = LIMIT,
  token: string,
  order: string,
  school_id: number,
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .get(`${SCHOOLPAYMENT_LIST_API}?page=${page}&limit=${limit}&orderBy=${order}&school_id=${school_id}`, header)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
};

const headers = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization",
  },
};


export const addRefund = async (id = "", userObj: any, token: string) => {
  headers.headers.Authorization = `Bearer ${token}`;
  return await axios
    .post(`${ADD_SCHOOLPAYMENTREFUND_API}/${id}`, userObj, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
};

export const SinglePaymentData = (id = "", token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${SINGLE_SCHOOLPAYMENT_API}/${id}`, header)
      .then((res) => {
        dispatch({
          type: SINGLE_SCHOOL_PAYMENT,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: SINGLE_SCHOOL_PAYMENT_ERROR,
        });
        toast.error(error.response.data.errorMsg);
        return error.response.data;
      });
  };
};

export const FetchRefundData = async (id = "", token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .get(`${SINGLE_SCHOOLPAYMENT_API}/${id}`, header)
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
      return error.response.data;
    });
};

export const deletePayments = async (id: string, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .delete(`${SCHOOLPAYMENT_LIST_API}/${id}`, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
};