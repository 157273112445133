import React, { useEffect, useState } from "react";
import { Box, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CommonHeadPart from "../../common-components/CommonHeadPart";
import FilterTabs from "../../common-components/FilterTabs";
import SchoolIcon from "../../svg_icons/school.svg";
import { InnerPageRoutes, ROUTES } from "../../constants/routes";
import { SchoolFilterTabs } from "../../constants/tabs";
import { Link, useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import * as Actions from "../../reducer-store/actions/schoolActions";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ToastMessage from "../../common-components/ToastMessage";
import { PAGE } from "../../reducer-store/constants";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Common.css";
import { SearchOutlined } from '@material-ui/icons';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import { TextField } from '@material-ui/core';
import IconButton from "@mui/material/IconButton";
import { CircularProgress, Typography } from "@mui/material";
import { SINGLE_SCHOOL_COMMUNICATION_ERROR } from "../../reducer-store/ActionTypes";

const useStyles = makeStyles((theme) => ({
  divInsideMain: {
    boxShadow: "0 0 5px #8e8e8e",
    borderRadius: "3px",
  },
  searchBar: {
    top: 0,
    borderColor: "black",
    "& hover": {
      borderColor: "black"
    },
    "& focused": {
      borderColor: "black"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black"
    }
  },
}));

const BreadCrumbSchool = [
  {
    labelText: "Home",
    linkUrl: ROUTES.home,
  },
  {
    labelText: "School",
    linkUrl: "",
  },
];

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .css-1ygcj2i-MuiTableCell-root": {
            fontweight: "bold",
            backgroundColor: '#FFFFFF'
          },
          "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
            display: "-webkit-box"
          },
          "& .tss-1fbujeu-MUIDataTableHeadCell-toolButton": {
            backgroundColor: '#FFFFFF'
          },
          "& .css-14e01q-MuiTableCell-root": {
            borderBottomColor: '#000',
            borderBottomWidth: 'thin',
          },
        },
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          lineHeight: "1.30 !important"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
          fontSize: "15px",
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
          fontSize: "15px",
        },
        head: {
          backgroundColor: "#FFFFFF"
        },
        footer: {
          backgroundColor: "#FFFFFF"
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#E53D12',
          borderRadius: '4px',
          color: 'white',
          marginRight: '25px',
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: "#F4F4F4",
          },
          // "&:nth-child(even)": {
          // },
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& .MuiTablePagination-displayedRows": {
            marginTop: "9%"
          },
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "5px",
        },
        gutterBottom: {
          fontFamily: "Arial",
          fontSize: "15px",
        }
      }
    },
  }
});

const School: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory();
  const classes = useStyles();
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const schoolList = useSelector((state: any) => state.school);
  const { schools } = schoolList;
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [page, setPage] = useState(PAGE);
  const [order, setOrder] = useState("desc");
  const [filter, setFilters] = useState("all");
  const [search, setSearch] = useState('');
  const [filterVal, setFilterVal] = useState('');
  const [searchApiData, setSearchApiData] = useState([]);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState(false);
  const { SchoolList } = bindActionCreators(Actions, dispatch);

  useEffect(() => {
    (async () => {
      dispatch({ type: SINGLE_SCHOOL_COMMUNICATION_ERROR });
      const schoolList = await SchoolList(page, rowsPerPage, token, order, search);
      renderDataTable(schoolList);
    })();
  }, [page, order, search]);

  const renderDataTable = (schoolList: any) => {
    const rowsDataTable = schoolList && schoolList.data.data.map((row: any, index: number) => {
      return {
        id: row?.id,
        name: row?.name,
        registration_date: moment(row?.created_at).format("YYYY-MM-DD"),
        status:
          <>{row.status === 0 ? (
            <Typography >Inactive</Typography>
          ) : (
            <Typography >Active</Typography>
          )}</>,
        payment_status: paymentStatus(row?.PaymentInfos, index),
        amount: <>
          {row.payment?.total_amount ? row.Plan?.symbol : "-"}{" "}
          {Number(row.payment?.total_amount).toFixed(2) || "-"}
        </>
      };
    });
    setData(rowsDataTable);
    setSearchApiData(rowsDataTable);
  };

  const HandleFilter = (e: any) => {
    if (e.target.value == "") {
      setData(searchApiData);
    } else {
      const filterData = searchApiData.filter((items: any) =>
        items.id.toString().includes(e.target.value.toString())
        || items.name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items.registration_date.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items.status.props.children.props.children.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items.amount.props.children[0].toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items.amount.props.children[2].toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
      );
      if (filterData.length > 0) {
        setData(filterData);
        setSearchData(false);
      } else {
        setData([]);
        setSearchData(true);
      }
    }
    setFilterVal(e.target.value);
  };

  const columns = [
    {
      label: 'Sr No',
      name: "id",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link to={{ pathname: `${InnerPageRoutes.newSchoolDetail}/${tableMeta.rowData[0]}`, state: `${tableMeta.rowData[1]}` }}>{tableMeta.rowIndex + 1}</Link>
        )
      }
    },
    {
      label: 'School Name',
      name: "name",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link to={{ pathname: `${InnerPageRoutes.newSchoolDetail}/${tableMeta.rowData[0]}`, state: `${tableMeta.rowData[1]}` }} > {value}</ Link>
        )
      }
    },
    {
      label: "Registration Date",
      name: "registration_date",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link to={{ pathname: `${InnerPageRoutes.newSchoolDetail}/${tableMeta.rowData[0]}`, state: `${tableMeta.rowData[1]}` }}>{value}</Link>
        )
      }
    },
    {
      label: 'Status',
      name: "status",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link to={{ pathname: `${InnerPageRoutes.newSchoolDetail}/${tableMeta.rowData[0]}`, state: `${tableMeta.rowData[1]}` }}>{value}</Link>
        )
      }
    },
    {
      label: 'Payment Status',
      name: "payment_status",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link to={{ pathname: `${InnerPageRoutes.newSchoolDetail}/${tableMeta.rowData[0]}`, state: `${tableMeta.rowData[1]}` }}>{value}</Link>
        )
      }
    },
    {
      label: 'Amount',
      name: "amount",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link to={{ pathname: `${InnerPageRoutes.newSchoolDetail}/${tableMeta.rowData[0]}`, state: `${tableMeta.rowData[1]}` }}>{value}</Link>
        )
      }
    },
  ];

  const options = {
    responsive: "vertical",
    search: false,
    print: false,
    viewColumns: false,
    filter: false,
    download: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    selectableRows: "none",
    rowsPerPageOptions: [],
    fixedHeader: false,
    textLabels: {
      body: {
        noMatch: searchData || data?.length == 0 && schools?.data?.length == 0 ?
          'Sorry, there is no matching data to display' :
          <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
            <CircularProgress sx={{ color: "#707070" }} />
          </Box >
        ,
      },
    },
    customToolbar: () => {
      return (<>
        <TextField
          variant="standard"
          placeholder="Search"
          onInput={(e: any) => HandleFilter(e)}
          value={filterVal}
          className={classes.searchBar}
          InputProps={{
            style: { color: "black" },
            endAdornment: (
              <IconButton>
                <SearchOutlined />
              </IconButton>
            ),
          }}
        />
      </>)
    },
  };

  const handleFilter = (newValue: string) => {
    setFilters(newValue);
  };

  const paymentStatus = (value: any, num: number) => {
    if (value === undefined)
      return "---";
    if (value.length > 0) {
      return "Received";
    } else {
      return "Failed";
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <>
      <ToastMessage />
      <CommonHeadPart
        componentText="School"
        Icon={SchoolIcon}
        buttonText=""
        BreadCrumbs={BreadCrumbSchool}
        urlLink=""
      />
      <div className={classes.divInsideMain}>

        <ThemeProvider theme={theme}>
          <MUIDataTable
            title={<FilterTabs
              SelectedFilter={filter}
              Filters={SchoolFilterTabs}
              HandleChange={handleFilter}
              isFilter={false}
              HandleSearch={handleSearch}
            />}
            data={data}
            columns={columns}
            options={options}
          />
        </ThemeProvider>

      </div>
    </>
  );
};

export default School;
