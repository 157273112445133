import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CommonHeadPart from "../../common-components/CommonHeadPart";
import { InnerPageRoutes, ROUTES } from "../../constants/routes";
import Divider from '@material-ui/core/Divider';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button, Typography } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap";
import CommunicationIcon from "../../svg_icons/communication.svg";
import ToastMessage from "../../common-components/ToastMessage";
import { useSelector } from "react-redux";
import moment from "moment";
import * as Actions from "../../reducer-store/actions/communicationAction";
import { Card } from "@material-ui/core";
import { fileSignedURL, allAdminList } from "../../reducer-store/actions/authActions";
import { CircularProgress } from "@mui/material";
import ImageComponent from "../../common-components/ImageComponent";

const useStyles = makeStyles(() => ({
	saveEmailButton: {
		borderRadius: "40px !important",
		backgroundColor: "#00A651 !important",
		border: "1px solid #00A651 !important",
		color: "#ffffff !important",
		padding: "0 35px !important",
		height: "40px !important",
		textTransform: "capitalize",
	},
	messageArea: {
		// height: '70vh',
	},
	avtar: {
		width: "40px",
		height: "40px",
		color: "#707070",
		backgroundColor: "#BEBEBE",
		borderColor: "#707070",
		borderRadius: 100,
		textAlign: "end",
		marginLeft: "10px"
	},
	userName1: {
		color: '#000',
		marginTop: "0",
		paddingLeft: "10px",
		textAlign: "start",
		textTransform: "capitalize",
	},
	listUser: {
		backgroundColor: "#FFD985",
		color: "#292929",
		borderRadius: 10
	},
	listuser1: {
		backgroundColor: "#84e0b1",
		color: "",
		borderRadius: 10
	},
	divInsideMain: {
		boxShadow: "0 0 5px #8e8e8e",
		borderRadius: "3px",
	},
	itemMsg: {
		marginLeft: "10px !important",
		background: "transparent !important",
		boxShadow: "none !important"
	},
	itemTimeZone: {
		background: "transparent !important",
		boxShadow: "none !important",
	},
	headingChat: {
		display: "flex",
		background: "#FFF",
		color: "#fff",
		alignItems: "center",
		padding: "15px 5px",
		borderRadius: "5px 5px 0 0",
		border: "solid 1px #ccc"
	},
	boxRowClass: {
		boxShadow: "0 0 1px #000",
		borderRadius: "0 0 5px 5px",
		margin: "0"
	},
	nameLogo: {
		boxShadow: "none !important",
		padding: "15px",
	},
	demo12: {
		boxShadow: "none !important",
		padding: "15px"
	},
	demoColor: {
		backgroundColor: "#84e0b1",
		borderRadius: "5px",
		padding: "10px"
	},
	demoColor2: {
		backgroundColor: "#FFD985",
		borderRadius: "5px",
		padding: "10px"
	},
	demoLeft: {
		textAlign: "left",
		marginTop: "5px"
	},
	demoRight: {
		textAlign: "right",
		marginTop: "5px"
	},
}));

const BreadCrumbCommunication = [
	{
		labelText: "Home",
		linkUrl: ROUTES.home,
	},
	{
		labelText: "Communication",
		linkUrl: ROUTES.communication,
	},
	{
		labelText: " Message Reply",
		linkUrl: "",
	},
];

const ReplyMessage: React.FC<any> = (props) => {
	const classes = useStyles();
	const { id }: any = useParams();
	const AuthToken = useSelector((state: any) => state.auth);
	const { token } = AuthToken;
	const { GetReplyMessaage } = Actions;
	const [messageData, setMessageData] = useState<any>(null);
	const [profileData, setProfileData] = useState<any>(null);
	const [allAdmin, setAllAdmin] = useState<any>([]);

	useEffect(() => {
		(async () => {
			let allAdminId: any = [];
			const adminList: any = await allAdminList("1", token);
			const { data } = adminList;
			data.map((d: any) => {
				allAdminId.push(d.id);
			})
			setAllAdmin(allAdminId);
		})();
	}, [])

	useEffect(() => {
		(async () => {
			const lastReplyMessage: any = await GetReplyMessaage(id, token);
			const { data } = lastReplyMessage;
			// const allMessages: any = await CommunicationList(1, 1000, token, "", "");
			// const { data: { data } } = allMessages;
			// const fetchSingleThread = data.filter((communicationInfo: any) => {
			// 	if (communicationInfo?.thread_id === parseInt(id))
			// 		return communicationInfo;
			// });
			setMessageData(data);
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (messageData?.School?.logo) {
				const newData: { url: string, msg: string, error: boolean } = await fileSignedURL(messageData && messageData?.School?.logo, token);
				const { url } = newData;
				setProfileData(url);
			}
		})();
	}, [messageData]);

	if (!messageData) {
		return (
			<Box style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
				<CircularProgress sx={{ color: '#707070' }} />
			</Box>
		)
	}

	return (
		<>
			<ToastMessage />
			<CommonHeadPart
				componentText="Communication"
				Icon={CommunicationIcon}
				buttonText=""
				urlLink=""
				BreadCrumbs={BreadCrumbCommunication}
			/>

			<Card className={classes.nameLogo}>
				<Row className="align-items-center">
					<Col md="6">
						<div className="align-items-center" style={{ display: "flex" }}>
							<Link to={ROUTES.communication}>
								<KeyboardBackspaceIcon style={{ marginLeft: 10, color: "#000" }} fontSize="large" />
							</Link>
							<Image className={classes.avtar} src={profileData} />
							<Typography variant="body1" className={classes.userName1}>
								{messageData?.School?.name} / {messageData?.subject}
							</Typography>
						</div>
					</Col>
				</Row>
			</Card>

			<Divider />

			<Card className={classes.demo12}>
				<div className={classes.messageArea}>
					{
						messageData?.threads?.length === 0 ? "Not found" : messageData?.threads?.map((thread: any, index: number) =>
							<Row key={`${thread?.body}_${thread?.id}_${index}`}>
								<div>
									{
										!allAdmin?.includes(thread.sent_by) ? <>
											<Row>
												<Col md="6">
													<div className={classes.demoColor}>
														<Typography>
															{thread?.body}
														</Typography>
														{thread?.MessageAttachments.map((data: any) => {
															return (
																<ImageComponent signedUrl={data?.attachment_key} type={data?.attachment_type} fileName={data?.attachment_name} />
															)
														})}
													</div>
												</Col>
												<Col md="6">
												</Col>
												<Col md="12" className={classes.demoLeft}>
													<Typography>
														{moment(thread?.sent_timestamp).format("MMM DD, YYYY | h:mm a")}
													</Typography>
												</Col>
											</Row>
										</> : <>
											<Row>
												<Col md="6">
												</Col>
												<Col md="6">
													<div className={classes.demoColor2}>
														<Typography>
															{thread?.body}
														</Typography>
														{thread?.MessageAttachments.map((data: any) => {
															return (
																<ImageComponent signedUrl={data?.attachment_key} type={data?.attachment_type} fileName={data?.attachment_name} />
															)
														})}
													</div>
												</Col>
												<Col md="12" className={classes.demoRight}>
													<Typography>
														{moment(thread?.sent_timestamp).format("MMM DD, YYYY | h:mm a")}
													</Typography>
												</Col>
											</Row>
										</>
									}
								</div>
							</Row>
						)
					}
				</div>
				<Divider />
				<Row style={{ float: "right" }} className="mt-4" >
					<Link to={{ pathname: `${InnerPageRoutes.messageReply}/${messageData?.threads[messageData?.threads?.length - 1]?.id}`, state: { messageData } }}>
						<Button
							type="button"
							variant="contained"
							className={classes.saveEmailButton}
						>
							Reply
						</Button>
					</Link>
				</Row>
			</Card>

		</>
	);
}

export default ReplyMessage;



