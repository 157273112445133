import React, { useEffect, useState } from "react";
import { TableRow, TextField, Typography, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@mui/icons-material/Edit";
import CommonHeadPart from "../../common-components/CommonHeadPart";
import FilterTabs from "../../common-components/FilterTabs";
import ProgramIcon from "../../svg_icons/program.svg";
import "../Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { InnerPageRoutes, ROUTES } from "../../constants/routes";
import { ProgramsFilterTabs } from "../../constants/tabs";
import TableCell from "@mui/material/TableCell";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import * as Actions from "../../reducer-store/actions/programsAction";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { PAGE } from "../../reducer-store/constants";
import ToastMessage from "../../common-components/ToastMessage";
import { Link } from "react-router-dom";
import { SearchOutlined } from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import Certificate from "../../svg_icons/certi.png";
import { CircularProgress } from "@mui/material";
import { LIST_OF_COURSE_ERROR, LIST_OF_MODUAL_ERROR, LIST_OF_TOPIC_ERROR } from "../../reducer-store/ActionTypes";
import { Visibility } from "@mui/icons-material";
import { yellow } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { Col, Row } from "react-bootstrap";

import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles(() => ({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  },
  divInsideMain: {
    boxShadow: "0 0 5px #8e8e8e",
    borderRadius: "3px",
  },
  searchBar: {
    top: 0,
    borderColor: "black",
    "& hover": {
      borderColor: "black"
    },
    "& focused": {
      borderColor: "black"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black"
    }
  },
  dialogContent: {
    textAlign: "center",
    justifyContent: 'center',
    alignItems: "center"
  },
}));

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .css-1ygcj2i-MuiTableCell-root": {
            fontweight: "bold",
            backgroundColor: '#FFFFFF'
          },
          "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
            display: "-webkit-box"
          },
          "& .tss-1fbujeu-MUIDataTableHeadCell-toolButton": {
            backgroundColor: '#FFFFFF'
          },
          "& .css-14e01q-MuiTableCell-root": {
            borderBottomColor: '#000',
            borderBottomWidth: 'thin',
          },
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
          fontSize: "15px",
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
          fontSize: "15px",
        },
        head: {
          backgroundColor: "#FFFFFF"
        },
        footer: {
          backgroundColor: "#FFFFFF"
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#E53D12',
          borderRadius: '4px',
          color: 'white',
          marginRight: '25px',
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: "#F4F4F4"
          },
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& .MuiTablePagination-displayedRows": {
            marginTop: "9%"
          },
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          fontFamily: "Arial",
          fontSize: "15px",
        }
      }
    }
  }
});

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Programs: React.FC = () => {
  const classes = useStyles();
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const programList = useSelector((state: any) => state.program);
  const { programs } = programList;
  const [filterVal, setFilterVal] = useState('');
  const [searchApiData, setSearchApiData] = useState([]);
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [page, setPage] = useState(PAGE);
  const [filter, setFilters] = useState("all");
  const dispatch = useDispatch();
  const { ProgramList } = bindActionCreators(Actions, dispatch);
  const { getProgramDetails } = Actions;
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("desc");
  const [searchData, setSearchData] = useState(false);
  const [programName, setProgramName] = useState('');
  const [programHours, setProgramHours] = useState<any>('');
  const [viewProgramInfos, setViewProgramInfos] = useState<any>([]);
  const [openProgramView, setProgramView] = React.useState(false);

  const BreadCrumbProgram = [
    {
      labelText: "Home",
      linkUrl: ROUTES.home,
    },
    {
      labelText: `${filter === "1" ? "Active Programs" : filter === "0" ? "Inactive Programs" : "Program"}`,
      linkUrl: "",
    },
  ];

  const handleFilter = (newValue: string) => {
    setFilters(newValue);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleClose = () => {
    setProgramView(false);
  };

  useEffect(() => {
    (async () => {
      let platformProgram: any = [];

      dispatch({ type: LIST_OF_MODUAL_ERROR });
      dispatch({ type: LIST_OF_COURSE_ERROR });
      dispatch({ type: LIST_OF_TOPIC_ERROR });

      const programList: any = await ProgramList(filter, page, rowsPerPage, token, order, search);

      programList?.data?.data?.map((data: any) => {
        if (!data?.school_id) {
          platformProgram.push(data);
        }
      })
      renderDataTable(platformProgram);
    })();
  }, [page, filter, order, search]);

  useEffect(() => {
    sessionStorage.removeItem("program_id");
    sessionStorage.removeItem("program_name");
    sessionStorage.removeItem("program_code");
    sessionStorage.removeItem("vehicle_type");
    sessionStorage.removeItem("description");
  }, [])

  const renderDataTable = (programList: any) => {
    const rowsDataTable = programList && programList?.map((row: any) => {
      return {
        id: row?.id,
        name_code: <> {row?.name}
          <br />
          {row?.program_code !== null ? row?.program_code : "---"}
        </>,
        hours: row?.total_duration_minutes !== null ? row?.total_duration_minutes / 60 : "---",
        description: row?.description ? row?.description : "---",
        courses: row?.courses ? row?.courses : "---",
        credential: row?.melt_compatible === true ? "Certificate" : "Non Certificate",
        certificate: row?.melt_compatible === true ? <img src={Certificate} height='35px' width="50px" alt="fvrv" /> : null,
        edit: <>
          <Visibility sx={{ color: yellow[700], marginRight: '15px', cursor: 'pointer' }} titleAccess="View Program Details" onClick={() => handleProgramView(row)} />
          <Link to={`${InnerPageRoutes.editPrograms}/${row.id}`}>
            <EditIcon /* onClick={buttonClick}  */ />
          </Link>
        </>,
      };
    });
    setData(rowsDataTable);
    setSearchApiData(rowsDataTable);
  };

  const handleProgramView = async (row: any) => {
    if (row) {
      setProgramName(row?.name);
      setProgramHours(Number(row?.total_duration_minutes / 60))
      const programData: any = await getProgramDetails(row?.id, token);
      if (programData?.data?.ProgramModules?.length > 0) {
        setViewProgramInfos(programData?.data?.ProgramModules);
        setProgramView(true);
      }
    }
  }

  const HandleFilter = (e: any) => {
    if (e.target.value == "") {
      setData(searchApiData);
    } else {
      const filterData = searchApiData.filter((items: any) =>
        items?.id?.toString().includes(e.target.value.toString())
        || items?.hours?.toString().includes(e.target.value.toString())
        || items?.name_code?.props?.children[0]?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items?.name_code?.props?.children[2]?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items?.description?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items?.courses?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items?.certificate?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items?.credential?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
      );
      if (filterData?.length > 0) {
        setData(filterData);
        setSearchData(false);
      } else {
        setData([]);
        setSearchData(true);
      }
    }
    setFilterVal(e.target.value);
  };

  const columns = [
    {
      label: 'Sr No',
      name: "id",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <span>{tableMeta.rowIndex + 1}</span>
        )
      }
    },
    {
      label: 'Name & Code',
      name: "name_code",
      options: {
        sort: false
      }
    },
    {
      label: 'Hours',
      name: "hours",
    },
    {
      label: 'Description',
      name: "description",
    },
    {
      label: 'Courses',
      name: "courses",
    },
    {
      label: 'Credential',
      name: "credential",
      options: {
        sort: false
      }
    },
    {
      label: ' ',
      name: "certificate",
      options: {
        sort: false,
      }
    },
    {
      label: 'Edit',
      name: "edit",
      options: {
        sort: false
      }
    },
  ];

  const options = {
    responsive: "vertical",
    search: false,
    print: false,
    viewColumns: false,
    filter: false,
    download: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    selectableRows: "none",
    rowsPerPageOptions: [],
    fixedHeader: false,
    textLabels: {
      body: {
        noMatch: searchData || data?.length == 0 && programs?.data?.length == 0 ?
          'Sorry, there is no matching data to display' :
          <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
            <CircularProgress sx={{ color: "#707070" }} />
          </Box >
        ,
      },
    },
    customToolbar: () => {
      return (<>
        <TextField
          variant="standard"
          placeholder="Search"
          onInput={(e: any) => HandleFilter(e)}
          value={filterVal}
          className={classes.searchBar}
          InputProps={{
            style: { color: "black" },
            endAdornment: (
              <IconButton>
                <SearchOutlined />
              </IconButton>
            ),
          }}
        />
      </>)
    },
  };

  const CollapsedRow = ({ row, childrenRow }: any) => {
    const [openFunction, setOpenFunction] = React.useState(false);
    return (
      <React.Fragment>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow style={{ backgroundColor: "#707070" }}>
              <TableCell></TableCell>
              <TableCell style={{ color: "#FFFFFF" }}>Course Name</TableCell>
              <TableCell style={{ color: "#FFFFFF" }}>Total Hours</TableCell>
              <TableCell style={{ color: "#FFFFFF" }}>Total inclass training hours</TableCell>
              <TableCell style={{ color: "#FFFFFF" }}>Total inyard training hours</TableCell>
              <TableCell style={{ color: "#FFFFFF" }}>Total incab training hours</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={childrenRow?.id}>
              <TableCell style={{ width: "62px" }}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenFunction(!openFunction)}
                >
                  {openFunction ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row">
                {childrenRow?.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {(+childrenRow.total_duration_minutes) / 60}
              </TableCell>
              <TableCell component="th" scope="row">
                {(+childrenRow.total_inclass_training_minutes) / 60}
              </TableCell>
              <TableCell component="th" scope="row">
                {(+childrenRow.total_inyard_training_minutes) / 60}
              </TableCell>
              <TableCell component="th" scope="row">
                {(+childrenRow.total_incab_training_minutes) / 60}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse
              in={openFunction}
              timeout="auto"
              unmountOnExit
            >
              {childrenRow?.ModuleCourseTopics?.length > 0 ?
                <Box padding={1}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#A2A2A2" }}>
                        <TableCell></TableCell>
                        <TableCell style={{ color: "#FFFFFF" }}>Topic Name</TableCell>
                        <TableCell style={{ color: "#FFFFFF" }}>Total Hours</TableCell>
                        <TableCell style={{ color: "#FFFFFF" }}>Total inclass training hours</TableCell>
                        <TableCell style={{ color: "#FFFFFF" }}>Total inyard training hours</TableCell>
                        <TableCell style={{ color: "#FFFFFF" }} >Total incab training hours</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {childrenRow?.ModuleCourseTopics?.map((childrenRow: any) => (
                        <TableRow key={childrenRow?.id}>
                          <TableCell></TableCell>
                          <TableCell component="th" scope="row">
                            {childrenRow?.name}
                          </TableCell>
                          <TableCell>{(+childrenRow.total_duration_minutes) / 60}</TableCell>
                          <TableCell>{(+childrenRow.total_inclass_training_minutes) / 60}</TableCell>
                          <TableCell>{(+childrenRow.total_inyard_training_minutes) / 60}</TableCell>
                          <TableCell>{(+childrenRow.total_incab_training_minutes) / 60}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box> : null}
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  function RowData(props: any) {
    const [openModule, setOpenModule] = useState(false);

    const { row }: any = props;
    return (<>
      <React.Fragment>
        <TableRow className={classes.root} key={row.id}>
          <TableCell style={{ width: "62px" }} key={row.id} >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenModule(!openModule)}
            >
              {openModule ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{row.name}</TableCell>
          <TableCell>{(+row.total_duration_minutes) / 60}</TableCell>
          <TableCell>{(+row.total_inclass_training_minutes) / 60}</TableCell>
          <TableCell>{(+row.total_inyard_training_minutes) / 60}</TableCell>
          <TableCell>{(+row.total_incab_training_minutes) / 60}</TableCell>
        </TableRow>
        <TableRow key={row.id}>
          <TableCell key={row.id} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse key={row.id} in={openModule} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {row?.Courses?.map((childrenRow: any) => (
                      <CollapsedRow row={row} childrenRow={childrenRow} />
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    </>)
  }

  return (
    <>
      <ToastMessage />
      <CommonHeadPart
        componentText="Programs"
        Icon={ProgramIcon}
        buttonText="Add New Program"
        BreadCrumbs={BreadCrumbProgram}
        urlLink={InnerPageRoutes.addNewPrograms}
      />
      <div className={classes.divInsideMain}>
        <ThemeProvider theme={theme}>
          <MUIDataTable
            title={<FilterTabs
              SelectedFilter={filter}
              Filters={ProgramsFilterTabs}
              HandleChange={handleFilter}
              isFilter={false}
              HandleSearch={handleSearch}
            />}
            data={data}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </div>

      <BootstrapDialog
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="customized-dialog-title"
        open={openProgramView}
      >
        {/* <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          View Program Details
        </BootstrapDialogTitle> */}

        <DialogContent dividers className={classes.dialogContent} >

          <Row className="mb-3">
            <Col lg="6" sm="6" xs="6">
              <Typography align="left">
                <b>Program Name:</b> {programName}
              </Typography>
            </Col>

            <Col lg="6" sm="6" xs="6">
              <Typography align="left">
                <b>Total Program Hours:</b> {programHours}
              </Typography>
            </Col>
          </Row>

          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow style={{ background: "#404040" }}>
                  <TableCell></TableCell>
                  <TableCell style={{ color: "#FFFFFF" }}>Module Name</TableCell>
                  <TableCell style={{ color: "#FFFFFF" }}>Total hours</TableCell>
                  <TableCell style={{ color: "#FFFFFF" }}>Total inclass training hours</TableCell>
                  <TableCell style={{ color: "#FFFFFF" }}>Total inyard training hours</TableCell>
                  <TableCell style={{ color: "#FFFFFF" }}>Total incab training hours</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {viewProgramInfos?.map((row: any, index: number) => (
                  <RowData key={row.id} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </BootstrapDialog >
    </>
  );
};

export default Programs;
