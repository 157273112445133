export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SELECT_OTP_PLATFORM = "SELECT_OTP_PLATFORM";
export const OTP_SENT = "LOGIN_OTP_SENT_SUCCESS";
export const OTP_SENT_ERROR = "LOGIN_OTP_SENT_ENRRO";
export const RESET_OTP_SENT = "RESET_OTP_SENT_SUCCESS";
export const RESET_OTP_SENT_ERROR = "RESET_OTP_SENT_ENRRO";
export const OTP_VERIFY = "LOGIN_OTP_VERIFY_SUCCESS";
export const OTP_VERIFY_ERROR = "LOGIN_OTP_VERIFY_ERROR";
export const RESET_OTP_VERIFY = "RESET_OTP_VERIFY_SUCCESS";
export const RESET_OTP_VERIFY_ERROR = "RESET_OTP_VERIFY_ERROR";
export const UPDATE_RESET_PASSWORD = "RESET_PASSWORD_SUCCESS";
export const UPDATE_RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESEND_OTP = "LOGIN_OTP_RESEND_SUCCESS";
export const RESEND_OTP_ERROR = "LOGIN_OTP_RESEND_ERROR";
export const USER_LOADING_SUCCESS = "USER_LOADING_SUCCESS";
export const USER_LOADING_ERROR = "USER_LOADING_ERROR";
export const USER_LOGOUT = "USER_LOGOUT";

export const GET_ADMIN_UPDATED_DATA = "GET_ADMIN_UPDATED_DATA";
export const GET_ADMIN_UPDATED_DATA_ERROR = "GET_ADMIN_UPDATED_DATA_ERROR";

export const REGISTER_ANOTHER_ACCOUNT = "REGISTER_ANOTHER_ACCOUNT";
export const REGISTER_ANOTHER_ACCOUNT_FAIL = "REGISTER_ANOTHER_ACCOUNT_FAIL";

export const LIST_OF_SCHOOL = "List_OF_SCHOOL";
export const LIST_OF_SCHOOL_ERROR = "List_OF_SCHOOL_ERROR";
export const SINGLE_SCHOOL_INFO = "SINGLE_SCHOOL_INFO";
export const SINGLE_SCHOOL_ERROR = "SINGLE_SCHOOL_ERROR";
export const SINGLE_SCHOOL_COMMUNICATION = "SINGLE_SCHOOL_COMMUNICATION";
export const SINGLE_SCHOOL_COMMUNICATION_ERROR =
  "SINGLE_SCHOOL_COMMUNICATION_ERROR";

export const LIST_OF_SCHOOL_PAYMENT = "List_OF_SCHOOL_PAYMENT";
export const LIST_OF_SCHOOL_PAYMENT_ERROR = "List_OF_SCHOOL_PAYMENT_ERROR";
export const SINGLE_SCHOOL_PAYMENT = "List_SINGLE_SCHOOL_PAYMENT";
export const SINGLE_SCHOOL_PAYMENT_ERROR = "List_OF_SINGLE_SCHOOL_PAYMENT_ERROR";

export const LIST_OF_SCHOOL_COUNT = "LIST_OF_SCHOOL_COUNT";
export const LIST_OF_SCHOOL_COUNT_ERROR = "LIST_OF_SCHOOL_COUNT_ERROR";

export const ADD_SCHOOL_SUCCESS = "List_OF_SCHOOL";
export const ADD_SCHOOL_ERROR = "List_OF_SCHOOL_ERROR";

export const UPDATE_SCHOOL_SUCCESS = "UPDATE_SCHOOL_SUCCESS";
export const UPDATE_SCHOOL_ERROR = "UPDATE_SCHOOL_ERROR";

export const LIST_OF_PLAN = "List_OF_PLAN";
export const LIST_OF_PLAN_ERROR = "List_OF_PLAN_ERROR";

export const LIST_OF_PROGRAMS = "List_OF_PROGRAMS";
export const LIST_OF_PROGRAMS_ERROR = "List_OF_PROGRAMS_ERROR";

export const LIST_OF_MODUAL = "List_OF_MODUAL";
export const LIST_OF_MODUAL_ERROR = "List_OF_MODUAL_ERROR";

export const LIST_OF_COURSE = "List_OF_COURSE";
export const LIST_OF_COURSE_ERROR = "List_OF_COURSE_ERROR";

export const LIST_OF_TOPIC = "List_OF_TOPIC";
export const LIST_OF_TOPIC_ERROR = "List_OF_TOPIC_ERROR";

export const LIST_OF_COMMUNICATION = "LIST_OF_COMMUNICATION";
export const LIST_OF_COMMUNICATION_ERROR = "LIST_OF_COMMUNICATION_ERROR";

export const COUNT_OF_UNREAD_COMMUNICATION = "COUNT_OF_UNREAD_COMMUNICATION";
export const COUNT_OF_UNREAD_COMMUNICATION_ERROR = "COUNT_OF_UNREAD_COMMUNICATION_ERROR";

export const LIST_OF_LICENSE = "List_OF_LICENSE";
export const LIST_OF_LICENSE_ERROR = "List_OF_LICENSE_ERROR";
export const SINGLE_LICENSE_INFO = "List_OF_LICENSE";
export const SINGLE_LICENSE_ERROR = "List_OF_LICENSE_ERROR";

export const LIST_OF_INVOICE = "List_OF_INVOICE";
export const LIST_OF_INVOICE_ERROR = "List_OF_INVOICE_ERROR";
export const SINGLE_INVOICE_INFO = "List_OF_INVOICE";
export const SINGLE_INVOICE_ERROR = "List_OF_INVOISE_ERROR";

export const LIST_OF_DASHBOARD = "LIST_OF_DASHBOARD";
export const LIST_OF_DASHBOARD_ERROR = "LIST_OF_DASHBOARD_ERROR";

export const LIST_OF_PROVINCE_TAX = "LIST_OF_PROVINCE_TAX";
export const LIST_OF_PROVINCE_TAX_ERROR = "LIST_OF_PROVINCE_TAX_ERROR";

export const LIST_OF_NOTIFICATION = "LIST_OF_NOTIFICATION";
export const LIST_OF_NOTIFICATION_ERROR = "LIST_OF_NOTIFICATION_ERROR";

export const LIST_OF_PLAN_HISTORY = "LIST_OF_PLAN_HISTORY";
export const LIST_OF_PLAN_HISTORY_ERROR = "LIST_OF_PLAN_HISTORY_ERROR";