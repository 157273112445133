import React, { useState } from 'react'
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Box, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Forgot_Pass from "../../svg_icons/forgot_pass.svg";
import { ROUTES } from "../../constants/routes";
import { isRequiredField } from "../FormComponent/FormValidator";
import { InputField } from "../FormComponent/FormFieldsComponent";
import * as authAction from "../../reducer-store/actions/authActions";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ToastMessage from "../ToastMessage";
import {
  OTP_SENT_SUCCESSFULLY,
} from "../../reducer-store/messages/actionMessages";

import CommonHeadPart from '../../common-components/CommonHeadPart';
import DashboardIcon from "../../svg_icons/dashboard.svg";

const BreadCrumbDashboard = [{
  labelText: "Home",
  linkUrl: ROUTES.home
}, {
  labelText: "Change Password",
  linkUrl: ""
}]

interface StateProps {
  auth: {
    user: UsedInfoProps
  }
}

interface UsedInfoProps {
  email: string;
  mobile: string;
}

const useStyles = makeStyles((theme) => ({
  sectionTop: {
    marginTop: "150px",
    minHeight: "80vh",
    marginBottom: "150px",
  },
  loginTitle: {
    fontSize: "44px",
    textAlign: "center",
    marginBottom: "40px",
    fontWeight: 700,
    color: "#000000",
  },
  forgotpass: {
    fontSize: "20px",
    textAlign: "right",
    marginBottom: "40px",
    fontWeight: 600,
    color: "#000000",
  },
  forgotpass1: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: "#000000",
      textDecoration: "none",
    },
  },
  createacc: {
    textDecoration: "none",
    fontSize: "20px",
    fontWeight: 600,
    color: "black",
    "&:hover": {
      color: "#000000",
      textDecoration: "none",
    },
  },
  CardContent: {
    minHeight: "200px",
  },
  radioButton: {
    display: "flex",
    flexDirection: "inherit",
    marginBottom: "10px",
  },
  saveEmailButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #393939 !important",
    color: "#393939 !important",
    padding: "0 35px !important",
    height: "40px !important",
    textTransform: "capitalize",
    fontSize: "0.875rem",
  },
  saveEmailButton1: {
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontSize: '15px',
    textTransform: 'capitalize'
  },
}));

const ChangePassword: React.FC = () => {
  const classes = useStyles();
  const Auth = useSelector((state: StateProps) => state.auth)
  const { user } = Auth;
  const { email, mobile } = user;
  const [isEmail, setIsEmail] = useState("email");
  const [info, setInfo] = useState({ email: email ? email : "", mobile: mobile ? mobile : "" });
  const [isSubmit, setIsSubmit] = useState(false);
  const [loaded, setLoader] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const { sentVerificationOTPCode, updateOtpPlatFormValue } =
    bindActionCreators(authAction, dispatch);
  let userObj;

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      (isEmail === "email" && isRequiredField(info.email)) ||
      (isEmail === "email" && isRequiredField(info.mobile))
    ) {
      setIsSubmit(false);
      setLoader(true);
      if (isEmail === "email") {
        userObj = {
          email: info.email,
        };
      } else {
        userObj = {
          phone: info.mobile,
        };
      }
      let resData: any = await sentVerificationOTPCode(userObj, isEmail);
      if (!resData.error) {
        setIsEmail("email");
        toast.success(OTP_SENT_SUCCESSFULLY);
        setTimeout(() => {
          setLoader(false);
          history.push(ROUTES.changePasswordOTP);
        }, 2000);
      }
      setLoader(false);
      return;
    }
    setIsValidValues(false);
  };

  return (
    <>
      {/* <ToastMessage />
      <section className={classes.sectionTop}>
        <Container className="mt-4">
          <Row className="stap-form">
            <Typography
              className={classes.loginTitle}
              variant="h5"
              component="div"
            >
              Change Password?
            </Typography>
          </Row>
          <Row className="stap-form">
            <Col lg={{ span: 6, offset: 3 }} sm="12">
              <Box>
                <Card className="Main_Card" variant="outlined">
                  {loaded && <LinearProgress />}
                  <Form onSubmit={SubmitHandler}>
                    <CardContent className={classes.CardContent}>
                      <RadioGroup
                        aria-label="type"
                        defaultValue={isEmail}
                        name="radio-buttons-group"
                        className={`Radio ${classes.radioButton}`}
                      >
                        <FormControlLabel
                          name="email"
                          value="email"
                          control={<Radio />}
                          label="Email Id"
                          onChange={(e: any) =>
                            setIsEmail(e.target.value)
                          }
                        />
                        <FormControlLabel
                          name="phone"
                          value="phone"
                          control={<Radio />}
                          label="Phone Number"
                          onChange={(e: any) =>
                            setIsEmail(e.target.value)
                          }
                        />
                      </RadioGroup>
                      {
                        isEmail === "email" ? <InputField
                          type="email"
                          lable="Email"
                          name="email"
                          rows={10}
                          multiline={false}
                          placeHolder="Enter email"
                          className="form-textfield-styles"
                          value={info.email}
                          handleChange={(e) => setInfo({ ...info, [e.target.name]: e.target.value })}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="Email field is required"
                          disabled={true}
                        /> :
                          <InputField
                            type="text"
                            lable="Mobile"
                            name="mobile"
                            rows={10}
                            multiline={false}
                            placeHolder="Enter mobile"
                            className="form-textfield-styles"
                            value={info.mobile}
                            handleChange={(e) => setInfo({ ...info, [e.target.name]: e.target.value })}
                            isValidValue={isValidValues}
                            isSubmit={isSubmit}
                            requiredMessage="Mobile field is required"
                            disabled={true}
                          />
                      }

                      <div className="text-center">
                        <Link
                          to={ROUTES.dashboard}
                        >
                          <Button
                            type="button"
                            variant="contained"
                            className={classes.saveEmailButton}
                          >
                            Back
                          </Button>
                        </Link>{" "}
                        <Button type="submit" variant="contained" className={classes.saveEmailButton1}>
                          Get OTP
                        </Button>
                      </div>
                    </CardContent>
                  </Form>
                </Card>
              </Box>
            </Col>
          </Row>
        </Container>
      </section> */}

      <ToastMessage />
      <CommonHeadPart
        componentText="Change Password"
        Icon={DashboardIcon}
        buttonText=""
        urlLink="/"
        BreadCrumbs={BreadCrumbDashboard}
      />
      <div>
        <Container className="mt-4">
          <Row className="stap-form">
            <Typography
              className={classes.loginTitle}
              variant="h5"
              component="div"
            >
              Change Password?
            </Typography>
          </Row>
          <Row className="stap-form">
            <Col lg={{ span: 6, offset: 3 }} sm="12">
              <Box>
                <Card className="Main_Card" variant="outlined">
                  {loaded && <LinearProgress />}
                  <Form onSubmit={SubmitHandler}>
                    <CardContent className={classes.CardContent}>
                      <RadioGroup
                        aria-label="type"
                        defaultValue={isEmail}
                        name="radio-buttons-group"
                        className={`Radio ${classes.radioButton}`}
                      >
                        <FormControlLabel
                          name="email"
                          value="email"
                          control={<Radio />}
                          label="Email Id"
                          onChange={(e: any) =>
                            setIsEmail(e.target.value)
                          }
                        />
                        <FormControlLabel
                          name="phone"
                          value="phone"
                          control={<Radio />}
                          label="Phone Number"
                          onChange={(e: any) =>
                            setIsEmail(e.target.value)
                          }
                        />
                      </RadioGroup>
                      {
                        isEmail === "email" ? <InputField
                          type="email"
                          lable="Email"
                          name="email"
                          rows={10}
                          multiline={false}
                          placeHolder="Enter email"
                          className="form-textfield-styles"
                          value={info.email}
                          handleChange={(e) => setInfo({ ...info, [e.target.name]: e.target.value })}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="Email field is required"
                          disabled={true}
                        /> :
                          <InputField
                            type="text"
                            lable="Mobile"
                            name="mobile"
                            rows={10}
                            multiline={false}
                            placeHolder="Enter mobile"
                            className="form-textfield-styles"
                            value={info.mobile}
                            handleChange={(e) => setInfo({ ...info, [e.target.name]: e.target.value })}
                            isValidValue={isValidValues}
                            isSubmit={isSubmit}
                            requiredMessage="Mobile field is required"
                            disabled={true}
                          />
                      }

                      <div className="text-center">
                        <Link
                          to={ROUTES.dashboard}
                        >
                          <Button
                            type="button"
                            variant="contained"
                            className={classes.saveEmailButton}
                          >
                            Back
                          </Button>
                        </Link>{" "}
                        <Button type="submit" variant="contained" className={classes.saveEmailButton1}>
                          Get OTP
                        </Button>
                      </div>
                    </CardContent>
                  </Form>
                </Card>
              </Box>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ChangePassword
