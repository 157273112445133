import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
/* import TextField from "@material-ui/core/TextField"; */
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ROUTES } from "../../../../../constants/routes";
/* import {
  CancelButtonComponent,
  SaveButtonComponent,
} from "../../../../../common-components/ButtonComponent"; */
import { SchoolSetupStateProps } from "../../../../../ts/FormType";
/* import {
  InputField,
  InputSelectField,
} from "../../../../../common-components/FormComponent/FormFieldsComponent"; */
import { isRequiredField } from "../../../../../common-components/FormComponent/FormValidator";
/* import { Link } from "react-router-dom"; */
import StickyNote from "./StickyNote";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fileSignedURL } from "../../../../../reducer-store/actions/authActions";
import { bindActionCreators } from "redux";
import * as Actions from "../../../../../reducer-store/actions/schoolActions";
import { useParams } from "react-router-dom";
import { Box } from "@material-ui/core";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles(() => ({
  RowSectionHeading: {
    marginBottom: "15px",
  },
  imageParentDiv: {
    width: "180px",
    height: "180px",
    border: "1px solid #707070",
    borderRadius: "50%"
  },
  avtar: {
    width: "100%",
    height: "100%",
    borderRadius: "50%"
  },
  disabledProfile: {
    color: "#CCC"
  },
  enabledProfile: {
    color: ""
  }
}));

type ProfileProps = {
  profileInfo: any;
};

const Profile: React.FC<ProfileProps> = ({ profileInfo }) => {
  const params: any = useParams();
  const { id } = params;
  const classes = useStyles();
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const dispatch = useDispatch();
  const { getStudentDetail } = bindActionCreators(Actions, dispatch)
  const studentcount = useSelector((state: any) => state.school);
  const { studentCount } = studentcount

  const [schoolSetup, setSchoolSetup] = useState<SchoolSetupStateProps>({
    name: profileInfo ? profileInfo.name : "",
    email: profileInfo ? profileInfo.email : "",
    phone: profileInfo?.phone || "--",
    address_1: profileInfo ? profileInfo.address_1 : "",
    address_2: profileInfo ? profileInfo.address_2 : "",
    business_number: profileInfo ? profileInfo.business_number : "",
    created_at: profileInfo ? profileInfo.created_at : "",
    logo: profileInfo ? profileInfo.logo : "",
    mobile: profileInfo ? profileInfo.mobile : "",
    whatsapp: profileInfo ? profileInfo.whatsapp : "",
    domain: profileInfo ? profileInfo.domain : "",
    document: null,
    school_user_1: "",
    school_user_2: "",
    School_id: "",
    currency: "",
    password: "",
    salutation: profileInfo ? profileInfo.title : "",
    firstname: profileInfo ? profileInfo.first_name : "",
    middlename: profileInfo ? profileInfo.middle_name : "",
    lastname: profileInfo ? profileInfo.last_name : "",
    province: profileInfo ? profileInfo.state : "",
    status: profileInfo ? profileInfo.status : "",
    registry_id: profileInfo ? profileInfo.registry_id : "",
    tin_number: profileInfo ? profileInfo.tin_number : "",
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const [profilePic, setProfilePic] = useState("");

  const navigateToExternalUrl = async (urlName: string, shouldOpenNewTab: boolean = true) => {
    const newData: { url: string, msg: string, error: boolean } = await fileSignedURL(urlName && urlName, token);
    const { url } = newData;
    setProfilePic(url);
  }

  useEffect(() => {
    if (profileInfo) {
      setSchoolSetup({
        ...schoolSetup,
        name: profileInfo.name,
        email: profileInfo.email,
        phone: profileInfo?.phone || "--",
        address_1: profileInfo.address_1,
        address_2: profileInfo.address_2,
        business_number: profileInfo ? profileInfo.business_number : "",
        created_at: profileInfo ? profileInfo.created_at : "",
        logo: profileInfo ? profileInfo.logo : "",
        mobile: profileInfo ? profileInfo.mobile : "",
        whatsapp: profileInfo ? profileInfo.whatsapp !== "" ? profileInfo.whatsapp : "--" : "--",
        School_id: profileInfo ? profileInfo.id : "",
        domain: profileInfo ? profileInfo.domain : "",
        salutation: profileInfo ? profileInfo.title !== null ? profileInfo.title : "-" : "",
        firstname: profileInfo ? profileInfo.first_name !== null ? profileInfo.first_name : "-" : "",
        middlename: profileInfo ? profileInfo.middle_name !== null ? profileInfo.middle_name : "-" : "",
        lastname: profileInfo ? profileInfo.last_name !== null ? profileInfo.last_name : "-" : "",
        province: profileInfo ? profileInfo.state : "",
        status: profileInfo ? profileInfo.status : "",
        registry_id: profileInfo ? profileInfo.registry_id : "",
        tin_number: profileInfo ? profileInfo.tin_number : "",
      });
      if (profileInfo?.logo) {
        navigateToExternalUrl(profileInfo ? profileInfo?.logo : "")
      }
    }
  }, [profileInfo]);

  useEffect(() => {
    (async () => {
      await getStudentDetail(id);
    })();
  }, [])

  if (!schoolSetup?.School_id) {
    return (
      <Box style={{ textAlign: 'center', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
        <CircularProgress sx={{ color: '#707070' }} />
      </Box>
    )
  }

  // const handleChangeEvent = (keyValue: string, value: string) => {
  //   setSchoolSetup({ ...schoolSetup, [keyValue]: value });
  // };

  const handleChangeUploadEvent = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setSchoolSetup({
        ...schoolSetup,
        [event.target.name]: URL.createObjectURL(event.target.files[0]),
      });
    }
  };

  const SubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      isRequiredField(schoolSetup.name) &&
      isRequiredField(schoolSetup.email) &&
      isRequiredField(schoolSetup.phone) &&
      isRequiredField(schoolSetup.address_1) &&
      isRequiredField(schoolSetup.school_user_1) &&
      isRequiredField(schoolSetup.school_user_2) &&
      isRequiredField(schoolSetup.currency) &&
      isRequiredField(schoolSetup.password)
    ) {
      setIsSubmit(false);
      return;
    }
    setIsValidValues(false);
  };

  return (
    <div>
      <Row>
        <Col lg="4" sm="12">
          <StickyNote title="Total Student:" value={studentCount !== null ? studentCount.data.totalStudents : "0"} />
        </Col>
        <Col lg="4" sm="12">
          <StickyNote title="Last Week Join:" value={studentCount !== null ? studentCount.data.studentRegisterThisWeek : "0"} />
        </Col>
        <Col lg="4" sm="12">
          <StickyNote title="Last Month Join:" value={studentCount !== null ? studentCount.data.studentRegisterThisMonth : "0"} />
        </Col>
      </Row>
      <form onSubmit={SubmitHandler}>
        <Row className="justify-content-sm-start">
          <Col lg="4" sm="12">
            <Card>
              <CardContent>
                <div>
                  <Typography variant="h6" component="div">
                    School Logo
                  </Typography>
                </div>
                <div className="stline"></div>
                <div className="d-flex justify-content-center">
                  <div className={classes.imageParentDiv}>
                    <img className={classes.avtar} src={profilePic} loading='eager' />
                  </div>
                </div>
              </CardContent>
            </Card>
          </Col>
          <Col lg="8" sm="12">
            <Card>
              <CardContent>
                <div>
                  <Typography variant="h6" align="justify">
                    School Details
                  </Typography>
                </div>
                <div className="stline"></div>
                <Row>
                  <Col lg="6" sm="12">
                    <Typography className="fontLabel">
                      School Id
                    </Typography>
                    <Typography className={schoolSetup.status ? classes.enabledProfile : classes.disabledProfile}>
                      {schoolSetup.School_id}
                      <hr style={{ marginTop: 2, height: "2px" }} />
                    </Typography>
                  </Col>
                  <Col lg="6" sm="12">
                    <Typography className="fontLabel">
                      School Name
                    </Typography>
                    <Typography className={schoolSetup.status ? classes.enabledProfile : classes.disabledProfile}>
                      {schoolSetup.name}
                      <hr style={{ marginTop: 2, height: "2px" }} />
                    </Typography>
                  </Col>
                  <Col lg="6" sm="12">
                    <Typography className="fontLabel">
                      Owner Full Name
                    </Typography>
                    <Typography className={schoolSetup.status ? classes.enabledProfile : classes.disabledProfile}>
                      {`${schoolSetup.salutation}${" "}${schoolSetup.firstname}${" "}${schoolSetup.middlename}${" "}${schoolSetup.lastname}`}
                      <hr style={{ marginTop: 2, height: "2px" }} />
                    </Typography>
                  </Col>
                  <Col lg="6" sm="12">
                    <Typography className="fontLabel">
                      Email
                    </Typography>
                    <Typography className={schoolSetup.status ? classes.enabledProfile : classes.disabledProfile}>
                      {schoolSetup.email}
                      <hr style={{ marginTop: 2, height: "2px" }} />
                    </Typography>
                  </Col>
                  <Col lg="6" sm="12">
                    <Typography className="fontLabel">
                      Phone
                    </Typography>
                    <Typography className={schoolSetup.status ? classes.enabledProfile : classes.disabledProfile}>
                      {schoolSetup.phone}
                      <hr style={{ marginTop: 2, height: "2px" }} />
                    </Typography>
                  </Col>
                  <Col lg="6" sm="12">
                    <Typography className="fontLabel">
                      Mobile No
                    </Typography>
                    <Typography className={schoolSetup.status ? classes.enabledProfile : classes.disabledProfile}>
                      {schoolSetup.mobile}
                      <hr style={{ marginTop: 2, height: "2px" }} />
                    </Typography>
                  </Col>
                  <Col lg="6" sm="12">
                    <Typography className="fontLabel">
                      WhatsApp No
                    </Typography>
                    <Typography className={schoolSetup.status ? classes.enabledProfile : classes.disabledProfile}>
                      {schoolSetup.whatsapp}
                      <hr style={{ marginTop: 2, height: "2px" }} />
                    </Typography>
                  </Col>
                  <Col lg="6" sm="12">
                    <Typography className="fontLabel">
                      Business Reg Number
                    </Typography>
                    <Typography className={schoolSetup.status ? classes.enabledProfile : classes.disabledProfile}>
                      {schoolSetup.business_number}
                      <hr style={{ marginTop: 2, height: "2px" }} />
                    </Typography>
                  </Col>
                  <Col lg="6" sm="12">
                    <Typography className="fontLabel">
                      Address1
                    </Typography>
                    <Typography className={schoolSetup.status ? classes.enabledProfile : classes.disabledProfile}>
                      {schoolSetup.address_1}
                      <hr style={{ marginTop: 2, height: "2px" }} />
                    </Typography>
                  </Col>
                  <Col lg="6" sm="12">
                    <Typography className="fontLabel">
                      Address2
                    </Typography>
                    <Typography className={schoolSetup.status ? classes.enabledProfile : classes.disabledProfile}>
                      {schoolSetup.address_2}
                      <hr style={{ marginTop: 2, height: "2px" }} />
                    </Typography>
                  </Col>
                  <Col lg="6" sm="12">
                    <Typography className="fontLabel">
                      Country
                    </Typography>
                    <Typography className={schoolSetup.status ? classes.enabledProfile : classes.disabledProfile}>
                      Canada
                      <hr style={{ marginTop: 2, height: "2px" }} />
                    </Typography>
                  </Col>
                  <Col lg="6" sm="12">
                    <Typography className="fontLabel">
                      Province
                    </Typography>
                    <Typography className={schoolSetup.status ? classes.enabledProfile : classes.disabledProfile}>
                      {schoolSetup.province == "1" ? "Alberta" :
                        schoolSetup.province == "2" ? "Ontario" :
                          schoolSetup.province == "3" ? "British Columbia" :
                            schoolSetup.province == "4" ? "Quebec" :
                              schoolSetup.province == "5" ? "Nova Scotia" :
                                schoolSetup.province == "6" ? "New Brunswick" :
                                  schoolSetup.province == "7" ? "Manitoba" :
                                    schoolSetup.province == "8" ? "Prince Edward Island" :
                                      schoolSetup.province == "9" ? "Saskatchewan" :
                                        schoolSetup.province == "10" ? "Newfoundland and Labrador" :
                                          schoolSetup.province == "11" ? "Northwest Territories" :
                                            schoolSetup.province == "12" ? "Nunavut" :
                                              schoolSetup.province == "13" ? "Yukon" : "- -"}
                      <hr style={{ marginTop: 2, height: "2px" }} />
                    </Typography>
                  </Col>
                  <Col lg="6" sm="12">
                    <Typography className="fontLabel">
                      Organization ID
                    </Typography>
                    <Typography className={schoolSetup.status ? classes.enabledProfile : classes.disabledProfile}>
                      {schoolSetup.registry_id}
                      <hr style={{ marginTop: 2, height: "2px" }} />
                    </Typography>
                  </Col>
                  <Col lg="6" sm="12">
                    <Typography className="fontLabel">
                      Tax Identification Number
                    </Typography>
                    <Typography className={schoolSetup.status ? classes.enabledProfile : classes.disabledProfile}>
                      {schoolSetup.tin_number}
                      <hr style={{ marginTop: 2, height: "2px" }} />
                    </Typography>
                  </Col>
                  <Col lg="6" sm="12">
                    <Typography className="fontLabel">
                      Created At
                    </Typography>
                    <Typography className={schoolSetup.status ? classes.enabledProfile : classes.disabledProfile}>
                      {moment(schoolSetup.created_at).format('DD-MM-YYYY h:mm A')}
                      <hr style={{ marginTop: 2, height: "2px" }} />
                    </Typography>
                  </Col>
                  <Col lg="6" sm="12">
                    <Typography className="fontLabel">
                      School Domain
                    </Typography>
                    <Typography className={schoolSetup.status ? classes.enabledProfile : classes.disabledProfile}>
                      {schoolSetup.domain}
                      <hr style={{ marginTop: 2, height: "2px" }} />
                    </Typography>
                    {/* <InputField
                      type="text"
                      lable="School Domain"
                      name="SchoolDomain"
                      rows={1}
                      multiline={false}
                      placeHolder="Enter address"
                      className="form-textfield-styles"
                      value={schoolSetup.domain}
                      isValidValue={isValidValues}
                      isSubmit={isSubmit}
                      requiredMessage="Address field is required"
                    /> */}
                  </Col>
                </Row>
              </CardContent>
            </Card>
          </Col>
        </Row>
        {/* </CardContent>
        </Card> */}
        <br />
        {/* <Card>
          <CardContent>
            <Typography
              variant="h6"
              component="div"
              className={classes.RowSectionHeading}
            >
              User Details
            </Typography>
            <Row>
              <Col lg="6" sm="12">
                <InputField
                  type="text"
                  lable="School User 1"
                  name="school_user_1"
                  rows={1}
                  multiline={false}
                  placeHolder="Enter school user 1"
                  className="form-textfield-styles"
                  value={schoolSetup.school_user_1}
                  handleChange={(e) =>
                    handleChangeEvent(e.target.name, e.target.value)
                  }
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="School user 1 field is required"
                />
              </Col>
              <Col lg="6" sm="12">
                <InputField
                  type="text"
                  lable="School user 2"
                  name="school_user_2"
                  rows={1}
                  multiline={false}
                  placeHolder="Enter school user 2"
                  className="form-textfield-styles"
                  value={schoolSetup.school_user_2}
                  handleChange={(e) =>
                    handleChangeEvent(e.target.name, e.target.value)
                  }
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="School user 2 field is required"
                />
              </Col>
              <Col lg="6" sm="12">
                <InputSelectField
                  select={true}
                  lable="Select Currency"
                  name="currency"
                  value={schoolSetup.currency}
                  className="form-textfield-styles"
                  options={[
                    { id: "", name: "Select currency" },
                    { id: "1", name: "Currency 1" },
                    { id: "2", name: "Currency 2" },
                    { id: "3", name: "Currency 3" },
                  ]}
                  handleChange={(e) =>
                    handleChangeEvent(e.target.name, e.target.value)
                  }
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Currency field is required"
                />
              </Col>
              <Col lg="6" sm="12">
                <InputField
                  type="password"
                  lable="Password"
                  name="password"
                  rows={1}
                  multiline={false}
                  placeHolder="Enter password"
                  className="form-textfield-styles"
                  value={schoolSetup.password}
                  handleChange={(e) =>
                    handleChangeEvent(e.target.name, e.target.value)
                  }
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Password field is required"
                />
              </Col>
            </Row>
          </CardContent>
        </Card> */}

        {/* <Row className="mt-4">
          <Col lg="12" sm="12">
            <div style={{ textAlign: "end" }}>
              <Link to={ROUTES.school}>
                <CancelButtonComponent ButtonLabel="Cancel" />
              </Link>
              {" "}
              <SaveButtonComponent ButtonType="submit" ButtonLabel="Save" />
            </div>
          </Col>
        </Row> */}
      </form>
    </div>
  );
};

export default Profile;
