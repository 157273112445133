import { ActionProps } from "../../ts";
import { LIST_OF_TOPIC, LIST_OF_TOPIC_ERROR } from "../ActionTypes";

const initialState = { topics: null };

const topicStore = (state: any = initialState, action: ActionProps) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_OF_TOPIC: {
      return {
        ...state,
        topics: payload,
      };
    }
    case LIST_OF_TOPIC_ERROR: {
      return {
        ...state,
        topics: null,
      };
    }
    default:
      return state;
  }
};

export default topicStore;
