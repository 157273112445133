import React, { useEffect } from "react";
import CommonHeadPart from "../../common-components/CommonHeadPart";
import DashboardIcon from "../../svg_icons/dashboard.svg";
import "../Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ROUTES } from "../../constants/routes";
import { Row, Col } from "react-bootstrap";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../reducer-store/actions/dashboardAction";
import ToastMessage from "../../common-components/ToastMessage";
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import { Box } from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';

const BreadCrumbDashboard = [
  {
    labelText: "Home",
    linkUrl: ROUTES.home,
  },
  {
    labelText: "Dashboard",
    linkUrl: "",
  },
];

const useStyles = makeStyles(() => ({
  divInsideMain: {
    alignItems: 'center',
  },
  dashCard: {
    position: "relative",
    minHeight: "200px",
    marginBottom: "20px"
  },
  divline: {
    position: "absolute",
    height: "3px !important",
    borderStyle: "none !important",
    opacity: "1 !important",
    backgroundColor: "#feba26",
    width: "100%",
    bottom: "70px"
  },
  cardCount: {
    // font: 'normal normal bold 38px / 43px Arial',
    letterSpacing: '0.15px',
    color: '#3D3D3D',
    textAlign: "center",
    fontWeight: 700,
  },

  iconTitle: {
    alignItems: "center !important",
  },

  cardTitle: {
    fontSize: "21px !important",
    fontWeight: 600
  },
  countCenter: {
    position: "absolute",
    bottom: "0px",
    // left: "45%"
  },
}));

const Dashboard: React.FC = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { DashboardData } = bindActionCreators(Actions, dispatch);
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const dashboardData = useSelector((state: any) => state.dashboard);
  const { dashboard } = dashboardData;

  useEffect(() => {
    (async () => {
      await DashboardData(token);
    })();
  }, []);

  return (
    <>
      <ToastMessage />
      <CommonHeadPart
        componentText="Dashboard"
        Icon={DashboardIcon}
        buttonText=""
        urlLink="/"
        BreadCrumbs={BreadCrumbDashboard}
      />
      <div className={classes.divInsideMain}>
        {!dashboard ? (
          <Box style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
            <CircularProgress sx={{ color: '#707070' }} />
          </Box>
        ) : dashboard?.data ?
          (
            <div>
              <Row>

                <Col xl="3" lg="4" md="6" sm="12" xs="12" >
                  {/* <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          viewBox="0 0 30 30"
                        >
                          <g
                            id="Group_5985"
                            data-name="Group 5985"
                            transform="translate(-142 -269)"
                          >
                            <path
                              id="Path_13407"
                              data-name="Path 13407"
                              d="M499.552,332.523c.477.344.512.605.146,1.088-.206.272-.4.552-.624.811-.115.135-.1.22.006.345.216.264.413.543.625.811a.668.668,0,0,1,.169.772.682.682,0,0,1-.7.322c-1.161,0-2.323.005-3.484-.006-.208,0-.265.053-.254.258.018.35,0,.7.008,1.054,0,.138-.038.191-.181.186-.263-.009-.527-.012-.79,0-.168.008-.187-.068-.187-.209q.006-2.371,0-4.743a.784.784,0,0,1,.4-.689Z"
                              transform="translate(-337.853 -63.523)"
                              fill="#e64934"
                            />
                            <path
                              id="Path_13408"
                              data-name="Path 13408"
                              d="M449.261,457.852c0,2.469,0,4.938.005,7.408,0,.212-.04.281-.267.274-.644-.018-2.288-.015-2.932,0-.2,0-.237-.057-.236-.243.007-1.845,0-3.689,0-5.534,0-.56-.17-.729-.737-.729H442.56c-.541,0-.718.175-.718.719,0,1.825-.007,3.65.007,5.475,0,.268-.075.32-.323.313-.653-.018-2.308-.012-2.961,0-.173,0-.23-.03-.23-.22q.009-7.451,0-14.9a.375.375,0,0,1,.156-.317q2.569-2.175,5.126-4.363c.164-.141.248-.124.4.008q2.518,2.167,5.052,4.316a.494.494,0,0,1,.193.422Q449.256,454.163,449.261,457.852Zm-5.412-2.041c.8,0,1.6,0,2.4,0a.566.566,0,1,0,0-1.127c-1.619-.005-3.239,0-4.858,0a.573.573,0,0,0-.466.2.536.536,0,0,0-.09.576.553.553,0,0,0,.555.353Z"
                              transform="translate(-286.907 -166.534)"
                              fill="#feba26"
                            />
                            <path
                              id="Path_13409"
                              data-name="Path 13409"
                              d="M573.533,540.029c0-1.9.005-3.807-.007-5.711,0-.253.072-.3.307-.3,2.03.01,4.061.005,6.091.006.719,0,1.038.317,1.038,1.033q0,5.344.006,10.689c0,.232-.061.286-.288.286q-3.441-.013-6.882,0c-.215,0-.273-.045-.272-.268C573.538,543.856,573.533,541.942,573.533,540.029Zm2.928,2.251h0c0-.2.012-.409,0-.612a.562.562,0,0,0-.549-.568.533.533,0,0,0-.577.529,10.36,10.36,0,0,0,0,1.281.557.557,0,0,0,.57.545.58.58,0,0,0,.562-.591C576.47,542.67,576.461,542.474,576.461,542.28Zm2.864.008a4.271,4.271,0,0,0-.037-.755.528.528,0,0,0-.515-.431.5.5,0,0,0-.559.4,4.558,4.558,0,0,0,0,1.535.5.5,0,0,0,.531.41.555.555,0,0,0,.543-.438A3.971,3.971,0,0,0,579.326,542.288Zm-2.864-4.785c0-.195.008-.389,0-.583a.586.586,0,0,0-.547-.609.567.567,0,0,0-.587.56,10.357,10.357,0,0,0,0,1.253.542.542,0,0,0,.594.543.568.568,0,0,0,.537-.581C576.47,537.893,576.461,537.7,576.461,537.5Zm1.709-.021c0,.2-.013.409,0,.613a.57.57,0,0,0,.546.574.55.55,0,0,0,.591-.552,10.173,10.173,0,0,0,0-1.253.564.564,0,0,0-.568-.552.587.587,0,0,0-.569.588C578.158,537.092,578.169,537.287,578.17,537.482Z"
                              transform="translate(-408.969 -247.038)"
                              fill="#feba26"
                            />
                            <path
                              id="Path_13410"
                              data-name="Path 13410"
                              d="M351.858,540.053c0,1.9,0,3.807,0,5.711,0,.208-.034.277-.264.276q-3.338-.016-6.677,0c-.237,0-.283-.068-.283-.291q.01-5.344.005-10.689c0-.7.323-1.028,1.014-1.028,1.972,0,3.944,0,5.916-.006.229,0,.291.056.29.288C351.854,536.227,351.858,538.14,351.858,540.053Zm-5.641,2.221c0,.195-.009.39,0,.583a.586.586,0,0,0,.555.6.568.568,0,0,0,.583-.566,10.613,10.613,0,0,0,0-1.224.552.552,0,0,0-.579-.565.575.575,0,0,0-.562.618C346.21,541.9,346.217,542.09,346.217,542.275Zm2.93.012h0c0,.2-.013.409,0,.612a.574.574,0,0,0,.562.559.555.555,0,0,0,.562-.52,6.672,6.672,0,0,0,0-1.309.535.535,0,0,0-.581-.525.563.563,0,0,0-.546.571C349.136,541.877,349.148,542.082,349.148,542.286Zm-1.775-4.8a4.745,4.745,0,0,0-.03-.7.573.573,0,0,0-.545-.475.533.533,0,0,0-.543.438,5.183,5.183,0,0,0,0,1.479.516.516,0,0,0,.566.439.543.543,0,0,0,.523-.458A4.686,4.686,0,0,0,347.372,537.486Zm1.774,0c0,.194-.008.389,0,.583a.573.573,0,0,0,.548.6.547.547,0,0,0,.587-.554,10.512,10.512,0,0,0,0-1.253.561.561,0,0,0-.566-.552.582.582,0,0,0-.565.59C349.137,537.1,349.147,537.292,349.146,537.486Z"
                              transform="translate(-202.639 -247.04)"
                              fill="#feba26"
                            />
                            <path
                              id="Path_13411"
                              data-name="Path 13411"
                              d="M424.454,413.2c-.075-.061-.136-.108-.2-.158l-6.431-5.462c-.438-.372-.642-.376-1.076-.01q-3.222,2.723-6.439,5.452c-.188.16-.279.159-.427-.037a9.173,9.173,0,0,0-.691-.8c-.135-.142-.119-.218.028-.341q2.3-1.942,4.6-3.9,1.635-1.389,3.268-2.783c.143-.123.228-.142.384-.009q3.936,3.358,7.886,6.7c.144.122.141.186.018.315-.27.284-.521.584-.78.878C424.552,413.1,424.506,413.146,424.454,413.2Z"
                              transform="translate(-260.349 -129.591)"
                              fill="#e64934"
                            />
                          </g>
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Total School
                        </Typography>
                      }
                    />
                    <Divider className={classes.divline} />
                    <CardActions className={classes.countCenter}>
                      <Typography
                        variant="h4"
                        component="div"
                        className={classes.cardCount}
                      >
                        {" "}
                        {!dashboard ? 'Loading' : dashboard.data.totalSchools}{" "}
                      </Typography>
                    </CardActions>
                  </Card> */}
                  <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          viewBox="0 0 30 30"
                        >
                          <g
                            id="Group_5985"
                            data-name="Group 5985"
                            transform="translate(-142 -269)"
                          >
                            <path
                              id="Path_13407"
                              data-name="Path 13407"
                              d="M499.552,332.523c.477.344.512.605.146,1.088-.206.272-.4.552-.624.811-.115.135-.1.22.006.345.216.264.413.543.625.811a.668.668,0,0,1,.169.772.682.682,0,0,1-.7.322c-1.161,0-2.323.005-3.484-.006-.208,0-.265.053-.254.258.018.35,0,.7.008,1.054,0,.138-.038.191-.181.186-.263-.009-.527-.012-.79,0-.168.008-.187-.068-.187-.209q.006-2.371,0-4.743a.784.784,0,0,1,.4-.689Z"
                              transform="translate(-337.853 -63.523)"
                              fill="#e64934"
                            />
                            <path
                              id="Path_13408"
                              data-name="Path 13408"
                              d="M449.261,457.852c0,2.469,0,4.938.005,7.408,0,.212-.04.281-.267.274-.644-.018-2.288-.015-2.932,0-.2,0-.237-.057-.236-.243.007-1.845,0-3.689,0-5.534,0-.56-.17-.729-.737-.729H442.56c-.541,0-.718.175-.718.719,0,1.825-.007,3.65.007,5.475,0,.268-.075.32-.323.313-.653-.018-2.308-.012-2.961,0-.173,0-.23-.03-.23-.22q.009-7.451,0-14.9a.375.375,0,0,1,.156-.317q2.569-2.175,5.126-4.363c.164-.141.248-.124.4.008q2.518,2.167,5.052,4.316a.494.494,0,0,1,.193.422Q449.256,454.163,449.261,457.852Zm-5.412-2.041c.8,0,1.6,0,2.4,0a.566.566,0,1,0,0-1.127c-1.619-.005-3.239,0-4.858,0a.573.573,0,0,0-.466.2.536.536,0,0,0-.09.576.553.553,0,0,0,.555.353Z"
                              transform="translate(-286.907 -166.534)"
                              fill="#feba26"
                            />
                            <path
                              id="Path_13409"
                              data-name="Path 13409"
                              d="M573.533,540.029c0-1.9.005-3.807-.007-5.711,0-.253.072-.3.307-.3,2.03.01,4.061.005,6.091.006.719,0,1.038.317,1.038,1.033q0,5.344.006,10.689c0,.232-.061.286-.288.286q-3.441-.013-6.882,0c-.215,0-.273-.045-.272-.268C573.538,543.856,573.533,541.942,573.533,540.029Zm2.928,2.251h0c0-.2.012-.409,0-.612a.562.562,0,0,0-.549-.568.533.533,0,0,0-.577.529,10.36,10.36,0,0,0,0,1.281.557.557,0,0,0,.57.545.58.58,0,0,0,.562-.591C576.47,542.67,576.461,542.474,576.461,542.28Zm2.864.008a4.271,4.271,0,0,0-.037-.755.528.528,0,0,0-.515-.431.5.5,0,0,0-.559.4,4.558,4.558,0,0,0,0,1.535.5.5,0,0,0,.531.41.555.555,0,0,0,.543-.438A3.971,3.971,0,0,0,579.326,542.288Zm-2.864-4.785c0-.195.008-.389,0-.583a.586.586,0,0,0-.547-.609.567.567,0,0,0-.587.56,10.357,10.357,0,0,0,0,1.253.542.542,0,0,0,.594.543.568.568,0,0,0,.537-.581C576.47,537.893,576.461,537.7,576.461,537.5Zm1.709-.021c0,.2-.013.409,0,.613a.57.57,0,0,0,.546.574.55.55,0,0,0,.591-.552,10.173,10.173,0,0,0,0-1.253.564.564,0,0,0-.568-.552.587.587,0,0,0-.569.588C578.158,537.092,578.169,537.287,578.17,537.482Z"
                              transform="translate(-408.969 -247.038)"
                              fill="#feba26"
                            />
                            <path
                              id="Path_13410"
                              data-name="Path 13410"
                              d="M351.858,540.053c0,1.9,0,3.807,0,5.711,0,.208-.034.277-.264.276q-3.338-.016-6.677,0c-.237,0-.283-.068-.283-.291q.01-5.344.005-10.689c0-.7.323-1.028,1.014-1.028,1.972,0,3.944,0,5.916-.006.229,0,.291.056.29.288C351.854,536.227,351.858,538.14,351.858,540.053Zm-5.641,2.221c0,.195-.009.39,0,.583a.586.586,0,0,0,.555.6.568.568,0,0,0,.583-.566,10.613,10.613,0,0,0,0-1.224.552.552,0,0,0-.579-.565.575.575,0,0,0-.562.618C346.21,541.9,346.217,542.09,346.217,542.275Zm2.93.012h0c0,.2-.013.409,0,.612a.574.574,0,0,0,.562.559.555.555,0,0,0,.562-.52,6.672,6.672,0,0,0,0-1.309.535.535,0,0,0-.581-.525.563.563,0,0,0-.546.571C349.136,541.877,349.148,542.082,349.148,542.286Zm-1.775-4.8a4.745,4.745,0,0,0-.03-.7.573.573,0,0,0-.545-.475.533.533,0,0,0-.543.438,5.183,5.183,0,0,0,0,1.479.516.516,0,0,0,.566.439.543.543,0,0,0,.523-.458A4.686,4.686,0,0,0,347.372,537.486Zm1.774,0c0,.194-.008.389,0,.583a.573.573,0,0,0,.548.6.547.547,0,0,0,.587-.554,10.512,10.512,0,0,0,0-1.253.561.561,0,0,0-.566-.552.582.582,0,0,0-.565.59C349.137,537.1,349.147,537.292,349.146,537.486Z"
                              transform="translate(-202.639 -247.04)"
                              fill="#feba26"
                            />
                            <path
                              id="Path_13411"
                              data-name="Path 13411"
                              d="M424.454,413.2c-.075-.061-.136-.108-.2-.158l-6.431-5.462c-.438-.372-.642-.376-1.076-.01q-3.222,2.723-6.439,5.452c-.188.16-.279.159-.427-.037a9.173,9.173,0,0,0-.691-.8c-.135-.142-.119-.218.028-.341q2.3-1.942,4.6-3.9,1.635-1.389,3.268-2.783c.143-.123.228-.142.384-.009q3.936,3.358,7.886,6.7c.144.122.141.186.018.315-.27.284-.521.584-.78.878C424.552,413.1,424.506,413.146,424.454,413.2Z"
                              transform="translate(-260.349 -129.591)"
                              fill="#e64934"
                            />
                          </g>
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Total School
                        </Typography>
                      }
                    />
                    <Divider color="#feba26" className={classes.divline} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CardActions className={classes.countCenter}>
                        <Typography
                          variant="h4"
                          component="div"
                          className={classes.cardCount}
                        >
                          {" "}
                          {!dashboard ? 'Loading' : dashboard.data.totalSchools}{" "}
                        </Typography>
                      </CardActions>
                    </div>
                  </Card>


                </Col>

                <Col xl="3" lg="4" md="6" sm="12" xs="12" >
                  {/* <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          viewBox="0 0 29 30"
                        >
                          <g
                            id="Group_5987"
                            data-name="Group 5987"
                            transform="translate(-408 -269)"
                          >
                            <path
                              id="Path_13446"
                              data-name="Path 13446"
                              d="M67.5,49A7.5,7.5,0,1,0,75,56.5,7.5,7.5,0,0,0,67.5,49Z"
                              transform="translate(362 232)"
                              fill="#e64934"
                            />
                            <path
                              id="Path_13447"
                              data-name="Path 13447"
                              d="M87.1,61.913l-.156-.079a1.582,1.582,0,0,0-2.131.737L82.05,68.207s0,.01-.006.015a.477.477,0,0,0-.018.048c0,.013-.009.026-.012.04a.48.48,0,0,0-.008.048c0,.016,0,.031,0,.047s0,.012,0,.019l.017,2.775a.474.474,0,0,0,.252.419.454.454,0,0,0,.476-.04l2.192-1.657a.458.458,0,0,0,.052-.048L85,69.86a.466.466,0,0,0,.066-.1l.006-.009,2.753-5.607a1.7,1.7,0,0,0,.091-1.273,1.637,1.637,0,0,0-.814-.96Z"
                              transform="translate(372.457 197.006) rotate(20)"
                              fill="#fff"
                            />
                            <path
                              id="Path_13448"
                              data-name="Path 13448"
                              d="M1.833,30H20.167a1.851,1.851,0,0,0,1.82-1.652q-.221.011-.445.012a8.633,8.633,0,0,1-6.925-3.515H4.01a.469.469,0,0,1,0-.937H14l.007.012a8.984,8.984,0,0,1-1.148-3.762H4.01a.469.469,0,0,1,0-.937h8.829a9.006,9.006,0,0,1,.918-3.75H4.01a.469.469,0,0,1,0-.937H14.289l0,0a8.657,8.657,0,0,1,7.255-3.987q.231,0,.458.012V1.875A1.857,1.857,0,0,0,20.167,0H1.833A1.857,1.857,0,0,0,0,1.875v26.25A1.857,1.857,0,0,0,1.833,30ZM18.1,10.781H4.01a.469.469,0,0,1,0-.937H18.1a.469.469,0,0,1,0,.938ZM3.9,5.156H17.99a.469.469,0,0,1,0,.938H3.9a.469.469,0,0,1,0-.937Z"
                              transform="translate(408 269)"
                              fill="#feba26"
                            />
                          </g>
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Last Month's Registration
                        </Typography>
                      }
                    />
                    <Divider className={classes.divline} />
                    <CardActions className={classes.countCenter}>
                      <Typography
                        variant="h4"
                        component="div"
                        className={classes.cardCount}
                      >
                        {" "}
                        {!dashboard ? 'Loading' : dashboard.data.registrationLastMonth}{" "}
                      </Typography>
                    </CardActions>

                  </Card> */}
                  <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          viewBox="0 0 29 30"
                        >
                          <g
                            id="Group_5987"
                            data-name="Group 5987"
                            transform="translate(-408 -269)"
                          >
                            <path
                              id="Path_13446"
                              data-name="Path 13446"
                              d="M67.5,49A7.5,7.5,0,1,0,75,56.5,7.5,7.5,0,0,0,67.5,49Z"
                              transform="translate(362 232)"
                              fill="#e64934"
                            />
                            <path
                              id="Path_13447"
                              data-name="Path 13447"
                              d="M87.1,61.913l-.156-.079a1.582,1.582,0,0,0-2.131.737L82.05,68.207s0,.01-.006.015a.477.477,0,0,0-.018.048c0,.013-.009.026-.012.04a.48.48,0,0,0-.008.048c0,.016,0,.031,0,.047s0,.012,0,.019l.017,2.775a.474.474,0,0,0,.252.419.454.454,0,0,0,.476-.04l2.192-1.657a.458.458,0,0,0,.052-.048L85,69.86a.466.466,0,0,0,.066-.1l.006-.009,2.753-5.607a1.7,1.7,0,0,0,.091-1.273,1.637,1.637,0,0,0-.814-.96Z"
                              transform="translate(372.457 197.006) rotate(20)"
                              fill="#fff"
                            />
                            <path
                              id="Path_13448"
                              data-name="Path 13448"
                              d="M1.833,30H20.167a1.851,1.851,0,0,0,1.82-1.652q-.221.011-.445.012a8.633,8.633,0,0,1-6.925-3.515H4.01a.469.469,0,0,1,0-.937H14l.007.012a8.984,8.984,0,0,1-1.148-3.762H4.01a.469.469,0,0,1,0-.937h8.829a9.006,9.006,0,0,1,.918-3.75H4.01a.469.469,0,0,1,0-.937H14.289l0,0a8.657,8.657,0,0,1,7.255-3.987q.231,0,.458.012V1.875A1.857,1.857,0,0,0,20.167,0H1.833A1.857,1.857,0,0,0,0,1.875v26.25A1.857,1.857,0,0,0,1.833,30ZM18.1,10.781H4.01a.469.469,0,0,1,0-.937H18.1a.469.469,0,0,1,0,.938ZM3.9,5.156H17.99a.469.469,0,0,1,0,.938H3.9a.469.469,0,0,1,0-.937Z"
                              transform="translate(408 269)"
                              fill="#feba26"
                            />
                          </g>
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Last Month's Registration
                        </Typography>
                      }
                    />
                    <Divider color="#feba26" className={classes.divline} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CardActions className={classes.countCenter}>
                        <Typography
                          variant="h4"
                          component="div"
                          className={classes.cardCount}
                        >
                          {" "}
                          {!dashboard ? 'Loading' : dashboard.data.registrationLastMonth}{" "}
                        </Typography>
                      </CardActions>
                    </div>
                  </Card>
                </Col>

                <Col xl="3" lg="4" md="6" sm="12" xs="12" >
                  {/* <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          viewBox="0 0 30 30"
                        >
                          <g
                            id="Group_5988"
                            data-name="Group 5988"
                            transform="translate(-674.028 -269.004)"
                          >
                            <path
                              id="Path_13449"
                              data-name="Path 13449"
                              d="M456.56,260.568a3.12,3.12,0,0,0-.223.674,3.635,3.635,0,0,1-3.362,2.425c-3.276.018-6.552.01-9.828,0a.9.9,0,0,0-.587.19q-1.593,1.212-3.2,2.406a.887.887,0,0,1-1.24-.049.809.809,0,0,1-.233-.551c0-.2,0-.409,0-.614q0-8.029,0-16.058a3.684,3.684,0,0,1,3.138-3.951c.012,0,.019-.029.029-.045h12.232c.02.017.039.045.061.048a3.608,3.608,0,0,1,2.9,2.16,8.732,8.732,0,0,1,.314,1.011Zm-9.332-7.1q-2.268,0-4.535,0c-.649,0-1.053.337-1.054.873s.41.879,1.051.883h.059q4.476,0,8.953,0a2.236,2.236,0,0,0,.378-.027.855.855,0,0,0,.693-1.04.882.882,0,0,0-.981-.688Q449.51,253.463,447.228,253.466Zm-.029-2.012h4.477c.107,0,.215,0,.322-.008a.855.855,0,0,0,.789-.737.87.87,0,0,0-.511-.928,1.233,1.233,0,0,0-.515-.083q-4.535,0-9.07,0a1.8,1.8,0,0,0-.349.03.822.822,0,0,0-.648.568.864.864,0,0,0,.882,1.155C444.117,251.458,445.658,251.453,447.2,251.453Zm-2.783,5.774c-.614,0-1.228,0-1.842,0a.877.877,0,0,0-.934.872.888.888,0,0,0,.931.881q1.856.01,3.713,0a.878.878,0,1,0,0-1.753C445.663,257.224,445.039,257.228,444.415,257.228Z"
                              transform="translate(247.468 24.004)"
                              fill="#feba26"
                            />
                            <path
                              id="Path_13450"
                              data-name="Path 13450"
                              d="M245.345,497.961a5.506,5.506,0,0,1,4.456-3.591.824.824,0,0,0,.245-.091,4.16,4.16,0,0,1-2.858-1.742,4.057,4.057,0,0,1-.662-3.253,4.15,4.15,0,0,1,8.189.482,4.205,4.205,0,0,1-3.682,4.529c.351.073.644.123.931.195a5.537,5.537,0,0,1,4.17,5.012c.024.408.009.819.033,1.227a1.018,1.018,0,0,1-.69,1.137h-9.832a1.386,1.386,0,0,1-.644-.644A10.819,10.819,0,0,1,245.345,497.961Z"
                              transform="translate(429.031 -202.863)"
                              fill="#e64934"
                            />
                          </g>
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          School Onboarding Request
                        </Typography>
                      }
                    />
                    <Divider className={classes.divline} />
                    <CardActions className={classes.countCenter}>
                      <Typography
                        variant="h4"
                        component="div"
                        className={classes.cardCount}
                      >
                        {" "}
                        {!dashboard ? 'Loading' : dashboard.data.onboardRequest}{" "}
                      </Typography>
                    </CardActions>

                  </Card> */}
                  <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          viewBox="0 0 30 30"
                        >
                          <g
                            id="Group_5988"
                            data-name="Group 5988"
                            transform="translate(-674.028 -269.004)"
                          >
                            <path
                              id="Path_13449"
                              data-name="Path 13449"
                              d="M456.56,260.568a3.12,3.12,0,0,0-.223.674,3.635,3.635,0,0,1-3.362,2.425c-3.276.018-6.552.01-9.828,0a.9.9,0,0,0-.587.19q-1.593,1.212-3.2,2.406a.887.887,0,0,1-1.24-.049.809.809,0,0,1-.233-.551c0-.2,0-.409,0-.614q0-8.029,0-16.058a3.684,3.684,0,0,1,3.138-3.951c.012,0,.019-.029.029-.045h12.232c.02.017.039.045.061.048a3.608,3.608,0,0,1,2.9,2.16,8.732,8.732,0,0,1,.314,1.011Zm-9.332-7.1q-2.268,0-4.535,0c-.649,0-1.053.337-1.054.873s.41.879,1.051.883h.059q4.476,0,8.953,0a2.236,2.236,0,0,0,.378-.027.855.855,0,0,0,.693-1.04.882.882,0,0,0-.981-.688Q449.51,253.463,447.228,253.466Zm-.029-2.012h4.477c.107,0,.215,0,.322-.008a.855.855,0,0,0,.789-.737.87.87,0,0,0-.511-.928,1.233,1.233,0,0,0-.515-.083q-4.535,0-9.07,0a1.8,1.8,0,0,0-.349.03.822.822,0,0,0-.648.568.864.864,0,0,0,.882,1.155C444.117,251.458,445.658,251.453,447.2,251.453Zm-2.783,5.774c-.614,0-1.228,0-1.842,0a.877.877,0,0,0-.934.872.888.888,0,0,0,.931.881q1.856.01,3.713,0a.878.878,0,1,0,0-1.753C445.663,257.224,445.039,257.228,444.415,257.228Z"
                              transform="translate(247.468 24.004)"
                              fill="#feba26"
                            />
                            <path
                              id="Path_13450"
                              data-name="Path 13450"
                              d="M245.345,497.961a5.506,5.506,0,0,1,4.456-3.591.824.824,0,0,0,.245-.091,4.16,4.16,0,0,1-2.858-1.742,4.057,4.057,0,0,1-.662-3.253,4.15,4.15,0,0,1,8.189.482,4.205,4.205,0,0,1-3.682,4.529c.351.073.644.123.931.195a5.537,5.537,0,0,1,4.17,5.012c.024.408.009.819.033,1.227a1.018,1.018,0,0,1-.69,1.137h-9.832a1.386,1.386,0,0,1-.644-.644A10.819,10.819,0,0,1,245.345,497.961Z"
                              transform="translate(429.031 -202.863)"
                              fill="#e64934"
                            />
                          </g>
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          School Onboarding Request
                        </Typography>
                      }
                    />
                    <Divider color="#feba26" className={classes.divline} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CardActions className={classes.countCenter}>
                        <Typography
                          variant="h4"
                          component="div"
                          className={classes.cardCount}
                        >
                          {" "}
                          {!dashboard ? 'Loading' : dashboard.data.onboardRequest}{" "}
                        </Typography>
                      </CardActions>
                    </div>
                  </Card>
                </Col>

                <Col xl="3" lg="4" md="6" sm="12" xs="12" >
                  {/* <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          viewBox="0 0 30.001 30"
                        >
                          <g
                            id="Group_6577"
                            data-name="Group 6577"
                            transform="translate(-1083 -268)"
                          >
                            <path
                              id="Path_13452"
                              data-name="Path 13452"
                              d="M503.946,346.858q0,7.083,0,14.165c0,.655-.143.8-.8.8H488.716c-.612,0-.764-.153-.765-.768,0-2.762,0-9.979,0-12.741a.7.7,0,0,1,.17-.48,8.488,8.488,0,0,0,0-10.872.7.7,0,0,1-.172-.479c.006-2.784,0-1.113,0-3.9,0-.526.169-.755.689-.755a2.021,2.021,0,0,1,.982.1c.237.155.2.6.283.921.062.225.115.452.175.677.1.378.255.492.674.492h10.361c.468,0,.6-.1.716-.566.108-.419.23-.837.31-1.261.049-.261.16-.344.417-.32.029,0,.546-.01.824,0a.546.546,0,0,1,.561.555c.006.1,0,.2,0,.3Z"
                              transform="translate(609.053 -63.819)"
                              fill="#feba26"
                            />
                            <path
                              id="Path_13453"
                              data-name="Path 13453"
                              d="M331.991,424a7.5,7.5,0,1,1,7.546,7.5A7.508,7.508,0,0,1,331.991,424Zm6.964,4.253a1.728,1.728,0,0,1,0,.267c-.039.244.043.318.3.313.772-.016.783,0,.767-.808a.4.4,0,0,1,.3-.46,2,2,0,0,0,1.3-1.756,2.048,2.048,0,0,0-.913-1.961,2.32,2.32,0,0,0-1.29-.389,1.056,1.056,0,0,1-.99-.964,1.079,1.079,0,0,1,.827-1.143.978.978,0,0,1,1.194.611c.106.461.36.459.7.434a1.27,1.27,0,0,1,.267,0c.2.029.244-.061.215-.245a1.782,1.782,0,0,0-.916-1.476,1.053,1.053,0,0,1-.682-1.308c.019-.142-.032-.184-.181-.193-.89-.051-.9-.055-.887.822a.376.376,0,0,1-.279.435,2.135,2.135,0,0,0,.837,4.109,1.068,1.068,0,0,1,.229,2.106.976.976,0,0,1-1.2-.59c-.12-.484-.4-.474-.755-.448a.834.834,0,0,1-.2,0c-.225-.039-.263.067-.229.262a2.114,2.114,0,0,0,1.36,1.722c.2.077.259.168.238.359A2.7,2.7,0,0,0,338.955,428.249Z"
                              transform="translate(751.009 -145.498)"
                              fill="#e64934"
                            />
                            <path
                              id="Path_13458"
                              data-name="Path 13458"
                              d="M.259,0H9.741A.254.254,0,0,1,10,.25l-.172.5A.254.254,0,0,1,9.569,1H.431A.254.254,0,0,1,.172.75L0,.25A.254.254,0,0,1,.259,0Z"
                              transform="translate(1100 268)"
                              fill="#feba26"
                            />
                            <path
                              id="Polygon_7"
                              data-name="Polygon 7"
                              d="M6.12,1.635a1,1,0,0,1,1.761,0l5.326,9.891A1,1,0,0,1,12.326,13H1.674a1,1,0,0,1-.88-1.474Z"
                              transform="translate(1098 278)"
                              fill="#fff"
                            />
                            <path
                              id="Path_13459"
                              data-name="Path 13459"
                              d="M.89-1.783h1.2L2.4-4.756V-7H.572v2.244ZM1.49,1a.977.977,0,0,0,1-.954.977.977,0,0,0-1-.954.977.977,0,0,0-1,.954A.977.977,0,0,0,1.49,1Z"
                              transform="translate(1103.51 289)"
                              fill="#e64934"
                            />
                          </g>
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Failed Payment
                        </Typography>
                      }
                    />
                    <Divider className={classes.divline} />
                    <CardActions className={classes.countCenter}>
                      <Typography
                        variant="h4"
                        component="div"
                        className={classes.cardCount}
                      >
                        {" "}
                        {!dashboard ? 'Loading' : dashboard.data.failedPayments}{" "}
                      </Typography>
                    </CardActions>
                  </Card> */}
                  <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          viewBox="0 0 30.001 30"
                        >
                          <g
                            id="Group_6577"
                            data-name="Group 6577"
                            transform="translate(-1083 -268)"
                          >
                            <path
                              id="Path_13452"
                              data-name="Path 13452"
                              d="M503.946,346.858q0,7.083,0,14.165c0,.655-.143.8-.8.8H488.716c-.612,0-.764-.153-.765-.768,0-2.762,0-9.979,0-12.741a.7.7,0,0,1,.17-.48,8.488,8.488,0,0,0,0-10.872.7.7,0,0,1-.172-.479c.006-2.784,0-1.113,0-3.9,0-.526.169-.755.689-.755a2.021,2.021,0,0,1,.982.1c.237.155.2.6.283.921.062.225.115.452.175.677.1.378.255.492.674.492h10.361c.468,0,.6-.1.716-.566.108-.419.23-.837.31-1.261.049-.261.16-.344.417-.32.029,0,.546-.01.824,0a.546.546,0,0,1,.561.555c.006.1,0,.2,0,.3Z"
                              transform="translate(609.053 -63.819)"
                              fill="#feba26"
                            />
                            <path
                              id="Path_13453"
                              data-name="Path 13453"
                              d="M331.991,424a7.5,7.5,0,1,1,7.546,7.5A7.508,7.508,0,0,1,331.991,424Zm6.964,4.253a1.728,1.728,0,0,1,0,.267c-.039.244.043.318.3.313.772-.016.783,0,.767-.808a.4.4,0,0,1,.3-.46,2,2,0,0,0,1.3-1.756,2.048,2.048,0,0,0-.913-1.961,2.32,2.32,0,0,0-1.29-.389,1.056,1.056,0,0,1-.99-.964,1.079,1.079,0,0,1,.827-1.143.978.978,0,0,1,1.194.611c.106.461.36.459.7.434a1.27,1.27,0,0,1,.267,0c.2.029.244-.061.215-.245a1.782,1.782,0,0,0-.916-1.476,1.053,1.053,0,0,1-.682-1.308c.019-.142-.032-.184-.181-.193-.89-.051-.9-.055-.887.822a.376.376,0,0,1-.279.435,2.135,2.135,0,0,0,.837,4.109,1.068,1.068,0,0,1,.229,2.106.976.976,0,0,1-1.2-.59c-.12-.484-.4-.474-.755-.448a.834.834,0,0,1-.2,0c-.225-.039-.263.067-.229.262a2.114,2.114,0,0,0,1.36,1.722c.2.077.259.168.238.359A2.7,2.7,0,0,0,338.955,428.249Z"
                              transform="translate(751.009 -145.498)"
                              fill="#e64934"
                            />
                            <path
                              id="Path_13458"
                              data-name="Path 13458"
                              d="M.259,0H9.741A.254.254,0,0,1,10,.25l-.172.5A.254.254,0,0,1,9.569,1H.431A.254.254,0,0,1,.172.75L0,.25A.254.254,0,0,1,.259,0Z"
                              transform="translate(1100 268)"
                              fill="#feba26"
                            />
                            <path
                              id="Polygon_7"
                              data-name="Polygon 7"
                              d="M6.12,1.635a1,1,0,0,1,1.761,0l5.326,9.891A1,1,0,0,1,12.326,13H1.674a1,1,0,0,1-.88-1.474Z"
                              transform="translate(1098 278)"
                              fill="#fff"
                            />
                            <path
                              id="Path_13459"
                              data-name="Path 13459"
                              d="M.89-1.783h1.2L2.4-4.756V-7H.572v2.244ZM1.49,1a.977.977,0,0,0,1-.954.977.977,0,0,0-1-.954.977.977,0,0,0-1,.954A.977.977,0,0,0,1.49,1Z"
                              transform="translate(1103.51 289)"
                              fill="#e64934"
                            />
                          </g>
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Failed Payment
                        </Typography>
                      }
                    />
                    <Divider color="#feba26" className={classes.divline} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CardActions className={classes.countCenter}>
                        <Typography
                          variant="h4"
                          component="div"
                          className={classes.cardCount}
                        >
                          {" "}
                          {!dashboard ? 'Loading' : dashboard.data.failedPayments}{" "}
                        </Typography>
                      </CardActions>
                    </div>
                  </Card>
                </Col>

              </Row>

              <Row>

                <Col xl="3" lg="4" md="6" sm="12" xs="12" >
                  {/* <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg id="Layer_1" width="50" height="50" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                          <path id="Path_13446" data-name="Path 13446"
                            d="M36.59,20A12.42,12.42,0,1,0,49,32.45,12.42,12.42,0,0,0,36.59,20Z" fill="#e64934" />
                          <path id="Path_13448" data-name="Path 13448"
                            d="M4,49.83H34.38a3.06,3.06,0,0,0,3-2.74c-.24,0-.49,0-.73,0A14.3,14.3,0,0,1,25.19,41.3H7.64a.78.78,0,0,1,0-1.56H24.17v0a15,15,0,0,1-1.9-6.22H7.64a.78.78,0,0,1,0-1.55H22.25a14.84,14.84,0,0,1,1.52-6.21H7.64a.78.78,0,0,1,0-1.55h17a14.32,14.32,0,0,1,12-6.6l.76,0V3.28a3.09,3.09,0,0,0-3-3.11H4A3.07,3.07,0,0,0,1,3.28V46.72A3.07,3.07,0,0,0,4,49.83ZM31,18H7.64a.78.78,0,0,1,0-1.55H31a.76.76,0,0,1,.77.77A.77.77,0,0,1,31,18ZM7.46,8.71H30.78a.76.76,0,0,1,.77.77.77.77,0,0,1-.77.78H7.46a.77.77,0,0,1-.74-.81.77.77,0,0,1,.74-.74Z"
                            fill="#feba26" />
                          <rect x="29.07" y="36.17" width="3.76" height="3.66" fill="#fff" />
                          <rect x="34.15" y="34.44" width="3.76" height="5.39" fill="#fff" />
                          <rect x="39.24" y="32" width="3.76" height="7.83" fill="#fff" />
                          <polygon points="42.49 27.83 40.15 25.49 43 24.99 42.49 27.83" fill="#fff" />
                          <rect x="40.05" y="26.71" width="0.81" height="1.63"
                            transform="translate(-7.62 36.67) rotate(-45)" fill="#fff" />
                          <rect x="38.93" y="27.83" width="0.81" height="1.63"
                            transform="translate(-8.73 36.21) rotate(-45)" fill="#fff" />
                          <polygon
                            points="38.93 30.07 37.81 28.95 35.88 30.99 34.15 29.26 29.32 34.09 30.44 35.21 34.1 31.55 35.93 33.27 38.93 30.07"
                            fill="#fff" />
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Total Sales
                        </Typography>
                      }
                    />
                    <Divider className={classes.divline} />
                    <CardActions className={classes.countCenter}>
                      <Typography
                        variant="h4"
                        component="div"
                        className={classes.cardCount}
                      >
                        {" "}
                        {!dashboard ? 'Loading' : dashboard.data.totalSales.toFixed(2)}{" "}
                      </Typography>
                    </CardActions>
                  </Card> */}
                  <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg id="Layer_1" width="50" height="50" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                          <path id="Path_13446" data-name="Path 13446"
                            d="M36.59,20A12.42,12.42,0,1,0,49,32.45,12.42,12.42,0,0,0,36.59,20Z" fill="#e64934" />
                          <path id="Path_13448" data-name="Path 13448"
                            d="M4,49.83H34.38a3.06,3.06,0,0,0,3-2.74c-.24,0-.49,0-.73,0A14.3,14.3,0,0,1,25.19,41.3H7.64a.78.78,0,0,1,0-1.56H24.17v0a15,15,0,0,1-1.9-6.22H7.64a.78.78,0,0,1,0-1.55H22.25a14.84,14.84,0,0,1,1.52-6.21H7.64a.78.78,0,0,1,0-1.55h17a14.32,14.32,0,0,1,12-6.6l.76,0V3.28a3.09,3.09,0,0,0-3-3.11H4A3.07,3.07,0,0,0,1,3.28V46.72A3.07,3.07,0,0,0,4,49.83ZM31,18H7.64a.78.78,0,0,1,0-1.55H31a.76.76,0,0,1,.77.77A.77.77,0,0,1,31,18ZM7.46,8.71H30.78a.76.76,0,0,1,.77.77.77.77,0,0,1-.77.78H7.46a.77.77,0,0,1-.74-.81.77.77,0,0,1,.74-.74Z"
                            fill="#feba26" />
                          <rect x="29.07" y="36.17" width="3.76" height="3.66" fill="#fff" />
                          <rect x="34.15" y="34.44" width="3.76" height="5.39" fill="#fff" />
                          <rect x="39.24" y="32" width="3.76" height="7.83" fill="#fff" />
                          <polygon points="42.49 27.83 40.15 25.49 43 24.99 42.49 27.83" fill="#fff" />
                          <rect x="40.05" y="26.71" width="0.81" height="1.63"
                            transform="translate(-7.62 36.67) rotate(-45)" fill="#fff" />
                          <rect x="38.93" y="27.83" width="0.81" height="1.63"
                            transform="translate(-8.73 36.21) rotate(-45)" fill="#fff" />
                          <polygon
                            points="38.93 30.07 37.81 28.95 35.88 30.99 34.15 29.26 29.32 34.09 30.44 35.21 34.1 31.55 35.93 33.27 38.93 30.07"
                            fill="#fff" />
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Total Sales
                        </Typography>
                      }
                    />
                    <Divider color="#feba26" className={classes.divline} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CardActions className={classes.countCenter}>
                        <Typography
                          variant="h4"
                          component="div"
                          className={classes.cardCount}
                        >
                          {" "}
                          {!dashboard ? 'Loading' : `CAD ${dashboard.data.totalSales.toLocaleString()}`}{" "}
                        </Typography>
                      </CardActions>
                    </div>
                  </Card>
                </Col>

                <Col xl="3" lg="4" md="6" sm="12" xs="12" >
                  {/* <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg id="Layer_1" width="50" height="50" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                          <path id="Path_13446" data-name="Path 13446"
                            d="M36.59,20A12.42,12.42,0,1,0,49,32.45,12.42,12.42,0,0,0,36.59,20Z" fill="#e64934" />
                          <path
                            d="M4,49.83H34.38a3.06,3.06,0,0,0,3-2.74c-.24,0-.49,0-.73,0A14.3,14.3,0,0,1,25.19,41.3H7.64a.78.78,0,0,1,0-1.56H24.17v0a15,15,0,0,1-1.9-6.22H7.64a.78.78,0,0,1,0-1.55H22.25a14.84,14.84,0,0,1,1.52-6.21H7.64a.78.78,0,0,1,0-1.55h17a14.32,14.32,0,0,1,12-6.6l.76,0V3.28a3.09,3.09,0,0,0-3-3.11H4A3.07,3.07,0,0,0,1,3.28V46.72A3.07,3.07,0,0,0,4,49.83Z"
                            fill="#feba26" />
                          <rect x="28.61" y="36.42" width="4.01" height="3.9" fill="#fff" />
                          <rect x="34.03" y="34.58" width="4.01" height="5.74" fill="#fff" />
                          <rect x="39.45" y="31.98" width="4.01" height="8.34" fill="#fff" />
                          <polygon points="42.91 27.53 40.42 25.04 43.46 24.5 42.91 27.53" fill="#fff" />
                          <rect x="40.31" y="26.34" width="0.87" height="1.73"
                            transform="translate(-7.3 36.78) rotate(-45)" fill="#fff" />
                          <rect x="39.12" y="27.53" width="0.87" height="1.73"
                            transform="translate(-8.5 36.29) rotate(-45)" fill="#fff" />
                          <polygon
                            points="39.12 29.92 37.93 28.73 35.87 30.89 34.03 29.05 28.88 34.2 30.07 35.39 33.98 31.49 35.92 33.33 39.12 29.92"
                            fill="#fff" />
                          <rect x="6.8" y="2.24" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="13.62" y="2.24" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="20.23" y="2.18" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="26.84" y="2.18" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="6.8" y="8.85" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="13.62" y="8.85" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="20.23" y="8.79" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="26.84" y="8.79" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="6.8" y="15.61" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="13.62" y="15.61" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="20.23" y="15.56" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Current Month Sales
                        </Typography>
                      }
                    />
                    <Divider className={classes.divline} />
                    <CardActions className={classes.countCenter}>
                      <Typography
                        variant="h4"
                        component="div"
                        className={classes.cardCount}
                      >
                        {" "}
                        {!dashboard ? 'Loading' : dashboard.data.totalSalesThisMonth}{" "}
                      </Typography>
                    </CardActions>

                  </Card> */}
                  <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg id="Layer_1" width="50" height="50" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                          <path id="Path_13446" data-name="Path 13446"
                            d="M36.59,20A12.42,12.42,0,1,0,49,32.45,12.42,12.42,0,0,0,36.59,20Z" fill="#e64934" />
                          <path
                            d="M4,49.83H34.38a3.06,3.06,0,0,0,3-2.74c-.24,0-.49,0-.73,0A14.3,14.3,0,0,1,25.19,41.3H7.64a.78.78,0,0,1,0-1.56H24.17v0a15,15,0,0,1-1.9-6.22H7.64a.78.78,0,0,1,0-1.55H22.25a14.84,14.84,0,0,1,1.52-6.21H7.64a.78.78,0,0,1,0-1.55h17a14.32,14.32,0,0,1,12-6.6l.76,0V3.28a3.09,3.09,0,0,0-3-3.11H4A3.07,3.07,0,0,0,1,3.28V46.72A3.07,3.07,0,0,0,4,49.83Z"
                            fill="#feba26" />
                          <rect x="28.61" y="36.42" width="4.01" height="3.9" fill="#fff" />
                          <rect x="34.03" y="34.58" width="4.01" height="5.74" fill="#fff" />
                          <rect x="39.45" y="31.98" width="4.01" height="8.34" fill="#fff" />
                          <polygon points="42.91 27.53 40.42 25.04 43.46 24.5 42.91 27.53" fill="#fff" />
                          <rect x="40.31" y="26.34" width="0.87" height="1.73"
                            transform="translate(-7.3 36.78) rotate(-45)" fill="#fff" />
                          <rect x="39.12" y="27.53" width="0.87" height="1.73"
                            transform="translate(-8.5 36.29) rotate(-45)" fill="#fff" />
                          <polygon
                            points="39.12 29.92 37.93 28.73 35.87 30.89 34.03 29.05 28.88 34.2 30.07 35.39 33.98 31.49 35.92 33.33 39.12 29.92"
                            fill="#fff" />
                          <rect x="6.8" y="2.24" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="13.62" y="2.24" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="20.23" y="2.18" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="26.84" y="2.18" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="6.8" y="8.85" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="13.62" y="8.85" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="20.23" y="8.79" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="26.84" y="8.79" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="6.8" y="15.61" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="13.62" y="15.61" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                          <rect x="20.23" y="15.56" width="4.74" height="4.74" rx="2.37" fill="#fff" />
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Current Month Sales
                        </Typography>
                      }
                    />
                    <Divider color="#feba26" className={classes.divline} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CardActions className={classes.countCenter}>
                        <Typography
                          variant="h4"
                          component="div"
                          className={classes.cardCount}
                        >
                          {" "}
                          {!dashboard ? 'Loading' : `CAD ${dashboard.data.totalSalesThisMonth.toLocaleString()}`}{" "}
                        </Typography>
                      </CardActions>
                    </div>
                  </Card>
                </Col>

                <Col xl="3" lg="4" md="6" sm="12" xs="12" >
                  {/* <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg id="Layer_1" height="50" width="50" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                          <path id="Path_13450" data-name="Path 13450"
                            d="M.73,43.39a9.11,9.11,0,0,1,7.38-6,1.56,1.56,0,0,0,.41-.15A6.92,6.92,0,0,1,3.78,34.4,6.72,6.72,0,0,1,2.68,29a6.88,6.88,0,0,1,13.57.8,7,7,0,0,1-6.1,7.5c.58.12,1.07.21,1.54.33a9.15,9.15,0,0,1,6.91,8.3c0,.68,0,1.36.06,2a1.69,1.69,0,0,1-1.14,1.89H1.22A2.29,2.29,0,0,1,.16,48.79,17.56,17.56,0,0,1,.73,43.39Z"
                            fill="#e64934" />
                          <path
                            d="M49.34,3.8A6,6,0,0,0,44.54.22s-.07,0-.1-.08H24.17s0,.08-.05.08a6.09,6.09,0,0,0-5.2,6.54V34.39a1.31,1.31,0,0,0,.39.91,1.46,1.46,0,0,0,2.05.08c1.78-1.32,3.54-2.64,5.3-4a1.5,1.5,0,0,1,1-.32c5.42,0,10.85,0,16.28,0a6,6,0,0,0,5.57-4A5.36,5.36,0,0,1,49.86,26V5.48A14.18,14.18,0,0,0,49.34,3.8Zm-15,24.08A10.93,10.93,0,1,1,34.38,6h.53L33,4.33,34.22,3l4.07,3.86L34.38,11,33.14,9.74,34.82,8h-.44a9,9,0,1,0,5.47,1.86l1.2-1.55a10.93,10.93,0,0,1-6.67,19.58Zm-3.47-8.75,2.3-.43a1.39,1.39,0,0,0,1.5,1q.93,0,.93-.51a.47.47,0,0,0-.22-.42,2.27,2.27,0,0,0-.77-.27,5.23,5.23,0,0,1-2.75-1.12,2.4,2.4,0,0,1-.58-1.57A2.62,2.62,0,0,1,32,14.08a3,3,0,0,1,1.94-.95V12h1.27v1.1a3.05,3.05,0,0,1,2.77,2l-2.06.64a1.25,1.25,0,0,0-1.29-.83c-.54,0-.81.17-.81.52a.42.42,0,0,0,.14.33,1.82,1.82,0,0,0,.76.27,15.2,15.2,0,0,1,2,.56,2.23,2.23,0,0,1,1,.83,2.42,2.42,0,0,1,.41,1.39,2.49,2.49,0,0,1-.8,1.88,3.67,3.67,0,0,1-2.19.92v1.14H33.9V21.63A3.24,3.24,0,0,1,30.91,19.13Z"
                            fill="#feba26" />
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Total Refund
                        </Typography>
                      }
                    />
                    <Divider className={classes.divline} />
                    <CardActions className={classes.countCenter}>
                      <Typography
                        variant="h4"
                        component="div"
                        className={classes.cardCount}
                      >
                        {" "}
                        {!dashboard ? 'Loading' : dashboard.data.totalRefund}{" "}
                      </Typography>
                    </CardActions>

                  </Card> */}
                  <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg id="Layer_1" height="50" width="50" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                          <path id="Path_13450" data-name="Path 13450"
                            d="M.73,43.39a9.11,9.11,0,0,1,7.38-6,1.56,1.56,0,0,0,.41-.15A6.92,6.92,0,0,1,3.78,34.4,6.72,6.72,0,0,1,2.68,29a6.88,6.88,0,0,1,13.57.8,7,7,0,0,1-6.1,7.5c.58.12,1.07.21,1.54.33a9.15,9.15,0,0,1,6.91,8.3c0,.68,0,1.36.06,2a1.69,1.69,0,0,1-1.14,1.89H1.22A2.29,2.29,0,0,1,.16,48.79,17.56,17.56,0,0,1,.73,43.39Z"
                            fill="#e64934" />
                          <path
                            d="M49.34,3.8A6,6,0,0,0,44.54.22s-.07,0-.1-.08H24.17s0,.08-.05.08a6.09,6.09,0,0,0-5.2,6.54V34.39a1.31,1.31,0,0,0,.39.91,1.46,1.46,0,0,0,2.05.08c1.78-1.32,3.54-2.64,5.3-4a1.5,1.5,0,0,1,1-.32c5.42,0,10.85,0,16.28,0a6,6,0,0,0,5.57-4A5.36,5.36,0,0,1,49.86,26V5.48A14.18,14.18,0,0,0,49.34,3.8Zm-15,24.08A10.93,10.93,0,1,1,34.38,6h.53L33,4.33,34.22,3l4.07,3.86L34.38,11,33.14,9.74,34.82,8h-.44a9,9,0,1,0,5.47,1.86l1.2-1.55a10.93,10.93,0,0,1-6.67,19.58Zm-3.47-8.75,2.3-.43a1.39,1.39,0,0,0,1.5,1q.93,0,.93-.51a.47.47,0,0,0-.22-.42,2.27,2.27,0,0,0-.77-.27,5.23,5.23,0,0,1-2.75-1.12,2.4,2.4,0,0,1-.58-1.57A2.62,2.62,0,0,1,32,14.08a3,3,0,0,1,1.94-.95V12h1.27v1.1a3.05,3.05,0,0,1,2.77,2l-2.06.64a1.25,1.25,0,0,0-1.29-.83c-.54,0-.81.17-.81.52a.42.42,0,0,0,.14.33,1.82,1.82,0,0,0,.76.27,15.2,15.2,0,0,1,2,.56,2.23,2.23,0,0,1,1,.83,2.42,2.42,0,0,1,.41,1.39,2.49,2.49,0,0,1-.8,1.88,3.67,3.67,0,0,1-2.19.92v1.14H33.9V21.63A3.24,3.24,0,0,1,30.91,19.13Z"
                            fill="#feba26" />
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Total Refund
                        </Typography>
                      }
                    />
                    <Divider color="#feba26" className={classes.divline} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CardActions className={classes.countCenter}>
                        <Typography
                          variant="h4"
                          component="div"
                          className={classes.cardCount}
                        >
                          {" "}
                          {!dashboard ? 'Loading' : `CAD ${dashboard.data.totalRefund.toLocaleString()}`}{" "}
                        </Typography>
                      </CardActions>
                    </div>
                  </Card>
                </Col>

                <Col xl="3" lg="4" md="6" sm="12" xs="12" >
                  {/* <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg id="Layer_1" height="50" width="50" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                          <path
                            d="M4,49.86H34.39a3.07,3.07,0,0,0,3-2.74h-.73a14.31,14.31,0,0,1-11.48-5.8H7.62a.78.78,0,1,1,0-1.56H24.17a15,15,0,0,1-1.9-6.23H7.62a.78.78,0,0,1-.69-.86A.79.79,0,0,1,7.62,32H22.25a14.92,14.92,0,0,1,1.52-6.22H7.62a.78.78,0,0,1-.69-.86.79.79,0,0,1,.69-.69h17a14.34,14.34,0,0,1,12-6.61h.76V3.25a3.1,3.1,0,0,0-3-3.11H4A3.07,3.07,0,0,0,1,3.25v43.5A3.07,3.07,0,0,0,4,49.86Z"
                            fill="#feba26" />
                          <path
                            d="M9.15,2.21h0a2.37,2.37,0,0,1,2.37,2.37h0A2.38,2.38,0,0,1,9.15,7h0A2.38,2.38,0,0,1,6.78,4.58h0A2.37,2.37,0,0,1,9.15,2.21Z"
                            fill="#fff" />
                          <path
                            d="M16,2.21h0a2.37,2.37,0,0,1,2.37,2.37h0A2.38,2.38,0,0,1,16,7h0A2.39,2.39,0,0,1,13.6,4.58h0A2.38,2.38,0,0,1,16,2.21Z"
                            fill="#fff" />
                          <path
                            d="M22.6,2.15h0A2.37,2.37,0,0,1,25,4.52h0A2.38,2.38,0,0,1,22.6,6.9h0a2.39,2.39,0,0,1-2.38-2.38h0A2.38,2.38,0,0,1,22.6,2.15Z"
                            fill="#fff" />
                          <path
                            d="M29.22,2.15h0a2.37,2.37,0,0,1,2.37,2.37h0A2.38,2.38,0,0,1,29.22,6.9h0a2.39,2.39,0,0,1-2.38-2.38h0A2.38,2.38,0,0,1,29.22,2.15Z"
                            fill="#fff" />
                          <path
                            d="M9.15,8.83h0a2.37,2.37,0,0,1,2.37,2.37h0a2.37,2.37,0,0,1-2.37,2.37h0A2.37,2.37,0,0,1,6.78,11.2h0A2.37,2.37,0,0,1,9.15,8.83Z"
                            fill="#fff" />
                          <path
                            d="M16,8.83h0a2.37,2.37,0,0,1,2.37,2.37h0A2.37,2.37,0,0,1,16,13.57h0A2.38,2.38,0,0,1,13.6,11.2h0A2.38,2.38,0,0,1,16,8.83Z"
                            fill="#fff" />
                          <path
                            d="M22.6,8.77h0A2.37,2.37,0,0,1,25,11.14h0a2.37,2.37,0,0,1-2.37,2.37h0a2.38,2.38,0,0,1-2.38-2.37h0A2.38,2.38,0,0,1,22.6,8.77Z"
                            fill="#fff" />
                          <path
                            d="M29.22,8.77h0a2.37,2.37,0,0,1,2.37,2.37h0a2.37,2.37,0,0,1-2.37,2.37h0a2.38,2.38,0,0,1-2.38-2.37h0A2.38,2.38,0,0,1,29.22,8.77Z"
                            fill="#fff" />
                          <path
                            d="M9.15,15.6h0A2.37,2.37,0,0,1,11.52,18h0a2.37,2.37,0,0,1-2.37,2.37h0A2.37,2.37,0,0,1,6.78,18h0A2.37,2.37,0,0,1,9.15,15.6Z"
                            fill="#fff" />
                          <path
                            d="M16,15.6h0A2.37,2.37,0,0,1,18.35,18h0A2.37,2.37,0,0,1,16,20.34h0A2.38,2.38,0,0,1,13.6,18h0A2.38,2.38,0,0,1,16,15.6Z"
                            fill="#fff" />
                          <path
                            d="M22.6,15.55h0A2.37,2.37,0,0,1,25,17.92h0a2.37,2.37,0,0,1-2.37,2.37h0a2.38,2.38,0,0,1-2.38-2.37h0A2.38,2.38,0,0,1,22.6,15.55Z"
                            fill="#fff" />
                          <path
                            d="M36.63,20h0A12.44,12.44,0,1,0,49,32.46,12.44,12.44,0,0,0,36.61,20Zm-.25,20.93a7.8,7.8,0,0,1,0-15.6h.38l-1.37-1.22.88-1,2.91,2.76-2.8,2.94L35.5,28l1.2-1.24h-.32a6.39,6.39,0,1,0,3.91,1.33l.85-1.11a7.8,7.8,0,0,1-4.76,14Zm-2.47-6.25,1.64-.31a1,1,0,0,0,1.06.73c.45,0,.67-.13.67-.37a.34.34,0,0,0-.15-.3,1.88,1.88,0,0,0-.55-.19,3.63,3.63,0,0,1-2-.8,1.66,1.66,0,0,1-.42-1.12,1.83,1.83,0,0,1,.46-1.24A2.12,2.12,0,0,1,36,30.39v-.8h.9v.78a2.16,2.16,0,0,1,2,1.44l-1.47.46a.88.88,0,0,0-.91-.6c-.39,0-.59.13-.59.38a.33.33,0,0,0,.1.23,1.53,1.53,0,0,0,.55.2,10.41,10.41,0,0,1,1.45.39,1.65,1.65,0,0,1,.74.6,1.72,1.72,0,0,1,.29,1,1.78,1.78,0,0,1-.57,1.34,2.67,2.67,0,0,1-1.57.66v.81H36v-.81A2.31,2.31,0,0,1,33.91,34.67Z"
                            fill="#e64934" />
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Current Month Refund
                        </Typography>
                      }
                    />
                    <Divider className={classes.divline} />
                    <CardActions className={classes.countCenter}>
                      <Typography
                        variant="h4"
                        component="div"
                        className={classes.cardCount}
                      >
                        {" "}
                        {!dashboard ? 'Loading' : dashboard.data.totalRefundThisMonth}{" "}
                      </Typography>
                    </CardActions>
                  </Card> */}
                  <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg id="Layer_1" height="50" width="50" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                          <path
                            d="M4,49.86H34.39a3.07,3.07,0,0,0,3-2.74h-.73a14.31,14.31,0,0,1-11.48-5.8H7.62a.78.78,0,1,1,0-1.56H24.17a15,15,0,0,1-1.9-6.23H7.62a.78.78,0,0,1-.69-.86A.79.79,0,0,1,7.62,32H22.25a14.92,14.92,0,0,1,1.52-6.22H7.62a.78.78,0,0,1-.69-.86.79.79,0,0,1,.69-.69h17a14.34,14.34,0,0,1,12-6.61h.76V3.25a3.1,3.1,0,0,0-3-3.11H4A3.07,3.07,0,0,0,1,3.25v43.5A3.07,3.07,0,0,0,4,49.86Z"
                            fill="#feba26" />
                          <path
                            d="M9.15,2.21h0a2.37,2.37,0,0,1,2.37,2.37h0A2.38,2.38,0,0,1,9.15,7h0A2.38,2.38,0,0,1,6.78,4.58h0A2.37,2.37,0,0,1,9.15,2.21Z"
                            fill="#fff" />
                          <path
                            d="M16,2.21h0a2.37,2.37,0,0,1,2.37,2.37h0A2.38,2.38,0,0,1,16,7h0A2.39,2.39,0,0,1,13.6,4.58h0A2.38,2.38,0,0,1,16,2.21Z"
                            fill="#fff" />
                          <path
                            d="M22.6,2.15h0A2.37,2.37,0,0,1,25,4.52h0A2.38,2.38,0,0,1,22.6,6.9h0a2.39,2.39,0,0,1-2.38-2.38h0A2.38,2.38,0,0,1,22.6,2.15Z"
                            fill="#fff" />
                          <path
                            d="M29.22,2.15h0a2.37,2.37,0,0,1,2.37,2.37h0A2.38,2.38,0,0,1,29.22,6.9h0a2.39,2.39,0,0,1-2.38-2.38h0A2.38,2.38,0,0,1,29.22,2.15Z"
                            fill="#fff" />
                          <path
                            d="M9.15,8.83h0a2.37,2.37,0,0,1,2.37,2.37h0a2.37,2.37,0,0,1-2.37,2.37h0A2.37,2.37,0,0,1,6.78,11.2h0A2.37,2.37,0,0,1,9.15,8.83Z"
                            fill="#fff" />
                          <path
                            d="M16,8.83h0a2.37,2.37,0,0,1,2.37,2.37h0A2.37,2.37,0,0,1,16,13.57h0A2.38,2.38,0,0,1,13.6,11.2h0A2.38,2.38,0,0,1,16,8.83Z"
                            fill="#fff" />
                          <path
                            d="M22.6,8.77h0A2.37,2.37,0,0,1,25,11.14h0a2.37,2.37,0,0,1-2.37,2.37h0a2.38,2.38,0,0,1-2.38-2.37h0A2.38,2.38,0,0,1,22.6,8.77Z"
                            fill="#fff" />
                          <path
                            d="M29.22,8.77h0a2.37,2.37,0,0,1,2.37,2.37h0a2.37,2.37,0,0,1-2.37,2.37h0a2.38,2.38,0,0,1-2.38-2.37h0A2.38,2.38,0,0,1,29.22,8.77Z"
                            fill="#fff" />
                          <path
                            d="M9.15,15.6h0A2.37,2.37,0,0,1,11.52,18h0a2.37,2.37,0,0,1-2.37,2.37h0A2.37,2.37,0,0,1,6.78,18h0A2.37,2.37,0,0,1,9.15,15.6Z"
                            fill="#fff" />
                          <path
                            d="M16,15.6h0A2.37,2.37,0,0,1,18.35,18h0A2.37,2.37,0,0,1,16,20.34h0A2.38,2.38,0,0,1,13.6,18h0A2.38,2.38,0,0,1,16,15.6Z"
                            fill="#fff" />
                          <path
                            d="M22.6,15.55h0A2.37,2.37,0,0,1,25,17.92h0a2.37,2.37,0,0,1-2.37,2.37h0a2.38,2.38,0,0,1-2.38-2.37h0A2.38,2.38,0,0,1,22.6,15.55Z"
                            fill="#fff" />
                          <path
                            d="M36.63,20h0A12.44,12.44,0,1,0,49,32.46,12.44,12.44,0,0,0,36.61,20Zm-.25,20.93a7.8,7.8,0,0,1,0-15.6h.38l-1.37-1.22.88-1,2.91,2.76-2.8,2.94L35.5,28l1.2-1.24h-.32a6.39,6.39,0,1,0,3.91,1.33l.85-1.11a7.8,7.8,0,0,1-4.76,14Zm-2.47-6.25,1.64-.31a1,1,0,0,0,1.06.73c.45,0,.67-.13.67-.37a.34.34,0,0,0-.15-.3,1.88,1.88,0,0,0-.55-.19,3.63,3.63,0,0,1-2-.8,1.66,1.66,0,0,1-.42-1.12,1.83,1.83,0,0,1,.46-1.24A2.12,2.12,0,0,1,36,30.39v-.8h.9v.78a2.16,2.16,0,0,1,2,1.44l-1.47.46a.88.88,0,0,0-.91-.6c-.39,0-.59.13-.59.38a.33.33,0,0,0,.1.23,1.53,1.53,0,0,0,.55.2,10.41,10.41,0,0,1,1.45.39,1.65,1.65,0,0,1,.74.6,1.72,1.72,0,0,1,.29,1,1.78,1.78,0,0,1-.57,1.34,2.67,2.67,0,0,1-1.57.66v.81H36v-.81A2.31,2.31,0,0,1,33.91,34.67Z"
                            fill="#e64934" />
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Current Month Refund
                        </Typography>
                      }
                    />
                    <Divider color="#feba26" className={classes.divline} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CardActions className={classes.countCenter}>
                        <Typography
                          variant="h4"
                          component="div"
                          className={classes.cardCount}
                        >
                          {" "}
                          {!dashboard ? 'Loading' : `CAD ${dashboard.data.totalRefundThisMonth.toLocaleString()}`}{" "}
                        </Typography>
                      </CardActions>
                    </div>
                  </Card>
                </Col>

              </Row>
            </div>
          ) : (
            <Box style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
              <p>{dashboard.message || "No Data Found"} </p>
            </Box>
          )}
      </div>
    </>
  );
};

export default Dashboard;
