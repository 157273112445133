import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import * as Actions from "../../../../../reducer-store/actions/schoolActions";
import * as AuthActions from "../../../../../reducer-store/actions/authActions";
import Box from "@material-ui/core/Box";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../../Common.css";
import { Col, Row } from "react-bootstrap";
import FormFieldComponent from "../../../../../common-components/FormFieldComponent";
import { CircularProgress } from "@mui/material";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
    boxBorder: {
        backgroundColor: "#ffffff !important",
        boxShadow: "0px 0px 2px #8e8e8e !important",
        borderRadius: "5px !important",
    },
    searchBar: {
        borderColor: "black !important",
        "& hover": {
            borderColor: "black"
        },
        "& focused": {
            borderColor: "black"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "black"
        }
    },
    boxBorderCurrent: {
        boxShadow: "0px 0px 5px #8e8e8e !important",
        backgroundColor: "#F9F9F9",
        borderRadius: "5px !important",
        padding: '20px !important',
    },
    boxBorderHistory: {
        boxShadow: "0px 0px 5px #8e8e8e !important",
        backgroundColor: "#DFDFDF !important",
        borderRadius: "5px !important",
        padding: '20px !important',
        marginTop: '10px'
    },
    divStyling: {
        padding: "15px 20px !important",
    },
    switchRight: {
        textAlign: "right",
    },
    rowStyling: {
        marginLeft: 20,
        marginTop: 10,
        marginBottom: 10,
    },
    colStyling: {
        // marginTop: 10,
        // marginBottom: 10,
    },
    valueStyle: {
        fontFamily: "Arial !important",
        fontWeight: 900,
        letterSpacing: '0.06px !important',
        color: '#3A3A3A !important',
        fontSize: '18px'
    },
}));

type onBoardingProps = {
    onBoarding: any;
};

const PlanDetails: React.FC<onBoardingProps> = ({ onBoarding }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const AuthToken = useSelector((state: any) => state.auth);
    const { token, plans, user } = AuthToken;
    const { planHistorySingle } = bindActionCreators(AuthActions, dispatch);
    const [totalTaxAmount, setTotalTaxAmount] = useState<any>("");
    const [totalTax, setTotalTax] = useState<any>("");
    const [planHistoryData, setPlanHistoryData] = useState<any>([]);
    const [currentPlanStart, setCurrentPlanStart] = useState('');

    useEffect(() => {
        (async () => {
            const response: any = await planHistorySingle(token, onBoarding?.id);
            const { data } = response;
            setPlanHistoryData(data);

            const provinceTaxData: any = await Actions.GetProvinceTax(onBoarding?.state);
            setTotalTax((provinceTaxData?.total_tax / 100) * onBoarding?.Plan?.price);
            setTotalTaxAmount((provinceTaxData?.total_tax / 100) * onBoarding?.Plan?.price + onBoarding?.Plan?.price);
            setCurrentPlanStart(data && data[0]?.plan_end_date || user?.School?.created_at);
        })();
    }, []);

    if (!onBoarding) {
        return (
            < Box style={{ textAlign: 'center', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
                <CircularProgress sx={{ color: '#707070' }} />
            </Box >
        )
    }

    return (
        <>
            <Box className={classes.boxBorder}>
                <div>
                    <Paper className={classes.boxBorderCurrent}>
                        <Row className={classes.rowStyling}>
                            <Col lg='12' sm="12" className={classes.colStyling} >
                                <span className={classes.valueStyle}>Current Plan Details</span>
                            </Col>
                        </Row>
                        <Row className={classes.rowStyling} >
                            <Col lg='4' sm="12" className={classes.colStyling}>
                                <FormFieldComponent FormFieldLable="Plan Name" FormFieldValue={`${onBoarding?.Plan ? onBoarding?.Plan?.name : "---"}`} />
                            </Col>
                            <Col lg='4' sm="12" className={classes.colStyling}>
                                <FormFieldComponent FormFieldLable="Plan Price" FormFieldValue={`CAD ${onBoarding?.Plan ? new Number(onBoarding?.Plan?.price).toFixed(2) : "---"}`} />
                            </Col>
                            <Col lg='4' sm="12" className={classes.colStyling}>
                                <FormFieldComponent FormFieldLable="Total Amount" FormFieldValue={`CAD ${onBoarding?.Plan ? new Number(onBoarding?.Plan?.total_amount).toFixed(2) : "---"}`} />
                            </Col>
                        </Row>

                        <Row className={classes.rowStyling}>
                            <Col lg='4' sm="12" className={classes.colStyling} >
                                <FormFieldComponent FormFieldLable="GST/HST" FormFieldValue={`CAD ${Number(onBoarding?.Plan?.tax2).toFixed(2) || "0.00"}`} />
                            </Col>
                            <Col lg='4' sm="12" className={classes.colStyling} >
                                <FormFieldComponent FormFieldLable="Provincial/Territorial Sales Tax" FormFieldValue={`CAD ${Number(onBoarding?.Plan?.tax1).toFixed(2) || "0.00"}`} />
                            </Col>
                            <Col lg='4' sm="12" className={classes.colStyling} >
                                <FormFieldComponent FormFieldLable="Combined Tax" FormFieldValue={`CAD ${Number(totalTax).toFixed(2) || "0.00"}`} />
                            </Col>
                        </Row>

                        <Row className={classes.rowStyling} >
                            <Col lg='4' sm="12" className={classes.colStyling}>
                                <FormFieldComponent FormFieldLable="Valid From" FormFieldValue={moment(currentPlanStart).format("YYYY-MM-DD")} />
                            </Col>
                            <Col lg='4' sm="12" className={classes.colStyling}>
                                <FormFieldComponent FormFieldLable="Expiry" FormFieldValue={moment(onBoarding?.license?.expiry_timestamp).format("YYYY-MM-DD")} />
                            </Col>
                        </Row>
                    </Paper>
                </div>
            </Box>

            {/* {onBoarding?.planHistory?.length > 0 ?
                <Box className={classes.boxBorder}>
                    <div>
                        <Paper className={classes.boxBorderCurrent}>
                            <Row className={classes.rowStyling}>
                                <Col lg='12' sm="12" className={classes.colStyling}>
                                    <span className={classes.valueStyle}>Plan History</span>
                                </Col>
                            </Row>
                            <Row className={classes.rowStyling} >
                                <Col lg='4' sm="12" className={classes.colStyling}>
                                    <FormFieldComponent FormFieldLable="Plan Name" FormFieldValue={`${onBoarding.planHistory.length > 0 ? onBoarding?.planHistory[0]?.PreviousPlan.name : "---"}`} />
                                </Col>
                                <Col lg='4' sm="12" className={classes.colStyling}>
                                    <FormFieldComponent FormFieldLable="Plan Price" FormFieldValue={`CAD ${onBoarding.planHistory.length > 0 ? new Number(onBoarding.planHistory[0]?.PreviousPlan.price).toFixed(2) : "---"}`} />
                                </Col>
                                <Col lg='4' sm="12" className={classes.colStyling}>
                                    <FormFieldComponent FormFieldLable="Plan Tax Amount" FormFieldValue={`CAD ${onBoarding.planHistory.length > 0 ? new Number(onBoarding.planHistory[0]?.PreviousPlan.tax_amount).toFixed(2) : "---"}`} />
                                </Col>
                            </Row>

                            <Row className={classes.rowStyling}>
                                <Col lg='4' sm="12" className={classes.colStyling}>
                                    <FormFieldComponent FormFieldLable="Total Amount" FormFieldValue={`CAD ${onBoarding.planHistory.length > 0 ? new Number(onBoarding.planHistory[0]?.PreviousPlan.total_amount).toFixed(2) : "---"}`} />
                                </Col>
                                <Col lg='4' sm="12" className={classes.colStyling}>
                                    <FormFieldComponent FormFieldLable="Plan Start Date" FormFieldValue={moment(onBoarding.license?.created_at).format("YYYY-MM-DD")} />
                                </Col>
                                <Col lg='4' sm="12" className={classes.colStyling}>
                                    <FormFieldComponent FormFieldLable="Plan End Date" FormFieldValue={moment(onBoarding.license?.expiry_timestamp).format("YYYY-MM-DD")} />
                                </Col>
                            </Row>
                        </Paper>
                    </div>
                </Box>
                : null} */}

            <div >
                {planHistoryData.length > 0 ?
                    planHistoryData.map((data: any, index: any) => (
                        <Box className={classes.boxBorder}>
                            <div>
                                <Paper className={classes.boxBorderHistory}>
                                    <Row className={classes.rowStyling}>
                                        <Col lg='12' sm="12" className={classes.colStyling} >
                                            <span className={classes.valueStyle}>Plan History ({data?.history_type} Plan)</span>
                                        </Col>
                                    </Row>
                                    <Row className={classes.rowStyling} >
                                        <Col lg='4' sm="12" className={classes.colStyling}>
                                            <FormFieldComponent FormFieldLable="Plan Name" FormFieldValue={`${data?.PreviousPlan?.name}`} />
                                        </Col>
                                        <Col lg='4' sm="12" className={classes.colStyling}>
                                            <FormFieldComponent FormFieldLable={`Plan Amount`} FormFieldValue={`CAD ${Number(data?.prev_total_amount - data?.prev_tax_amount).toFixed(2)}`} />
                                        </Col>
                                        <Col lg='4' sm="12" className={classes.colStyling}>
                                            <FormFieldComponent FormFieldLable={`Total Amount Paid For ${data?.history_type}`} FormFieldValue={`CAD ${Number(data?.total_amount).toFixed(2)}`} />
                                        </Col>
                                    </Row>

                                    <Row className={classes.rowStyling}>
                                        <Col lg='4' sm="12" className={classes.colStyling}>
                                            <FormFieldComponent FormFieldLable={`Total Plan Amount (Excluding Tax)`} FormFieldValue={`CAD ${Number(data?.amount).toFixed(2)}`} />
                                        </Col>
                                        <Col lg='4' sm="12" className={classes.colStyling} >
                                            <FormFieldComponent FormFieldLable="GST/HST" FormFieldValue={`CAD ${data?.tax2_amount ? Number(data?.tax2_amount).toFixed(2) : "0.00"}`} />
                                        </Col>
                                        <Col lg='4' sm="12" className={classes.colStyling} >
                                            <FormFieldComponent FormFieldLable="Provincial/Territorial Sales Tax" FormFieldValue={`CAD ${data?.tax1_amount ? (data?.tax1_amount).toFixed(2) : "0.00"}`} />
                                        </Col>

                                    </Row>

                                    <Row className={classes.rowStyling} >
                                        <Col lg='4' sm="12" className={classes.colStyling} >
                                            <FormFieldComponent FormFieldLable="Combined Tax" FormFieldValue={`CAD ${data?.tax_amount ? Number(data?.tax_amount).toFixed(2) : "0.00"}`} />
                                        </Col>
                                        <Col lg='4' sm="12" className={classes.colStyling}>
                                            <FormFieldComponent FormFieldLable="Valid From" FormFieldValue={moment(data?.plan_start_date).format("YYYY-MM-DD")} />
                                        </Col>
                                        <Col lg='4' sm="12" className={classes.colStyling}>
                                            <FormFieldComponent FormFieldLable="Expiry" FormFieldValue={moment(data?.plan_end_date).format("YYYY-MM-DD")} />
                                        </Col>
                                    </Row>
                                </Paper>
                            </div>
                        </Box>
                    ))
                    : null}
            </div>

        </>
    );
};

export default PlanDetails;
