import React from "react";
import {
  IconButton,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import NotificationMessage from "../common-components/NotificationMessage";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  closeicon: {
    textAlign: "right",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 0",
    backgroundColor: "#00A651",
    fontSize: "18px",
    letterSpacing: "0.07px",
    color: "#fff",
    height: "50px",
  },
  box: {
    width: "250px",
    margin: "0px auto",
    textAlign: "center",
  },
  img: {
    width: "100px",
    padding: "30px 0",
  },
  button: {
    marginBottom: "10px",
    background: "#4a90e2",
    width: "219px",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: "#4a90e2",
    },
  },
  typography: {
    color: "#091e42",
    fontSize: 14,
    fontWeight: 400,
  },
}));

interface SidebarPanelProps {
  handleDrawerClose: () => void;
  notification: {
    data: NotificationProps[]
  }
};

interface NotificationProps {
  messages: string;
  id: string;
  created_at: string
}

const SidebarRegister: React.FC<SidebarPanelProps> = ({
  handleDrawerClose,
  notification
}) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.closeicon}>
        <Typography variant="h6" style={{ paddingLeft: "15px" }}>All Notification</Typography>
        <IconButton style={{ color: "#fff" }}>
          <CloseIcon onClick={handleDrawerClose} />
        </IconButton>
      </div>
      <ul style={{ listStyleType: "none", padding: "0", marginBottom: "0" }}>
        {
          notification && notification.data.length > 0 ? notification.data.map((notification, index) => {
            return <NotificationMessage
              key={`notification_${index}`}
              title="Notification"
              time={moment(new Date(notification.created_at)).startOf('minute').fromNow()}
              content={notification.messages} />
          }) : <li style={{ padding: "10px 15px", borderBottom: "3px solid #CBCBCB", textAlign: 'left' }}>
            <span>No notifcations found</span>
          </li>
        }
      </ul>
    </div>
  );
};

export { SidebarRegister };
