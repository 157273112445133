import { ActionProps } from "../../ts";
import {
  COUNT_OF_UNREAD_COMMUNICATION,
  COUNT_OF_UNREAD_COMMUNICATION_ERROR,
  LIST_OF_COMMUNICATION,
  LIST_OF_COMMUNICATION_ERROR,
} from "../ActionTypes";

const initialState = { messages: null, messagesCount: null };

const communicationStore = (state: any = initialState, action: ActionProps) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_OF_COMMUNICATION: {
      return {
        ...state,
        messages: payload,
      };
    }
    case LIST_OF_COMMUNICATION_ERROR: {
      return {
        ...state,
        messages: null,
      };
    }
    case COUNT_OF_UNREAD_COMMUNICATION: {
      return {
        ...state,
        messagesCount: payload,
      };
    }
    case COUNT_OF_UNREAD_COMMUNICATION_ERROR: {
      return {
        ...state,
        messagesCount: null,
      };
    }
    default:
      return state;
  }
};

export default communicationStore;
