import React, { useState, useEffect, SetStateAction } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Row, Col } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputField,
  InputSelectField,
} from "../../../common-components/FormComponent/FormFieldsComponent";
import {
  isRequiredField,
  isValidRegisterID,
  isValidNo
} from "../../../common-components/FormComponent/FormValidator";
import {
  CancelButtonComponent,
  SaveButtonComponent,
} from "../../../common-components/ButtonComponent";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { FaRegEdit } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Box from "@mui/material/Box";
// import Collapse from "@mui/material/Collapse";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, CircularProgress } from "@mui/material";
import TypographyComponent from "./TableComponent/TypographyComponent";
import * as Actions from "../../../reducer-store/actions/programsAction";
import { DropDownProgramList, ModualListByProgramId } from "../../../reducer-store/actions/topicAction";
import {
  PROGRAM_SUCCESSFULLY_CREATED,
  MODUAL_SUCCESSFULLY_CREATED,
  PROGRAM_SUCCESSFULLY_UPDATED,
  MODUAL_SUCCESSFULLY_UPDATED,
} from "../../../reducer-store/ToastMessage";
import { toast } from "react-toastify";
import "../../Common.css";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { PAGE } from "../../../reducer-store/constants";
import ToastMessage from "../../../common-components/ToastMessage";
import { ROUTES } from "../../../constants/routes";
// import { Class, ImportantDevices, SearchOutlined } from '@material-ui/icons';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
// import { FormHelperText, Input, InputLabel, TextField } from '@material-ui/core';
// import PaginationComponent from "../../../common-components/PaginationComponent";
import { MARKSLIMITE } from "../../../reducer-store/constants";
// import CommonHeadPart from "../../../common-components/CommonHeadPart";
// import ProgramIcon from "../../../svg_icons/program.svg";
// import { ClassNames } from "@emotion/react";
import { AiOutlineFolderAdd } from "react-icons/ai";

const useStyles = makeStyles(() => ({
  programCard: {
    backgroundColor: "#ececec",
  },
  button_save: {
    textAlign: "right",
  },
  headDisable: {
    color: "rgba(0, 0, 0, 0.38) !important",
    fontSize: "16px !important",
    fontWeight: 600,
  },
  fieldDisable: {
    color: "rgba(0, 0, 0, 0.38) !important",
    fontSize: "16px !important",
  },
  addModule: {
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontWeight: 500,
    fontFamily: 'Arial !important',
    fontSize: "0.875rem",
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "capitalize",
    minWidth: "64px",
  },
  saveEmailButton1: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #000000 !important",
    color: "#000000 !important",
    padding: "0 35px !important",
    height: "40px !important",
    marginRight: 20,
    marginBottom: 15,
    textTransform: "capitalize",
  },
  searchBar: {
    borderColor: "black",
    "& hover": {
      borderColor: "black"
    },
    "& focused": {
      borderColor: "black"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black"
    }
  },
  dialogTitle: {
    backgroundColor: "#646464",
    color: "#ffffff",
  },
}));

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .css-1ygcj2i-MuiTableCell-root": {
            fontweight: "bold",
            color: "#FFFFFF",
          },
          "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
            display: "-webkit-box"
          },
          "& .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader": {
            backgroundColor: "#404040",
          },
          "& .tss-1akey0g-MUIDataTableHeadCell-data": {
            color: "#FFFFFF",
            fontFamily: 'Arial'
          },
          "& .tss-1fz4yw6-MUIDataTableSelectCell-fixedLeft": {
            backgroundColor: "#404040",
          },
          // "& .css-11k72hi-MuiTableCell-root.MuiTableCell-head": {
          //   backgroundColor: "#646464",
          // },
          // "& .css-1ge9txp-MuiTableCell-root.MuiTableCell-head": {
          //   backgroundColor: "#646464",
          // },
          "& .tss-tlx3x1-MUIDataTableToolbar-root": {
            minHeight: "0px !important",
          },
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Arial'
        },
        head: {
          color: "#FFFFFF",
          fontFamily: 'Arial',
        },
        footer: {
          backgroundColor: "#FFFFFF"
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          fill: "#FFFFFF"
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#E53D12',
          borderRadius: '4px',
          color: 'white',
          marginRight: '25px',
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: "#DFDFDF"
          },
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "0px !important",
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& .MuiTablePagination-displayedRows": {
            marginTop: "9%"
          },
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          minHeight: 0,
        }
      }
    }
  }
});

type AddProgramModulesProps = {
  handleStep: React.Dispatch<SetStateAction<number>>;
  stepValue: number;
  editProgramInfo?: any;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} style={{ color: "#ffffff", backgroundColor: "#646464" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[100],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function AddProgramModules({
  handleStep,
  editProgramInfo,
}: AddProgramModulesProps) {
  const classes = useStyles();
  const params: any = useParams();
  const { id } = params;
  const [open, setOpen] = React.useState(false);
  const [programName, setProgramName] = useState("");
  const [addProgramCheck, setAddProgramCheck] = useState(false);
  const [programCode, setProgramCode] = useState("");
  const [totalHours, setTotalHours] = useState<any>(null);
  const [inClassTrainingHours, setInclassTrainingHours] = useState<any>(null);
  const [inYardTrainingHours, setInyardTrainingHours] = useState<any>(null);
  const [inCabTrainingHours, setIncabTrainingHours] = useState<any>(null);
  const [vehicleType, setVehicleType] = useState("");
  const [country, setCountry] = useState("1");
  const [province, setProvince] = useState("");
  const [provinces, setProvinces] = useState([{ id: "", name: "Choose province" }]);
  const [description, setDescription] = useState("");
  const [meltCompatible, setMeltCompatible] = useState(false);
  const [data, setData] = useState([]);
  // const [filterVal, setFilterVal] = useState('');

  // Module
  const [Program, setProgram] = useState("");
  const [displayProgram, setDisplayProgram] = useState("");
  const [programId, setProgramId] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [moduleVisible, setModuleVisible] = useState(false);
  const [moduleTotalHours, setModuleTotalHours] = useState<any>(null);
  const [moduleInClassTrainingHours, setModuleInclassTrainingHours] = useState<any>(null);
  const [moduleInYardTrainingHours, setModuleInyardTrainingHours] = useState<any>(null);
  const [moduleInCabTrainingHours, setModuleIncabTrainingHours] = useState<any>(null);
  const [moduleAssessmentMax, setModuleAssessmentMax] = useState("");
  const [moduleMinimumPassTheory, setModuleMinimumPassTheory] = useState("");
  const [moduleMinimumPassPractical, setModuleMinimumPassPractical] = useState("");
  const [moduleTheoryTestMax, setModuleTheoryTestMax] = useState("");
  const [moduleDependency, setModuleDependency] = useState<any>("");

  const [moduleTitleObjective, setModuleTitleObjective] = useState("");
  const [moduleDescription, setModuleDescription] = useState("");
  const [saveUpdate, setSaveUpdate] = useState(false)
  const [programList, setProgramList] = useState([{ id: "", name: "Select Program" }])

  // End Module

  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitModule, setIsSubmitModule] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const modualList = useSelector((state: any) => state.modual);
  const { moduals } = modualList;
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    addProgram,
    updateProgram,
    addModual,
    updateModual,
    GetCountryAndProvinceList,
    GetProvinceListByCountryId
  } = Actions;
  const { ModualList } = bindActionCreators(Actions, dispatch);
  const [page, setPage] = useState(PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [moduleId, setModuleId] = useState("");
  const [addCourseVisible, setAddCourseVisible] = useState(false);
  const [dropDownModule, setDropDownModule] = useState(false);
  const [moduleList, setModuleList] = useState([{ id: "", name: "Select Module" }])
  const [moduleDependencyName, setModuleDependencyName] = useState<any>("");

  const columns = [
    {
      label: 'Module Name',
      name: "module_name",
    },
    {
      label: 'Total Hours',
      name: "total_hours",
    },
    {
      label: 'Total Inclass Training Hours',
      name: "inclass",
    },
    {
      label: 'Total Inyard Training Hours',
      name: "inyard",
    },
    {
      label: 'Total Incab Training Hours',
      name: "incab",
    },
    {
      label: 'Edit',
      name: "edit",
      options: {
        sort: false
      }
    },
    {
      label: 'Max Practical Assessment Marks',
      name: 'practical',
      options: {
        display: false,
      }
    },
    {
      label: "min_theory_percent",
      name: "min_theory_percent",
      options: {
        display: false,
      }
    },
    {
      label: "min_practical_percent",
      name: "min_practical_percent",
      options: {
        display: false,
      }
    },
    {
      label: 'Max Theory Test Marks',
      name: 'theory',
      options: {
        display: false,
      }
    },
    {
      label: 'Dependency On Another Module',
      name: 'dependency',
      options: {
        display: false,
      }
    },
    {
      label: 'Title of objective',
      name: 'objective',
      options: {
        display: false,
      }
    },
    {
      label: 'Description',
      name: 'description',
      options: {
        display: false,
      }
    },
  ];

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }, []);

  useEffect(() => {
    (async () => {
      if (editProgramInfo) {
        const program_code: any = sessionStorage.getItem("program_code");
        setProgramCode(programCode);
      }
      if (!editProgramInfo) {
        const program_Id: any = sessionStorage.getItem("program_id");
        setProgramId(program_Id)
        const program_name: any = sessionStorage.getItem("program_name");
        setDisplayProgram(program_name);
        const vehicle: any = sessionStorage.getItem("vehicle_type");
        setVehicleType(vehicle);
        const provi: any = sessionStorage.getItem("province");
        setProvince(provi);
        const desc: any = sessionStorage.getItem("description");
        setDescription(desc);
      }
    })();
  }, [page, token]);

  useEffect(() => {
    (async () => {
      if (programId) {
        const moduleList: any = await ModualList(page, programId, rowsPerPage, token);
        renderDataTable(moduleList);
      }
    })();
  }, [programId]);

  useEffect(() => {
    if (editProgramInfo) {
      setProgramId(editProgramInfo && editProgramInfo?.id);
      sessionStorage.setItem("program_id", programId);
      setProgramName(editProgramInfo && editProgramInfo?.name);
      setDisplayProgram(editProgramInfo && editProgramInfo?.name);
      sessionStorage.setItem("program_name", displayProgram);
      setTotalHours(editProgramInfo && editProgramInfo?.total_duration_minutes);
      setProgramCode(editProgramInfo && editProgramInfo.program_code ? editProgramInfo?.program_code : "");
      sessionStorage.setItem("program_code", programCode);
      setInclassTrainingHours(editProgramInfo && editProgramInfo?.total_inclass_training_minutes);
      setInyardTrainingHours(editProgramInfo && editProgramInfo?.total_inyard_training_minutes);
      setIncabTrainingHours(editProgramInfo && editProgramInfo?.total_incab_training_minutes);
      setVehicleType(editProgramInfo && editProgramInfo?.vehicle_type);
      setDescription(editProgramInfo && editProgramInfo?.description);
      setCountry(editProgramInfo && editProgramInfo?.country);
      setProvince(editProgramInfo && editProgramInfo?.province);
      setMeltCompatible(editProgramInfo && editProgramInfo?.melt_compatible ? editProgramInfo?.melt_compatible : false);
    }
  }, [editProgramInfo, programName]);

  const renderDataTable = (schoolPaymentList: any) => {
    const rowsDataTable = schoolPaymentList && schoolPaymentList?.data?.data.map((row: any) => {
      return {
        module_name: row?.name,
        total_hours: row?.total_duration_minutes !== null ? row?.total_duration_minutes / 60 : "---",
        inclass: row?.total_inclass_training_minutes !== null ? row?.total_inclass_training_minutes / 60 : "---",
        inyard: row?.total_inyard_training_minutes !== null ? row?.total_inyard_training_minutes / 60 : "---",
        incab: row?.total_incab_training_minutes ? row?.total_incab_training_minutes / 60 : "---",
        edit: <FaRegEdit size={18} onClick={() => handleEditModule(row)} />,
        practical: row ? row?.practical_assessment_max_marks : "---",
        min_theory_percent: row?.minimum_pass_percent_theory,
        min_practical_percent: row?.minimum_pass_percent_practical,
        theory: row?.theory_test_max_marks !== null ? row?.theory_test_max_marks : "---",
        dependency: row?.dependency,
        objective: row?.title_objective !== null ? row?.title_objective : "---",
        description: row?.description !== null ? row?.description : "---",
      };
    });
    setData(rowsDataTable);
  };

  useEffect(() => {
    (async () => {
      const progrmArr = await DropDownProgramList(page, token);
      setProgramList([...programList, ...progrmArr.data])
    })();
  }, [])

  useEffect(() => {
    (async () => {
      if (programId) {
        const moduleArr = await ModualListByProgramId(programId, token);
        setModuleList(moduleArr.data.length > 0 ? [{ id: "", name: "Select Module" }, ...moduleArr.data] : [{ id: "", name: "No Module Found" }])
      }
      else {
        setModuleList([{ id: "", name: "Select Module" }])
      }
    })();
  }, [dropDownModule])

  useEffect(() => {
    (async () => {
      if (country) {
        const provinceArr = await GetProvinceListByCountryId(country);
        const newProvinceArr = await provinceArr.Provinces;
        setProvinces([...provinces, ...newProvinceArr]);
      }
    })();
  }, [country])

  const options = {
    search: false,
    print: false,
    viewColumns: false,
    filter: false,
    download: false,
    responsive: "vertical",
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    selectableRows: "none",
    rowsPerPageOptions: [],
    textLabels: {
      body: {
        noMatch: (moduals == null && data?.length == 0 && programId) ?
          <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
            <CircularProgress sx={{ color: "#707070" }} />
          </Box> : 'Sorry, there is no matching data to display',
      },
    },
    renderExpandableRow: (row: any) => {
      return (
        <StyledTableRow>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={7}
          >
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">
                      Max Practical Assessment Marks
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Minimum Practical Pass Percentage
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Max Theory Test Marks
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Minimum Theory Pass Percentage
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Dependency On Another Module
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row[6]}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row[8]}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row[9]}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row[7]}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row[10]}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
              <hr style={{ marginTop: 5, color: "#000000", opacity: 1 }} />

              <div style={{ flexDirection: "row", display: 'flex' }}>
                <Typography fontWeight={700} color="#404040">
                  Title Of Objective:
                </Typography>

                <Typography sx={{ marginLeft: 1 }} color="#606060">
                  {row[11] !== null ? row[11] : "---"}
                </Typography>
              </div>

              <Typography fontWeight={700} color="#404040">
                Description:
              </Typography>
              <Typography color="#606060">
                {row[12] !== null ? row[12] : "---"}
              </Typography>
            </Box>
          </StyledTableCell>
        </StyledTableRow >
      );
    },
  };

  // const handleFilter = (newValue: string) => {
  //   setFilters(newValue);
  // };

  // const handleChangeCountry = async (value: any) => {
  //   setCountry(value);
  /*  countrys.filter((countryObj: any) => {
     if (countryObj.id !== parseInt(value)) {
       setProvinces([...provinces]);
       return;
     }
     setProvinces([...provinces, ...countryObj.Provinces]);
   }); */
  // };

  const handleMeltCompatible = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMeltCompatible(event.target.checked);
    handleChange(event.target.checked);
  };

  const handleClickOpen = () => {
    setOpen(true);
    resetModule();
    setSaveUpdate(true);
    setIsSubmit(false);
    setModuleVisible(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetModule();
    setIsSubmit(false);
    setModuleVisible(true);
  };

  //Auto Populate Data in Module Tab
  const handleEditModule = async (row: any) => {
    setOpen(true);
    setModuleVisible(false);
    setProgram(row?.program_id);
    setModuleId(row?.id);
    setModuleName(row?.name);
    setModuleTotalHours(row?.total_duration_minutes / 60);
    setModuleInclassTrainingHours(row?.total_inclass_training_minutes / 60);
    setModuleInyardTrainingHours(row?.total_inyard_training_minutes / 60);
    setModuleIncabTrainingHours(row?.total_incab_training_minutes / 60);
    setModuleAssessmentMax(row?.practical_assessment_max_marks);
    setModuleMinimumPassTheory(row?.minimum_pass_percent_theory);
    setModuleMinimumPassPractical(row?.minimum_pass_percent_practical);
    setModuleTheoryTestMax(row?.theory_test_max_marks ? row?.theory_test_max_marks : "");
    setModuleDependency(row.dependency ? row?.dependency : "");
    setModuleTitleObjective(row.title_objective ? row?.title_objective : "");
    setModuleDescription(row?.description);
    setSaveUpdate(false)
  }

  // Reset the module State when click on Add Module Button
  const resetModule = () => {
    // setProgram("");
    setModuleName("");
    setModuleTotalHours(null);
    setModuleInclassTrainingHours(null);
    setModuleInyardTrainingHours(null);
    setModuleIncabTrainingHours(null);
    setModuleAssessmentMax("");
    setModuleMinimumPassPractical("")
    setModuleMinimumPassTheory("")
    setModuleTheoryTestMax("");
    setModuleDependency("");
    setModuleTitleObjective("");
    setModuleDescription("");
  }

  // const resetData = () => {
  //   // setProgramName("");
  //   setProgramCode("");
  //   setTotalHours(null);
  //   setInclassTrainingHours(null);
  //   setInyardTrainingHours(null);
  //   setIncabTrainingHours(null);
  //   setVehicleType("");
  //   setCountry("");
  //   setProvince("");
  //   setDescription("");
  //   setMeltCompatible(false);
  // };

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);

    if (
      isRequiredField(programName) &&
      isRequiredField(vehicleType) &&
      isValidRegisterID(vehicleType) &&
      isRequiredField(country) &&
      isRequiredField(province) &&
      isRequiredField(description) &&
      isValidRegisterID(description)
    ) {
      setIsSubmit(false);
      let programObj = {
        name: programName,
        /*  program_code: programCode, */
        description: description,
        duration_minutes: totalHours * 60,
        inclass_training_minutes: inClassTrainingHours * 60,
        inyard_training_minutes: inYardTrainingHours * 60,
        incab_training_minutes: inCabTrainingHours * 60,
        vehicle_type: vehicleType,
        total_score: totalHours,
        melt_compatible: meltCompatible,
        country: country,
        province: province,
        // status: 1,
        tenant_id: "",
      };
      if (addProgramCheck === true && programId) {
        let resData: any = await updateProgram(programId, programObj, token)
        if (!resData.error) {
          toast.success(PROGRAM_SUCCESSFULLY_UPDATED);
          setTimeout(() => {
            // resetData();
            // resetModule();
            setModuleVisible(true);
            sessionStorage.setItem("program_id", programId);
            sessionStorage.setItem("program_name", programName);
            setSaveUpdate(true);
            // setOpen(true);
            // return history.push(ROUTES.programs);
          }, 2000);
        }
        return;
      } else if (addProgramCheck === false && programId === null) {
        let resData = await addProgram(programObj, token);
        if (!resData.error) {
          toast.success(PROGRAM_SUCCESSFULLY_CREATED);
          setTimeout(() => {
            setModuleVisible(!moduleVisible);
            // setOpen(true);
            sessionStorage.setItem("program_id", resData.data.id);
            sessionStorage.setItem("program_name", programName);
            sessionStorage.setItem("vehicle_type", vehicleType);
            sessionStorage.setItem("province", province);
            sessionStorage.setItem("description", description);
            setProgramId(resData.data.id);
            setDisplayProgram(programName)
            // resetData();
            resetModule();
            setSaveUpdate(true);
            setIsSubmit(false);
            // handleStep((stepValue) => stepValue + 1);
          }, 2000);
        }
      } else if (addProgramCheck === false && programId) {
        setModuleVisible(true);
        setSaveUpdate(true);
        setOpen(true);
        return;
      } else {
        setModuleVisible(true);
        setSaveUpdate(true);
        setOpen(true);
        return;
      }
    }
    setIsValidValues(false);
  };

  const SubmitHandlerCourses = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    handleStep((stepValue) => stepValue + 1);
  };

  const SubmitHandlerModual = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitModule(true);
    if (
      isRequiredField(displayProgram) &&
      isRequiredField(moduleName) &&
      isRequiredField(moduleMinimumPassTheory) &&
      isValidNo(moduleMinimumPassTheory) &&
      isRequiredField(moduleMinimumPassPractical) &&
      isValidNo(moduleMinimumPassPractical) &&
      isRequiredField(moduleTheoryTestMax) &&
      isValidNo(moduleTheoryTestMax) &&
      isRequiredField(moduleAssessmentMax) &&
      isValidNo(moduleAssessmentMax) &&
      isRequiredField(moduleTitleObjective) &&
      isRequiredField(moduleDescription) &&
      isValidRegisterID(moduleDescription)
    ) {
      setIsSubmitModule(false);
      let programObj = {
        program_id: programId,
        name: moduleName,
        description: moduleDescription,
        /* duration_minutes: moduleTotalHours,
        inclass_training_minutes: moduleInClassTrainingHours,
        inyard_training_minutes: moduleInYardTrainingHours,
        incab_training_minutes: moduleInCabTrainingHours, */
        minimum_pass_percent_theory: moduleMinimumPassTheory,
        minimum_pass_percent_practical: moduleMinimumPassPractical,
        dependency: moduleDependencyName,
        title_objective: moduleTitleObjective,
        theory_test_max_marks: moduleTheoryTestMax,
        practical_assessment_max_marks: moduleAssessmentMax,
        status: 1,
        vehicle_type: vehicleType,
        tenant_id: "",
      };

      let resData: any = saveUpdate ? await addModual(programObj, token) : await updateModual(moduleId, programObj, token);

      if (!resData.error) {
        toast.success(saveUpdate ? MODUAL_SUCCESSFULLY_CREATED : MODUAL_SUCCESSFULLY_UPDATED);
        setTimeout(async () => {
          sessionStorage.setItem("program_id", programId);
          sessionStorage.setItem("program_name", programName);
          // resetData();
          const moduleList = await ModualList(page, programId, rowsPerPage, token);
          renderDataTable(moduleList);
          setDropDownModule(!dropDownModule);
          setOpen(false);
          setAddCourseVisible(true);
        }, 2000);
      }
    }
    setIsValidValues(false);
  };

  const handleChange = (e: any) => {
    if (programId && e) {
      setAddProgramCheck(true);
    } else {
      setAddProgramCheck(false);
    }
  }

  const moduleDep = (e: any) => {
    const moduleName = moduleList.find((module) => module.id == e)
    setModuleDependencyName(moduleName?.name);
  }

  const onChangeProgram = (value: any, setMethod: any) => {
    var regexp = /^[a-zA-Z0-9_]+$/;
    if (value === '' || regexp.test(value))
      setMethod(value);
  }

  return (
    <>
      <ToastMessage />
      <form onSubmit={SubmitHandler}>
        <Card className={classes.programCard}>
          <CardContent>
            <Row>
              <Col lg="4" sm="12">
                <InputField
                  disabled={programId ? true : false}
                  type="text"
                  lable="Program Name"
                  name={displayProgram}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Program Name"
                  className="form-textfield-styles"
                  value={displayProgram || programName}
                  handleChange={(e) => onChangeProgram(e.target.value, setProgramName)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Program Name field is required"
                />
              </Col>
              {
                id !== undefined ?
                  <Col lg="4" sm="12">
                    <InputField
                      type="text"
                      lable="Program Code ( auto generate )"
                      name={programCode}
                      rows={1}
                      multiline={false}
                      placeHolder="Enter Program Code"
                      className="form-textfield-styles"
                      value={programCode}
                      handleChange={(e) => setProgramCode(e.target.value)}
                      isValidValue={isValidValues}
                      isSubmit={isSubmit}
                      requiredMessage="Program Code field is required"
                      isCheckValidRID={true}
                      disabled={true}
                    />
                  </Col>
                  : ""
              }
              {
                id !== undefined || programId ?
                  <> <Col lg="4" sm="12">
                    <Typography className="fontLabelDisable">
                      Total Hours
                    </Typography>
                    <Typography className={classes.fieldDisable}>
                      {totalHours !== null ? (+totalHours) / 60 : "---"}
                      <hr style={{ marginTop: 3 }} />
                    </Typography>
                  </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabelDisable">
                        Total Inclass Training Hours
                      </Typography>
                      <Typography className={classes.fieldDisable}>
                        {inClassTrainingHours !== null ? (+inClassTrainingHours) / 60 : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabelDisable">
                        Total Inyard Training Hours
                      </Typography>
                      <Typography className={classes.fieldDisable}>
                        {inYardTrainingHours !== null ? (+inYardTrainingHours) / 60 : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabelDisable">
                        Total Incab Training Hour
                      </Typography>
                      <Typography className={classes.fieldDisable}>
                        {inCabTrainingHours !== null ? (+inCabTrainingHours) / 60 : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                  </> : ""}

              <Col lg="4" sm="12">
                <InputSelectField
                  select={true}
                  lable="Vehicle Type"
                  name={vehicleType}
                  value={vehicleType ? vehicleType : ""}
                  className="form-textfield-styles"
                  options={[
                    { id: "", name: "Select type" },
                    { id: "tractor", name: "Tractor" },
                    { id: "trailer", name: "Trailer" },
                    { id: "car", name: "Car" },
                    { id: "bus", name: "Bus" },
                    { id: "minibus", name: "Minibus" },
                    { id: "heavy equipment", name: "Heavy Equipment" },
                    { id: "others", name: "Others" },
                  ]}
                  handleChange={(e) => { setVehicleType(e.target.value); handleChange(e.target.value) }}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Vehicle Type field is required"
                />
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  disabled
                  type="text"
                  lable="Country"
                  name={country}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Country"
                  className="form-textfield-styles"
                  value={"Canada"}
                  handleChange={(e) => setVehicleType(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Vehicle Type field is required"
                  isCheckValidRID={true}
                />
              </Col>
              <Col lg="4" sm="12">
                <InputSelectField
                  // disabled={editProgramInfo ? false : true}
                  select={true}
                  lable="Province"
                  name={province}
                  value={province}
                  className="form-textfield-styles"
                  options={provinces}
                  handleChange={(e) => { setProvince(e.target.value); handleChange(e.target.value) }}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Province field is required"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" sm="12">
                <InputField
                  // disabled={editProgramInfo ? false : true}
                  type="text"
                  lable="Description"
                  name={description}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter description"
                  className="form-textfield-styles"
                  value={description}
                  handleChange={(e) => { setDescription(e.target.value); handleChange(e.target.value) }}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Description field is required"
                  isCheckValidRID={true}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="5" sm="12">
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // disabled={editProgramInfo === undefined ? true : false}
                        checked={meltCompatible}
                        onChange={handleMeltCompatible}
                        color="success"
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      />
                    }
                    label="MELT Compatible"
                  />
                </div>
              </Col>
              <Col lg="7" sm="12">
                <div className={classes.button_save}>
                  <Link to={ROUTES.programs}>
                    <CancelButtonComponent ButtonLabel="Cancel" />
                  </Link>
                  {" "}
                  <Button
                    disabled={addProgramCheck || programId === null ? false : true}
                    type="submit"
                    className={classes.addModule}
                    variant="contained"
                  >
                    Save
                  </Button>
                  {" "}
                  <Button
                    disabled={programId === null ? true : false}
                    type="button"
                    onClick={handleClickOpen}
                    className={classes.addModule}
                    variant="contained"
                  >
                    Add Modules
                  </Button>
                </div>
              </Col>
            </Row>
          </CardContent>
        </Card>
        <TypographyComponent label="Module List" />

        <ThemeProvider theme={theme} >
          <MUIDataTable
            title={" "}
            data={data}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </form>

      <form onSubmit={SubmitHandlerCourses}>
        <Row className="mt-4">
          <Col lg="12" sm="12">
            <div className="stepperButton">
              <Button
                disabled={editProgramInfo || programId ? false : true}
                type="submit"
                className={classes.addModule}
                variant="contained"
              >
                {id ? "Add/Edit Course" : "Add Course"}
              </Button>
            </div>
          </Col>
        </Row>
      </form>

      <BootstrapDialog
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <form onSubmit={SubmitHandlerModual}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            {moduleVisible ? <AiOutlineFolderAdd size={28} style={{ marginBottom: 4, marginRight: 4 }} />
              :
              <FaRegEdit size={20} style={{ marginBottom: 6, marginRight: 4 }} color="#FFFFFF" />
            }
            {moduleVisible ? "Add Modules and Objective" : "Update Modules and Objective"}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            {saveUpdate ?
              <Typography variant="h6" gutterBottom component="div">
                Add Module
              </Typography> :
              <Typography variant="h6" gutterBottom component="div">
                Update Module
              </Typography>
            }
            <Row>
              <Col lg="4" sm="12">
                <InputField
                  disabled
                  type="text"
                  lable="Program Name"
                  name={displayProgram}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Program Name"
                  className="form-textfield-styles"
                  value={displayProgram}
                  isValidValue={isValidValues}
                  isSubmit={isSubmitModule}
                  requiredMessage="Program field is required"
                />
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  disabled={moduleVisible ? false : true}
                  type="text"
                  lable="Module Name"
                  name={moduleName}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Module Name"
                  className="form-textfield-styles"
                  value={moduleName}
                  handleChange={(e) => onChangeProgram(e.target.value, setModuleName)}
                  isValidValue={false}
                  isSubmit={false}
                  requiredMessage="Module Name field is required"
                  isCheckValidRID={true}
                />
              </Col>
              {
                saveUpdate ? "" :
                  <>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabelDisable">
                        Total Hours
                      </Typography>
                      <Typography className={classes.fieldDisable}>
                        {moduleTotalHours !== null ? moduleTotalHours : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabelDisable">
                        Total Inclass Training Hours
                      </Typography >
                      <Typography className={classes.fieldDisable}>
                        {moduleInClassTrainingHours !== null ? moduleInClassTrainingHours : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabelDisable">
                        Total Inyard Training Hours
                      </Typography>
                      <Typography className={classes.fieldDisable}>
                        {moduleInYardTrainingHours !== null ? moduleInYardTrainingHours : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabelDisable">
                        Total Incab Training Hours
                      </Typography>
                      <Typography className={classes.fieldDisable}>
                        {moduleInCabTrainingHours !== null ? moduleInCabTrainingHours : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                  </>
              }

              <Col lg="4" sm="12">
                <InputField
                  type="number"
                  lable="Max Practical Assessment Marks"
                  name={moduleAssessmentMax}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Max Practical Assessment Marks"
                  className="form-textfield-styles"
                  value={moduleAssessmentMax}
                  handleChange={(e) => setModuleAssessmentMax(e.target.value.slice(0, MARKSLIMITE))}
                  isValidValue={isValidValues}
                  isSubmit={isSubmitModule}
                  requiredMessage="Max Practical Assessment Marks field is required"
                  isCheckValidNoValue={true}
                  disablePrevious="1"
                />
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  type="number"
                  lable="Minimum Practical Pass Percentage"
                  name={moduleMinimumPassPractical}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Minimum Practical Pass Percentage"
                  className="form-textfield-styles"
                  value={moduleMinimumPassPractical}
                  handleChange={(e) => setModuleMinimumPassPractical(e.target.value.slice(0, MARKSLIMITE))}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Minimum Practical Pass Percentage field is required"
                  isCheckValidRID={true}
                  disablePrevious="0"
                />
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  type="number"
                  lable="Max Theory Test Marks"
                  name={moduleTheoryTestMax}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Max Theory Test Marks"
                  className="form-textfield-styles"
                  value={moduleTheoryTestMax}
                  handleChange={(e) => setModuleTheoryTestMax(e.target.value.slice(0, MARKSLIMITE))}
                  isValidValue={isValidValues}
                  isSubmit={isSubmitModule}
                  requiredMessage="Max Theory Test Marks field is required"
                  isCheckValidNoValue={true}
                  disablePrevious="0"
                />
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  type="number"
                  lable="Minimum Theory Pass Percentage"
                  name={moduleMinimumPassTheory}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Minimum Theory Pass Percentage"
                  className="form-textfield-styles"
                  value={moduleMinimumPassTheory}
                  handleChange={(e) => setModuleMinimumPassTheory(e.target.value.slice(0, MARKSLIMITE))}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Minimum Theory Pass Percentage field is required"
                  isCheckValidRID={true}
                  disablePrevious="0"
                />
              </Col>
              <Col lg="4" sm="12">
                <InputSelectField
                  select={true}
                  lable="Dependency on Another Module"
                  name={moduleDependency}
                  value={moduleVisible ? moduleDependency : moduleList.find((mod: any) => { if (mod.name == moduleDependency) { return mod.name } })?.id}
                  className="form-textfield-styles"
                  options={moduleList}
                  handleChange={(e) => { moduleDep(e.target.value); setModuleDependency(e.target.value) }}
                  isValidValue={false}
                  isSubmit={false}
                  requiredMessage="Module field is required"
                />
                {/*<InputField
                  type="text"
                  lable="Dependency on Another Module"
                  name={moduleDependency}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Dependency on Another Module"
                  className="form-textfield-styles"
                  value={moduleDependency}
                  handleChange={(e) => setModuleDependency(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Dependency on Another Module field is required"
                  isCheckValidRID={true}
                /> */}
              </Col>
            </Row>
            <div className="stline"></div>
            {saveUpdate ?
              <Typography variant="h6" gutterBottom component="div">
                Add Objective
              </Typography> :
              <Typography variant="h6" gutterBottom component="div">
                Update Objective
              </Typography>
            }
            <Row>
              <Col lg="12" sm="12">
                <InputField
                  type="text"
                  lable="Title of Objective "
                  name={moduleTitleObjective}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Title of Objective "
                  className="form-textfield-styles"
                  value={moduleTitleObjective}
                  handleChange={(e) => setModuleTitleObjective(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmitModule}
                  requiredMessage="Title of Objective field is required"
                  isCheckValidRID={true}
                />
              </Col>
              <Col lg="12" sm="12">
                <InputField
                  type="text"
                  lable="Description"
                  name={moduleDescription}
                  rows={4}
                  multiline={true}
                  placeHolder="Enter description"
                  className="form-textfield-styles"
                  value={moduleDescription}
                  handleChange={(e) => setModuleDescription(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmitModule}
                  requiredMessage="Description field is required"
                />
              </Col>
            </Row>
          </DialogContent>
          <DialogActions className="mainButton">
            <Button
              type="button"
              variant="contained"
              onClick={handleClose}
              className="saveEmailButton1"
            >
              Cancel
            </Button>{" "}
            <SaveButtonComponent ButtonType="submit" ButtonLabel={saveUpdate ? 'Save' : "Update"} />
          </DialogActions>
        </form>
      </BootstrapDialog>
    </>
  );
}
