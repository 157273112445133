import React from "react";
import { makeStyles } from "@material-ui/core/styles";

type StickyNoteProps = {
  title: string;
  value: string;
};

const useStyles = makeStyles(() => ({
  stickyNoteTextAlignment: {
    display: "flex",
    justifyContent: "space-between",
    color: "#fff !important",
    backgroundColor: "#848484 !important",
    borderColor: "#848484 !important",
  },
}));

const StickyNote: React.FC<StickyNoteProps> = ({
  title = "Test Test",
  value = "150",
}) => {
  const classes = useStyles();
  return (

    <div className={`alert alert-dark ${classes.stickyNoteTextAlignment}`}>
      <span>{title}</span>
      <span style={{ fontWeight: 'bold', fontSize: 20 }}>{value}</span>
    </div>
  );
};

export default StickyNote;
