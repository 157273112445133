import React, { useState, useEffect, SetStateAction } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Row, Col } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { InputField, InputSelectField } from "../../../common-components/FormComponent/FormFieldsComponent";
import { isRequiredField, isValidRegisterID, isValidNo } from "../../../common-components/FormComponent/FormValidator";
import { SaveButtonComponent } from "../../../common-components/ButtonComponent";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Button, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import TypographyComponent from "./TableComponent/TypographyComponent";
import { toast } from "react-toastify";
import * as Actions from "../../../reducer-store/actions/courseAction";
import { DropDownProgramList, DropDownModualList } from "../../../reducer-store/actions/topicAction";
import { COURSE_SUCCESSFULLY_CREATED, COURSE_SUCCESSFULLY_UPDATED } from "../../../reducer-store/ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { PAGE } from "../../../reducer-store/constants";
import ToastMessage from "../../../common-components/ToastMessage";
import { HOURSLIMITE } from "../../../reducer-store/constants";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import { FaRegEdit } from "react-icons/fa";
import FieldError from "../../../common-components/FormComponent/ErrorMessage";

type AddCoursesProps = {
  handleStep: React.Dispatch<SetStateAction<number>>;
  stepValue: number;
  editProgramInfo?: any;
};

const useStyles = makeStyles(() => ({
  programCard: {
    backgroundColor: "#ececec",
  },
  fieldDisable: {
    color: "rgba(0, 0, 0, 0.38) !important",
    fontSize: "16px !important",
  },
  addCourse: {
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontWeight: 500,
    fontFamily: 'Arial !important',
    fontSize: "0.875rem",
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "capitalize",
    minWidth: "64px",
  },
  backButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #393939 !important",
    color: "#393939 !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontWeight: 500,
    fontFamily: 'Arial !important',
    fontSize: "0.875rem",
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "capitalize",
    minWidth: "64px",
  },
  searchBar: {
    borderColor: "black",
    "& hover": {
      borderColor: "black"
    },
    "& focused": {
      borderColor: "black"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black"
    }
  },
  dialogTitle: {
    backgroundColor: "#646464",
    color: "#ffffff",
  },
}));

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .css-1ygcj2i-MuiTableCell-root": {
            fontweight: "bold",
            color: "#FFFFFF",
          },
          "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
            display: "-webkit-box"
          },
          "& .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader": {
            backgroundColor: "#393939",
            fontFamily: 'Arial'
          },
          "& .tss-1akey0g-MUIDataTableHeadCell-data": {
            color: "#FFFFFF",
          },
          "& .tss-1fz4yw6-MUIDataTableSelectCell-fixedLeft": {
            backgroundColor: "#393939",
          },
          "& .css-11k72hi-MuiTableCell-root.MuiTableCell-head": {
            backgroundColor: "#646464",
          },
          "& .css-1ge9txp-MuiTableCell-root.MuiTableCell-head": {
            backgroundColor: "#646464",
          },
          "& .tss-tlx3x1-MUIDataTableToolbar-root": {
            minHeight: "0px !important",
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Arial'
        },
        head: {
          color: "#FFFFFF",
          fontFamily: 'Arial',
        },
        footer: {
          backgroundColor: "#FFFFFF"
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          fill: "#FFFFFF"
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#E53D12',
          borderRadius: '4px',
          color: 'white',
          marginRight: '25px',
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: "#DFDFDF"
          },
        }
      }
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "0px !important",
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& .MuiTablePagination-displayedRows": {
            marginTop: "9%"
          },
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          minHeight: 0,
        }
      }
    }
  }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// function createData(
//   name: string,
//   description: string,
//   total_incab_training_minutes: number,
//   total_inclass_training_minutes: number,
//   total_inyard_training_minutes: number,
//   total_duration_minutes: number
// ) {
//   return {
//     name,
//     description,
//     total_incab_training_minutes,
//     total_inclass_training_minutes,
//     total_inyard_training_minutes,
//     total_duration_minutes,
//   };
// }

export default function AddCourses({ handleStep, stepValue, editProgramInfo }: AddCoursesProps) {
  const classes = useStyles();
  const [Module, setModule] = useState("");
  const [CourseName, setCourseName] = useState("");
  const [courseVisible, setCourseVisible] = useState(true);
  const [TotalHours, setTotalHours] = useState<any>(null);
  const [InclassTrainingHours, setInclassTrainingHours] = useState<any>(null);
  const [InyardTrainingHours, setInyardTrainingHours] = useState<any>(null);
  const [IncabTrainingHours, setIncabTrainingHours] = useState<any>(null);
  const [CourseHoursPerDay, setCourseHoursPerDay] = useState<any>("");
  const [Description, setDescription] = useState("");
  const [saveUpdate, setSaveUpdate] = useState(true)
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const { addCourse, updateCourse } = Actions;
  const dispatch = useDispatch();
  const { CourseList } = bindActionCreators(Actions, dispatch);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [page, setPage] = useState(PAGE);
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const courseList = useSelector((state: any) => state.course);
  const { courses } = courseList;
  const [moduleList, setModuleList] = useState([{ id: "", name: "Select Module" }])
  const [programList, setProgramList] = useState([{ id: "", name: "Select Program" }])
  const [courseId, setcourseId] = useState("");
  const [programName, setProgramName] = useState("");
  const [programId, setProgramId] = useState("");
  const [data, setData] = useState([]);
  const [addCourseVisible, setAddCourseVisible] = useState(false);
  const [workingHourError, setWorkingHourError] = useState(false);
  const [workingHourErrorMsg, setWorkingHourErrorMsg] = useState("");

  const columns = [
    {
      label: 'Course Name',
      name: "course_name",
    },
    {
      label: 'Module name',
      name: "module_name",
    },
    {
      label: 'Total Hours',
      name: "total_hours",
    },
    {
      label: 'Total Inclass Training Hours',
      name: "inclass",
    },
    {
      label: 'Total Inyard Training Hours',
      name: "inyard",
    },
    {
      label: 'Total Incab Training Hours',
      name: "incab",
    },
    {
      label: 'Edit',
      name: "edit",
      options: {
        sort: false
      }
    },
    {
      label: 'Description',
      name: 'description',
      options: {
        display: false,
      }
    },
  ];

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    (async () => {
      const programId: any = sessionStorage.getItem("program_id");
      setProgramId(programId)
      const program_name: any = sessionStorage.getItem("program_name");
      setProgramName(program_name);

      if (programId === null || programId === "") {
        const courseList = await CourseList(page, -1, rowsPerPage, token);
        renderDataTable(courseList);
      } else {
        const courseList = await CourseList(page, programId, rowsPerPage, token);
        renderDataTable(courseList);
      }
    })();
  }, [page, programId, addCourseVisible]);

  useEffect(() => {
    (async () => {
      const programId: any = sessionStorage.getItem("program_id");
      setProgramId(programId)
      const program_name: any = sessionStorage.getItem("program_name");
      setProgramName(program_name);
      const moduleArr = await DropDownModualList(page, programId, token);
      const progrmArr = await DropDownProgramList(page, token);
      setProgramList([...programList, ...progrmArr.data])
      setModuleList([...moduleList, ...moduleArr.data])
    })();
  }, [])

  const renderDataTable = (courseList: any) => {
    const rowsDataTable = courseList && courseList?.data?.data?.map((row: any) => {
      return {
        course_name: row.name,
        module_name: row.ProgramModule.name,
        total_hours: row.total_duration_minutes !== null ? row.total_duration_minutes / 60 : "---",
        inclass: row.total_inclass_training_minutes !== null ? row.total_inclass_training_minutes / 60 : "---",
        inyard: row.total_inyard_training_minutes !== null ? row.total_inyard_training_minutes / 60 : "---",
        incab: row.total_incab_training_minutes ? row.total_incab_training_minutes / 60 : "---",
        edit: <FaRegEdit size={18} onClick={() => handleEditCourse(row)} />,
        description: row.description !== null ? row.description : "---",
      };
    });
    setData(rowsDataTable);
  };

  const options = {
    search: false,
    print: false,
    viewColumns: false,
    filter: false,
    download: false,
    responsive: "vertical",
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    selectableRows: "none",
    rowsPerPageOptions: [],
    textLabels: {
      body: {
        noMatch: !courses || (courses?.data?.length > 0 && data?.length === 0) ?
          <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
            <CircularProgress sx={{ color: "#707070" }} />
          </Box > :
          'Sorry, there is no matching data to display',
      },
    },
    renderExpandableRow: (rowData: any) => {
      return (
        <StyledTableCell
          style={{ backgroundColor: "#F0F0F0 !important", paddingBottom: 0, paddingTop: 0 }}
          colSpan={8}
        >
          <Box sx={{ margin: 1 }}>
            <Typography fontWeight={700} color="#404040">
              Description :
            </Typography>
            <Typography color="#606060">
              {rowData[7]}
            </Typography>
          </Box>
        </StyledTableCell>
      );
    },
  };

  const handleBack = () => {
    handleStep((stepValue) => stepValue - 1);
  };

  const handleCancel = () => {
    resetData();
    setSaveUpdate(true);
    setIsSubmit(false);
  }

  const resetData = () => {
    // setModule("");
    // setProgram("");
    setCourseName("");
    setTotalHours(null);
    setInclassTrainingHours(null);
    setInyardTrainingHours(null);
    setIncabTrainingHours(null);
    setCourseHoursPerDay("");
    setDescription("");
  };

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      isRequiredField(Module) &&
      isRequiredField(programName) &&
      isRequiredField(CourseName) &&
      isRequiredField(CourseHoursPerDay) &&
      isValidNo(CourseHoursPerDay) &&
      isRequiredField(Description) &&
      isValidRegisterID(Description) &&
      !workingHourError
    ) {
      setIsSubmit(false);
      let courseObj = {
        program_id: programId,
        module_id: Module,
        name: CourseName,
        description: Description,
        inclass_training_minutes: InclassTrainingHours * 60,
        inyard_training_minutes: InyardTrainingHours * 60,
        incab_training_minutes: IncabTrainingHours * 60,
        total_hours: TotalHours * 60,
        course_hours_per_day: CourseHoursPerDay * 60,
        assessment_name: "Name 1",
        assessment_type: "Type 1",
        total_score: 100,
        status: 1,
      };

      let resData: any = saveUpdate ? await addCourse(courseObj, token) : await updateCourse(
        courseId,
        courseObj,
        token
      );
      // && setAddCourseVisible(true);

      if (!resData.error) {
        toast.success(saveUpdate ? COURSE_SUCCESSFULLY_CREATED : COURSE_SUCCESSFULLY_UPDATED);
        setTimeout(async () => {
          const courseList: any = await CourseList(page, programId, rowsPerPage, token);
          renderDataTable(courseList);
          setAddCourseVisible(true);
          resetData();
          setSaveUpdate(true);
          // saveUpdate ? handleStep((stepValue) => stepValue + 1) : CourseList(page, programId, rowsPerPage, token) && setSaveUpdate(!saveUpdate);
        }, 2000);
      }
    }
    setIsValidValues(false);
  };

  const SubmitHandlerCourses = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    handleStep((stepValue) => stepValue + 1);
  };

  const handleEditCourse = async (row: any) => {
    window.scrollTo(0, 0);
    setcourseId(row.id);
    setCourseVisible(false);
    handleStep(stepValue);
    setModule(row.module_id);
    setCourseName(row.name);
    setTotalHours(row.total_duration_minutes / 60);
    setInclassTrainingHours(row.total_inclass_training_minutes / 60);
    setInyardTrainingHours(row.total_inyard_training_minutes / 60);
    setIncabTrainingHours(row.total_incab_training_minutes / 60);
    setCourseHoursPerDay(row.course_hours_per_day ? row.course_hours_per_day / 60 : "");
    setDescription(row.description);
    setSaveUpdate(false);
  }

  const handleWokringHourCheck = (value: any) => {
    if (value > 8) {
      setWorkingHourError(true);
      setWorkingHourErrorMsg("Course hours per day should not be greater than 8 hours");
    } else {
      setWorkingHourError(false);
      setWorkingHourErrorMsg("");
    }
  }

  const onChangeProgram = (value: any, setMethod: any) => {
    var regexp = /^[a-zA-Z0-9_]+$/;
    if (value === '' || regexp.test(value))
      setMethod(value);
  }

  return (
    <>
      <ToastMessage />
      <form onSubmit={SubmitHandler}>
        <Card className={classes.programCard}>
          <CardContent>
            <Row>
              <Col lg="4" sm="12">
                <InputField
                  disabled
                  type="text"
                  lable="Program Name"
                  name={programName}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Program Name"
                  className="form-textfield-styles"
                  value={programName}
                  // handleChange={(e) => setCourseName(e.target.value)}
                  isValidValue={false}
                  isSubmit={false}
                  requiredMessage="Program Name field is required"
                // isCheckValidRID={true}
                />
              </Col>

              {/* <InputSelectField
                  select={true}
                  lable="Select Program"
                  name={Program}
                  value={Program}
                  className="form-textfield-styles"
                  options={programList}
                  handleChange={(e) => setProgram(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Program field is required"
                />
              </Col> */}
              <Col lg="4" sm="12">
                <InputSelectField
                  select={true}
                  lable="Select Module"
                  name={Module}
                  value={Module}
                  className="form-textfield-styles"
                  options={moduleList}
                  handleChange={(e) => setModule(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Module field is required"
                />
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  disabled={courseVisible || saveUpdate ? false : true}
                  type="text"
                  lable="Course Name"
                  name={CourseName}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Course Name"
                  className="form-textfield-styles"
                  value={CourseName}
                  handleChange={(e) => onChangeProgram(e.target.value, setCourseName)}
                  isValidValue={false}
                  isSubmit={false}
                  requiredMessage="Course Name field is required"
                  isCheckValidRID={true}
                />
              </Col>
              {
                saveUpdate ? "" :
                  <>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabelDisable">
                        Total Hours
                      </Typography>
                      <Typography className={classes.fieldDisable}>
                        {TotalHours !== null ? TotalHours : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabelDisable">
                        Total Inclass Training Hours
                      </Typography>
                      <Typography className={classes.fieldDisable}>
                        {InclassTrainingHours !== null ? InclassTrainingHours : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabelDisable">
                        Total Inyard Training Hours
                      </Typography>
                      <Typography className={classes.fieldDisable}>
                        {InyardTrainingHours !== null ? InyardTrainingHours : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabelDisable">
                        Total Incab Training Hours
                      </Typography>
                      <Typography className={classes.fieldDisable}>
                        {IncabTrainingHours !== null ? IncabTrainingHours : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                  </>
              }
              <Col lg="4" sm="12">
                <InputField
                  type="number"
                  lable="Course Hours Per Day"
                  name={CourseHoursPerDay}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Course Hours Per Day"
                  className={`${`form-textfield-styles ${workingHourError ? "errorBottom" : ""}`}`}
                  value={CourseHoursPerDay}
                  handleChange={(e) => { handleWokringHourCheck(e.target.value); setCourseHoursPerDay(e.target.value.slice(0, HOURSLIMITE)) }}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Course Hours Per Day field is required"
                  isCheckValidNoValue={true}
                  disablePrevious="0"
                  disableUpcoming="8"
                />
                {
                  workingHourError ? <FieldError message={workingHourErrorMsg} /> : null
                }
              </Col>
              <Col lg="12" sm="12">
                <InputField
                  type="text"
                  lable="Description"
                  name={Description}
                  rows={5}
                  multiline={true}
                  placeHolder="Enter Description"
                  className="form-textfield-styles"
                  value={Description}
                  handleChange={(e) => setDescription(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Description field is required"
                  isCheckValidRID={true}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="12" sm="12">
                <div className="mainButton">
                  <Button
                    variant="outlined"
                    onClick={handleCancel}
                    className="saveEmailButton1"
                  >
                    Cancel
                  </Button>
                  {" "}
                  <SaveButtonComponent ButtonType="submit" ButtonLabel={saveUpdate ? "Save" : "Update"} />
                  {" "}
                </div>
              </Col>
            </Row>
          </CardContent>
        </Card>
        <TypographyComponent label="Course List" />
        {/* <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>Course Name</StyledTableCell>
                <StyledTableCell align="center">Total Hours</StyledTableCell>
                <StyledTableCell align="center">
                  Total Inclass Training Hours
                </StyledTableCell>
                <StyledTableCell align="center">
                  Total Inyard Training Hours
                </StyledTableCell>
                <StyledTableCell align="center">
                  Total Incab Training Hours
                </StyledTableCell>
                <StyledTableCell align="right">Edit</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {!courses ? (
                <StyledTableRow>
                  <StyledTableCell align="center" colSpan={6}>
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : courses.data.length > 0 ? (
                courses.data.map((row: any) => <Row2 key={row.id} row={row} />)
              ) : (
                <StyledTableRow key="test1">
                  <StyledTableCell component="td" align="center" colSpan={7}>
                    No Data Found
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
          <PaginationComponent
            page={page}
            setPage={setPage}
            paginationData={courses && courses.meta}
          />
        </TableContainer> */}

        <ThemeProvider theme={theme} >
          <MUIDataTable
            title={" "}
            data={data}
            columns={columns}
            options={options}
          />
        </ThemeProvider>

      </form>
      <form onSubmit={SubmitHandlerCourses}>
        <Row className="mt-4">
          <Col lg="12" sm="12">
            <div className="stepperButton">
              <Button
                variant="outlined"
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>{" "}
              <Button
                disabled={editProgramInfo || addCourseVisible ? false : true}
                type="submit"
                className={classes.addCourse}
                variant="contained"
              >
                {editProgramInfo ? "Add/Edit Topic" : "Add Topic"}
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
}
