import React, { useState } from "react";
import CommonHeadPart from "../../common-components/CommonHeadPart";
import { InnerPageRoutes, ROUTES } from "../../constants/routes";
import SettingIcon from "../../svg_icons/Setting.svg";
import { makeStyles } from "@material-ui/core/styles";
import "../Common.css";
import PaymentKey from "./PaymentKey/PaymentKey";
import FilterTabs from "../../common-components/FilterTabs";
import { Settings } from "../../constants/tabs";
import { Box, Card, CardContent } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    divInsideMain: {
        boxShadow: "0 0 5px #8e8e8e",
        borderRadius: "3px",
    },
    divStyling: {
        padding: "15px 20px",
    }
}));

const Setting: React.FC = () => {

    const classes = useStyles();
    const [filter, setFilters] = useState("Payment Key");
    const [search, setSearch] = useState('');


    const [value, setValue] = React.useState("1");

    const handleFilter = (newValue: string) => {
        setFilters(newValue);
    };
    const handleSearch = (value: string) => {
        setSearch(value);
    };

    const handleChange = (newValue: string) => {
        setValue(newValue);
    };

    const BreadCrumbDashboard = [
        {
            labelText: "Home",
            linkUrl: ROUTES.home,
        },
        {
            labelText: "Settings",
            linkUrl: ROUTES.settings,
        },
        {
            labelText: filter,
            linkUrl: "",
        },
    ];


    return (
        <>
            <CommonHeadPart
                componentText="Settings"
                Icon={SettingIcon}
                buttonText=""
                // buttonText={filter === "Whitelist Ip's" ? "Add whitelist ip's" : ""}
                urlLink={"/"}
                BreadCrumbs={BreadCrumbDashboard}
            />

            <Card>
                <CardContent>
                    <FilterTabs
                        SelectedFilter={filter}
                        Filters={Settings}
                        HandleChange={handleFilter}
                        isFilter={false}
                        HandleSearch={handleSearch}
                        isSearch={false}
                    />
                </CardContent>
                <div className={classes.divStyling}>
                    {filter === "Payment Key" ? <PaymentKey /> : null}
                </div>
            </Card>
        </>
    );
};

export default Setting;
