import React, { useState, useEffect } from "react";
import { Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CommonHeadPart from "../../common-components/CommonHeadPart";
import SchoolPaymentIcon from "../../svg_icons/school_payment.svg";
import VisaCardIcon from "../../svg_icons/visa.png";
import MasterCardIcon from "../../svg_icons/mastro.png";
import { ROUTES } from "../../constants/routes";
import "../Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import FilterTabs from "../../common-components/FilterTabs";
import { SchoolPaymentFilterTabs } from "../../constants/tabs";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import * as Actions from "../../reducer-store/actions/schoolPaymentActions";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { PAGE } from "../../reducer-store/constants";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Common.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import AvTimer from "@mui/icons-material/AvTimer";
import { RiRefund2Fill } from "react-icons/ri";
import DoneOutlined from "@mui/icons-material/DoneOutlined";
import { Row, Col, Button, Container } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { InputDatePicker, InputField, InputSelectField } from "../../common-components/FormComponent/FormFieldsComponent";
import { SaveButtonComponent } from "../../common-components/ButtonComponent";
import { green, red } from "@mui/material/colors";
import { isRequiredDate, isRequiredField, isSelctionField } from "../../common-components/FormComponent/FormValidator";
import { REFUND_SUCCESSFULLY_CREATED } from "../../reducer-store/ToastMessage";
import { toast } from "react-toastify";
import ToastMessage from "../../common-components/ToastMessage";
import Box from "@material-ui/core/Box";
import GppGoodRoundedIcon from "@mui/icons-material/GppGoodRounded";
import Typography from "@mui/material/Typography";
import { SearchOutlined } from '@material-ui/icons';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import { TextField } from '@material-ui/core';
import { CircularProgress } from "@mui/material";
import { fileSignedURL } from "../../reducer-store/actions/authActions";
import pdfIcon from "../../../src/svg_icons/pdfIcon.png";
import imgIcon from "../../../src/svg_icons/image.png";

const useStyles = makeStyles(() => ({
  divInsideMain: {
    boxShadow: "0 0 5px #8e8e8e",
    borderRadius: "3px",
  },
  dialogAction: {
    justifyContent: "end !important",
  },
  icon: {
    width: "30%",
    height: "auto",
    color: "#000",
  },
  saveEmailButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #393939 !important",
    color: "#393939 !important",
    padding: "0 35px !important",
    height: "40px !important",
    textTransform: "capitalize",
    fontSize: "0.875rem",
  },
  boxBorder1: {
    textAlign: "center",
    margin: "20px 0px",
  },
  TransferInitiated: {
    color: "#00a651",
  },
  TransferFaild: {
    color: "red",
  },
  line: {
    backgroundColor: "#00a651",
    width: "100%",
    height: "5px",
    marginTop: "2.5%",
  },
  Right: {
    textAlign: "end",
  },
  Left: {
    textAlign: "start",
  },
  searchBar: {
    borderColor: "black",
    "& hover": {
      borderColor: "black"
    },
    "& focused": {
      borderColor: "black"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black"
    }
  },
  saveButton: {
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontFamily: 'Arial !important',
    fontSize: '14px !important',
    textTransform: "capitalize",
  },
  tableHover: {
    cursor: 'default !important'
  }
}));

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .css-1ygcj2i-MuiTableCell-root": {
            fontweight: "bold",
            backgroundColor: '#FFFFFF'
          },
          "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
            display: "-webkit-box"
          },
          "& .tss-1fbujeu-MUIDataTableHeadCell-toolButton": {
            backgroundColor: '#FFFFFF'
          },
          "& .css-14e01q-MuiTableCell-root": {
            borderBottomColor: '#000',
            borderBottomWidth: 'thin',
          },
          "& .tss-ylo811-MUIDataTableSelectCell-headerCell": {
            borderBottomColor: '#000',
            borderBottomWidth: 'thin',
          }
        },
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          lineHeight: "1.30 !important"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
          fontSize: "15px",
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
          fontSize: "15px",
        },
        head: {
          backgroundColor: "#FFFFFF"
        },
        footer: {
          backgroundColor: "#FFFFFF"
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#E53D12',
          borderRadius: '4px',
          color: 'white',
          marginRight: '25px',
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: "#F4F4F4"
          },
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& .MuiTablePagination-displayedRows": {
            marginTop: "9%"
          },
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          fontFamily: "Arial",
          fontSize: "15px",
        }
      }
    }
  }
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface RefundProps {
  [x: string]: any;
  id: number;
  amount: string;
  payment_id: string;
  reason: string;
  status: string;
  refund_id: string;
  created: string;
};

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} style={{ color: "#ffffff", backgroundColor: "#646464" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const SchoolPayment: React.FC = () => {
  const classes = useStyles();
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const schoolPaymentList = useSelector((state: any) => state.payment);
  const { payments } = schoolPaymentList;
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [page, setPage] = useState(PAGE);
  const [filter, setFilters] = useState("all");
  const dispatch = useDispatch();
  const { SchoolPaymentList } = bindActionCreators(Actions, dispatch);
  const [order, setOrder] = useState("desc");
  const [id, setID] = React.useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState([]);
  const [searchApiData, setSearchApiData] = useState([]);
  const [filterVal, setFilterVal] = useState('');
  const [searchData, setSearchData] = useState(false);
  // const [imgError, setImgError] = useState(false);
  // const [imgErrorMsg, setImgErrorMsg] = useState("");
  const [attachmentFile, setAttachmentFile] = useState<any>([]);
  // const [attachmentFileName, setAttachmentFileName] = useState<any>([]);

  const handlefilter = (e: any) => {
    if (e.target.value === "") {
      setData(searchApiData);
    } else {
      const filterData = searchApiData.filter((items: any) =>
        items.id?.toString().includes(e.target.value.toString())
        || items.school_id?.toString().includes(e.target.value.toString())
        || items.schoolname?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items.date.props.children?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items.transaction_id?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items.fee_type?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items.amount.props.children[0]?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items.amount.props.children[2]?.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
      );
      if (filterData.length > 0) {
        setData(filterData);
        setSearchData(false);
      } else {
        setData([]);
        setSearchData(true);
      }
    }
    setFilterVal(e.target.value);
  }

  const paymentStatus = (value: number) => {
    switch (value) {
      case 1:
        return "Received";
      case 2:
        return "Failed";
      case 3:
        return "Cancelled";
      case 4:
        return "Refund Requested";
      case 5:
        return "Refunded";
      default:
        return "Failed";
    }
  };

  const columns = [
    {
      label: 'Sr No',
      name: "id",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <span>{tableMeta.rowIndex + 1}</span>
        )
      }
    },
    {
      label: 'School Name',
      name: "schoolname",
    },
    {
      label: 'School ID',
      name: "school_id",
    },
    {
      label: "Date",
      name: "date",
    },
    {
      label: 'Amount',
      name: "amount",
    },
    {
      label: 'Status',
      name: "paymentStatus",
    },
    {
      label: 'Transaction ID',
      name: "transaction_id",
    },
    {
      label: 'Fee type',
      name: "fee_type",
    },
    {
      label: 'Transaction acc',
      name: "transaction_acc",
      options: {
        sort: false
      }
    },
    {
      label: 'Instrument logo',
      name: "logo",
      options: {
        sort: false
      }
    },
    {
      label: 'Refund',
      name: "refund",
      options: {
        sort: false
      }
    },
    {
      label: 'Payment Initiate',
      name: "dateInitiate",
      options: {
        display: false,
      }
    },
    {
      label: 'Payment Complete',
      name: "dateComplete",
      options: {
        display: false,
      }
    },
    {
      label: 'Payment Gateway Id',
      name: "paymentgateway_id",
      options: {
        display: false,
      }
    },
    {
      label: 'Refund Information',
      name: "refundInformation",
      options: {
        display: false,
      }
    },
    {
      label: 'totalAmount',
      name: "totalAmount",
      options: {
        display: false,
      }
    },
    {
      label: 'payId',
      name: "payId",
      options: {
        display: false,
      }
    },
  ];

  const getExtensionFromURL: any = (URL: any) => {
    if (URL != null) {
      const tokens = URL && URL?.match(/(\.[a-zA-Z0-9]+(\?|\#|$))/g)
      if (!tokens[0]) return false
      return tokens[0].replace(/[^A-Za-z0-9\.]/g, '')
    }
  }

  const options = {
    search: false,
    print: false,
    viewColumns: false,
    filter: false,
    download: false,
    responsive: "vertical",
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    selectableRows: "none",
    rowsPerPageOptions: [],
    textLabels: {
      body: {
        noMatch: searchData || data?.length == 0 && payments?.data?.length == 0 ?
          'Sorry, there is no matching data to display' :
          <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
            <CircularProgress sx={{ color: "#707070" }} />
          </Box>,
      },
    },
    customToolbar: () => {
      return (<>
        <TextField
          variant="standard"
          placeholder="Search"
          onInput={(e: any) => handlefilter(e)}
          value={filterVal}
          className={classes.searchBar}
          InputProps={{
            style: { color: "black" },
            endAdornment: (
              <IconButton>
                <SearchOutlined />
              </IconButton>
            ),
          }}
        />
      </>)
    },
    renderExpandableRow: (row: any) => {
      const totalAmountBank = row?.[14]?.filter((data: any) => { return data.type === "bank" }).reduce((prev: any, next: any) => { return prev + next.amount }, 0);
      const totalAmountCard = row?.[14]?.filter((data: any) => { return data.type === "card" }).reduce((prev: any, next: any) => { return prev + next.amount }, 0) / 100;
      return (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Box className={classes.boxBorder1}>
              <div className="row">
                <Col lg="4" sm="4" xs="12" className={classes.Right}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    className={classes.TransferInitiated}
                  >
                    <GppGoodRoundedIcon />
                    Transfer Initiated
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    component="div"
                  >
                    Timestamp : {moment(row[11]).format("MMM D, YYYY | h:mm:ss A")}
                  </Typography>
                </Col>
                <Col lg="4" sm="4" xs="12">
                  <div className={classes.line}></div>
                </Col>

                <Col lg="4" sm="4" xs="12" className={classes.Left}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    className={classes.TransferInitiated}
                  // className={row[10] === 1 ? classes.TransferInitiated : classes.TransferFaild}
                  >
                    <GppGoodRoundedIcon />
                    Transfer Completed
                    {/* Transfer {paymentStatus(row[10])} */}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    component="div"
                  >
                    Timestamp : {row[12] !== null ? moment(row[12]).format("MMM D, YYYY | h:mm:ss A") : "---"}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    component="div"
                  >
                    Payment Gateway ID : {row[13]}
                  </Typography>
                </Col>
              </div>
            </Box>
            <hr style={{ margin: '0 auto', marginTop: 2, color: "#000000", opacity: 1, width: '70%' }} />

            <Container className="mb-3">
              <Col lg="12" sm="12" xs="12">
                <Table aria-label="simple table" >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                        Date
                      </StyledTableCell>
                      <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                        Refunded Amount
                      </StyledTableCell>
                      <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                        Payment Gateway Reason
                      </StyledTableCell>
                      <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                        Status
                      </StyledTableCell>
                      <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                        Remark
                      </StyledTableCell>
                      <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                        Payment Reference
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      row[14].length > 0 ? (
                        row[14].map((refund: RefundProps, index: number) => (
                          <StyledTableRow key={refund.id}>
                            <StyledTableCell align="center">
                              {refund.type === "bank" ? moment(new Date(refund.created)).format("YYYY-MM-DD") : moment(new Date(refund.created_at)).format("YYYY-MM-DD")}
                            </StyledTableCell>
                            <StyledTableCell scope="row" align="center">
                              CAD {refund.type === 'bank' ? Number(refund.amount).toFixed(2) : (Number(refund.amount) / 100).toFixed(2)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {refund.reason || "--"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {refund.status}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {refund?.metadata?.remark || "--"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {refund.bank_transaction_proof ?
                                <img
                                  title={refund.bank_transaction_proof.match(/.*\/(.*)$/)[1]}
                                  style={{ cursor: "pointer" }}
                                  width={32}
                                  src={getExtensionFromURL(refund.bank_transaction_proof) === ".pdf" ? pdfIcon : imgIcon}
                                  onClick={() => navigateToExternalUrl(refund.bank_transaction_proof)}
                                />
                                : <></>}
                              {refund.bank_transaction_proof2 ?
                                <img
                                  title={refund.bank_transaction_proof2.match(/.*\/(.*)$/)[1]}
                                  style={{ cursor: "pointer" }}
                                  width={32}
                                  src={getExtensionFromURL(refund.bank_transaction_proof2) === ".pdf" ? pdfIcon : imgIcon}
                                  onClick={() => navigateToExternalUrl(refund.bank_transaction_proof2)}
                                />
                                : <></>}
                              {refund.bank_transaction_proof3 ?
                                <img
                                  title={refund.bank_transaction_proof3.match(/.*\/(.*)$/)[1]}
                                  style={{ cursor: "pointer" }}
                                  width={32}
                                  src={getExtensionFromURL(refund.bank_transaction_proof3) === ".pdf" ? pdfIcon : imgIcon}
                                  onClick={() => navigateToExternalUrl(refund.bank_transaction_proof3)}
                                />
                                : <></>}
                              {refund.bank_transaction_proof4 ?
                                <img
                                  title={refund.bank_transaction_proof4.match(/.*\/(.*)$/)[1]}
                                  style={{ cursor: "pointer" }}
                                  width={32}
                                  src={getExtensionFromURL(refund.bank_transaction_proof4) === ".pdf" ? pdfIcon : imgIcon}
                                  onClick={() => navigateToExternalUrl(refund.bank_transaction_proof4)}
                                />
                                : <></>}
                              {refund.bank_transaction_proof5 ?
                                <img
                                  title={refund.bank_transaction_proof5.match(/.*\/(.*)$/)[1]}
                                  style={{ cursor: "pointer" }}
                                  width={32}
                                  src={getExtensionFromURL(refund.bank_transaction_proof5) === ".pdf" ? pdfIcon : imgIcon}
                                  onClick={() => navigateToExternalUrl(refund.bank_transaction_proof5)}
                                />
                                : <></>}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      ) : (
                        <StyledTableRow>
                          <StyledTableCell align="center" colSpan={8}>
                            No refunds found
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                  </TableBody>
                </Table>

                <Row className="mt-3">
                  <Col lg="6" sm="6" xs="12">
                    {row[14] && row[14]?.length > 0 ?
                      <Typography variant="subtitle1" gutterBottom component="div">
                        <b>Total Amount Refunded:</b> CAD {Number(totalAmountBank + totalAmountCard).toFixed(2)}
                      </Typography> : null}
                  </Col>
                  <Col lg="6" sm="6" xs="12">
                    {row[14] && row[14]?.length > 0 ?
                      row[15] > Number(totalAmountBank + totalAmountCard).toFixed(2) ?
                        <div className="button123" style={{ textAlign: "end", margin: 10 }}>
                          <Button className="saveButton" onClick={() => handlePartialRefund(row[16])}>
                            Add More Refund
                          </Button>
                        </div>
                        : null : null}
                  </Col>
                </Row>
              </Col>
            </Container>
          </TableCell>
        </TableRow >
      );
    },
  };

  const BreadCrumbSchoolPayment = [
    {
      labelText: "Home",
      linkUrl: ROUTES.home,
    },
    {
      labelText: "School payments",
      linkUrl: ROUTES.schoolPayment,
    },
    {
      labelText: `${filter === "1" ? "Received" : filter === "2" ? "Failed" : filter === "5" ? "Refunded" : "All payments"}`,
      linkUrl: "",
    },
  ];

  useEffect(() => {
    (async () => {
      const schoolPaymentList: any = await SchoolPaymentList(filter, page, rowsPerPage, token, order, search);
      renderDataTable(schoolPaymentList);
    })();
  }, [page, filter, order, search]);

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files;

    var imageContainer: any = [];

    for (var i = 0; i < file.length; i++) {
      var fileUpload: any = file[i];
      if (fileUpload) {
        imageContainer.push(fileUpload);
      }
    }

    if (imageContainer?.length > 0) {
      setAttachmentFile(imageContainer);
    }
  };

  const renderDataTable = (schoolPaymentList: any) => {
    const rowsDataTable = schoolPaymentList && schoolPaymentList.data.data.map((row: any) => {
      return {
        id: row ? row.id : "--",
        schoolname: row?.School ? row?.School?.name : "--",
        school_id: row?.School ? row?.School?.id : "--",
        date: <>{moment(row.created_at).format("YYYY-MM-DD")}</>,
        amount: <>{row.Plan ? row.Plan.symbol : "CAD"}{" "}{row ? Number(row.total_amount)?.toFixed(2) : "0"}</>,
        transaction_id: row.PaymentInfos[0] != null ? row.PaymentInfos[0].transaction : "--",
        fee_type: row && row.details != null ? row.details : "--",
        transaction_acc: <>**** **** ****{" "}{row.PaymentInfos ? managePaymentInfosLast(row.PaymentInfos) : "****"}</>,
        logo: row.PaymentInfos ? cardshow(row.PaymentInfos) : "--",
        refund: row.RefundInfos ? refundStatus(row) : "--",
        paymentStatus: row?.RefundInfos?.length && (row?.total_amount > Number(row?.RefundInfos?.map?.((data: any) => data.amount)?.reduce((prev: any, next: any) => Number(prev + next), 0) / 100).toFixed(2)) ?
          "Partially Refunded" : paymentStatus(row?.status),
        dateInitiate: row.created_at ? row.created_at : "--",
        dateComplete: row.paid_on ? row.paid_on : "--",
        paymentgateway_id: row?.payment_id,
        refundInformation: row?.RefundInfos,
        totalAmount: row?.total_amount,
        payId: row?.id,
      };
    });
    setData(rowsDataTable);
    setSearchApiData(rowsDataTable);
  };

  const handleFilter = (newValue: string) => {
    setFilters(newValue);
  };

  // const manageStatus = (value: number) => {
  //   switch (value) {
  //     case 1:
  //       return "Received";
  //     case 2:
  //       return "Failed";
  //     case 3:
  //       return "Cancelled";
  //     case 4:
  //       return "Refund Requested";
  //     case 5:
  //       return "Refunded";
  //     default:
  //       return "Received";
  //   }
  // };

  const handleClickOpen = async (id: any) => {
    // setViewRefunds(false);
    setID(id);
    setTimeout(() => {
      setOpen(true);
    }, 1000);
  };

  const handleClose = () => {
    setID("");
    setBankTransactionId("");
    setRefundDate(null);
    setRefundRemarks("");
    setIsValidValues(true);
    resetData();
    setOpen(false);
    setTimeout(() => {
      setUpdate(false);
    }, 1000);
  };

  const refundStatus = (value: any) => {
    // if (value.status === 1) {
    //   return (<RiRefund2Fill size={22} color="green" />);
    // }
    if (value.RefundInfos.length === 0) {
      return (
        <Tooltip title="Add Refund" style={{ cursor: 'pointer' }}>
          <RiRefund2Fill size={22} color="green" onClick={() => handleClickOpen(value.id)} />
          {/* <AvTimer onClick={() => handleClickOpen(value.id)} sx={{ fill: red[600], fontSize: 20 }} /> */}
        </Tooltip>
      );
    }
    else {
      return (
        <Tooltip title="Refunded">
          <DoneOutlined sx={{ fill: green[600], fontSize: 20 }} />
        </Tooltip>
      );
    }
  };

  // const managePaymentInfosCardId = (value: any) => {
  //   if (value.length === 0) {
  //     return "---";
  //   }
  //   return value[0].charge_id;
  // };

  const managePaymentInfosLast = (value: any) => {
    if (value.length === 0) {
      return " ****";
    }
    return value[0].last4;
  };

  const cardshow = (value: any) => {
    if (value.length === 0) {
      return "---";
    }
    switch (value[0].brand) {
      case "visa":
        return <img src={VisaCardIcon} alt="visa" />;
      case "master":
        return <img src={MasterCardIcon} alt="visa" />;
      default:
        return <img src={VisaCardIcon} alt="visa" />;
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const resetData = () => {
    setRefundAmount("");
    setRefundType("");
    setPaymentPdf([]);
  };

  // const creactSortHandler = async () => {
  //   setOrder(order === "asc" ? "desc" : "asc");
  // };

  const handleSearch = (value: string) => {
    setSearch(value);
  };
  // model
  const { addRefund } = Actions;
  const [refundAmount, setRefundAmount] = useState("");
  const [refundDate, setRefundDate] = useState<any>(null);
  const [refundType, setRefundType] = useState("");
  const [refundRemarks, setRefundRemarks] = useState("");
  const [bankTransactionId, setBankTransactionId] = useState("");
  const [update, setUpdate] = useState(false)
  // const [viewRefunds, setViewRefunds] = useState(false);
  const [paymentPdf, setPaymentPdf] = useState<any>({});

  const SubmitHandlerRefund = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);

    if (refundType === "bank" ?
      isRequiredField(refundType) &&
      isRequiredField(bankTransactionId) &&
      isRequiredDate(refundDate) &&
      isRequiredField(refundAmount) &&
      isRequiredField(refundRemarks)
      :
      isRequiredField(refundType) &&
      isRequiredField(refundAmount) &&
      isRequiredField(refundRemarks)
    ) {
      setIsSubmit(false);
      const formData = new FormData();

      formData.append("amount", refundAmount)
      formData.append("type", refundType);
      formData.append("remark", refundRemarks);
      if (refundType === "bank") {
        formData.append("bank_proof", attachmentFile[0] ? attachmentFile[0] : null);
        formData.append("bank_proof2", attachmentFile[1] ? attachmentFile[1] : null);
        formData.append("bank_proof3", attachmentFile[2] ? attachmentFile[2] : null);
        formData.append("bank_proof4", attachmentFile[3] ? attachmentFile[3] : null);
        formData.append("bank_proof5", attachmentFile[4] ? attachmentFile[4] : null);
        formData.append("bank_transaction_id", bankTransactionId);
        formData.append("created", refundDate);
      } else {
        formData.append("reason", "requested_by_customer");
      }

      let resData: any = await addRefund(id, formData, token);
      if (!resData.error) {
        toast.success(REFUND_SUCCESSFULLY_CREATED);
        setTimeout(async () => {
          handleClose();
          const schoolPaymentList: any = await SchoolPaymentList(filter, page, rowsPerPage, token, order, search);
          renderDataTable(schoolPaymentList);
        }, 2000);
      }
      return
    }
    setIsValidValues(false);
  };

  // const viewAllRefunds = async (id: string) => {
  //   setViewRefunds(true);
  //   setID(id);
  //   const response: RefundProps = await Actions.FetchRefundData(id, token);

  //   var totalRefunded = response?.RefundInfos?.map?.((data: any) => data.amount).reduce((prev: any, next: any) => Number(prev + next))

  //   if (response?.total_amount > Number(totalRefunded / 100).toFixed(2)) {
  //     setIsMoreRefund(true);
  //   }

  //   setPaymentId(response?.payment_id)
  //   setRefundData(response?.RefundInfos);
  //   setTimeout(() => {
  //     setOpen(true);
  //   }, 1000);
  // };

  // const handleRefundDate = (date: any) => {
  //   setRefundDate(date);
  // };

  const handlePartialRefund = (payId: string) => {
    handleClickOpen(payId);
  }

  const navigateToExternalUrl = async (urlName: string, shouldOpenNewTab: boolean = true) => {
    if (urlName) {
      const newData: { url: string, msg: string, error: boolean } = await fileSignedURL(urlName && urlName, token);
      const { url, msg, error } = newData;
      if (error) {
        toast.error(msg);
        return;
      }
      shouldOpenNewTab ? window.open(url, "_blank") : window.location.href = url;
      return;
    }
  }

  // const navigateToUrl: any = async (urlName: string, shouldOpenNewTab: boolean = true) => {
  //   if (urlName) {
  //     const newData: { url: string, msg: string, error: boolean } = await fileSignedURL(urlName && urlName, token);
  //     const { url, msg, error } = newData;
  //     if (error) {
  //       return;
  //     }
  //     return url;
  //   }
  // }

  const handleRefundDate = (date: any) => {
    setRefundDate(date);
  };

  return (
    <>
      <ToastMessage />
      <CommonHeadPart
        componentText="School Payments"
        Icon={SchoolPaymentIcon}
        buttonText=""
        urlLink="/"
        BreadCrumbs={BreadCrumbSchoolPayment}
      />
      <div className={classes.divInsideMain}>
        <ThemeProvider theme={theme} >
          <MUIDataTable
            title={<FilterTabs
              SelectedFilter={filter}
              Filters={SchoolPaymentFilterTabs}
              HandleChange={handleFilter}
              HandleSearch={handleSearch}
              isSearch={false}
            />}
            data={data}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </div>

      <BootstrapDialog
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <form onSubmit={SubmitHandlerRefund}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Add Refund
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Row>
              <Col lg="6" sm="12">
                <InputField
                  type="number"
                  lable="Refund Amount"
                  name={refundAmount}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Refund Amount"
                  className="form-textfield-styles"
                  value={refundAmount}
                  handleChange={(e) => setRefundAmount(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Refund Amount field is required"
                  disablePrevious="0"
                />
              </Col>
              <Col lg="6" sm="12">
                <InputSelectField
                  select={true}
                  lable="Transfer Type"
                  name={refundType}
                  value={refundType}
                  className="form-textfield-styles"
                  options={[
                    { id: "", name: "Select Transfer Type" },
                    { id: "card", name: "Transfer To Existing Acccount" },
                    { id: "bank", name: "Bank Transfer" },
                  ]}
                  handleChange={(e) => setRefundType(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Transfer Type field is required"
                />
                {/* <InputField
                  disabled
                  type="text"
                  lable="Refund Reason"
                  name={refundReason}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Refund Reason"
                  className="form-textfield-styles"
                  value="requested_by_customer"
                  handleChange={() => { }}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Refund Reason field is required"
                />
                <InputDatePicker
                  className="form-textfield-styles errorBottom"
                  value={refundDate}
                  lable="Refund date"
                  handleChange={(e) => handleRefundDate(e)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Refund Amount field is required"
                /> */}
              </Col>
            </Row>
            {refundType === "bank" ?
              <>
                <Row>
                  <Col lg="6" sm="12">
                    <InputField
                      type="text"
                      lable="Bank Transaction ID"
                      name={bankTransactionId}
                      rows={1}
                      multiline={false}
                      placeHolder="Enter Bank Transaction ID"
                      className="form-textfield-styles"
                      value={bankTransactionId}
                      handleChange={(e) => setBankTransactionId(e.target.value)}
                      isValidValue={isValidValues}
                      isSubmit={isSubmit}
                      requiredMessage="Bank Transaction ID field is required" />
                  </Col>
                  <Col lg="6" sm="12">
                    <InputDatePicker
                      className="form-textfield-styles"
                      value={refundDate}
                      lable="Bank Transfer date"
                      handleChange={(e) => handleRefundDate(e)}
                      isValidValue={isValidValues}
                      isSubmit={isSubmit}
                      requiredMessage="Refund Amount field is required" />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" sm="12">
                    <label style={{ fontSize: 12 }}>
                      <b>Payment Reference</b><br />
                      <input
                        id='file'
                        name="file"
                        required
                        multiple
                        accept=".pdf, .png, .jpg, .jpeg"
                        style={{ fontSize: 16 }}
                        className="form-textfield-styles"
                        type='file'
                        placeholder="Upload an Image"
                        onChange={(e: any) => onChangeFile(e)} />
                    </label>
                  </Col>
                </Row>
              </>
              : null}
            <Row>
              <Col lg="12" sm="12">
                <InputField
                  type="text"
                  lable="Refund Remark"
                  name={refundRemarks}
                  rows={4}
                  multiline={true}
                  placeHolder="Enter Refund Remark"
                  className="form-textfield-styles"
                  value={refundRemarks}
                  handleChange={(e) => setRefundRemarks(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Refund Remark field is required"
                />
              </Col>
            </Row>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
            <Button
              type="button"
              variant="contained"
              onClick={handleClose}
              className={classes.saveEmailButton}
            >
              Cancel
            </Button>{" "}
            {update === true ? null : <SaveButtonComponent ButtonType="submit" ButtonLabel="Submit" />}
          </DialogActions>
        </form>
      </BootstrapDialog >
    </>
  );
};

export default SchoolPayment;
