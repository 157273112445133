export const ROUTES = {
  login: "/",
  forgotPassword: "/forgot-password",
  newPassword: "/new-password",
  generateOTP: "/generate-otp",
  changePassword: "/change-password",
  changePasswordOTP: "/change-password-otp",
  changeNewPassword: "/change-new-password",
  addOTP: "/add-otp",
  v2AddOTP: "/add-otp-2",
  home: "/dashboard",
  dashboard: "/dashboard",
  communication: "/communication",
  updatePlansFees: "/update-plans-fees",
  platformFeatureTogglee: "/platform-feature-togglee",
  schoolPayment: "/school-payment",
  school: "/school",
  programs: "/programs",
  generateInvoice: "/generate-invoice",
  manageLicence: "/manage-standard-licence",
  profile: "/profile",
  addnewuser: "/add-new-user",
  tax: "/province-tax",
  settings: "/settings",
};

export const InnerPageRoutes = {
  composeMessage: `${ROUTES.communication}/compose-message`,
  sendDraftMessage: `${ROUTES.communication}/send-draft-message`,
  createNewPlan: `${ROUTES.updatePlansFees}/create-new-plan`,
  addNewToggle: `${ROUTES.platformFeatureTogglee}/add-new-toggle`,
  newSchoolDetail: `${ROUTES.school}/detail-page`,
  addNewPrograms: `${ROUTES.programs}/add-new-programs`,
  editPrograms: `${ROUTES.programs}/edit-program`,
  generateInvoice: `${ROUTES.generateInvoice}/create-invoice`,
  sendDraftInvoice: `${ROUTES.generateInvoice}/send-draft-invoice`,
  viewLicense: `${ROUTES.generateInvoice}/view-license`,
  addNewLicense: `${ROUTES.manageLicence}/add-new-license`,
  updateLicense: `${ROUTES.manageLicence}/update-license`,
  allMessages: `${ROUTES.communication}/all-messages`,
  singleMessageReply: `${ROUTES.communication}/reply-message`,
  messageReply: `${ROUTES.communication}/message-reply`,
};

