import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core/styles";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { CancelButtonProps, SaveButtonProps } from "../ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { SendButtonProps } from "../ts/FormType";
import { textTransform } from "@mui/system";

const useStyles = makeStyles(() => ({
  floatRightText: {
    float: "right",
    paddingTop: "9px !important",
    borderRadius: "50px !important",
    backgroundColor: "#00a651 !important",
    fontFamily: 'Arial !important',
    textTransform: 'capitalize',
  },
  discardButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #8d8d8d !important",
    color: "#393939 !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontFamily: 'Arial !important',
    fontSize: '14px !important',
    textTransform: "capitalize",
  },
  saveEmailButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #393939 !important",
    color: "#393939 !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontFamily: 'Arial !important',
    fontSize: '14px !important',
    textTransform: "capitalize",
  },
  sendButton: {
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontFamily: 'Arial !important',
    textTransform: 'capitalize',
    fontSize: '14px !important',
  },
  cancelButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #393939 !important",
    color: "#393939 !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontFamily: 'Arial !important',
    fontSize: '14px !important',
    textTransform: 'capitalize',
  },
  saveButton: {
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontFamily: 'Arial !important',
    fontSize: '14px !important',
    textTransform: "capitalize",
  },
}));

type ButtonTextProps = {
  buttonText: string;
  link: string;
};

const ButtonComponent: React.FC<ButtonTextProps> = ({
  buttonText,
  link,
}): JSX.Element => {
  const classes = useStyles();
  return (
    <Link to={link}>
      <Button variant="contained" className={classes.floatRightText}>
        {buttonText}
      </Button>
    </Link>
  );
};

export default ButtonComponent;

export const DiscardButtonComponet: React.FC = () => {
  const classes = useStyles();
  return (
    <Button variant="contained" className={classes.discardButton}>
      Discard
    </Button>
  );
};

export const SaveButtonComponet: React.FC = () => {
  const classes = useStyles();
  return (
    <Button variant="contained" className={classes.saveEmailButton}>
      Save
    </Button>
  );
};

export const SendButtonComponet: React.FC<SendButtonProps> = ({
  disableProps = false,
}) => {
  const classes = useStyles();
  return (
    <Button
      type="submit"
      variant="contained"
      className={classes.sendButton}
      disabled={disableProps}
    >
      Send
    </Button>
  );
};

export const FilterButtonComponent: React.FC = () => {
  return (
    <Button
      endIcon={<FontAwesomeIcon icon={faFilter} />}
      style={{ color: "#000000" }}
    >
      Filter
    </Button>
  );
};

export const SaveButtonComponent: React.FC<SaveButtonProps> = ({
  ButtonLabel,
  ButtonType,
}): JSX.Element => {
  const classes = useStyles();
  return (
    <Button type="submit" variant="contained" className={classes.saveButton}>
      {ButtonLabel}
    </Button>
  );
};

export const CancelButtonComponent: React.FC<CancelButtonProps> = ({
  ButtonLabel,
}): JSX.Element => {
  const classes = useStyles();
  return (
    <Button variant="outlined" className={classes.cancelButton}>
      {ButtonLabel}
    </Button>
  );
};
