import React, { useState } from "react";
import "../Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col, Button } from "react-bootstrap";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux";
import CommonHeadPart from "../../common-components/CommonHeadPart";
import DashboardIcon from "../../svg_icons/dashboard.svg";
import { ROUTES } from "../../constants/routes";
import moment from "moment";
import { InputField } from "../../common-components/FormComponent/FormFieldsComponent";
import FieldError from "../../common-components/FormComponent/ErrorMessage";
import * as Action from "../../reducer-store/actions/authActions"
import { bindActionCreators } from "redux";
import { UPDATE_ADMIN_SUCCESSFULLY } from "../../reducer-store/ToastMessage";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import ToastMessage from "../../common-components/ToastMessage";

const useStyles = makeStyles((theme: Theme) => ({
  divInsideMain: {
    boxShadow: "0 0 0 #8e8e8e",
    borderRadius: "3px",
    marginLeft: "20px",
    marginRight: "20px",
    paddingBottom: "30px"
  },
  backGroundColor: {
    borderRadius: '10px',
  },
  saveEmailButton: {
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    font: 'normal normal normal 14px Arial !important',
    marginRight: 20,
    marginTop: 6,
    textTransform: 'capitalize'
  },
}));

const BreadCrumbDashboard = [
  {
    labelText: "Home",
    linkUrl: ROUTES.home,
  },
  {
    labelText: "Profile",
    linkUrl: "",
  },
];

const BasicDetails: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.auth.user);
  const data = useSelector((state: any) => state.auth);
  const { token, userUpdated } = data;
  const { SingleAdminUser } = bindActionCreators(Action, dispatch);
  const { updateProfile } = Action;
  const [isValidValues, setIsValidValues] = useState(true);
  const [photoIdImgError, setPhotoIdImgError] = useState(false);
  const [photoIdImgErrorMsg, setPhotoIdImgErrorMsg] = useState("");
  const [photoId, setPhotoID] = useState<any>([]);

  const imageValidate = (fileInfo: any) => {
    if (fileInfo === null) {
      setPhotoIdImgErrorMsg("Please upload Require Document");
      setPhotoIdImgError(true)
      return false;
    }
    let typeImage = ["image/jpeg", "image/jpg", "image/png"];
    if (typeImage.includes(fileInfo.type)) {
      setPhotoIdImgError(false)
      return true;
    } else {
      setPhotoIdImgError(true)
      setPhotoIdImgErrorMsg("Please upload valid image(jpg,jpeg,png).");
      return false;
    };
  }

  const handleImage = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    let file = event.target.files[0];
    if (imageValidate(file)) {
      setPhotoID(file);
      setIsValidValues(true);
      return;
    }
    setIsValidValues(false);
    setPhotoID(file);
  };

  const SubmitHandler = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("profile", photoId);
    let resData: any = await updateProfile(formData, token)
    if (!resData?.error) {
      toast.success(UPDATE_ADMIN_SUCCESSFULLY);
      await SingleAdminUser(token);
      setTimeout(() => {
        history.push(ROUTES.dashboard);
      }, 2000);
    }
    return;
  }

  return (
    <>
      <CommonHeadPart
        componentText="Profile"
        Icon={DashboardIcon}
        buttonText=""
        urlLink="/"
        BreadCrumbs={BreadCrumbDashboard}
      />

      <div className={classes.divInsideMain}>
        <ToastMessage />
        <Row className="justify-content-sm-start">
          <Col lg="12" sm="12">
            <Card className={classes.backGroundColor}>
              <CardContent>
                <Typography variant="h6" align="justify">
                  Basic Details
                </Typography>
                <div className="stline"></div>
                <Row>
                  <Col lg="4" sm="12">
                    <InputField
                      disabled
                      type="text"
                      lable="Id"
                      name={"id"}
                      rows={1}
                      multiline={false}
                      placeHolder="Enter id"
                      className="form-textfield-styles"
                      value={user?.id}
                      handleChange={(e) => { }}
                      isValidValue={false}
                      isSubmit={false}
                      requiredMessage="Name field is required"
                    />
                  </Col>
                  <Col lg="4" sm="12">
                    <InputField
                      disabled
                      type="text"
                      lable="Name"
                      name={"name"}
                      rows={1}
                      multiline={false}
                      placeHolder="Enter name"
                      className="form-textfield-styles"
                      value={user?.full_name}
                      handleChange={(e) => { }}
                      isValidValue={false}
                      isSubmit={false}
                      requiredMessage="Name field is required"
                    />
                  </Col>
                  <Col lg="4" sm="12">
                    <InputField
                      disabled
                      type="email"
                      lable="Username"
                      name={"username"}
                      rows={1}
                      multiline={false}
                      placeHolder="Enter Email-Id"
                      className="form-textfield-styles"
                      value={user?.username}
                      handleChange={(e) => { }}
                      isValidValue={false}
                      isSubmit={false}
                      requiredMessage=""
                    />
                  </Col>

                </Row>
                <Row>
                  <Col lg="4" sm="12">
                    <InputField
                      disabled
                      type="type"
                      lable="User Role"
                      name={"user_role"}
                      rows={1}
                      multiline={false}
                      placeHolder="Enter Email-Id"
                      className="form-textfield-styles"
                      value={user?.Role?.name}
                      handleChange={(e) => { }}
                      isValidValue={false}
                      isSubmit={false}
                      requiredMessage="Email-Id field is required"
                    />
                  </Col>
                  <Col lg="4" sm="12">
                    <InputField
                      disabled
                      type="text"
                      lable="Phone Number"
                      name={"phoneNumber"}
                      rows={1}
                      multiline={false}
                      placeHolder="Enter phone number"
                      className="form-textfield-styles"
                      value={user?.mobile}
                      handleChange={(e) => { }}
                      isValidValue={false}
                      isSubmit={false}
                      requiredMessage="Phone number is required"
                    />
                  </Col>
                  <Col lg="4" sm="12">
                    <InputField
                      disabled
                      type="email"
                      lable="Email-Id"
                      name={"email"}
                      rows={1}
                      multiline={false}
                      placeHolder="Enter Email-Id"
                      className="form-textfield-styles"
                      value={user?.email}
                      handleChange={(e) => { }}
                      isValidValue={false}
                      isSubmit={false}
                      requiredMessage="Email-Id field is required"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" sm="12">
                    <InputField
                      disabled
                      type="text"
                      lable="Created At"
                      name={"phoneNumber"}
                      rows={1}
                      multiline={false}
                      placeHolder="Enter phone number"
                      className="form-textfield-styles"
                      value={moment(user?.created_at).format("DD-MM-YYYY H:s")}
                      handleChange={(e) => { }}
                      isValidValue={false}
                      isSubmit={false}
                      requiredMessage="Phone number is required"
                    />
                  </Col>
                  <Col lg="4" sm="12">
                    <InputField
                      disabled
                      type="text"
                      lable="Last Updated"
                      name={"lastUpdated"}
                      rows={1}
                      multiline={false}
                      placeHolder="Enter House/Apt Number"
                      className="form-textfield-styles"
                      value={moment(user?.last_updated).format("DD-MM-YYYY H:s")}
                      handleChange={(e) => { }}
                      isValidValue={false}
                      isSubmit={false}
                      requiredMessage="House/Apt number is required"
                    />
                  </Col>
                  {/* <Col lg="4" sm="12">
                    <InputField
                      disabled
                      type="text"
                      lable="Street Name "
                      name={"streetname"}
                      rows={1}
                      multiline={false}
                      placeHolder="Enter Street Name"
                      className="form-textfield-styles"
                      value={user?.street_address}
                      handleChange={(e) => { }}
                      isValidValue={false}
                      isSubmit={false}
                      requiredMessage="Street Name is required"
                    />
                  </Col>
                  <Col lg="4" sm="12">
                    <InputField
                      disabled
                      type="text"
                      lable="City "
                      name={"city"}
                      rows={1}
                      multiline={false}
                      placeHolder="Enter City"
                      className="form-textfield-styles"
                      value={user?.city}
                      handleChange={(e) => { }}
                      isValidValue={false}
                      isSubmit={false}
                      requiredMessage="City is required"
                    />
                  </Col> */}
                </Row>
                <Row>
                  <Col lg="2" sm="12">
                    <input style={{ margin: '10px' }} accept="image/*" multiple type="file" onChange={(e) => handleImage(e)} />
                  </Col>
                  <Col>
                    {photoId.length !== 0 ? (
                      <img
                        src={URL.createObjectURL(photoId)}
                        width={130}
                        height={130}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg="3" sm="12">
                    <button
                      disabled={photoId.length == 0 ? true : false}
                      type="submit" onClick={SubmitHandler}
                      className={classes.saveEmailButton}>
                      {user?.profile_url || userUpdated?.data?.profile_url ? "Update Profile Picture" : "Add Profile Picture"}
                    </button>
                    {/* <Button
                      disabled={photoId.length == 0 ? true : false}
                      type="submit"
                      variant="contained"
                      className={classes.saveEmailButton}
                      onClick={SubmitHandler}
                    >
                      {user?.profile_url || userUpdated?.data?.profile_url ? "Update Profile Picture" : "Add Profile Picture"}
                    </Button> */}
                    {
                      photoIdImgError ? <FieldError message={photoIdImgErrorMsg} /> : null
                    }
                  </Col>
                </Row>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BasicDetails;

