import { ActionProps } from "../../ts";
import { LIST_OF_PLAN, LIST_OF_PLAN_ERROR } from "../ActionTypes";

const initialState = { plans: null };

const planStore = (state: any = initialState, action: ActionProps) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_OF_PLAN: {
      return {
        ...state,
        plans: payload,
      };
    }
    case LIST_OF_PLAN_ERROR: {
      return {
        ...state,
        plans: null,
      };
    }
    default:
      return state;
  }
};

export default planStore;
