import React, { useState, useEffect, SetStateAction } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Row, Col } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputField,
  InputSelectField,
} from "../../../common-components/FormComponent/FormFieldsComponent";
import { isRequiredField, isValidRegisterID, isValidNo } from "../../../common-components/FormComponent/FormValidator";
import { SaveButtonComponent } from "../../../common-components/ButtonComponent";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
// import TableContainer from "@mui/material/TableContainer";
// import Table from "@mui/material/Table";
// import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Button, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
// import Collapse from "@mui/material/Collapse";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TypographyComponent from "./TableComponent/TypographyComponent";
import ToastMessage from "../../../common-components/ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../reducer-store/actions/topicAction";
import { bindActionCreators } from "redux";
import { LIMIT, PAGE } from "../../../reducer-store/constants";
import { toast } from "react-toastify";
import { TOPIC_SUCCESSFULLY_CREATED, TOPIC_SUCCESSFULLY_UPDATED } from "../../../reducer-store/ToastMessage";
// import PaginationComponent from "../../../common-components/PaginationComponent";
import { HOURSLIMITE } from "../../../reducer-store/constants";
import { useHistory } from "react-router-dom";
// import { ROUTES } from "../../../constants/routes";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import { FaRegEdit } from "react-icons/fa";

type AddCoursesProps = {
  handleStep: React.Dispatch<SetStateAction<number>>;
  stepValue: number;
};

const useStyles = makeStyles(() => ({
  programCard: {
    backgroundColor: "#ececec",
  },
  button_save: {
    textAlign: "right",
  },
  fieldDisable: {
    color: "rgba(0, 0, 0, 0.38) !important",
    fontSize: "16px !important",
  },
  backButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #393939 !important",
    color: "#393939 !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontWeight: 500,
    fontFamily: 'Arial !important',
    fontSize: "0.875rem",
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "capitalize",
    minWidth: "64px",
  },
  searchBar: {
    borderColor: "black",
    "& hover": {
      borderColor: "black"
    },
    "& focused": {
      borderColor: "black"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black"
    }
  },
  dialogTitle: {
    backgroundColor: "#646464",
    color: "#ffffff",
  },
}));

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontFamily: 'Arial',
          "& .css-1ygcj2i-MuiTableCell-root": {
            fontweight: "bold",
            color: "#FFFFFF",
            fontFamily: 'Arial'
          },
          "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
            display: "-webkit-box"
          },
          "& .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader": {
            backgroundColor: "#393939",
            fontFamily: 'Arial',
          },
          "& .tss-1akey0g-MUIDataTableHeadCell-data": {
            color: "#FFFFFF",
          },
          "& .tss-1fz4yw6-MUIDataTableSelectCell-fixedLeft": {
            backgroundColor: "#393939",
          },
          "& .css-11k72hi-MuiTableCell-root.MuiTableCell-head": {
            backgroundColor: "#646464",
          },
          "& .css-1ge9txp-MuiTableCell-root.MuiTableCell-head": {
            backgroundColor: "#646464",
          },
          "& .tss-tlx3x1-MUIDataTableToolbar-root": {
            minHeight: "0px !important",
          }
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          fill: "#FFFFFF"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Arial'
        },
        head: {
          color: "#FFFFFF",
          fontFamily: 'Arial',
        },
        footer: {
          backgroundColor: "#FFFFFF"
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: "#DFDFDF"
          },
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "0px !important",
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#E53D12',
          borderRadius: '4px',
          color: 'white',
          marginRight: '25px',
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& .MuiTablePagination-displayedRows": {
            marginTop: "9%"
          },
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          minHeight: 0,
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          fontFamily: "Arial",
        }
      }
    }
  }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function AddTopics({ handleStep, stepValue }: AddCoursesProps) {
  const [Courses, setCourses] = useState("");
  const [Modual, setModual] = useState("");
  const [topicVisible, setTopicVisible] = useState(true);
  const [Program, setProgram] = useState("");
  const [TopicName, setTopicName] = useState("");
  const [TotalHours, setTotalHours] = useState<any>("");
  const [InclassTrainingHours, setInclassTrainingHours] = useState<any>("");
  const [InyardTrainingHours, setInyardTrainingHours] = useState<any>("");
  const [IncabTrainingHours, setIncabTrainingHours] = useState<any>("");
  const [Description, setDescription] = useState("");
  const [saveUpdate, setSaveUpdate] = useState(true)
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const classes = useStyles()
  const history = useHistory();
  const dispatch = useDispatch();
  const { TopicList } = bindActionCreators(Actions, dispatch);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [page, setPage] = useState(PAGE);
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const topicList = useSelector((state: any) => state.topic);
  const { topics } = topicList;
  const [topicId, setTopicId] = useState("")
  const [courseList, setCourseList] = useState([{ id: "", name: "Select Course" }])
  const [moduleList, setModuleList] = useState([{ id: "", name: "Select Module" }])
  const [programList, setProgramList] = useState([{ id: "", name: "Select Program" }])
  const [programName, setProgramName] = useState("");
  const [programId, setProgramId] = useState("");
  const [data, setData] = useState([]);
  const [addCourseVisible, setAddCourseVisible] = useState(false);
  const [filterVal, setFilterVal] = useState('');

  const columns = [
    {
      label: 'Topic Name',
      name: "topic_name",
    },
    {
      label: 'Course Name',
      name: "course_name",
    },
    {
      label: 'Module Name',
      name: "module_name",
    },
    {
      label: 'Total Hours',
      name: "total_hours",
    },
    {
      label: 'Total Inclass Training Hours',
      name: "inclass",
    },
    {
      label: 'Total Inyard Training Hours',
      name: "inyard",
    },
    {
      label: 'Total Incab Training Hours',
      name: "incab",
    },
    {
      label: 'Edit',
      name: "edit",
      options: {
        sort: false
      }
    },
    {
      label: 'Description',
      name: 'description',
      options: {
        display: false,
      }
    },
  ];

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    (async () => {
      const programId: any = sessionStorage.getItem("program_id");
      setProgramId(programId)
      const program_name: any = sessionStorage.getItem("program_name");
      setProgramName(program_name);
      if (programId === null || programId === "") {
        const topicList = await TopicList(page, -1, rowsPerPage, token);
        renderDataTable(topicList);
      } else {
        const topicList = await TopicList(page, programId, rowsPerPage, token);
        renderDataTable(topicList);
      }
    })();
  }, [page, addCourseVisible]);

  useEffect(() => {
    (async () => {
      const programId: any = sessionStorage.getItem("program_id");
      setProgramId(programId)
      const program_name: any = sessionStorage.getItem("program_name");
      setProgramName(program_name);
      // const courseArr = await Actions.DropDownCourseList(page, programId, token);
      const moduleArr = await Actions.DropDownModualList(page, programId, token)
      // const programArr = await Actions.DropDownProgramList(page, token)
      // setCourseList([...courseList, ...courseArr.data])
      setModuleList([...moduleList, ...moduleArr.data])
      // setProgramList([...programList, ...programArr.data])
    })();
  }, [programId])

  useEffect(() => {
    (async () => {
      if (Modual === null || Modual === "") {
        return;
      } else {
        const courseArr = await Actions.DropDownCourseList(page, Modual, token);
        setCourseList(courseArr.data.length > 0 ? [{ id: "", name: "Select Course" }, ...courseArr.data] : [{ id: "", name: "No Course Found" }])
      }
    })();
  }, [Modual])

  const renderDataTable = (topicList: any) => {
    const rowsDataTable = topicList && topicList.data.data.map((row: any) => {
      return {
        topic_name: row.name,
        course_name: row.Course.name,
        module_name: row?.ProgramModule?.name,
        total_hours: row.duration_minutes !== null ? row.duration_minutes / 60 : "---",
        inclass: row.inclass_training_minutes !== null ? row.inclass_training_minutes / 60 : "---",
        inyard: row.inyard_training_minutes !== null ? row.inyard_training_minutes / 60 : "---",
        incab: row.incab_training_minutes ? row.incab_training_minutes / 60 : "---",
        edit: <FaRegEdit size={18} onClick={() => handleEditTopics(row)} />,
        description: row.description !== null ? row.description : "---",
      };
    });
    setData(rowsDataTable);
  };

  const options = {
    search: false,
    print: false,
    viewColumns: false,
    filter: false,
    download: false,
    responsive: "vertical",
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    selectableRows: "none",
    rowsPerPageOptions: [],
    textLabels: {
      body: {
        noMatch: !topics || (topics?.data?.length > 0 && data?.length == 0) ?
          <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
            <CircularProgress sx={{ color: "#707070" }} />
          </Box > :
          'Sorry, there is no matching data to display',
      },
    },
    renderExpandableRow: (row: any) => {
      return (
        <StyledTableCell
          style={{ backgroundColor: "#F0F0F0 !important", paddingBottom: 0, paddingTop: 0 }}
          colSpan={9}
        >
          <Box sx={{ margin: 1 }}>
            <Typography fontWeight={700} color="#404040">
              Description :
            </Typography>
            <Typography color="#606060">
              {row[8] ? row[8] : "No Description Found"}
            </Typography>
          </Box>
        </StyledTableCell>
      );
    },
  };

  const handleBack = () => {
    handleStep((stepValue) => stepValue - 1);
  };

  const handleCancel = () => {
    resetData();
    setSaveUpdate(true);
    setIsSubmit(false);
  }

  const resetData = () => {
    // setCourses("");
    // setModual("");
    setProgram("");
    setTopicName("");
    setTotalHours("");
    setInclassTrainingHours("");
    setInyardTrainingHours("");
    setIncabTrainingHours("");
    setDescription("");
  };

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      isRequiredField(Courses) &&
      isRequiredField(Modual) &&
      isRequiredField(TopicName) &&
      isRequiredField(InclassTrainingHours) &&
      isRequiredField(InyardTrainingHours) &&
      isRequiredField(IncabTrainingHours) &&
      isRequiredField(Description) &&
      isValidRegisterID(Description)
    ) {
      setIsSubmit(false);
      let topicObj = {
        program_id: programId,
        module_id: Modual,
        course_id: Courses,
        name: TopicName,
        description: Description,
        duration_minutes: Math.floor(InclassTrainingHours * 60) + Math.floor(InyardTrainingHours * 60) + Math.floor(IncabTrainingHours * 60),
        inclass_training_minutes: Math.floor(InclassTrainingHours * 60),
        inyard_training_minutes: Math.floor(InyardTrainingHours * 60),
        incab_training_minutes: Math.floor(IncabTrainingHours * 60),
        total_score: 100,
        status: 1,
      };

      let resData: any = saveUpdate ? await Actions.addTopic(topicObj, token) : await Actions.UpdateTopic(
        topicId,
        topicObj,
        token
      );
      if (!resData.error) {
        toast.success(saveUpdate ? TOPIC_SUCCESSFULLY_CREATED : TOPIC_SUCCESSFULLY_UPDATED);

        const topicList: any = await TopicList(page, programId, rowsPerPage, token);
        renderDataTable(topicList);
        setTimeout(() => {
          setAddCourseVisible(!addCourseVisible);
          resetData();
          setSaveUpdate(true);
          // saveUpdate ? history.push(ROUTES.programs) : TopicList(page, programId, rowsPerPage, token) && setSaveUpdate(!saveUpdate);
        }, 2000);
      }
    }
    setIsValidValues(false);
  };

  const handleEditTopics = async (row: any) => {
    setTopicVisible(false);
    window.scrollTo(0, 0);
    setTopicId(row.id)
    setCourses(row.course_id)
    setProgram(row.program_id)
    setModual(row.module_id)
    setTopicName(row.name)
    setTotalHours(row.duration_minutes / 60)
    setInclassTrainingHours(row.inclass_training_minutes / 60)
    setInyardTrainingHours(row.inyard_training_minutes / 60)
    setIncabTrainingHours(row.incab_training_minutes / 60)
    setDescription(row.description)
    setSaveUpdate(false)
  }

  const onChangeHours = (value: any, setMethod: any) => {
    const re = /^(?=.*[1-9])\d+(?:\.[05]0?)?$/;
    if (value === '' || re.test(value))
      setMethod(value);
  }

  const onChangeProgram = (value: any, setMethod: any) => {
    var regexp = /^[a-zA-Z0-9_]+$/;
    if (value === '' || regexp.test(value))
      setMethod(value);
  }

  return (
    <>
      <ToastMessage />
      <form onSubmit={SubmitHandler}>
        <Card className={classes.programCard}>
          <CardContent>
            <Row>
              <Col lg="4" sm="12">
                <InputField
                  disabled
                  type="text"
                  lable="Program Name"
                  name={programName}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Program Name"
                  className="form-textfield-styles"
                  value={programName}
                  isValidValue={false}
                  isSubmit={false}
                  requiredMessage="Topic Name field is required"
                // isCheckValidRID={true}
                />
              </Col>

              {/* <Col lg="4" sm="12">
                <InputSelectField
                  select={true}
                  lable="Select Program"
                  name={Program}
                  value={Program}
                  className="form-textfield-styles"
                  options={programList}
                  handleChange={(e) => setProgram(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Program field is required"
                />
              </Col> */}

              <Col lg="4" sm="12">
                <InputSelectField
                  select={true}
                  lable="Select Module"
                  name={Modual}
                  value={Modual}
                  className="form-textfield-styles"
                  options={moduleList}
                  handleChange={(e) => setModual(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Module field is required"
                />
              </Col>
              <Col lg="4" sm="12">
                <InputSelectField
                  disabled={!saveUpdate ? true : false}
                  select={true}
                  lable="Select Courses"
                  name={Courses}
                  value={Courses}
                  className="form-textfield-styles"
                  options={courseList}
                  handleChange={(e) => onChangeProgram(e.target.value, setCourses)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Courses field is required"
                />
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  disabled={topicVisible || saveUpdate ? false : true}
                  type="text"
                  lable="Topic Name"
                  name={TopicName}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Topic Name"
                  className="form-textfield-styles"
                  value={TopicName}
                  handleChange={(e) => setTopicName(e.target.value)}
                  isValidValue={false}
                  isSubmit={false}
                  requiredMessage="Topic Name field is required"
                  isCheckValidRID={true}
                />
              </Col>
              {
                saveUpdate ? "" :
                  <Col lg="4" sm="12">
                    <Typography className="fontLabelDisable">
                      Total Hours
                    </Typography>
                    <Typography className={classes.fieldDisable}>
                      {TotalHours === "" ? "---" : TotalHours}
                      <hr style={{ marginTop: 5 }} />
                    </Typography>
                  </Col>
              }
              <Col lg="4" sm="12">
                <InputField
                  type="number"
                  lable="Total Inclass Training Hours"
                  name={InclassTrainingHours}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Inclass Training Hours"
                  className="form-textfield-styles"
                  value={InclassTrainingHours}
                  handleChange={(e) => onChangeHours(e.target.value.slice(0, 3), setInclassTrainingHours)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Inclass Training Hours field is required"
                  disablePrevious="0"
                />
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  type="number"
                  lable="Total Inyard Training Hours"
                  name={InyardTrainingHours}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Inyard Training Hours"
                  className="form-textfield-styles"
                  value={InyardTrainingHours}
                  handleChange={(e) => onChangeHours(e.target.value.slice(0, 3), setInyardTrainingHours)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Inyard Training Hours field is required"
                  disablePrevious="0"
                />
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  type="number"
                  lable="Total Incab Training Hours"
                  name={IncabTrainingHours}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Incab Training Hours"
                  className="form-textfield-styles"
                  value={IncabTrainingHours}
                  handleChange={(e) => onChangeHours(e.target.value.slice(0, 3), setIncabTrainingHours)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Incab Training Hours field is required"
                  disablePrevious="0"
                />
              </Col>
              <Col lg="12" sm="12">
                <InputField
                  type="text"
                  lable="Description"
                  name={Description}
                  rows={5}
                  multiline={true}
                  placeHolder="Enter Description"
                  className="form-textfield-styles"
                  value={Description}
                  handleChange={(e) => setDescription(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Description field is required"
                  isCheckValidRID={true}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="12" sm="12">
                <div className={classes.button_save}>
                  <Button
                    variant="outlined"
                    onClick={handleCancel}
                    className={classes.backButton}
                  >
                    Cancel
                  </Button>
                  {" "}
                  <SaveButtonComponent ButtonType="submit" ButtonLabel={saveUpdate ? "Save" : "Update"} />
                  {" "}
                </div>
              </Col>
            </Row>
          </CardContent>
        </Card>

        <TypographyComponent label="Topic List" />
        {/* <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>Topic Name</StyledTableCell>
                <StyledTableCell align="right">Total Hours</StyledTableCell>
                <StyledTableCell align="right">
                  Total Inclass Training Hours
                </StyledTableCell>
                <StyledTableCell align="right">
                  Total Inyard Training Hours
                </StyledTableCell>
                <StyledTableCell align="right">
                  Total Incab Training Hours
                </StyledTableCell>
                <StyledTableCell align="right">Edit</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {!topics ? (
                <StyledTableRow>
                  <StyledTableCell align="center" colSpan={6}>
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : topics.data.length > 0 ? (
                topics.data.map((row: any) => <Row2 key={row.id} row={row} />)
              ) : (
                <StyledTableRow key="test1">
                  <StyledTableCell component="td" align="center" colSpan={7}>
                    No Data Found
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
          <PaginationComponent
            page={page}
            setPage={setPage}
            paginationData={topics && topics.meta}
          />
        </TableContainer> */}

        <ThemeProvider theme={theme} >
          <MUIDataTable
            title={" "}
            data={data}
            columns={columns}
            options={options}
          />
        </ThemeProvider>

        <Row className="mt-4">
          <Col lg="12" sm="12">
            <div className="stepperButton">
              <Button
                variant="outlined"
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>{" "}
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
}
