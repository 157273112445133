import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CommonHeadPart from "../../../common-components/CommonHeadPart";
import CommunicationIcon from "../../../svg_icons/communication.svg";
import { ROUTES } from "../../../constants/routes";
import { Tooltip } from "@material-ui/core";
import ReplyIcon from '@mui/icons-material/Reply';
import { InputField } from "../../../common-components/FormComponent/FormFieldsComponent";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { Clear } from "@material-ui/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  DiscardButtonComponet,
  SendButtonComponet,
} from "../../../common-components/ButtonComponent";
import { fetchSchoolSingleMessage } from "../../../reducer-store/actions/schoolActions";
import * as CommunicationActions from "../../../reducer-store/actions/communicationAction";
import { useSelector } from "react-redux";
import moment from "moment";
import { isRequiredField } from "../../../common-components/FormComponent/FormValidator";
import { toast } from "react-toastify";
import { COMMUNICATION_MESSAGE_SUCCESSFULLY_REPLY_SENT } from "../../../reducer-store/ToastMessage";
import { ACCOUNT, DELETE_MESSAGE, OTHER, SALES, SUPPORT, TECHNICAL, manageIconType } from "../../../reducer-store/constants";
import { Box, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { BsFillCheckCircleFill } from "react-icons/bs";
import ToastMessage from "../../../common-components/ToastMessage";
import FolderIcon from "../../../svg_icons/File.png";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  saveEmailButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #393939 !important",
    color: "#393939 !important",
    padding: "0 35px !important",
    height: "40px !important",
    textTransform: "capitalize",
  },
  body_text: {
    marginTop: "10px",
  },
  attachement: {
    textAlign: "end",
  },
  attached_button: {
    textTransform: "none"
  },
  icon: {
    marginRight: "20px",
    cursor: 'pointer'
  },
  button_save: {
    textAlign: "right",
  },
  dialogContent: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const BreadCrumbComposeMessage = [
  {
    labelText: "Home",
    linkUrl: ROUTES.dashboard,
  },
  {
    labelText: "Communication",
    linkUrl: ROUTES.communication,
  },
  {
    labelText: "Message Reply",
    linkUrl: "",
  },

];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// const BootstrapDialogTitle = (props: DialogTitleProps) => {
//   const { children, onClose, ...other } = props;

//   return (
//     <DialogTitle
//       sx={{ m: 0, p: 2 }}
//       style={{ color: "#ffffff", backgroundColor: "#646464" }}
//       {...other}
//     >
//       {children}
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           onClick={() => onClose()}
//           sx={{
//             position: "absolute",
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[100],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </DialogTitle>
//   );
// };

const MessageReply: React.FC = () => {
  const param: any = useParams();
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const [attachmentFile, setAttachmentFile] = useState<any>([]);
  const [attachmentFileName, setAttachmentFileName] = useState<any>([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const AuthToken = useSelector((state: any) => state.auth);
  const { token, user } = AuthToken;
  const [receivedMsg, setReceivedMsg] = useState<any>(null);
  const [category, setCategory] = useState("");
  const [subject, setSubject] = useState("");
  const [imgError, setImgError] = useState(false);
  const [imgErrorMsg, setImgErrorMsg] = useState("");
  const [email, setEmail] = useState("");
  const [wrongImage, setWrongImg] = useState(false);
  const [wrongImageError, setWrongImgError] = useState("");
  const typeArray = [
    "image/jpeg",
    "image/jpg",
    "image/png"
  ]
  const { sendReplyMessage, updateMessage } = CommunicationActions;
  const history: any = useHistory();

  useEffect(() => {
    (async () => {
      if (param && param.id) {
        (async () => {
          const messageData: any = await fetchSchoolSingleMessage(param.id, token);
          setCategory(messageData.data.category);
          setSubject(messageData.data.subject);
          setEmail(messageData.data.School?.email)
          setReceivedMsg(messageData.data);
        })();
      }
    })();
  }, [param]);

  if (receivedMsg === null) {
    return (
      <Box style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
        <CircularProgress sx={{ color: '#707070' }} />
      </Box>
    );
  }

  const manageCategory = (value: string) => {
    switch (value) {
      case "sales":
        return SALES;
      case "support":
        return SUPPORT;
      case "account":
        return ACCOUNT;
      case "technical":
        return TECHNICAL;
      case "other":
        return OTHER;
      default:
        return SALES;
    }
  };

  const imageValidationCheck = (fileInfo: any) => {
    let typeImage = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
      "application/msword",
      "text/plain",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",];

    if (fileInfo.every((x: any) => typeImage.some((y: any) => x.type === y))) {
      setImgError(false)
      return true;
    } else {
      setImgError(true)
      setImgErrorMsg("Please upload valid Document(pdf,doc,ppt,jpg,jpeg,gif,excel,png,xlsx,xls,docx,txt,pptx,docx).");
      return false;
    }
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files;
    var imageContainer = [];
    var imageName = [];

    for (var i = 0; i < file.length; i++) {
      var fileUpload: any = file[i];
      if (fileUpload) {
        imageContainer.push(fileUpload);
        imageName.push(fileUpload?.name);
      }
    }

    if (imageValidationCheck(imageContainer)) {
      setAttachmentFile(imageContainer);
      setAttachmentFileName(imageName);
      return;
    }
    setAttachmentFile(imageContainer);
    setAttachmentFileName(imageName);
    return;
  };

  const removeSelectedAttachment = async (id: number) => {
    const newArr = await attachmentFileName.filter((files: string, index: number) => {
      return id !== index;
    });
    const newFileArr = await attachmentFile.filter((files: any, index: number) => {
      return id !== index;
    });
    setAttachmentFileName(newArr);
    setAttachmentFile(newFileArr);
    setImgError(false);
    setWrongImg(false);
  };

  const onChangeFile1 = () => {
    setWrongImg(true);
    setWrongImgError("Please Remove Unsupport File First")
  }

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      isRequiredField(email) &&
      isRequiredField(category) &&
      isRequiredField(subject) &&
      isRequiredField(message)
    ) {
      setIsSubmit(false);
      const formData = new FormData();
      formData.append("category", category);
      formData.append("subject", subject);
      formData.append("body", message);
      formData.append("send_to", email);
      formData.append("fromAdmin", "1");
      {
        attachmentFile.map((filesData: any) => {
          formData.append("files", filesData);
        })
      }

      let archieveObj = {
        archive: false
      }

      if (history?.location?.state?.messageData?.archived_by?.includes(JSON.stringify(user?.id))) {
        Promise.allSettled(receivedMsg?.threads?.map(async (data: any) => {
          await updateMessage(`${DELETE_MESSAGE}/${data.id}`, archieveObj, token);
        })).then(async (onfulfilled: any) => {
          let resData: any = await sendReplyMessage(param.id, formData, token);
          if (!resData.error) {
            toast.success("Reply Sent Successfully");
            setOpenSuccess(true);
            setTimeout(() => {
              setOpenSuccess(false);
              history.push(ROUTES.communication);
            }, 4500);
            return;
          }
          toast.error(resData?.errMsg);
          return;
        }).catch(reject => {
          toast.error("Something went wrong");
          return;
        })
      } else {
        let resData: any = await sendReplyMessage(param.id, formData, token);
        if (!resData?.error) {
          toast.success(COMMUNICATION_MESSAGE_SUCCESSFULLY_REPLY_SENT);
          setTimeout(() => {
            history.push(ROUTES.communication);
          }, 2000);
          return;
        }
        toast.error(resData?.errMsg);
        return;
      }
    }
    setIsValidValues(false);
  };

  return (<>
    <ToastMessage />
    <CommonHeadPart
      componentText="Communication"
      Icon={CommunicationIcon}
      buttonText=""
      urlLink=""
      BreadCrumbs={BreadCrumbComposeMessage}
    />
    <Card>
      <CardContent>
        <Row>
          <Col lg="10" sm="12">
            <Typography variant="subtitle1">
              {receivedMsg && moment(receivedMsg?.sent_timestamp).format("MMM DD, YYYY | h:mm a")}
            </Typography>
          </Col>
          {/* <Col lg="1" sm="12" style={{ textAlign: "end" }}>
            <StarBorderIcon />
          </Col>
          <Col lg="1" sm="12">
            <PrintIcon />
          </Col> */}
        </Row>
        <Row>
          <Typography variant="h6">
            {receivedMsg && receivedMsg.subject}
          </Typography>
        </Row>
        <Row>
          <Col lg="6" sm="12">
            <Row>
              <Col lg="8" sm="12">
                <Typography variant="body2" className={classes.body_text}>
                  From: {receivedMsg && receivedMsg.SentBy?.full_name}
                </Typography>
              </Col>
              <Col lg="4" sm="12">
                <Typography variant="body2" className={classes.body_text}>
                  Categories: {receivedMsg && manageCategory(receivedMsg?.category)}
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="hrline" />
        <Row>
          {/* <Typography variant="body2" className={classes.body_text}>
            Hello Driving School Cloud Support,
            {receivedMsg && receivedMsg.SentTo?.full_name}
          </Typography> */}
          <Typography variant="body2" className={classes.body_text}>
            {receivedMsg && receivedMsg?.body}
          </Typography>
        </Row>
        {/* <Row>
          <Typography variant="body2" className={classes.body_text}>
            Have a nice Day,
          </Typography>
          <Typography variant="body2" >
            {receivedMsg && receivedMsg.SentBy?.full_name}
          </Typography>
        </Row> */}

        {/*
          <Row className={classes.attachement}>
            <Col lg="8" sm="12">
            </Col>
            <Col lg="4" sm="12">
              <Button className={classes.attached_button} >
                Download all attachments
                <DownloadIcon />
              </Button>
            </Col>
          </Row>
        */}
        <hr className="hrline" />
        <form onSubmit={SubmitHandler}>
          <Row>
            <Col lg="12" sm="12">
              <Typography>
                <ReplyIcon /> To: {receivedMsg.School ? `${receivedMsg.School?.name} (${receivedMsg.School?.email})` : `${receivedMsg.SentBy?.full_name} (${receivedMsg.SentBy?.email})`}
              </Typography>
            </Col>
            <Col lg="12" sm="12">
              <InputField
                type="text"
                lable=""
                name={message}
                rows={10}
                multiline={true}
                placeHolder="Reply"
                className="form-textfield-styles"
                value={message}
                handleChange={(e) => setMessage(e.target.value)}
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="Reply Message is required"
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col lg="6" sm="6">
              <div className="mb-2">
                <Tooltip title="Attach files">
                  <label htmlFor="file" style={{ cursor: 'pointer' }}>
                    <b>Attach File</b><AttachFileIcon className={classes.icon} />
                  </label>
                </Tooltip>
                <input
                  multiple
                  onChange={imgError === false ? (e) => onChangeFile(e) : () => onChangeFile1()}
                  type="file"
                  id="file"
                  style={{ display: "none" }}
                />
                {/* <InsertEmoticonIcon className={classes.icon} />
                <InsertLinkIcon className={classes.icon} /> */}
              </div>
              {
                attachmentFileName.map((fileName: string, index: number) => {
                  return <div>
                    {fileName}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => removeSelectedAttachment(index)}
                    >
                      <Clear className={classes.icon} />
                    </span>
                  </div>
                })
              }
            </Col>
            <Col lg="6" sm="6" className={classes.button_save}>
              <Link to={ROUTES.communication}>
                <DiscardButtonComponet />
              </Link>
              {" "}
              {/* <Button
                type="button"
                variant="contained"
                className={classes.saveEmailButton}
              >
                Draft
              </Button>{" "} */}
              <SendButtonComponet />
            </Col>
          </Row>
        </form>
      </CardContent>
    </Card>

    {attachmentFile?.length > 0 ?
      <Card className="p-2">
        {attachmentFile?.map((data: any) => {
          return (
            <img
              className="m-1 rounded"
              src={typeArray.includes(data?.type) ? URL.createObjectURL(data) : manageIconType(data?.type)}
              width={typeArray.includes(data?.type) ? 100 : 60}
            />)
        })}
      </Card>
      : null}
  </>)
}

export default MessageReply;