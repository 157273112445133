import React, { useEffect, useState } from "react";
import { Box, TableRow, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CommonHeadPart from "../../common-components/CommonHeadPart";
import FilterTabs from "../../common-components/FilterTabs";
import LicenseIcon from "../../svg_icons/licence.svg";
import { InnerPageRoutes, ROUTES } from "../../constants/routes";
import "../Common.css";
import { ManageLicenseFilterTabs } from "../../constants/tabs";
import { yellow, red } from "@mui/material/colors";
import { Visibility, Delete } from "@mui/icons-material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import * as Actions from "../../reducer-store/actions/licenseAction";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "react-bootstrap";
import { Typography } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { LICENSE_SUCCESSFULLY_DELETED } from "../../reducer-store/ToastMessage";
import { PAGE } from "../../reducer-store/constants";
import ToastMessage from "../../common-components/ToastMessage";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import { SearchOutlined } from "@material-ui/icons";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { RiErrorWarningFill } from "react-icons/ri";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles(() => ({
  divInsideMain: {
    boxShadow: "0 0 5px #8e8e8e",
    borderRadius: "3px",
  },
  dialogContent: {
    textAlign: "center",
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: "center",
  },
  dialogAction: {
    justifyContent: "center !important",
  },
  icon: {
    width: '30%',
    height: 'auto',
    color: "#000",
  },
  searchBar: {
    top: 0,
    borderColor: "black",
    "& hover": {
      borderColor: "black"
    },
    "& focused": {
      borderColor: "black"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black"
    }
  },
  yesButton: {
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontFamily: 'Arial !important',
    fontSize: '15px',
    textTransform: 'capitalize'
  },
  noButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #000000 !important",
    color: "#000000 !important",
    padding: "0 35px !important",
    height: "40px !important",
    textTransform: 'capitalize',
    fontSize: '15px',
  },
}));

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .css-1ygcj2i-MuiTableCell-root": {
            fontweight: "bold",
            backgroundColor: '#FFFFFF'
          },
          "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
            display: "-webkit-box"
          },
          "& .tss-1fbujeu-MUIDataTableHeadCell-toolButton": {
            backgroundColor: '#FFFFFF'
          },
          "& .css-14e01q-MuiTableCell-root": {
            borderBottomColor: '#000',
            borderBottomWidth: 'thin',
          },
        },
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          lineHeight: "1.30 !important"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
          fontSize: "15px",
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
          fontSize: "15px",
        },
        head: {
          backgroundColor: "#FFFFFF"
        },
        footer: {
          backgroundColor: "#FFFFFF"
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#E53D12',
          borderRadius: '4px',
          color: 'white',
          marginRight: '25px',
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: "#F4F4F4"
          },
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& .MuiTablePagination-displayedRows": {
            marginTop: "9%"
          },
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          fontFamily: "Arial",
          fontSize: "15px",
        }
      }
    }
  }
});

const BreadCrumbLicense = [
  {
    labelText: "Home",
    linkUrl: ROUTES.home,
  },
  {
    labelText: "Manage platform licenses",
    linkUrl: ROUTES.manageLicence,
  },
  {
    labelText: "All license",
    linkUrl: "",
  },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const ManageStandardLicence = () => {
  const history = useHistory();
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [page, setPage] = useState(PAGE);
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const licenseList = useSelector((state: any) => state.license);
  const { licenses } = licenseList;
  const [filter, setFilters] = useState("all");
  const dispatch = useDispatch();
  const { LicenseList } = bindActionCreators(Actions, dispatch);
  const [open, setOpen] = React.useState(false);
  const [id, setID] = React.useState('');
  const { deleteLicence } = Actions
  const [search, setSearch] = useState('');
  const [filterVal, setFilterVal] = useState('');
  const [searchApiData, setSearchApiData] = useState([]);
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("desc");
  const [searchData, setSearchData] = useState(false);

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} style={{ color: "#ffffff", backgroundColor: "#646464" }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[100],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const handleFilter = (newValue: string) => {
    setFilters(newValue);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  useEffect(() => {
    (async () => {
      const licenseList = await LicenseList(page, rowsPerPage, token, order, search);
      renderDataTable(licenseList);
    })();
  }, [page, order, search, open]);

  const renderDataTable = (licenseList: any) => {
    const rowsDataTable = licenseList && licenseList.data.data.map((row: any) => {
      return {
        id: row ? row.id : "---",
        license_name: row.name,
        description: row ? row.description : "---",
        date_added: row ? moment(row.start_timestamp).format("YYYY-MM-DD") : "---",
        action:
          <>
            <Link to={`${InnerPageRoutes.updateLicense}/${row.id}`}>
              <Visibility sx={{ color: yellow[700] }} />
            </Link>
            <Delete sx={{ fill: red[600], cursor: 'pointer' }} onClick={() => handleClickOpen(row.id)} />
          </>,
      };
    });
    setData(rowsDataTable);
    setSearchApiData(rowsDataTable);
  };

  const HandleFilter = (e: any) => {
    if (e.target.value == "") {
      setData(searchApiData);
    } else {
      const filterData = searchApiData.filter((items: any) =>
        items.id.toString().includes(e.target.value.toString())
        || items.license_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items.description.toLowerCase().includes(e.target.value.toLowerCase())
        || items.date_added.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
      );
      if (filterData.length > 0) {
        setData(filterData);
        setSearchData(false);
      } else {
        setData([]);
        setSearchData(true);
      }
    }
    setFilterVal(e.target.value);
  };

  const columns = [
    {
      label: 'Sr No',
      name: "id",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link to={`${InnerPageRoutes.updateLicense}/${tableMeta.rowData[0]}`}>{tableMeta.rowIndex + 1}</Link>
        )
      }
    },
    {
      label: 'License Name',
      name: "license_name",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link to={`${InnerPageRoutes.updateLicense}/${tableMeta.rowData[0]}`}>{value}</Link>
        )
      }
    },
    {
      label: 'Description',
      name: "description",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link to={`${InnerPageRoutes.updateLicense}/${tableMeta.rowData[0]}`}>{value}</Link>
        )
      }
    },
    {
      label: "Date Added",
      name: "date_added",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link to={`${InnerPageRoutes.updateLicense}/${tableMeta.rowData[0]}`}>{value}</Link>
        )
      }
    },
    {
      label: 'Action',
      name: "action",
      options: {
        sort: false
      }
    },
  ];

  const options = {
    responsive: "vertical",
    search: false,
    print: false,
    viewColumns: false,
    filter: false,
    download: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    selectableRows: "none",
    rowsPerPageOptions: [],
    fixedHeader: false,
    textLabels: {
      body: {
        noMatch: searchData || data?.length == 0 && licenses?.data?.length == 0 ?
          'Sorry, there is no matching data to display' :
          <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
            <CircularProgress sx={{ color: "#707070" }} />
          </Box >
        ,
      },
    },
    customToolbar: () => {
      return (<>
        <TextField
          variant="standard"
          placeholder="Search"
          onInput={(e: any) => HandleFilter(e)}
          value={filterVal}
          className={classes.searchBar}
          InputProps={{
            style: { color: "black" },
            endAdornment: (
              <IconButton>
                <SearchOutlined />
              </IconButton>
            ),
          }}
        />
      </>)
    },
  };

  const handleClickOpen = async (id: any) => {
    setID(id)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    let resData: any = await deleteLicence(id, token);
    if (!resData.error) {
      toast.success(LICENSE_SUCCESSFULLY_DELETED);
      setTimeout(() => {
        LicenseList(page, rowsPerPage, token, order, search);
        setOpen(false);
      }, 1000);
    }
  }

  return (
    <>
      <ToastMessage />
      <CommonHeadPart
        componentText="Manage platform licenses"
        Icon={LicenseIcon}
        buttonText="Add New License"
        BreadCrumbs={BreadCrumbLicense}
        urlLink={InnerPageRoutes.addNewLicense}
      />
      <div className={classes.divInsideMain}>

        <ThemeProvider theme={theme}>
          <MUIDataTable
            title={<FilterTabs
              SelectedFilter={filter}
              Filters={ManageLicenseFilterTabs}
              HandleChange={handleFilter}
              isFilter={false}
              HandleSearch={handleSearch}
            />}
            data={data}
            columns={columns}
            options={options}
          />
        </ThemeProvider>

      </div>

      <BootstrapDialog
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        open={open}
      >

        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Delete License
        </BootstrapDialogTitle>

        <DialogContent dividers className={classes.dialogContent} >
          <RiErrorWarningFill color="#FDB924" size={30} style={{ marginRight: 10 }} />
          <Typography variant="h6" component="div">
            Are you sure you want to delete this record?
          </Typography>
        </DialogContent>

        <DialogActions className={classes.dialogAction} >
          <Button
            type="button"
            variant="contained"
            className={classes.noButton}
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            type="button"
            variant="contained"
            className={classes.yesButton}
            onClick={handleDelete}
          >
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default ManageStandardLicence;
