import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { styled } from "@mui/material/styles";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import { TableRow } from "@material-ui/core";
// import PaginationComponent from "../../../../../common-components/PaginationComponent";
import * as Actions from "../../../../../reducer-store/actions/schoolActions";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import {
  ACCOUNT,
  DELETED,
  DELETE_MESSAGE,
  IMPORTANT,
  IMPORTANT_MESSAGE,
  LIMIT,
  OTHER,
  PAGE,
  SALES,
  SENT,
  SUPPORT,
  TECHNICAL,
} from "../../../../../reducer-store/constants";
import { useParams } from "react-router-dom";
import moment from "moment";
import ReplyMessage from "./ReplyMessage";
// import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../../Common.css";
import MUIDataTable from "mui-datatables";
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
// import { TextField, IconButton } from '@material-ui/core';
// import { SearchOutlined } from '@material-ui/icons';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { red } from "@material-ui/core/colors";
import * as authActions from "../../../../../reducer-store/actions/authActions";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { UnreadCommunication, updateMessage, readMessage, deletePermenant } from "../../../../../reducer-store/actions/communicationAction";
import FilterTabs from "../../../../../common-components/FilterTabs";
import { CommunicationFilterTabs } from "../../../../../constants/tabs";
import { BsArchiveFill } from "react-icons/bs";
import { FaTrashRestoreAlt } from "react-icons/fa";
import { IconButton, TextField } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  boxBorder: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 2px #8e8e8e",
    borderRadius: "5px",
  },
  searchBar: {
    borderColor: "black",
    "& hover": {
      borderColor: "black"
    },
    "& focused": {
      borderColor: "black"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black"
    }
  },
  linkstyle: {
    textDecoration: "none",
    color: "black",
  },
  linkstyleBold: {
    textDecoration: "none",
    color: "black",
    fontWeight: 'bold'
  },
}));

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .css-1ygcj2i-MuiTableCell-root": {
            fontweight: "bold",
            backgroundColor: '#FFFFFF',
            fontSize: "15px",
          },
          "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
            display: "-webkit-box"
          },
          "& .tss-1fbujeu-MUIDataTableHeadCell-toolButton": {
            backgroundColor: '#FFFFFF',
          },
          "& .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader": {
            borderBottomColor: '#000',
            borderBottomWidth: 'thin'
          }
        },
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          lineHeight: "1.30 !important"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
          fontSize: "15px",
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        wrapped: {
          fontSize: "15px",
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
          fontSize: "15px",
        },
        head: {
          backgroundColor: "#FFFFFF"
        },
        footer: {
          backgroundColor: "#FFFFFF"
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#E53D12',
          borderRadius: '4px',
          color: 'white',
          marginRight: '25px',
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: "#F4F4F4"
          },
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& .MuiTablePagination-displayedRows": {
            marginTop: "9%"
          },
        }
      }
    },
  }
});

const Communicatin: React.FC = () => {
  const queryPara: any = useParams();
  const classes = useStyles();
  const AuthToken = useSelector((state: any) => state.auth);
  const { token, user } = AuthToken;
  const communicationList = useSelector((state: any) => state.school);
  const { communication } = communicationList;
  const [page, setPage] = useState(PAGE);
  const [filter, setFilters] = useState("");
  const [allAdmin, setAllAdmin] = useState<any>([]);
  const [order, setOrder] = useState("desc");
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const { SingleSchoolCommunicationData } = bindActionCreators(Actions, dispatch);
  const { allAdminList } = authActions;
  const [showReply, setShowReply] = useState(true)
  const [replyData, setReplyData] = useState({})
  const [data, setData] = useState([]);
  const [searchApiData, setSearchApiData] = useState([]);
  const [actionLoader, setActionLoader] = useState(false);
  const [searchData, setSearchData] = useState(false);
  const [filterVal, setFilterVal] = useState('');
  const MESSAGE_LENGTH = 42;
  let role_id = "1";

  const handlefilter = (e: any) => {
    if (e.target.value == "") {
      setData(searchApiData);
    } else {
      const filterData = searchApiData.filter((items: any) =>
        items?.category?.props?.children?.props.children?.toString()?.toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items?.date?.props?.children?.toString()?.toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items?.username?.props?.children.toString()?.toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items?.body.props.children.toString()?.toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items?.id.props.children?.toString()?.toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items?.status.props.children?.toString()?.toLowerCase().includes(e.target.value.toString().toLowerCase())
      );
      if (filterData.length > 0) {
        setData(filterData);
        setSearchData(false);
      } else {
        setData([]);
        setSearchData(true);
      }
    }
    setFilterVal(e.target.value);
  };

  const handleFilter = (newValue: string) => {
    setFilters(newValue);
    setPage(1);
  };

  const manageStatus = (value: number) => {
    switch (value) {
      case 1:
        return "Received";
      case 2:
        return "Sent";
      case 4:
        return "Important";
      case 5:
        return "Archived";
      case 6:
        return "Draft";
      default:
        return "Received";
    }
  };

  const handleRead = async (status: any, id: string, token: string) => {
    let resData: any = !status ? await readMessage(id, token) && await UnreadCommunication(token) : null;

    const singleCommunication = await SingleSchoolCommunicationData(queryPara.id, page, LIMIT, order, token, filter, user?.id, true);
    renderDataTable(singleCommunication);
    return
  }

  const columns = [
    {
      label: " ",
      name: "star",
      options: {
        sort: false
      }
    },
    {
      label: " ",
      name: "read",
      options: {
        display: false,
      }
    },
    {
      label: 'Sr No',
      name: "id",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <span>{tableMeta.rowIndex + 1}</span>
        )
      }
    },
    {
      label: 'Username',
      name: "username",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <span className={tableMeta.rowData[2] ? classes.linkstyle : classes.linkstyleBold}>
            {value}
          </span>
        ),
      }
    },
    {
      label: 'Category',
      name: "category",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <span className={tableMeta.rowData[2] ? classes.linkstyle : classes.linkstyleBold}>
            {value}
          </span>
        ),
      }
    },
    {
      label: 'Status',
      name: "status",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <span className={tableMeta.rowData[2] ? classes.linkstyle : classes.linkstyleBold}>
            {value}
          </span>
        ),
      }
    },
    {
      label: "Date",
      name: "date",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <span className={tableMeta.rowData[2] ? classes.linkstyle : classes.linkstyleBold}>
            {value}
          </span>
        ),
      }
    },
    {
      label: 'Subject',
      name: "body",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <span className={tableMeta.rowData[2] ? classes.linkstyle : classes.linkstyleBold}>
            {value}
          </span>
        ),
      }
    },
    {
      label: 'Action',
      name: "action",
      options: {
        sort: false,
      }
    },
  ];

  const options = {
    search: false,
    print: false,
    viewColumns: false,
    filter: false,
    download: false,
    responsive: "vertical",
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    selectableRows: "none",
    rowsPerPageOptions: [],
    textLabels: {
      body: {
        noMatch: searchData || (communication?.data?.length == 0 && data?.length == 0) ?
          'Sorry, there is no matching data to display' :
          <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
            <CircularProgress sx={{ color: "#707070" }} />
          </Box >,
      },
    },
    customToolbar: () => {
      return (<>
        <TextField
          variant="standard"
          placeholder="Search"
          onInput={(e: any) => handlefilter(e)}
          value={filterVal}
          className={classes.searchBar}
          InputProps={{
            style: { color: "black" },
            endAdornment: (
              <IconButton>
                <SearchOutlined />
              </IconButton>
            ),
          }}
        />
      </>)
    },
  };

  const options1 = {
    search: false,
    print: false,
    viewColumns: false,
    filter: false,
    download: false,
    responsive: "vertical",
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    selectableRows: "none",
    rowsPerPageOptions: [],
    textLabels: {
      body: {
        noMatch:
          <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
            <CircularProgress sx={{ color: "#707070" }} />
          </Box>,
      },
    },
    customToolbar: () => {
      return (
        <>
          <TextField
            variant="standard"
            placeholder="Search"
            onInput={(e: any) => handlefilter(e)}
            value={filterVal}
            className={classes.searchBar}
            InputProps={{
              style: { color: "black" },
              endAdornment: (
                <IconButton>
                  <SearchOutlined />
                </IconButton>
              ),
            }}
          />
        </>
      );
    },
  };

  useEffect(() => {
    (async () => {
      let allAdminId: any = [];
      const adminList: any = await allAdminList(role_id, token);
      const { data } = adminList;
      data.map((d: any) => {
        allAdminId.push(d.id);
      })
      setAllAdmin(allAdminId);
    })();
  }, [])

  useEffect(() => {
    (async () => {
      const singleCommunication: any = await SingleSchoolCommunicationData(queryPara.id, page, LIMIT, order, token, filter, user?.id, true);
      renderDataTable(singleCommunication);
    })();
  }, [page, filter]);

  const renderDataTable = (singleCommunication: any) => {
    const rowsDataTable = singleCommunication && singleCommunication.data.data.map((row: any) => {
      return {
        star: <>
          {(row?.archived_by?.includes(JSON.stringify(user?.id)) && row?.archive) || row?.status === 6 ? null :
            row?.tags_added_by?.includes(JSON.stringify(user?.id)) && row?.status == 4 ?
              <span onClick={() => updateInfo(`${IMPORTANT_MESSAGE}`, SENT, row?.threads, false, token)}>
                <StarOutlinedIcon sx={{ color: red[500], fontSize: 20 }} />
              </span> :
              <span onClick={() => updateInfo(`${IMPORTANT_MESSAGE}`, IMPORTANT, row?.threads, false, token)}>
                <StarOutlineOutlinedIcon sx={{ color: red[500], fontSize: 20 }} />
              </span>}
        </>,
        read: row?.threads?.at(-1)?.read,
        id: <div onClick={() => { handleReply(row); handleRead(row?.read, row?.id, token) }}>{row.id}</div>,
        username: <div onClick={() => { handleReply(row); handleRead(row?.read, row?.id, token) }}>{row?.SentBy && row?.SentBy?.full_name || "---"}</div>,
        category: <div onClick={() => { handleReply(row); handleRead(row?.read, row?.id, token) }}>{manageCategory(row.category)}</div>,
        status: <div onClick={() => { handleReply(row); handleRead(row?.read, row?.id, token) }}>{manageStatus(
          row?.status === 6 ? 6 :
            row?.tags_added_by?.includes(JSON.stringify(user?.id)) && row?.status == 4 ? 4 :
              row?.archived_by?.includes(JSON.stringify(user?.id)) ? 5 :
                row?.threads?.at(-1)?.sent_by === user?.id ? 2 : 1
        )}</div>,
        date: <div onClick={() => { handleReply(row); handleRead(row?.read, row?.id, token) }}>{moment(row.sent_timestamp).format("YYYY-MM-DD")}</div>,
        body: <div onClick={() => { handleReply(row); handleRead(row?.read, row?.id, token) }}>{row.subject.length <= 8 ? row.subject : row.subject.substring(0, MESSAGE_LENGTH) + "..."}</div>,
        action: (<>
          {row?.status === 6 || (row?.archived_by?.includes(JSON.stringify(user?.id)) && filter !== "5") ? null :
            filter !== "5"
              ? (<span onClick={() => updateInfo(`${DELETE_MESSAGE}`, DELETED, row?.threads, true, token)}>
                <BsArchiveFill
                  style={{ cursor: 'pointer' }} size={20}
                  color="black"
                  title="Archive Message" />
              </span>
              ) : (
                <span onClick={() => updateInfo(`${DELETE_MESSAGE}`, SENT, row?.threads, false, token)}>
                  <FaTrashRestoreAlt style={{ cursor: 'pointer' }} size={20} color="black" title="Restore Message" />
                </span>)}</>),
      };
    });
    setData(rowsDataTable);
    setSearchApiData(rowsDataTable);
  }

  const handleImportantArchive = (threads: any, token: string) => {
    let obj = {
      status: SENT,
    }

    let archieveObj = {
      archive: true
    }

    Promise.allSettled(threads?.map(async (data: any) => {
      await updateMessage(`${IMPORTANT_MESSAGE}/${data.id}`, obj, token);
    })).then(threads?.map(async (data: any) => {
      return await updateMessage(`${DELETE_MESSAGE}/${data.id}`, archieveObj, token);
    })).then(async (fullFilled: any) => {
      const singleCommunication = await SingleSchoolCommunicationData(queryPara.id, page, LIMIT, order, token, filter, user?.id, true);
      renderDataTable(singleCommunication);

      setActionLoader(false);
      toast.success("Message marked as archived")
    }).catch(reject => {
      toast.error("Something went wrong");
      return;
    })
  }

  const updateInfo = async (url: string, status_id: number, threads: any, isRestored: boolean, token: string) => {
    let obj = {
      status: status_id,
    }

    let archieveObj = {
      archive: isRestored
    }

    setActionLoader(true);

    if (threads[0]?.status == 4 && status_id == 5) {
      handleImportantArchive(threads, token);
    } else {
      Promise.all(threads?.map(async (data: any) => {
        return await updateMessage(`${url}/${data.id}`, url.split("/").pop() === "delete" ? archieveObj : obj, token)
      })).then(async (onfulfilled: any) => {
        const singleCommunication = await SingleSchoolCommunicationData(queryPara.id, page, LIMIT, order, token, filter, user?.id, true);
        renderDataTable(singleCommunication);

        setActionLoader(false);
        toast.success(onfulfilled[0]?.msg);
      }).catch(reject => {
        toast.error("Something went wrong");
        return;
      })
    }
  }

  const handleReply = (row: any) => {
    setShowReply(!showReply)
    setReplyData({ message: row.body, schoolName: row.School.name, schoolLogo: row.School.logo, subject: row.subject, id: row.id, threads: row.threads, messageAttachments: row.MessageAttachments })
  }

  // function createData(
  //   id: number,
  //   userName: string,
  //   categories: string,
  //   date: string,
  //   message: string
  // ) {
  //   return { id, userName, categories, date, message };
  // }

  const manageCategory = (value: string) => {
    switch (value) {
      case "sales":
        return SALES;
      case "support":
        return SUPPORT;
      case "account":
        return ACCOUNT;
      case "technical":
        return TECHNICAL;
      case "other":
        return OTHER;
      default:
        return SALES;
    }
  };

  function handleSearch(value: string): void {
    setSearch(value);
  }

  return (
    <>
      <Box className={classes.boxBorder}>
        {
          showReply === true ?
            <>
              <ThemeProvider theme={theme}>
                {actionLoader ?
                  <MUIDataTable
                    title={<FilterTabs
                      SelectedFilter={filter}
                      Filters={CommunicationFilterTabs}
                      HandleChange={handleFilter}
                      HandleSearch={handleSearch}
                      isSearch={false}
                    />}
                    data={[]}
                    columns={columns}
                    options={options1}
                  />
                  :
                  <MUIDataTable
                    title={<FilterTabs
                      SelectedFilter={filter}
                      Filters={CommunicationFilterTabs}
                      HandleChange={handleFilter}
                      HandleSearch={handleSearch}
                      isSearch={false}
                    />}
                    data={data}
                    columns={columns}
                    options={options}
                  />
                }
              </ThemeProvider>
            </>
            :
            <ReplyMessage allAdminId={allAdmin} messageData={replyData} showReplyData={showReply} manageShowReply={setShowReply} />
          // <>
          //   <KeyboardBackspaceIcon style={{ marginLeft: 10, marginTop: 10 }} fontSize="large" onClick={() => setShowReply(!showReply)} />
          // </>
        }
      </Box>
    </>
  );
};

export default Communicatin;
