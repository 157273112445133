import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CommonHeadPart from "../../common-components/CommonHeadPart";
import UpdatePlanFeeIcon from "../../svg_icons/update_plan_fee.svg";
import "../Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { InnerPageRoutes, ROUTES } from "../../constants/routes";
import { Box, Typography } from "@material-ui/core";
import * as Actions from "../../reducer-store/actions/updatePlanFeesAction";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Row, Col, Button } from "react-bootstrap";
import CheckIcon from "@material-ui/icons/Check";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SaveButtonComponent } from "../../common-components/ButtonComponent";
import { InputField } from "../../common-components/FormComponent/FormFieldsComponent";
import { isRequiredField, isValidNo } from "../../common-components/FormComponent/FormValidator";
import { toast } from "react-toastify";
import ToastMessage from "../../common-components/ToastMessage";
import { PLAN_SUCCESSFULLY_UPDATED } from "../../reducer-store/ToastMessage";
import { LIMIT, PAGE, PLATINUM } from "../../reducer-store/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles(() => ({
  img_title: {
    marginLeft: "10px",
    color: "#464646",
  },
  platform: {
    fontWeight: 700,
    color: "#000000",
    marginBottom: "10px",
  },
  iconHover: {
    '&:hover': {
      cursor: "pointer"
    }
  },
  saveEmailButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #393939 !important",
    color: "#393939 !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontSize: "0.875rem",
    fontFamily: 'Arial !important',
    textTransform: 'capitalize',
  },
  plan: {
    "@media (max-width: 900px)": {
      marginTop: '20px'
    },
  }

}));

const BreadCrumbUpdatePlan = [
  {
    labelText: "Home",
    linkUrl: ROUTES.home,
  },
  {
    labelText: "Update plans & fees",
    linkUrl: "",
  },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .css-st2zsy-MuiTypography-root-MuiDialogTitle-root": {
    backgroundColor: "#646464",
    color: "#FFFFFF"
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, backgroundColor: '#616161', color: '#FFFFFF' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[100],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const UpdatePlansFees: React.FC = () => {
  const classes = useStyles();
  const planList = useSelector((state: any) => state.plan);
  const { plans } = planList;
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const dispatch = useDispatch();
  const { PlanList, SinglePlanData } = bindActionCreators(Actions, dispatch);
  const { updatePlan } = Actions;

  // Module
  const [planData, setPlanData] = useState<any>({} || "");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    (async () => {
      await PlanList(PAGE, LIMIT, token);
    })();
  }, []);

  const handleClickOpen = async (id: any) => {
    const response: any = await SinglePlanData(id, token);
    const { data } = response.data;
    setPlanData(data);
    setTimeout(() => {
      setOpen(true);
    }, 1100);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updatePlanData = (e: any) => {
    setPlanData({ ...planData, [e.target.name]: e.target.value });
  };

  const SubmitHandlerModual = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      isRequiredField(planData.name) &&
      isRequiredField(planData.admin) &&
      isValidNo(planData.admin) &&
      isRequiredField(planData.price) &&
      isValidNo(planData.price) &&
      isRequiredField(planData.staff) &&
      isValidNo(planData.staff) &&
      isRequiredField(planData.students) &&
      isValidNo(planData.students) &&
      isRequiredField(planData.instructor) &&
      isValidNo(planData.instructor)
    ) {
      setIsSubmit(false);
      let resData: any = await updatePlan(planData.id, planData);
      if (!resData.error) {
        toast.success(PLAN_SUCCESSFULLY_UPDATED);
        setTimeout(() => {
          PlanList(PAGE, LIMIT, token);
          setOpen(false);
          setPlanData({});
        }, 1000);
      }
      return;
    }
    setIsValidValues(false);
  };

  return (
    <>
      <ToastMessage />
      <CommonHeadPart
        componentText="Update Plans & Fee's"
        Icon={UpdatePlanFeeIcon}
        BreadCrumbs={BreadCrumbUpdatePlan}
        urlLink={InnerPageRoutes.createNewPlan}
      />
      <Row className="justify-content-md-center">
        {!plans ? (
          <Box style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
            <CircularProgress sx={{ color: '#707070' }} />
          </Box>
        ) : plans?.data.length > 0 ?
          (
            // { plans &&
            plans.data.map((row: any, index: number) => (
              <Col key={index} xl="4" lg="6" md="12" sm="12" xs="12">
                <Card className={classes.plan}>
                  <CardContent>
                    <Row>
                      <Col lg="10" sm="10">
                        <Typography variant="h6" component="div">
                          <b>
                            {row.name}
                          </b>
                          <br />
                          <b>
                            {row.symbol}{" "}
                            {row.price}</b> / Year
                        </Typography>
                      </Col>
                      <Col lg="2" sm="2" className="text-end">
                        <FaRegEdit size={20} style={{ cursor: 'pointer' }} onClick={() => handleClickOpen(row.id)} />
                      </Col>
                    </Row>
                    <div className="stline"></div>
                    <Typography variant="h6" component="div">
                      One license for all course types.
                    </Typography>
                    <div className="stline"></div>

                    <Row>
                      <Col lg="6" sm="6" xs="6">
                        <img src="assets/images/az.png" alt="" />{" "}
                        <span className={classes.img_title}> AZ </span>
                      </Col>
                      <Col lg="6" sm="6" xs="6">
                        <img src="assets/images/dz.png" alt="" />{" "}
                        <span className={classes.img_title}> DZ </span>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col lg="6" sm="6" xs="6">
                        <img src="assets/images/bz.png" alt="" />{" "}
                        <span className={classes.img_title}> BZ </span>
                      </Col>
                      <Col lg="6" sm="6" xs="6">
                        <img src="assets/images/cz.png" alt="" />{" "}
                        <span className={classes.img_title}> CZ </span>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col lg="6" sm="6" xs="6">
                        <img src="assets/images/bike.png" alt="" />{" "}
                        <span className={classes.img_title}> Bike </span>
                      </Col>
                      <Col lg="6" sm="6" xs="6">
                        <img src="assets/images/car.png" alt="" />{" "}
                        <span className={classes.img_title}> Car </span>
                      </Col>
                    </Row>

                    <div className="stline"></div>
                    <Typography
                      className={classes.platform}
                      variant="h6"
                      component="div"
                    >
                      Following school, personas come with the platform.
                    </Typography>
                    <Typography component="div">
                      <CheckIcon />{" "}
                      {`${row.staff !== 99999 ? row.staff : "Unlimited"} Staff`}
                    </Typography>
                    <Typography component="div">
                      <CheckIcon />{" "}
                      {`${row.instructor !== 99999 ? row.instructor : " Unlimited"
                        } Instructor`}
                    </Typography>
                    <Typography component="div">
                      <CheckIcon />{" "}
                      {`${row.admin !== 99999 ? row.admin : "Unlimited"} Admin`}
                    </Typography>
                    <Typography component="div">
                      <CheckIcon />{" "}
                      1 owner
                    </Typography>
                    <Typography component="div">
                      <CheckIcon />{" "}
                      {`${row.students !== 99999 ? row.students : "Unlimited"
                        } Students`}
                    </Typography>
                  </CardContent>
                </Card>
              </Col>
            ))
            // }
          ) : (
            <Box style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
              <p>{plans.msg || "No Data Found"}</p>
            </Box>
          )}
      </Row>

      <BootstrapDialog
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <form onSubmit={SubmitHandlerModual}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}

          >
            <FaRegEdit size={20} style={{ marginRight: "5px", marginBottom: '5px' }} />
            {planData && planData.name} Plan Edit
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Row>
              <Col lg="4" sm="12">
                <InputField
                  type="text"
                  lable="Plan Name"
                  name="name"
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Plan Name"
                  className="form-textfield-styles"
                  value={planData && planData.name}
                  handleChange={(e) => updatePlanData(e)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Plan Name field is required"
                  disabled={planData.id === PLATINUM ? true : false}
                />
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  type="text"
                  lable="Plan Price"
                  name="price"
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Plan Price"
                  className="form-textfield-styles"
                  value={planData && planData.price}
                  handleChange={(e) => updatePlanData(e)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Plan Price field is required"
                  isCheckValidNoValue={true}
                />
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  type="text"
                  lable="Staff"
                  name="staff"
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Staff"
                  className="form-textfield-styles"
                  value={planData && planData.staff === 99999 ? 'Unlimited' : planData.staff}
                  handleChange={(e) => updatePlanData(e)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Staff field is required"
                  disabled={planData.id === PLATINUM ? true : false}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="4" sm="12">
                <InputField
                  type="text"
                  lable="Instructor"
                  name="instructor"
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Instructor"
                  className="form-textfield-styles"
                  value={planData && planData.instructor === 99999 ? 'Unlimited' : planData.instructor}
                  handleChange={(e) => updatePlanData(e)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Instructor field is required"
                  disabled={planData.id === PLATINUM ? true : false}
                />
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  type="text"
                  lable="Admin"
                  name="admin"
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Admin"
                  className="form-textfield-styles"
                  value={planData && planData.admin === 99999 ? 'Unlimited' : planData.admin}
                  handleChange={(e) => updatePlanData(e)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Admin field is required"
                  disabled={planData.id === PLATINUM ? true : false}
                />
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  type="text"
                  lable="Students"
                  name="students"
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Students"
                  className="form-textfield-styles"
                  value={planData && planData.students === 99999 ? 'Unlimited' : planData.students}
                  handleChange={(e) => updatePlanData(e)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Students field is required"
                  disabled={planData.id === PLATINUM ? true : false}
                />
              </Col>
            </Row>
          </DialogContent>
          <DialogActions>
            <Button
              type="button"
              variant="contained"
              onClick={handleClose}
              className={classes.saveEmailButton}
            >
              Cancel
            </Button>
            {" "}
            <SaveButtonComponent ButtonType="submit" ButtonLabel="Save Changes" />
          </DialogActions>
        </form>
      </BootstrapDialog>
    </>
  );
};

export default UpdatePlansFees;
