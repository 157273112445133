import axios from "axios";
import { toast } from "react-toastify";
import {
  LIST_OF_SCHOOL,
  LIST_OF_SCHOOL_COUNT,
  LIST_OF_SCHOOL_COUNT_ERROR,
  LIST_OF_SCHOOL_ERROR,
  SINGLE_SCHOOL_COMMUNICATION,
  SINGLE_SCHOOL_COMMUNICATION_ERROR,
  SINGLE_SCHOOL_ERROR,
  SINGLE_SCHOOL_INFO,
} from "../ActionTypes";
import {
  SCHOOL_LIST_API,
  ADD_SCHOOL_API,
  PAGE,
  LIMIT,
  SINGLE_SCHOOL_DATA,
  SINGLE_SCHOOL_COMMUNICATION_DATA,
  COMMUNICATION_LIST_API,
  SCHOOL_STUDENT_COUNT_API,
  UPDATE_SCHOOL_API,
  GET_PROVINCE_TAX,
} from "../constants";

const token = sessionStorage.getItem("token");
const header = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization",
  },
};

type AddSchoolProps = {
  name: string;
  email: string;
  mobile: string;
  address: string;
  owner_name: string;
  password: string;
  address_1: string;
  address_2: string;
  state: string;
  registry_id: string;
  tin_number: string;
  tenant_id: string;
  status: string,
};

export const SchoolList = (
  page = PAGE,
  limit = LIMIT,
  token: string,
  order: string,
  search: any,
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${SCHOOL_LIST_API}?page=${page}&limit=${limit}&orderBy=${order}&search=${search}`, header,)
      .then((res) => {
        dispatch({
          type: LIST_OF_SCHOOL,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_SCHOOL_ERROR,
        });
        toast.error(error.response.data.errorMsg);
        return error.response;
      });
  };
};

export const SingleSchoolData = (id = "", token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${SINGLE_SCHOOL_DATA}/${id}`, header)
      .then((res) => {
        dispatch({
          type: SINGLE_SCHOOL_INFO,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: SINGLE_SCHOOL_ERROR,
        });
        toast.error(error.response.data.errorMsg);
        return error.response;
      });
  };
};

export const SingleSchoolCommunicationData = (
  id = "",
  page = PAGE,
  limit = LIMIT,
  order: string,
  token: string,
  status = "",
  userId: any,
  archive: boolean
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(
        `${SINGLE_SCHOOL_COMMUNICATION_DATA}/${id}?page=${page}&limit=${limit}&orderBy=${order}${status == "5" ? `&archive=${archive}` : `&status=${status}`}${status == "4" ? `&tags=${userId}` : status == "" ? "" : archive && status !== "6" ? `&archived_by=${userId}` : ""}`,
        header
      )
      .then((res) => {
        dispatch({
          type: SINGLE_SCHOOL_COMMUNICATION,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: SINGLE_SCHOOL_COMMUNICATION_ERROR,
        });
        toast.error(error.response.data.errorMsg);
        return error.response;
      });
  };
};

export const addSchool = async (userObj: AddSchoolProps) => {
  const body = JSON.stringify(userObj);
  return await axios
    .post(ADD_SCHOOL_API, body, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
      return error.response.data;
    });
};

export const fetchSchoolSingleMessage = async (id: string, token: string) => {
  header.headers.Authorization = `Bearer ${token} `;
  return await axios
    .get(`${COMMUNICATION_LIST_API}/${id}`, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
      return error.response.data;
    });
};

export const getStudentDetail = (
  schoolid: any
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${SCHOOL_STUDENT_COUNT_API}/${schoolid}`, header)
      .then((res) => {
        dispatch({
          type: LIST_OF_SCHOOL_COUNT,
          payload: res?.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_SCHOOL_COUNT_ERROR,
        });
        toast.error(error?.response?.data?.errorMsg);
        return error?.response;
      });
  };
};

export const updateSchool = async (id: string, userObj: any) => {
  const body = JSON.stringify(userObj);
  return await axios
    .put(`${UPDATE_SCHOOL_API}/${id}`, body, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
      return error.response.data;
    });
};

export const GetProvinceTax = async (province_id: string) => {
  const { data }: any = await axios.get(`${GET_PROVINCE_TAX}?province_id=${province_id}`);
  return data.data[0];
};