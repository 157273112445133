import { Switch } from "react-router-dom";
import { InnerPageRoutes, ROUTES } from "./constants/routes";
import Layout from "./components/Layout";
import {
  Dashboard,
  Communication,
  UpdatePlansFees,
  GenerateInvoice,
  ManageStandardLicence,
  PlatformFeatureToggle,
  SchoolPayment,
  School,
  Programs,
} from "./pages";
import Login from "./pages/LoginPages/Login";
import ForgotPassword from "./pages/LoginPages/Forgotpass";
import ResetPassword from "./pages/LoginPages/Newpassword";
import GenerateOTP from "./pages/LoginPages/OnetimePass";
import OTP from "./pages/LoginPages/Onetime_otp";
import OTPF2 from "./pages/LoginPages/Onetimepass_f2";
import ComposeMessgeForm from "./pages/Communication/From/ComposeMessgeForm";
/* import NewSchoolSetupForm from "./pages/School/Form/NewSchoolSetupForm"; */
import DetailPage from "./pages/School/Form/Pages/DetailPage";
import AddNewPlatformLicense from "./pages/ManageStandardLicence/From/AddNewPlatformLicense";
import addcomponenthere from "./pages/GenerateInvoice/From/AddGenerateInvoice";
import Profile from "./pages/LoginPages/Profile";

import AddNewProgram from "./pages/Programs/Form/AddNewProgram";
import { PrivateRoute, PublicRoute } from "./CustomRoute";
import "bootstrap/dist/css/bootstrap.min.css";
import ChangePassword from "./common-components/ChangePassword/ChangePassword";
import ChangePasswordOTP from "./common-components/ChangePassword/OTP";
import Newpassword from "./common-components/ChangePassword/NewPassword";
import MessageReply from "./pages/Communication/From/MessageReply";
// import ReplyMessage from "./pages/School/Form/Pages/Communication/ReplyMessage";
import AllMessagesCommunication from "./pages/Communication/ReplyMessage";
import MessageReplyCommunication from "./pages/Communication/MessageReply";
import AddNewUser from "./pages/AddnewUser/AddNewUser";
import Tax from './pages/Tax/Tax'

import { MuiThemeProvider, StylesProvider, createTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Setting from "./pages/Settings/Setting";
// import { useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { USER_LOGOUT } from "./reducer-store/ActionTypes";

const theme = createTheme({
  typography: {
    fontFamily: "Arial",
  },
});

function App() {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Switch>
            <PublicRoute
              path={ROUTES.login}
              restricted={true}
              exact
              Component={Login}
            />
            <PublicRoute
              path={ROUTES.forgotPassword}
              restricted={true}
              exact
              Component={ForgotPassword}
            />
            <PublicRoute
              path={ROUTES.newPassword}
              restricted={true}
              exact
              Component={ResetPassword}
            />
            <PublicRoute
              path={ROUTES.generateOTP}
              restricted={true}
              exact
              Component={GenerateOTP}
            />
            <PublicRoute
              path={ROUTES.addOTP}
              restricted={true}
              exact
              Component={OTP}
            />
            <PublicRoute
              path={ROUTES.v2AddOTP}
              restricted={true}
              exact
              Component={OTPF2}
            />
            <Layout>
              <PrivateRoute exact path={ROUTES.dashboard} Component={Dashboard} />
              <PrivateRoute exact path={ROUTES.changePassword} Component={ChangePassword} />
              <PrivateRoute exact path={ROUTES.changePasswordOTP} Component={ChangePasswordOTP} />
              <PrivateRoute exact path={ROUTES.changeNewPassword} Component={Newpassword} />
              <PrivateRoute exact path={ROUTES.settings} Component={Setting} />
              <PrivateRoute
                exact
                path={ROUTES.communication}
                Component={Communication}
              />
              <PrivateRoute
                exact
                path={InnerPageRoutes.composeMessage}
                Component={ComposeMessgeForm}
              />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.sendDraftMessage}/:id`}
                Component={ComposeMessgeForm}
              />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.messageReply}/:id`}
                Component={MessageReply}
              />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.allMessages}/:id`}
                Component={AllMessagesCommunication}
              />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.singleMessageReply}/:id`}
                Component={MessageReplyCommunication}
              />
              <PrivateRoute
                exact
                path={ROUTES.updatePlansFees}
                Component={UpdatePlansFees}
              />
              <PrivateRoute
                exact
                path={ROUTES.platformFeatureTogglee}
                Component={PlatformFeatureToggle}
              />
              <PrivateRoute
                exact
                path={ROUTES.generateInvoice}
                Component={GenerateInvoice}
              />
              <PrivateRoute
                exact
                path={ROUTES.addnewuser}
                Component={AddNewUser}
              />
              <PrivateRoute
                exact
                path={InnerPageRoutes.generateInvoice}
                Component={addcomponenthere}
              />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.sendDraftInvoice}/:id`}
                Component={addcomponenthere}
              />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.viewLicense}/:id`}
                Component={addcomponenthere}
              />
              <PrivateRoute
                exact
                path={ROUTES.manageLicence}
                Component={ManageStandardLicence}
              />
              <PrivateRoute
                exact
                path={InnerPageRoutes.addNewLicense}
                Component={AddNewPlatformLicense}
              />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.updateLicense}/:id`}
                Component={AddNewPlatformLicense}
              />
              <PrivateRoute
                exact
                path={ROUTES.schoolPayment}
                Component={SchoolPayment}
              />
              <PrivateRoute exact path={ROUTES.school} Component={School} />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.newSchoolDetail}/:id`}
                Component={DetailPage}
              />
              {/*    <PrivateRoute
          exact
          path={`${subInnerPageRoutes.replyMessage}/:id`}
          Component={ReplyMessage}
        /> */}
              <PrivateRoute
                exact
                path={InnerPageRoutes.addNewPrograms}
                Component={AddNewProgram}
              />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.editPrograms}/:id`}
                Component={AddNewProgram}
              />

              <PrivateRoute exact path={ROUTES.programs} Component={Programs} />
              <PrivateRoute
                path={ROUTES.profile}
                restricted={true}
                exact
                Component={Profile}
              />
              <PrivateRoute
                exact
                path={`${ROUTES.tax}`}
                Component={Tax}
              />
            </Layout>
          </Switch>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
