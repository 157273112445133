import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  notificationHead: {
    justifyContent: "space-between",
    display: "flex"
  },
  notificationTitle: {
    color: "#000",
    font: 'normal normal normal 16px/18px Arial',
    letterSpacing: '0.06px',
  },
  notificationTime: {
    color: "#888888",
    font: 'normal normal normal 12px/14px Arial',
    letterSpacing: '0.05px',
  },
  notificationMsg: {
    color: "#888888",
    font: 'normal normal normal 12px/14px Arial',
    letterSpacing: '0.05px',
  }
}));

interface MessageProps {
  title: string;
  time: string;
  content: string;
}

const NotificationMessage: React.FC<MessageProps> = ({ title, time, content }) => {
  const classes = useStyles();
  return (
    <li style={{ padding: "10px 15px", borderBottom: "3px solid #CBCBCB" }}>
      <div className={classes.notificationHead}>
        <span className={classes.notificationTitle}>{title}</span>
        <span className={classes.notificationTime}>{time}</span>
      </div>
      <div className="text-left">
        <span className={classes.notificationMsg}>
          {content}
        </span>
      </div>
    </li>
  )
}

export default NotificationMessage
