import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import GppGoodRoundedIcon from "@mui/icons-material/GppGoodRounded";
import VisaCardIcon from "../../../../../svg_icons/visa.png";
import MasterCardIcon from "../../../../../svg_icons/mastro.png";
import moment from "moment";
import { CircularProgress, Tooltip, styled } from "@mui/material";
import * as Actions from "../../../../../reducer-store/actions/schoolPaymentActions";
import { useSelector } from "react-redux";
import { LIMIT, PAGE } from "../../../../../reducer-store/constants";
import { useParams } from "react-router-dom";
import { RiRefund2Fill } from "react-icons/ri";
import { DoneOutlined } from "@mui/icons-material";
import { green } from "@material-ui/core/colors";
import { InputDatePicker, InputField, InputSelectField } from "../../../../../common-components/FormComponent/FormFieldsComponent";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { Row, Col, Button, Container } from "react-bootstrap";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { SaveButtonComponent } from "../../../../../common-components/ButtonComponent";
import { isRequiredDate, isRequiredField } from "../../../../../common-components/FormComponent/FormValidator";
import { REFUND_SUCCESSFULLY_CREATED } from "../../../../../reducer-store/ToastMessage";
import { toast } from "react-toastify";
import pdfIcon from "../../../../../svg_icons/pdfIcon.png";
import { fileSignedURL } from "../../../../../reducer-store/actions/authActions";

const useStyles = makeStyles(() => ({
  boxBorder: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 2px #8e8e8e",
    borderRadius: "5px",
  },
  boxBorder1: {
    textAlign: "center",
    margin: "20px 0px",
  },
  TransferInitiated: {
    color: "#00a651",
  },
  TransferFaild: {
    color: "red",
  },
  line: {
    backgroundColor: "#00a651",
    width: "100%",
    height: "5px",
    marginTop: "2.5%",
  },
  Right: {
    textAlign: "end",
  },
  Left: {
    textAlign: "start",
  },
  dialogAction: {
    justifyContent: "end !important",
  },
  saveEmailButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #393939 !important",
    color: "#393939 !important",
    padding: "0 35px !important",
    height: "40px !important",
    textTransform: "capitalize",
    fontSize: "0.875rem",
  },
  saveButton: {
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontFamily: 'Arial !important',
    fontSize: '14px !important',
    textTransform: "capitalize",
  },
  tableHover: {
    cursor: 'default !important'
  }
}));

type PaymentProps = {
  paymentInfo: any;
};

interface RefundProps {
  [x: string]: any;
  id: number;
  amount: string | Number;
  payment_id: string;
  reason: string;
  status: string;
  refund_id: string;
  created: string;
};

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} style={{ color: "#ffffff", backgroundColor: "#646464" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Profile: React.FC<PaymentProps> = ({ paymentInfo }) => {
  const classes = useStyles();
  const params: any = useParams();
  const { PaymentListBySchoolId, addRefund } = Actions;
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const [order, setOrder] = useState("desc");
  const [paymentData, setPaymentData] = useState([]);
  const [viewRefunds, setViewRefunds] = useState(false);
  // const [refundData, setRefundData] = useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const [id, setID] = React.useState("");
  const [paymentId, setPaymentId] = useState("");
  const [update, setUpdate] = useState(false);
  const [refundAmount, setRefundAmount] = useState("");
  const [refundDate, setRefundDate] = useState<any>(null);
  // const [RefundRefNo, setRefundRefNo] = useState("");
  // const [RefundType, setRefundType] = useState("Requested_by_customer");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const [refundRemarks, setRefundRemarks] = useState("");
  // const [refundReason, setRefundReason] = useState("requested_by_customer");
  const [bankTransactionId, setBankTransactionId] = useState("");
  const [paymentPdf, setPaymentPdf] = useState<any>({});
  const [refundType, setRefundType] = useState("");

  function createData(
    Id: number,
    SchoolName: string,
    StatusDate: string,
    Amount: string,
    TransactionID: string,
    FeeType: string,
    TransactionAcc: string,
    InstrumentLogo: string,
    Refund: string
  ) {
    return {
      Id,
      SchoolName,
      StatusDate,
      Amount,
      TransactionID,
      FeeType,
      TransactionAcc,
      InstrumentLogo,
      Refund,
    };
  }

  useEffect(() => {
    (async () => {
      const schoolPaymentList: any = await PaymentListBySchoolId(PAGE, LIMIT, token, order, params?.id);
      if (!schoolPaymentList?.error) {
        setPaymentData(schoolPaymentList?.data);
      }
    })();
  }, [PAGE, LIMIT, order]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const manageStatus = (value: number) => {
    switch (value) {
      case 1:
        return "Received";
      case 2:
        return "Failed";
      case 3:
        return "Cancelled";
      case 4:
        return "Refund Requested";
      case 5:
        return "Refunded";
      default:
        return "Failed";
    }
  };

  const paymentStatus = (value: number) => {
    switch (value) {
      case 1:
        return "Success";
      case 2:
        return "Failed";
      default:
        return "Success";
    }
  };

  const managePaymentInfosLast = (value: any) => {
    if (value.length === 0) {
      return " ****";
    }
    return value[0].last4;
  };

  const cardshow = (value: any) => {
    if (value.length === 0) {
      return "---";
    }
    switch (value[0].brand) {
      case "visa":
        return <img src={VisaCardIcon} alt="visa" />;
      case "master":
        return <img src={MasterCardIcon} alt="visa" />;
      default:
        return <img src={VisaCardIcon} alt="visa" />;
    }
  };

  const SubmitHandlerRefund = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);

    if (refundType === "bank" ?
      isRequiredField(refundType) &&
      isRequiredField(bankTransactionId) &&
      isRequiredDate(refundDate) &&
      isRequiredField(refundAmount) &&
      isRequiredField(refundRemarks)
      :
      isRequiredField(refundType) &&
      isRequiredField(refundAmount) &&
      isRequiredField(refundRemarks)
    ) {
      setIsSubmit(false);
      const formData = new FormData();

      formData.append("amount", refundAmount);
      formData.append("type", refundType);
      formData.append("remark", refundRemarks);
      if (refundType === "bank") {
        formData.append("bank_proof", paymentPdf);
        formData.append("bank_transaction_id", bankTransactionId);
        formData.append("created", refundDate);
      } else {
        formData.append("reason", "requested_by_customer");
      }

      let resData: any = await addRefund(id, formData, token);
      if (!resData.error) {
        toast.success(REFUND_SUCCESSFULLY_CREATED);
        setTimeout(async () => {
          handleClose();
          resetData();
          const schoolPaymentList: any = await PaymentListBySchoolId(PAGE, LIMIT, token, order, params?.id);
          if (!schoolPaymentList?.error) {
            setPaymentData(schoolPaymentList?.data);
          }
        }, 2000);
      }
      return
    }
    setIsValidValues(false);
  };

  const resetData = () => {
    setRefundAmount("");
    setRefundDate(null);
    // setRefundRefNo("");
  };

  const handleClose = () => {
    setID("");
    setRefundRemarks("");
    setIsValidValues(true);
    resetData();
    setOpen(false);
    setTimeout(() => {
      setUpdate(false);
    }, 1000);
  };

  const handleClickOpen = async (id: any) => {
    setViewRefunds(false);
    setID(id);
    setTimeout(() => {
      setOpen(true);
    }, 1000);
  };

  const refundStatus = (value: any) => {
    // if (value.status === 1) {
    //   return (<RiRefund2Fill size={22} color="green" onClick={() => viewAllRefunds(value.id)} />);
    // }
    if (value.RefundInfos.length === 0) {
      return (
        <Tooltip title="Add Refund" style={{ cursor: 'pointer' }}>
          <RiRefund2Fill size={22} color="green" onClick={() => handleClickOpen(value.id)} />
          {/* <AvTimer onClick={() => handleClickOpen(value.id)} sx={{ fill: red[600], fontSize: 20 }} /> */}
        </Tooltip>
      );
    }
    else {
      return (
        <Tooltip title="Refunded">
          <DoneOutlined sx={{ fill: green[600], fontSize: 20 }} />
        </Tooltip>
      );
    }
  };

  const handlePartialRefund = (payId: string) => {
    handleClickOpen(payId);
  }

  const navigateToExternalUrl = async (urlName: string, shouldOpenNewTab: boolean = true) => {
    if (urlName) {
      const newData: { url: string, msg: string, error: boolean } = await fileSignedURL(urlName && urlName, token);
      const { url, msg, error } = newData;
      if (error) {
        toast.error(msg);
        return;
      }
      shouldOpenNewTab ? window.open(url, "_blank") : window.location.href = url;
      return;
    }
  }

  const handleRefundDate = (date: any) => {
    setRefundDate(date);
  };

  function RowData(props: { row: ReturnType<typeof createData>, rowIndex: number }) {
    const { row, rowIndex }: any = props;
    const [open, setOpen] = useState(false);

    const totalAmountBank = row?.RefundInfos?.filter((data: any) => { return data.type === "bank" }).reduce((prev: any, next: any) => { return prev + next.amount }, 0);
    const totalAmountCard = row?.RefundInfos?.filter((data: any) => { return data.type === "card" }).reduce((prev: any, next: any) => { return prev + next.amount }, 0) / 100;

    return (
      <React.Fragment>
        {
          row ?
            <>
              <TableRow>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
                <TableCell>{rowIndex + 1}</TableCell>
                <TableCell align="center">
                  {row?.School?.name}
                  <br />
                  {row?.School?.id}
                </TableCell>
                <TableCell align="center">
                  {manageStatus(row?.status)}
                  <br />
                  {moment(row?.created_at).format("MMM D, YYYY")}
                </TableCell>
                <TableCell align="center">
                  {row?.currency ? (row?.currency).toUpperCase() : "-"}{" "}
                  {row?.total_amount ? row?.total_amount : "-"}
                </TableCell>
                <TableCell align="center">
                  {row?.PaymentInfos ? row.PaymentInfos[0]?.transaction : "-"}
                </TableCell>
                <TableCell align="center">{row?.details}</TableCell>
                <TableCell align="center">
                  **** **** ****{" "}
                  {row.PaymentInfos
                    ? managePaymentInfosLast(row?.PaymentInfos)
                    : "****"}
                </TableCell>
                <TableCell align="center">
                  {row.PaymentInfos ? cardshow(row?.PaymentInfos) : ""}
                </TableCell>
                <TableCell align="center">
                  {row?.status ? manageStatus(row?.status) : "-"}
                </TableCell>
                <TableCell align="center">
                  {row?.status ? refundStatus(row) : "-"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box className={classes.boxBorder1}>
                      <div className="row">
                        <Col lg="4" sm="4" xs="12" className={classes.Right}>
                          <Typography
                            variant="h6"
                            gutterBottom
                            component="div"
                            className={classes.TransferInitiated}
                          >
                            <GppGoodRoundedIcon />
                            Transfer Initiated
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            component="div"
                          >
                            Timestamp : {moment(row?.created_at).format("MMM D, YYYY | h:mm:ss A")}
                          </Typography>
                        </Col>
                        <Col lg="4" sm="4" xs="12">
                          <div className={classes.line}></div>
                        </Col>

                        <Col lg="4" sm="4" xs="12" className={classes.Left}>
                          <Typography
                            variant="h6"
                            gutterBottom
                            component="div"
                            className={row?.status === 2 ? classes.TransferFaild : classes.TransferInitiated}
                          >
                            <GppGoodRoundedIcon />
                            Transfer {row?.status ? paymentStatus(row?.status) : "-"}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            component="div"
                          >
                            Timestamp : {row?.paid_on !== null ? moment(row?.paid_on).format("MMM D, YYYY | h:mm:ss A") : moment(row?.created_at).format("MMM D, YYYY | h:mm:ss A")}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            component="div"
                          >
                            Payment Gateway ID : {row?.payment_id}
                          </Typography>
                        </Col>
                      </div>
                    </Box>
                    <hr style={{ margin: '0 auto', marginTop: 2, color: "#000000", opacity: 1, width: '70%' }} />

                    <Container className="mb-3">
                      <Col lg="12" sm="12" xs="12">
                        <Table aria-label="simple table" >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                                Date
                              </StyledTableCell>
                              <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                                Refunded Amount
                              </StyledTableCell>
                              <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                                Payment Gateway Reason
                              </StyledTableCell>
                              <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                                Status
                              </StyledTableCell>
                              <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                                Remark
                              </StyledTableCell>
                              <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                                Payment Reference
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              row?.RefundInfos?.length > 0 ? (
                                row?.RefundInfos?.map((refund: RefundProps, index: number) => (
                                  <StyledTableRow key={refund.id}>
                                    <StyledTableCell align="center">
                                      {refund.type === "bank" ? moment(new Date(refund.created)).format("YYYY-MM-DD") : moment(new Date(refund.created_at)).format("YYYY-MM-DD")}
                                    </StyledTableCell>
                                    <StyledTableCell scope="row" align="center">
                                      CAD {refund.type === 'bank' ? Number(refund.amount).toFixed(2) : (Number(refund.amount) / 100).toFixed(2)}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {refund.reason || "--"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {refund.status}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {refund?.metadata?.remark || "--"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {refund.type === "bank" && refund.bank_transaction_proof ?
                                        <img
                                          title="Download Payment Reference"
                                          style={{ cursor: "pointer" }}
                                          width={32}
                                          src={pdfIcon}
                                          onClick={() => navigateToExternalUrl(refund.bank_transaction_proof)}
                                        /> : "--"}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))
                              ) : (
                                <StyledTableRow>
                                  <StyledTableCell align="center" colSpan={8}>
                                    No refunds found
                                  </StyledTableCell>
                                </StyledTableRow>
                              )}
                          </TableBody>
                        </Table>

                        <Row className="mt-3">
                          <Col lg="6" sm="6" xs="12">
                            {row?.RefundInfos && row?.RefundInfos?.length > 0 ?
                              <Typography variant="subtitle1" gutterBottom component="div">
                                <b>Total Amount Refunded:</b> CAD {Number(totalAmountBank + totalAmountCard).toFixed(2)}
                              </Typography> : null}
                          </Col>
                          <Col lg="6" sm="6" xs="12">
                            {row?.RefundInfos && row?.RefundInfos?.length > 0 ?
                              row?.total_amount > Number(totalAmountBank + totalAmountCard).toFixed(2) ?
                                <div style={{ textAlign: "end", margin: 10 }}>
                                  <Button className={classes.saveButton} onClick={() => handlePartialRefund(row.id)}>
                                    Add More Refund
                                  </Button>
                                </div>
                                : null
                              : null}
                          </Col>
                        </Row>
                      </Col>
                    </Container>
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
            :
            <TableRow>
              <TableCell style={{ "textAlign": "center" }} colSpan={9}>
                Payments not found
              </TableCell>
            </TableRow>
        }
      </React.Fragment>
    );
  }

  const rows = paymentData && paymentData;

  return (
    <>
      <Box className={classes.boxBorder}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell component="th">Id</TableCell>
              <TableCell align="center" component="th">School Name & ID</TableCell>
              <TableCell align="center" component="th">Status & Date</TableCell>
              <TableCell align="center" component="th">Amount</TableCell>
              <TableCell align="center" component="th">Transaction ID</TableCell>
              <TableCell align="center" component="th">Fee Type</TableCell>
              <TableCell align="center" component="th">Transaction Acc</TableCell>
              <TableCell align="center" component="th">Instrument Logo</TableCell>
              <TableCell align="center" component="th">Payment Status</TableCell>
              <TableCell align="center" component="th">Refund</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!paymentInfo ? (
              <Box style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
                <CircularProgress sx={{ color: '#707070' }} />
              </Box>
            ) : paymentInfo ?
              (
                rows.map((row: any, index) => (
                  <RowData key={row?.SchoolName} row={row} rowIndex={index} />
                ))
              ) : (
                <Box style={{ position: 'absolute', margin: '0 auto', top: '55%', left: '50%', justifyContent: 'center' }}>
                  <p>{paymentInfo?.msg || "No Data Found"}</p>
                </Box>
              )}
          </TableBody>
        </Table>
      </Box>

      <BootstrapDialog
        onClose={handleClose}
        fullWidth
        maxWidth={"md"}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <form onSubmit={SubmitHandlerRefund}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Add Refund
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Row>
              <Col lg="6" sm="12">
                <InputField
                  type="number"
                  lable="Refund Amount"
                  name={refundAmount}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Refund Amount"
                  className="form-textfield-styles"
                  value={refundAmount}
                  handleChange={(e) => setRefundAmount(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Refund Amount field is required"
                  disablePrevious="0"
                />
              </Col>
              <Col lg="6" sm="12">
                <InputSelectField
                  select={true}
                  lable="Select Transfer Type"
                  name={refundType}
                  value={refundType}
                  className="form-textfield-styles"
                  options={[
                    { id: "", name: "Select Transfer Type" },
                    { id: "card", name: "Transfer To Existing Acccount" },
                    { id: "bank", name: "Bank Transfer" },
                  ]}
                  handleChange={(e) => setRefundType(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Transfer Type field is required"
                />
              </Col>
              {/* <Col lg="6" sm="12">
                <InputField
                  disabled
                  type="text"
                  lable="Refund Reason"
                  name={refundReason}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Refund Reason"
                  className="form-textfield-styles"
                  value={"requested_by_customer"}
                  handleChange={() => { }}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Refund Reason field is required"
                />
                <InputDatePicker
                  className="form-textfield-styles errorBottom"
                  value={refundDate}
                  lable="Refund date"
                  handleChange={(e) => handleRefundDate(e)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Refund Amount field is required" />
              </Col> */}
            </Row>
            {refundType === "bank" ?
              <>
                <Row>
                  <Col lg="6" sm="12">
                    <InputField
                      type="text"
                      lable="Bank Transaction ID"
                      name={bankTransactionId}
                      rows={1}
                      multiline={false}
                      placeHolder="Enter Bank Transaction ID"
                      className="form-textfield-styles"
                      value={bankTransactionId}
                      handleChange={(e) => setBankTransactionId(e.target.value)}
                      isValidValue={isValidValues}
                      isSubmit={isSubmit}
                      requiredMessage="Bank Transaction ID field is required" />
                  </Col>
                  <Col lg="6" sm="12">
                    <InputDatePicker
                      className="form-textfield-styles"
                      value={refundDate}
                      lable="Bank Transfer date"
                      handleChange={(e) => handleRefundDate(e)}
                      isValidValue={isValidValues}
                      isSubmit={isSubmit}
                      requiredMessage="Refund Amount field is required" />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" sm="12">
                    <label style={{ fontSize: 12 }}>
                      <b>Payment Reference PDF</b><br />
                      <input
                        accept=".pdf"
                        style={{ fontSize: 16 }}
                        required
                        className="form-textfield-styles"
                        type='file'
                        id='file'
                        name="file"
                        placeholder="Upload a document"
                        onChange={(e: any) => setPaymentPdf(e.target.files[0])} />
                    </label>
                  </Col>
                </Row>
              </>
              : null}
            <Row>
              <Col lg="12" sm="12">
                <InputField
                  type="text"
                  lable="Refund Remark"
                  name={refundRemarks}
                  rows={1}
                  multiline={true}
                  placeHolder="Enter Refund Remark"
                  className="form-textfield-styles"
                  value={refundRemarks}
                  handleChange={(e) => setRefundRemarks(e.target.value)}
                  isValidValue={false}
                  isSubmit={false}
                  requiredMessage="Refund Remark field is required"
                />
              </Col>
            </Row>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
            <Button
              type="button"
              variant="contained"
              onClick={handleClose}
              className={classes.saveEmailButton}
            >
              Cancel
            </Button>{" "}
            {update === true ? null : <SaveButtonComponent ButtonType="submit" ButtonLabel="Submit" />}
          </DialogActions>
        </form>
        {/* } */}
      </BootstrapDialog></>
  );
};

export default Profile;