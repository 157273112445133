import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import CommonHeadPart from "../../../common-components/CommonHeadPart";
import { ROUTES } from "../../../constants/routes";
import { InputField } from "../../../common-components/FormComponent/FormFieldsComponent";
import { isRequiredField, renderRequiredError, isValidRegisterID, isValidNo } from "../../../common-components/FormComponent/FormValidator";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice, faSchool } from "@fortawesome/free-solid-svg-icons";
import { DiscardButtonComponet, SendButtonComponet } from "../../../common-components/ButtonComponent";
import { Link, useParams } from "react-router-dom";
import InvoiceIcon from "../../../svg_icons/invoice.svg";
import { useSelector } from "react-redux";
import * as Actions from "../../../reducer-store/actions/invoiceAction";
import * as schoolAction from "../../../reducer-store/actions/schoolActions";
import { INVOICE_SUCCESSFULLY_SAVE, INVOICE_SUCCESSFULLY_SEND, INVOICE_SUCCESSFULLY_UPDATED } from "../../../reducer-store/ToastMessage";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import ToastMessage from "../../../common-components/ToastMessage";
import { PAGE } from "../../../reducer-store/constants";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import moment from "moment";
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

const useStyles = makeStyles(() => ({
  CardTitle: {
    backgroundColor: "#393939",
    color: "#ffffff",
  },
  button_save: {
    textAlign: "right",
  },
  termsCondi: {
    color: "#e5b120",
    "&:hover": {
      color: "#e9ad06",
    },
  },
  saveEmailButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #393939 !important",
    color: "#393939 !important",
    padding: "0 35px !important",
    height: "40px !important",
    textTransform: "capitalize",
    margin: '8px !important'
  }
}));

const AddNewPlatformLicense: React.FC = () => {
  const params: any = useParams();
  const AuthToken = useSelector((state: any) => state.auth);
  const { user, token } = AuthToken;
  const [schoolName, setSchoolName] = useState("");
  const [schoolID, setSchoolID] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailID, setEmailID] = useState("");
  const [invoiceEmail, setInvoiceEmail] = useState("");
  const { saveInvoice, sendCustomInvoice, sendDraftInvoice, DropDownSchoolList, SingleInvoiceData, UpdateDraftInvoice } = Actions;
  const { GetProvinceTax } = schoolAction;
  const [planName, setPlanName] = useState("");
  const [planID, setPlanId] = useState("");
  // const [invoiceTemplate, setInvoiceTemplate] = useState("");
  // const [createdBy, setCreatedby] = useState(user && user.full_name);
  const [invoiceDescription, setInvoiceDescription] = useState("");
  const [totalPrice, setTotalPrice] = useState<any>(0);
  // const [fromDate, setFromDate] = useState<any>(null);
  // const [toDate, setToDate] = useState<any>(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const history = useHistory();
  const [schoolList, setSchoolList] = useState<any>([]);
  const [tax, setTax] = useState<any>({});
  const [planList, setPlanList] = useState("");
  const [province, setProvince] = useState("");
  const [bookRoomData, setBookRoomData] = useState<any>([{ name: "", price: "" }]);
  const [removeBtn, setRemoveBtn] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [defaultObj, setDefaultObj] = useState<any>({});
  const [composeTo, setComposeTo] = useState("");
  const isCheckValidEmailValue = false;
  const isCheckValidNoValue = false;
  const isCheckValidPhoneValue = false;
  const isCheckValidRID = false;
  const isCheckValidAddress = false;

  const BreadCrumbInvoice = [
    {
      labelText: "Home",
      linkUrl: ROUTES.home,
    },
    {
      labelText: "Generate Invoice",
      linkUrl: ROUTES.generateInvoice,
    },
    {
      labelText: params?.id ? "Update Invoice" : "Add New Generate Invoice",
      linkUrl: "",
    },
  ];

  useEffect(() => {
    if (params?.id) {
      (async () => {
        const singleInvoice: any = await SingleInvoiceData(params?.id, token);
        const { data } = singleInvoice;
        setComposeTo(data?.School?.email);
        setBookRoomData(data?.addons?.data);
      })();
    }
  }, []);

  useEffect(() => {
    (async () => {
      const schools = await DropDownSchoolList(PAGE, token);
      setSchoolList(schools.data);
      /* const plans = await DropDownPlanList(PAGE, token);
      setPlanList(plans.data); */
    })();
  }, []);

  useEffect(() => {
    if (schoolList?.length > 0) {
      var temp = schoolList.find((v: any) => v.email === composeTo)
      if (params?.id) {
        setDefaultObj(temp);
        handleSelectedSchool(temp);
      }
    }
  }, [schoolList, defaultObj, composeTo])

  useEffect(() => {
    (async () => {
      if (province) {
        const provinceTax: any = await GetProvinceTax(province);
        setTax(provinceTax);
      }
    })();
  }, [province])

  useEffect(() => {
    var val = bookRoomData.reduce(function (previousValue: any, currentValue: any) {
      return {
        price: previousValue.price + currentValue.price,
      }
    });
    setTotalPrice(val?.price);
  }, [bookRoomData])

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      isRequiredField(schoolName) &&
      checkAndVerify(bookRoomData[0]) &&
      checkAndVerify(bookRoomData[1]) &&
      checkAndVerify(bookRoomData[2]) &&
      checkAndVerify(bookRoomData[3]) &&
      checkAndVerify(bookRoomData[4])
    ) {
      setIsSubmit(false);
      let userObj = {
        school_id: schoolID,
        from_date: moment().format("DD/MM/YYYY"),
        to_date: moment().format("DD/MM/YYYY"),
        addons: {
          "data": bookRoomData
        },
        status: 1,
      };

      let resData: any = params?.id ? await sendDraftInvoice(params?.id, token) : await sendCustomInvoice(userObj, token);
      if (!resData?.data.error) {
        toast.success(INVOICE_SUCCESSFULLY_SEND);
        setTimeout(() => {
          return history.push(ROUTES.generateInvoice);
        }, 1000);
        return;
      }
    }
    setIsValidValues(false);
  };

  const SaveHandler = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      isRequiredField(schoolName) &&
      checkAndVerify(bookRoomData[0]) &&
      checkAndVerify(bookRoomData[1]) &&
      checkAndVerify(bookRoomData[2]) &&
      checkAndVerify(bookRoomData[3]) &&
      checkAndVerify(bookRoomData[4])
    ) {
      setIsSubmit(false);

      let invoidData: any = {
        addons: {
          "data": bookRoomData
        },
        status: 1,
      };

      if (!params?.id) {
        invoidData.school_id = schoolID;
        invoidData.from_date = moment().format("DD/MM/YYYY");
        invoidData.to_date = moment().format("DD/MM/YYYY");
      }

      let resData: any = params?.id ? await UpdateDraftInvoice(params?.id, invoidData, token) : await saveInvoice(invoidData, token);
      if (!resData.error) {
        toast.success(params?.id ? INVOICE_SUCCESSFULLY_UPDATED : INVOICE_SUCCESSFULLY_SAVE);
        setTimeout(() => {
          history.push(ROUTES.generateInvoice);
        }, 1000);
        return;
      }
    }
    setIsValidValues(false);
  };

  const handleSelectedSchool = (newValue: any) => {
    setSchoolName(newValue ? newValue.name : "");
    setSchoolID(newValue ? newValue.id : "");
    setOwnerName(newValue ? `${newValue.title}${" "}${newValue.first_name}${" "}${newValue.middle_name}${" "}${newValue.last_name}` : "");
    setMobileNumber(newValue ? newValue.mobile : "");
    setPhoneNumber(newValue ? newValue.phone : "");
    setEmailID(newValue ? newValue.email : "");
    setInvoiceEmail(newValue ? newValue.email : "");
    setPlanName(newValue ? newValue.Plan.name : "");
    setPlanId(newValue ? newValue.Plan.id : "");
    setPlanList(newValue ? newValue.Plan.name : "");
    setProvince(newValue ? newValue.state : "");
  }

  const classes = useStyles();

  // const handleFromDate = (date: any) => {
  //   setFromDate(date);
  // };

  // const handleToDate = (date: any) => {
  //   setToDate(date);
  // };

  const checkAndVerify = (value: any) => {
    if (value === undefined)
      return true;
    if (
      isRequiredField(value.name) &&
      isRequiredField(value.price)
    ) {
      return true;
    }
    return false;
  }

  const handleAddFields = () => {
    const values = [...bookRoomData];
    setRemoveBtn(true);

    if (values.length < 5) {
      values.push({ name: "", price: "" });
    } else {
      setBtnDisable(true);
    }
    setBookRoomData(values);
  };

  const handleRemoveFields = () => {
    const values = [...bookRoomData];
    if (values.length > 1) values.pop();
    if (values.length < 2) setRemoveBtn(false);
    setBookRoomData(values);
    setBtnDisable(false);
  };

  const handleGenerate = (indexNum: any, event: any) => {
    const values = [...bookRoomData];
    const newTimeList = values.map((data: any, index: number) => {
      if (index === parseInt(indexNum)) {
        if (event.target.name === "name")
          return { ...data, [event.target.name]: event.target.value };
        if (event.target.name === "price")
          return { ...data, [event.target.name]: event.target.value && parseFloat(event.target.value.slice(0, 4)) };
        return { ...data, [event.target.name]: event.target.value && parseFloat(event.target.value.slice(0, 4)) };
      }
      return data;
    });
    setBookRoomData(newTimeList);
  };

  return (
    <>
      <ToastMessage />
      <CommonHeadPart
        componentText="Generate Invoice"
        Icon={InvoiceIcon}
        buttonText=""
        BreadCrumbs={BreadCrumbInvoice}
        urlLink=""
      />

      <Card>
        <div className={classes.CardTitle}>
          <CardContent>
            <Typography variant="h6" component="div">
              <FontAwesomeIcon
                icon={faFileInvoice}
                style={{ marginRight: "10px" }}
              />
              {params?.id ? "Update invoice" : "New invoice"}
            </Typography>
          </CardContent>
        </div>
        <form onSubmit={SubmitHandler}>
          <CardContent>
            <Typography variant="h6" component="div">
              <FontAwesomeIcon
                icon={faSchool}
                style={{ marginRight: "10px" }}
              />
              Bill to School
            </Typography>
            <Row className="mt-4">
              <Col lg="6" sm="12">
                <Autocomplete
                  id="size-small-standard"
                  size="small"
                  options={schoolList}
                  value={defaultObj || null}
                  onChange={(event: any, newValue) => { setDefaultObj(newValue); handleSelectedSchool(newValue) }}
                  getOptionLabel={(option: any) => option.name || ""}
                  isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="School name"
                      placeholder="Enter School Name"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
                {!isValidValues && renderRequiredError(schoolName, isSubmit, "Please select school name", isCheckValidEmailValue, isCheckValidNoValue, isCheckValidPhoneValue, isCheckValidRID, isCheckValidAddress)}
              </Col>
              <Col lg="3" sm="12">
                <InputField
                  type="text"
                  lable="School ID"
                  name={schoolID}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter School ID"
                  className="form-textfield-styles"
                  value={schoolID}
                  handleChange={(e) => setSchoolID(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={false}
                  disabled={true}
                  requiredMessage="To field is required"
                />
              </Col>
              <Col lg="3" sm="12">
                <InputField
                  type="text"
                  lable="Province"
                  name={province}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Province"
                  className="form-textfield-styles"
                  value={province == "1" ? "Alberta" :
                    province == "2" ? "Ontario" :
                      province == "3" ? "British Columbia" :
                        province == "4" ? "Quebec" :
                          province == "5" ? "Nova Scotia" :
                            province == "6" ? "New Brunswick" :
                              province == "7" ? "Manitoba" :
                                province == "8" ? "Prince Edward Island" :
                                  province == "9" ? "Saskatchewan" :
                                    province == "10" ? "Newfoundland and Labrador" :
                                      province == "11" ? "Northwest Territories" :
                                        province == "12" ? "Nunavut" :
                                          province == "13" ? "Yukon" : ""}
                  handleChange={(e) => setProvince(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={false}
                  disabled={true}
                  requiredMessage="To field is required"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="6" sm="12">
                <InputField
                  type="text"
                  lable="Owner name"
                  name={ownerName}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Owner Name"
                  className="form-textfield-styles"
                  value={ownerName}
                  handleChange={(e) => setOwnerName(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={false}
                  disabled={true}
                  requiredMessage="To field is required"
                />
              </Col>
              <Col lg="3" sm="12">
                <InputField
                  type="text"
                  lable="Phone number"
                  name={phoneNumber}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Phone Number"
                  className="form-textfield-styles"
                  value={phoneNumber}
                  handleChange={(e) => setPhoneNumber(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={false}
                  disabled={true}
                  requiredMessage="To field is required"
                />
              </Col>
              <Col lg="3" sm="12">
                <InputField
                  type="text"
                  lable="Mobile number"
                  name={mobileNumber}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Mobile Number"
                  className="form-textfield-styles"
                  value={mobileNumber}
                  handleChange={(e) => setMobileNumber(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={false}
                  disabled={true}
                  requiredMessage="To field is required"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="6" sm="12">
                <InputField
                  type="text"
                  lable="Email ID"
                  name={emailID}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Email ID"
                  className="form-textfield-styles"
                  value={emailID}
                  handleChange={(e) => setEmailID(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={false}
                  disabled={true}
                  requiredMessage="To field is required"
                />
              </Col>
              <Col lg="6" sm="12">
                <InputField
                  type="text"
                  lable="Invoice email"
                  name={invoiceEmail}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Invoice Email"
                  className="form-textfield-styles"
                  value={invoiceEmail}
                  handleChange={(e) => setInvoiceEmail(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={false}
                  disabled={true}
                  requiredMessage="To field is required"
                />
              </Col>
            </Row>
            <hr className="hrline" />
            <Typography variant="h6" component="div">
              <FontAwesomeIcon
                icon={faFileInvoice}
                style={{ marginRight: "10px" }}
              />
              Invoice Details
            </Typography>
            <Row className="mt-4">
              {/* <Col lg="6" sm="12">
                <InputField
                  type="text"
                  lable="Plan name"
                  name={planList}
                  rows={1}
                  multiline={false}
                  placeHolder="Plan Name"
                  className="form-textfield-styles"
                  value={planList}
                  handleChange={(e) => setPlanList(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={false}
                  disabled={true}
                  requiredMessage="To field is required"
                />
              </Col> */}
              {/* <Col lg="6" sm="12">
                <Autocomplete
                  id="size-small-standard"
                  size="small"
                  options={planList}
                  onChange={(event: any, newValue) => {
                    setPlanName(newValue ? newValue.name : "");
                    setPlanId(newValue ? newValue.id : "");
                  }}
                  getOptionLabel={(option: any) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Plan Name"
                      placeholder="Enter Plan Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
                {!isValidValues && renderRequiredError(planName, isSubmit, "Please select plan")}
              </Col> */}
              {/* <Col lg="6" sm="12">
                <InputField
                  type="text"
                  lable="Invoice template"
                  name={invoiceTemplate}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Invoice Template"
                  className="form-textfield-styles"
                  value={invoiceTemplate}
                  handleChange={(e) => setInvoiceTemplate(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Invoice Template field is required"
                  isCheckValidRID={true}
                />
              </Col> */}
            </Row>
            {bookRoomData && bookRoomData?.map((data: any, i: any) => {
              return (
                <Row>
                  {/* <Col lg="6" sm="12">
                <InputField
                  type="text"
                  lable="Created by"
                  name="created_by"
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Created by"
                  className="form-textfield-styles"
                  value={createdBy}
                  handleChange={(e) => setCreatedby(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="To field is required"
                  disabled={true}
                />
              </Col> */}
                  <Col lg="6" sm="12">
                    <InputField
                      disabled={schoolName ? false : true}
                      type="text"
                      lable={`Invoice Description ${i + 1}`}
                      name="name"
                      rows={1}
                      multiline={false}
                      placeHolder={`Enter Invoice Description ${i + 1}`}
                      className="form-textfield-styles"
                      value={data.name}
                      handleChange={(e) => handleGenerate(i, e)}
                      isValidValue={isValidValues}
                      isSubmit={isSubmit}
                      requiredMessage={`Invoice Description ${i + 1} field is required`}
                    />
                  </Col>
                  <Col lg="5" sm="12">
                    <InputField
                      disabled={schoolName ? false : true}
                      type="number"
                      lable={`Amount ${i + 1}`}
                      name="price"
                      rows={1}
                      multiline={false}
                      placeHolder={`Enter Amount ${i + 1}`}
                      className="form-textfield-styles"
                      value={data.price}
                      handleChange={(e) => handleGenerate(i, e)}
                      isValidValue={isValidValues}
                      isSubmit={isSubmit}
                      requiredMessage={`Amount field ${i + 1} is required`}
                      disablePrevious="0"
                      disableUpcoming="9999"
                    />
                  </Col>
                  <Col lg="1" sm="12">
                    <div className="multi-row-add">
                      <button type="button"
                        disabled={btnDisable}
                        className="add-more"
                        onClick={handleAddFields}
                      >
                        <AddBoxIcon titleAccess="Add Line Items" />
                      </button>

                      {" "}
                      {
                        removeBtn || params?.id ?
                          <button type="button" className="remove-button" onClick={handleRemoveFields}>
                            <IndeterminateCheckBoxIcon />
                          </button>
                          :
                          ""
                      }
                    </div>
                  </Col>
                </Row>
              );
            })}
            {/* <Row>
              <Col lg="6" sm="12">
                <InputField
                  type="date"
                  lable="From date"
                  name={fromDate}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter From Date"
                  className="form-textfield-styles"
                  value={fromDate}
                  handleChange={(e) => setFromDate(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="From Date field is required"
                  disableUpcoming={moment(new Date("9999-01-01")).format("YYYY-MM-DD")}
                />
                <InputDatePicker
                  className="form-textfield-styles errorBottom"
                  value={fromDate}
                  lable="From date"
                  handleChange={(e) => handleFromDate(e)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="From Date field is required"
                />
              </Col>
              <Col lg="6" sm="12">
                <InputField
                  type="date"
                  lable="To date"
                  name={toDate}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter To Date"
                  className="form-textfield-styles"
                  value={toDate}
                  handleChange={(e) => setToDate(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="To Date field is required"
                  disablePrevious={fromDate}
                  disableUpcoming={moment(new Date("9999-01-01")).format("YYYY-MM-DD")}
                />
                <InputDatePicker
                  className="form-textfield-styles errorBottom"
                  value={toDate}
                  lable="To date"
                  handleChange={(e) => handleToDate(e)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  min={fromDate?.length > 0 ? fromDate : ""}
                  requiredMessage="To Date field is required"
                />
              </Col>
            </Row> */}
            <hr className="hrline" />
            <Row className="mt-4">
              <Col lg="6" sm="12">
                <Typography variant="h6" component="div">
                  {/* <Link to="/" className={classes.termsCondi}> */}
                  Terms & Conditions of Online Payment
                  {/*  </Link> */}
                </Typography>
              </Col>
              <Col lg={{ span: 3, offset: 3 }} sm="12">
                <Row>
                  <Col lg="6" sm="6" xs="6">
                    <div className="Sttext1">
                      <Typography variant="subtitle1">Sub Total:</Typography>
                    </div>
                  </Col>
                  <Col lg="3" sm="3" xs="3" className="summary">
                    <Typography className="text-end" variant="subtitle1">
                      CAD
                    </Typography>
                  </Col>
                  <Col lg="3" sm="3" xs="3" className="summary">
                    <Typography className="text-end" variant="subtitle1">
                      {Number(totalPrice)?.toFixed(2)}
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" sm="6" xs="6">
                    <div className="Sttext1">
                      <Typography variant="subtitle1">Province Tax:</Typography>
                    </div>
                  </Col>
                  <Col lg="3" sm="3" xs="3" className="summary">
                    <Typography className="text-end" variant="subtitle1">
                      CAD
                    </Typography>
                  </Col>
                  <Col lg="3" sm="3" xs="3" className="summary">
                    <Typography className="text-end" variant="subtitle1">
                      {totalPrice ? Number((totalPrice * tax?.tax_slab_1) / 100).toFixed(2) : (0).toFixed(2)}
                    </Typography>
                  </Col>
                  <Col lg="6" sm="6" xs="6">
                    <div className="Sttext1">
                      <Typography variant="subtitle1">County Tax:</Typography>
                    </div>
                  </Col>
                  <Col lg="3" sm="3" xs="3" className="summary">
                    <Typography className="text-end" variant="subtitle1">
                      CAD
                    </Typography>
                  </Col>
                  <Col lg="3" sm="3" xs="3" className="summary">
                    <Typography className="text-end" variant="subtitle1">
                      {totalPrice ? Number((totalPrice * tax?.tax_slab_2) / 100).toFixed(2) : (0).toFixed(2)}
                    </Typography>
                  </Col>
                  <Col lg="6" sm="6" xs="6">
                    <div className="Sttext1">
                      <Typography variant="subtitle1">Total Tax:</Typography>
                    </div>
                  </Col>
                  <Col lg="3" sm="3" xs="3" className="summary">
                    <Typography className="text-end" variant="subtitle1">
                      CAD
                    </Typography>
                  </Col>
                  <Col lg="3" sm="3" xs="3" className="summary">
                    <Typography className="text-end" variant="subtitle1">
                      {totalPrice ? Number((totalPrice * tax?.total_tax) / 100).toFixed(2) : (0).toFixed(2)}
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" sm="6" xs="6">
                    <div className="Sttext1">
                      <Typography variant="subtitle1">Total:</Typography>
                    </div>
                  </Col>
                  <Col lg="3" sm="3" xs="3" className="summary">
                    <Typography className="text-end" variant="subtitle1">
                      CAD
                    </Typography>
                  </Col>
                  <Col lg="3" sm="3" xs="3" className="summary">
                    <Typography className="text-end" variant="subtitle1">
                      {totalPrice ?
                        (Number(totalPrice) + Number((totalPrice * tax?.total_tax) / 100)).toFixed(2) :
                        (0).toFixed(2)}
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" sm="6" xs="6">
                    <div className="Sttext1">
                      <Typography variant="subtitle1">Balance Due:</Typography>
                    </div>
                  </Col>
                  <Col lg="3" sm="3" xs="3" className="summary">
                    <Typography className="text-end" variant="subtitle1">
                      CAD
                    </Typography>
                  </Col>
                  <Col lg="3" sm="3" xs="3" className="summary">
                    <Typography className="text-end" variant="subtitle1">
                      {(0).toFixed(2)}
                    </Typography>
                  </Col>
                </Row>
                <div className="stline"></div>
                <Row>
                  <Col lg="6" sm="6" xs="6">
                    <Typography className="StLicence" variant="h6" component="div">
                      Grand Total:
                    </Typography>
                  </Col>
                  <Col lg="3" sm="3" xs="3" className="summary">
                    <Typography className="text-end" variant="h6">
                      CAD
                    </Typography>
                  </Col>
                  <Col lg="3" sm="3" xs="3" className="summary">
                    <Typography className="text-end" variant="h6" component="div">
                      {totalPrice ? (Number(totalPrice) + Number((totalPrice * tax?.total_tax) / 100)).toFixed(2) : (0).toFixed(2)}
                    </Typography>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              {/* <Col lg="6" sm="6"> */}
              <div>
                {/* <RemoveRedEyeIcon className={classes.icon} />
                  <DownloadForOfflineIcon className={classes.icon} />
                  <LocalPrintshopIcon className={classes.icon} /> */}
              </div>
              {/* </Col> */}
              <Col xl="12" lg="12" md="12" sm="12" xs="12" className={classes.button_save}>
                <Link to={ROUTES.generateInvoice}>
                  <DiscardButtonComponet />
                </Link>
                <Button
                  type="button"
                  variant="contained"
                  className={classes.saveEmailButton}
                  onClick={(e) => SaveHandler(e)}
                >
                  {params?.id ? "Save" : "Draft"}
                </Button>
                <SendButtonComponet />
              </Col>
            </Row>
          </CardContent>
        </form>
      </Card>
    </>
  );
};

export default AddNewPlatformLicense;
