import PhoneIcon from '@mui/icons-material/Phone';
export const CommunicationFilterTabs = [
  {
    lable: "All Messages",
    value: "",
  },
  // {
  //   lable: "Received",
  //   value: "1",
  // },
  // {
  //   lable: "Sent",
  //   value: "2",
  // },
  // {
  //   lable: "Failed",
  //   value: "3",
  // },
  {
    lable: "Important",
    value: "4",
  },
  {
    lable: "Archived",
    value: "5",
  },
  {
    lable: "Draft",
    value: "6",
  },
  // {
  //   lable: "Status",
  //   value: "7",
  // },
];

export const SchoolPaymentFilterTabs = [
  {
    lable: "All payments",
    value: "all",
  },
  {
    lable: "Received",
    value: "1",
  },
  {
    lable: "Failed",
    value: "2",
  },
  {
    lable: "Refunded",
    value: "5",
  },
];

export const SchoolFilterTabs = [
  {
    lable: "All school list",
    value: "all",
  },
];

export const ProgramsFilterTabs = [
  {
    lable: "All Programs",
    value: "all",
  },
  // {
  //   lable: "Active Programs",
  //   value: "1",
  // },
  // {
  //   lable: "Inactive Programs",
  //   value: "0",
  // },
];

export const GenerateInvoiceFilterTabs = [
  {
    lable: "All Invoices",
    value: "all",
  },
  {
    lable: "Draft",
    value: true,
  },
];

export const ManageLicenseFilterTabs = [
  {
    lable: "All Licenses",
    value: "all",
  },
];

export const Settings = [
  {
    lable: "Payment Key",
    value: "Payment Key",
  },
];