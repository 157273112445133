import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { DRAWER_LIST } from "../constants/menu";
import MenuItem from "./MenuItem";
import * as Actions from "../reducer-store/actions/communicationAction";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { MESSAGES_REFRESH_INTERVAL } from "../reducer-store/constants";

const useStyles = makeStyles((theme: Theme) => ({
  padding: {
    padding: 0,
  },
  icon: {
    padding: "10px 10px",
  },
}));

const MenuItemsList = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const AuthToken = useSelector((state: any) => state.auth);
  const { user, token } = AuthToken;
  const { UnreadCommunication } = bindActionCreators(Actions, dispatch);
  const [count, setCount] = useState<any>(0);

  function manageActiveMenu(path: string, string: string) {
    if (path === "/school-payment" && string === "/school-payment") {
      return true;
    } else if ((path === "/school" || path.includes("/school/")) && string === "/school") {
      return true;
    } else {
      return string != "/school-payment" && string != "/school" ? path.includes(string) : false;
    }
  }

  useEffect(() => {
    var tempCount = 0;
    (async () => {
      const resData: any = await UnreadCommunication(token);
      resData?.data?.map((data: any) => {
        data?.threads?.map((dataThread: any) => {
          if (!dataThread?.read && dataThread?.sent_by !== user?.id) {
            tempCount++;
          }
        })
      })
      setCount(tempCount);
    })();

    const interval = setInterval(async () => {
      var tempCount = 0;
      const resData: any = await UnreadCommunication(token);
      resData?.data?.map((data: any) => {
        data?.threads?.map((dataThread: any) => {
          if (!dataThread?.read && dataThread?.sent_by !== user?.id) {
            tempCount++;
          }
        })
      })
      setCount(tempCount);
    }, MESSAGES_REFRESH_INTERVAL)
  }, [])

  return (
    <Grid>
      <List className={classes.padding}>
        {/* <IconButton
          color="inherit"
          onClick={() => toggleIsOpened(!isOpened)}
          className={`${classes.icon} drawer-icon`}
        >
          {isOpened ? <ArrowBack /> : <MenuIcon />}
        </IconButton> */}
        {DRAWER_LIST.map(({ literal, route, Icon }) =>
          <MenuItem
            Icon={Icon}
            literal={literal == "Communication" ? `Communication ${count > 0 ? `(${count})` : ""}` : literal}
            route={route}
            key={route}
            selected={route ? manageActiveMenu(pathname, route) : false}
          />
        )}
      </List>
    </Grid>
  );
};

export default MenuItemsList;
