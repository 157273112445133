import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { Clear } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CommonHeadPart from "../../../common-components/CommonHeadPart";
import CommunicationIcon from "../../../svg_icons/communication.svg";
import { ROUTES } from "../../../constants/routes";
import { DiscardButtonComponet, SendButtonComponet, } from "../../../common-components/ButtonComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { InputField, InputSelectField, } from "../../../common-components/FormComponent/FormFieldsComponent";
import { isRequiredField } from "../../../common-components/FormComponent/FormValidator";
import { useSelector } from "react-redux";
import * as Actions from "../../../reducer-store/actions/communicationAction";
import { COMMUNICATION_MESSAGE_SUCCESSFULLY_SAVE, COMMUNICATION_MESSAGE_SUCCESSFULLY_SENT, } from "../../../reducer-store/ToastMessage";
import { toast } from "react-toastify";
import { Link, useHistory, useParams } from "react-router-dom";
import { Autocomplete, Button } from "@mui/material";
import ToastMessage from "../../../common-components/ToastMessage";
import { PAGE, manageIconType } from "../../../reducer-store/constants";
import { renderRequiredError } from "../../../common-components/FormComponent/FormValidator";
import { TextField } from "@material-ui/core";
import FieldError from "../../../common-components/FormComponent/ErrorMessage";
import FolderIcon from "../../../svg_icons/File.png";
import * as CommunicationActions from "../../../reducer-store/actions/communicationAction";
import ImageComponent from "../../../common-components/ImageComponent";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  saveEmailButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #393939 !important",
    color: "#393939 !important",
    padding: "0 35px !important",
    height: "40px !important",
    textTransform: "capitalize",
  },
  CardTitle: {
    backgroundColor: "#646464",
    color: "#ffffff",
  },
  icon: {
    marginRight: "20px",
  },
  button_save: {
    textAlign: "right",
  },
  dialogContent: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const BreadCrumbComposeMessage = [
  {
    labelText: "Home",
    linkUrl: ROUTES.dashboard,
  },
  {
    labelText: "Communication",
    linkUrl: ROUTES.communication,
  },
  {
    labelText: "Compose new message",
    linkUrl: "",
  },
];

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiDialogContent-root": {
//     padding: theme.spacing(2),
//   },
//   "& .MuiDialogActions-root": {
//     padding: theme.spacing(1),
//   },
// }));

// const BootstrapDialogTitle = (props: DialogTitleProps) => {
//   const { children, onClose, ...other } = props;

//   return (
//     <DialogTitle
//       sx={{ m: 0, p: 2 }}
//       style={{ color: "#ffffff", backgroundColor: "#646464" }}
//       {...other}
//     >
//       {children}
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           onClick={() => onClose()}
//           sx={{
//             position: "absolute",
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[100],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </DialogTitle>
//   );
// };

const ComposeMessgeForm: React.FC = () => {
  const classes = useStyles();
  const params: any = useParams();
  const { sendMessage, saveMessage, DropDownCommunicationList } = Actions;
  const { UpdateDraftMessaage } = Actions
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const { GetReplyMessaage, sendDraftMessage } = CommunicationActions;
  const history = useHistory();
  const [composeTo, setComposeTo] = useState("");
  const [subject, setSubject] = useState("");
  const [category, setCategory] = useState("");
  const [message, setMessage] = useState("");
  const [attachmentFile, setAttachmentFile] = useState<any>([]);
  const [attachmentFileName, setAttachmentFileName] = useState<any>([]);
  const [stylingClass, setStylingClass] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const [SchoolList, setSchoolList] = useState<any>([]);
  const isCheckValidEmailValue = false;
  const isCheckValidNoValue = false;
  const isCheckValidPhoneValue = false;
  const isCheckValidRID = false;
  const isCheckValidAddress = false;
  const [imgError, setImgError] = useState(false);
  const [imgErrorMsg, setImgErrorMsg] = useState("");
  const [wrongImage, setWrongImg] = useState(false);
  const [wrongImageError, setWrongImgError] = useState("");
  const [imageData, setImageData] = useState<any>([]);
  const [defaultObj, setDefaultObj] = useState<any>({});
  const typeArray = [
    "image/jpeg",
    "image/jpg",
    "image/png"
  ]

  useEffect(() => {
    (async () => {
      const arr = await DropDownCommunicationList(PAGE, token);
      setSchoolList(arr.data);
    })();
  }, []);

  useEffect(() => {
    if (params?.id?.length > 0) {
      (async () => {
        const lastReplyMessage: any = await GetReplyMessaage(params?.id, token);
        const { data } = lastReplyMessage;
        setComposeTo(data?.School?.email);
        setImageData(data?.threads[0]?.MessageAttachments);
        setMessage(data?.threads[data?.threads?.length - 1]?.body)
        setSubject(data?.threads[data?.threads?.length - 1]?.subject)
        setCategory(data?.threads[data?.threads?.length - 1]?.category)
      })();
    }
  }, []);

  useEffect(() => {
    if (SchoolList?.length > 0) {
      var temp = SchoolList.find((v: any) => v.email === composeTo)
      setDefaultObj(temp);
    }
  }, [SchoolList, defaultObj, composeTo])

  const imageValidationCheck = (fileInfo: any) => {
    let typeImage = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
      "application/msword",
      "text/plain",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",];

    if (fileInfo.every((x: any) => typeImage.some((y: any) => x.type === y))) {
      setImgError(false)
      return true;
    } else {
      setImgError(true)
      setImgErrorMsg("Please upload valid Document (pdf,doc,ppt,jpg,jpeg,excel,png,xlsx,xls,docx,txt,pptx,docx).");
      return false;
    }
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files;

    var imageContainer = [];
    var imageName = [];

    for (var i = 0; i < file.length; i++) {
      var fileUpload: any = file[i];
      if (fileUpload) {
        imageContainer.push(fileUpload);
        imageName.push(fileUpload?.name);
      }
    }

    if (imageValidationCheck(imageContainer)) {
      setAttachmentFile(imageContainer);
      setAttachmentFileName(imageName);
      return;
    }
    setAttachmentFile(imageContainer);
    setAttachmentFileName(imageName);
    return;
  };

  const removeSelectedAttachment = async (id: number) => {
    const newArr = await attachmentFileName?.filter((files: string, index: number) => {
      return id !== index;
    });
    const newFileArr = await attachmentFile?.filter((files: any, index: number) => {
      return id !== index;
    });
    setAttachmentFileName(newArr);
    setAttachmentFile(newFileArr);
    setImgError(false);
    setWrongImg(false);
  };

  const onChangeFile1 = () => {
    setWrongImg(true);
    setWrongImgError("Please Remove Unsupport File First")
  }

  const resetData = () => {
    setComposeTo("");
    setSubject("");
    setCategory("");
    setMessage("");
  };

  const manageCategory = (e: any) => {
    const str = e.target.value;
    const str2 = str.charAt(0).toUpperCase() + str.slice(1);
    setCategory(str);
    setStylingClass(str2);
  };

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      isRequiredField(composeTo) &&
      isRequiredField(category) &&
      isRequiredField(subject) &&
      isRequiredField(message) &&
      (imgError === false)
    ) {
      setIsSubmit(false);
      const formData = new FormData();
      formData.append("category", category);
      formData.append("subject", subject);
      formData.append("body", message);
      formData.append("send_to", composeTo);
      formData.append("fromAdmin", "1");
      {
        attachmentFile.map((filesData: any) => {
          formData.append("files", filesData);
        })
      }
      let resData: any = params?.id ? await sendDraftMessage(formData, token, params?.id) : await sendMessage(formData, token);
      if (!resData?.error) {
        toast.success(COMMUNICATION_MESSAGE_SUCCESSFULLY_SENT);
        setTimeout(() => {
          resetData();
          history.push(ROUTES.communication);
        }, 1500);
        return;
      }
      toast.error(params?.id ? resData?.errorMsg : resData?.errMsg);
      return;
    }
    setIsValidValues(false);
  };

  const SaveHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      isRequiredField(composeTo) &&
      isRequiredField(category) &&
      isRequiredField(subject) &&
      isRequiredField(message)
    ) {
      setIsSubmit(false);
      const formData = new FormData();
      formData.append("category", category);
      formData.append("subject", subject);
      formData.append("body", message);
      formData.append("send_to", composeTo);
      formData.append("fromAdmin", "1");
      if (params?.id) {
        formData.append("status", "6")
      }
      {
        attachmentFile.map((filesData: any) => {
          formData.append("files", filesData);
        })
      }
      let resData: any = params?.id ? await UpdateDraftMessaage(params?.id, token, formData) : await saveMessage(formData, token);
      if (!resData?.data?.error) {
        toast.success(COMMUNICATION_MESSAGE_SUCCESSFULLY_SAVE);
        setTimeout(() => {
          resetData();
          history.push(ROUTES.communication);
        }, 1500);
        return;
      }
      toast.error(resData?.data?.errorMsg);
      return;
    }
    setIsValidValues(false);
  };

  return (
    <>
      <ToastMessage />
      <CommonHeadPart
        componentText="Communication"
        Icon={CommunicationIcon}
        buttonText=""
        urlLink=""
        BreadCrumbs={BreadCrumbComposeMessage}
      />
      <Card>
        <div className={classes.CardTitle}>
          <CardContent>
            <Typography variant="h6" component="div">
              <FontAwesomeIcon icon={faEdit} style={{ marginRight: "5px" }} />
              Compose Message
            </Typography>
          </CardContent>
        </div>
        <CardContent>
          <form onSubmit={SubmitHandler}>
            <Row>
              <Col lg="10" sm="12" className="mb-2">
                <Autocomplete
                  id="size-small-standard"
                  size="small"
                  options={SchoolList}
                  value={defaultObj}
                  onChange={(event: any, newValue: any) => { setDefaultObj(newValue); setComposeTo(newValue ? newValue.email : "") }}
                  getOptionLabel={(option: any) => option?.name || ""}
                  isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="To"
                      placeholder="Enter email/ school id/ school name"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
                {!isValidValues && renderRequiredError(composeTo, isSubmit, "Please select school", isCheckValidEmailValue, isCheckValidNoValue, isCheckValidPhoneValue, isCheckValidRID, isCheckValidAddress)}
              </Col>
              <Col lg="2" sm="12">
                <InputSelectField
                  select={true}
                  lable="Categories"
                  name={category}
                  value={category}
                  className={`form-textfield-styles ${stylingClass}`}
                  options={[
                    { id: "", name: "Select category" },
                    { id: "sales", name: "Sales" },
                    { id: "support", name: "Support" },
                    { id: "account", name: "Account" },
                    { id: "technical", name: "Technical" },
                    { id: "other", name: "Other" },
                  ]}
                  handleChange={(e) => manageCategory(e)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Category field is required"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg="12" sm="12">
                <InputField
                  type="text"
                  lable="Subject"
                  name={subject}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter subject"
                  className="form-textfield-styles"
                  value={subject}
                  handleChange={(e) => setSubject(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Subject field is required"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg="12" sm="12">
                <InputField
                  type="text"
                  lable="Message"
                  name={message}
                  rows={10}
                  multiline={true}
                  placeHolder="Enter message"
                  className="form-textfield-styles"
                  value={message}
                  handleChange={(e) => setMessage(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Message field is required"
                />
              </Col>
            </Row>
            <br />
            <Row className="mt-2">
              <Col xl="6" lg="6" md="12" sm="12" xs="12" >
                <div className="mb-2">
                  <label htmlFor="file" style={{ cursor: 'pointer' }}>
                    <b>Attach File</b> <AttachFileIcon titleAccess="Attach files" className={classes.icon} /><br />
                  </label>
                  <input
                    multiple
                    onChange={imgError === false ? (e) => onChangeFile(e) : () => onChangeFile1()}
                    type="file"
                    id="file"
                    style={{ display: "none" }}
                  />

                  {/* <InsertEmoticonIcon className={classes.icon} />
                  <InsertLinkIcon className={classes.icon} /> */}
                </div>
                {
                  attachmentFileName.map((fileName: string, index: number) => {
                    return <div>
                      {fileName}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => removeSelectedAttachment(index)}
                      >
                        <Clear className={classes.icon} />
                      </span>
                    </div>
                  })
                }
                {
                  imgError ? <FieldError message={imgErrorMsg} /> : null
                }
                {<br />}
                {
                  wrongImage ? <FieldError message={wrongImageError} /> : null
                }
              </Col>
              <Col xl="6" lg="6" md="12" sm="12" xs="12" className={classes.button_save}>
                <Link to={ROUTES.communication}>
                  <DiscardButtonComponet />
                </Link>
                {" "}
                <Button
                  type="button"
                  variant="contained"
                  className={classes.saveEmailButton}
                  onClick={(e) => SaveHandler(e)}
                >
                  {params?.id ? "Save" : "Draft"}
                </Button>{" "}
                <SendButtonComponet />
              </Col>
            </Row>
          </form>
        </CardContent>
      </Card>

      {attachmentFile?.length > 0 ?
        <Card className="p-2">
          {attachmentFile?.map((data: any) => {
            return (
              <img
                alt=''
                loading='eager'
                className="m-1 rounded"
                src={typeArray.includes(data?.type) ? URL.createObjectURL(data) : manageIconType(data?.type)}
                width={typeArray.includes(data?.type) ? 100 : 60}
              />)
          })}
        </Card>
        : null}

      {params && params?.id ?
        <Card className="p-2">
          {imageData.map((data: any) => {
            return (
              <ImageComponent signedUrl={data?.attachment_key} type={data?.attachment_type} fileName={data?.attachment_name} />
            )
          })}
        </Card>
        : null}
    </>
  );
};

export default ComposeMessgeForm;
