import { DrawerItem } from '../ts';
import { ROUTES } from './routes';
import { ReactComponent as DashboardIcon } from "../svg_icons/dashboard.svg";
import { ReactComponent as CommunicationIcon } from "../svg_icons/communication.svg";
import { ReactComponent as UpdatePlanFeeIcon } from "../svg_icons/update_plan_fee.svg";
import { ReactComponent as PlatformIcon } from "../svg_icons/platform.svg";
import { ReactComponent as SchoolPaymentIcon } from "../svg_icons/school_payment.svg";
import { ReactComponent as SchoolIcon } from "../svg_icons/school.svg";
import { ReactComponent as InvoiceIcon } from "../svg_icons/invoice.svg";
import { ReactComponent as LicenceIcon } from "../svg_icons/licence.svg";
import { ReactComponent as ProgramIcon } from "../svg_icons/program.svg";
import { ReactComponent as AddNewAccount } from "../svg_icons/NewAccount.svg";
import { ReactComponent as Tax } from "../svg_icons/tax.svg";
import { ReactComponent as SettingIcon } from "../svg_icons/setting_new.svg";

import PersonAdd from "@mui/icons-material/PersonAdd";

export const DRAWER_LIST: DrawerItem[] = [
  {
    route: ROUTES.dashboard,
    literal: "Dashboard",
    Icon: DashboardIcon,
  },
  {
    route: ROUTES.communication,
    literal: "Communication",
    Icon: CommunicationIcon,
  },
  {
    route: ROUTES.updatePlansFees,
    literal: "Update Plans & Fee's",
    Icon: UpdatePlanFeeIcon,
  },
  // {
  //   route: ROUTES.platformFeatureTogglee,
  //   literal: 'Platform Feature Togglee',
  //   Icon: PlatformIcon,
  // },
  {
    route: ROUTES.schoolPayment,
    literal: "School Payments",
    Icon: SchoolPaymentIcon,
  },
  {
    route: ROUTES.school,
    literal: "School",
    Icon: SchoolIcon,
  },
  {
    route: ROUTES.programs,
    literal: "Programs",
    Icon: ProgramIcon,
  },
  {
    route: ROUTES.generateInvoice,
    literal: "Generate Invoice",
    Icon: InvoiceIcon,
  },
  {
    route: ROUTES.manageLicence,
    literal: "Manage Standard Licence",
    Icon: LicenceIcon,
  },
  {
    route: ROUTES.addnewuser,
    literal: "Add New Admin",
    Icon: AddNewAccount,
  },
  {
    route: ROUTES.tax,
    literal: "Tax",
    Icon: Tax,
  },
  {
    route: ROUTES.settings,
    literal: "Settings",
    Icon: SettingIcon,
  },
];
