import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InnerPageRoutes } from "../../../../../constants/routes";
import { fileSignedURL } from "../../../../../reducer-store/actions/authActions";
// import Grid from '@material-ui/core/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Divider from '@material-ui/core/Divider';
import { Button, Card, Typography } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { Row, Image, Col } from "react-bootstrap";
import moment from "moment";
import { useSelector } from "react-redux";
import * as Actions from "../../../../../reducer-store/actions/communicationAction";
import ImageComponent from "../../../../../common-components/ImageComponent";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles(() => ({
  saveEmailButton: {
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontFamily: 'Arial !important',
    fontSize: '14px !important',
    textTransform: 'capitalize',
  },
  messageArea: {
    // minHeight: '50vh',
  },
  avtar: {
    width: "40px",
    height: "40px",
    color: "#707070",
    backgroundColor: "#BEBEBE",
    borderColor: "#707070",
    borderRadius: 100,
  },
  userName1: {
    paddingLeft: "10px",
    textAlign: "start",
    color: '#000',
  },
  listUser: {
    backgroundColor: "#FFD985",
    color: "#292929",
    borderRadius: 10
  },
  listuser1: {
    backgroundColor: "#84e0b1",
    color: "",
    borderRadius: 10
  },
  divInsideMain: {
    boxShadow: "0 0 5px #8e8e8e",
    borderRadius: "3px",
  },
  itemMsg: {
    marginLeft: "10px !important",
    background: "transparent !important",
    boxShadow: "none !important"
  },
  itemTimeZone: {
    background: "transparent !important",
    boxShadow: "none !important",
  },
  headingChat: {
    display: "flex",
    background: "#FFFFFF",
    color: "#fff",
    alignItems: "center",
    padding: "15px 5px",
  },
  nameLogo: {
    boxShadow: "none !important",
    padding: "15px",
  },
  demo12: {
    boxShadow: "none !important",
    padding: "15px"
  },
  demoColor: {
    backgroundColor: "#84e0b1",
    borderRadius: "5px",
    padding: "10px"
  },
  demoColor2: {
    backgroundColor: "#FFD985",
    borderRadius: "5px",
    padding: "10px"
  },
  demoPlatformHead: {
    paddingLeft: "10px"
  },
  demoLeft: {
    textAlign: "left",
    marginTop: "5px"
  },
  demoRight: {
    textAlign: "right",
    marginTop: "5px"
  },
  schoolAvtar: {
    width: "50%",
    height: "auto",
  }
}));

const ReplyMessage: React.FC<any> = (props: any) => {
  const { allAdminId, messageData, manageShowReply, showReplyData } = props;
  const { id }: any = useParams();
  const classes = useStyles();
  const AuthToken: any = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const { GetReplyMessaage } = Actions;
  const [profilePic, setProfilePic] = useState("");
  const [message, setMessage] = useState<any>(null);
  let lengthOfMessage = messageData.threads.length;

  useEffect(() => {
    (async () => {
      const lastReplyMessage: any = await GetReplyMessaage(messageData && messageData?.id, token);
      const { data } = lastReplyMessage;
      // const allMessages: any = await CommunicationList(1, 1000, token, "", "");
      // const { data: { data } } = allMessages;
      // const fetchSingleThread = data.filter((communicationInfo: any) => {
      // 	if (communicationInfo?.thread_id === parseInt(id))
      // 		return communicationInfo;
      // });
      setMessage(data);
    })();
  }, []);

  useEffect(() => {
    navigateToExternalUrl(messageData ? messageData.schoolLogo : "")
  }, [])

  const navigateToExternalUrl = async (urlName: string, shouldOpenNewTab: boolean = true) => {
    if (urlName) {
      const newData: { url: string, msg: string, error: boolean } = await fileSignedURL(urlName && urlName, token);
      const { url } = newData;
      setProfilePic(url);
    }
  }

  return (
    <>
      <Card className={classes.nameLogo}>
        <Row className="align-items-center">
          <Col md="6">
            <div className="align-items-center" style={{ display: "flex" }}>
              <KeyboardBackspaceIcon style={{ color: '#000' }} fontSize="large" onClick={() => manageShowReply(!showReplyData)} />
              <Image className={classes.avtar} />
              <Typography variant="body1" className={classes.userName1}>
                {messageData.schoolName} / {messageData.subject}
              </Typography>
            </div>
          </Col>
          <Col md="6">
            <Row className="align-items-center" style={{ display: "flex" }}>
              <Col md="9" style={{ textAlign: "left", display: "flex", justifyContent: "flex-end" }}>
                <Typography><b>School Name: {messageData.schoolName}</b><br />School ID: {messageData.id}</Typography>
              </Col>
              <Col md="3" style={{ textAlign: "right" }}>
                <img className={classes.schoolAvtar} src={profilePic} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <Divider />

      <Card className={classes.demo12}>
        <div className={classes.messageArea}>
          {
            message?.threads?.length === 0 ? "Not found" : message?.threads?.map((thread: any, index: number) =>
              <Row key={`${thread.body}_${thread.id}_${index}`}>
                <div>
                  {
                    !allAdminId.includes(thread.sent_by) ? <>
                      <Row>
                        <Col md="6">
                          <div className={classes.demoColor}>
                            <Typography>
                              {thread?.body}
                            </Typography>
                            {thread?.MessageAttachments?.map((data: any) => {
                              return (
                                <ImageComponent signedUrl={data?.attachment_key} type={data?.attachment_type} fileName={data?.attachment_name} />
                              )
                            })}
                          </div>
                        </Col>
                        <Col md="6">
                        </Col>
                        <Col md="12" className={classes.demoLeft}>
                          <Typography>
                            {moment(thread?.sent_timestamp).format("MMM DD, YYYY | h:mm a")}
                          </Typography>
                        </Col>
                      </Row>
                    </> : <>
                      <Row>
                        <Col md="6">

                        </Col>
                        <Col md="6">
                          <div className={classes.demoPlatformHead}>
                            <Typography>
                              Driving School Admin
                            </Typography>
                          </div>
                          <div className={classes.demoColor2}>
                            <Typography>
                              {thread?.body}
                            </Typography>
                            {thread?.MessageAttachments?.map((data: any) => {
                              return (
                                <ImageComponent signedUrl={data?.attachment_key} type={data?.attachment_type} fileName={data?.attachment_name} />
                              )
                            })}
                          </div>
                        </Col>
                        <Col md="12" className={classes.demoRight}>
                          <Typography>
                            {moment(thread?.sent_timestamp).format("MMM DD, YYYY | h:mm a")}
                          </Typography>
                        </Col>
                      </Row>
                    </>
                  }
                </div>
              </Row>
            )
          }
        </div>
        <Divider />
        <Row style={{ float: "right" }} className="mt-4" >
          <Link to={`${InnerPageRoutes.messageReply}/${messageData.threads[lengthOfMessage - 1].id}`}>
            <Button
              type="button"
              variant="contained"
              className={classes.saveEmailButton}
            >
              Reply
            </Button>
          </Link>
        </Row>
      </Card>


    </>
  )
}

export default ReplyMessage;



