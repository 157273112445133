import TextField from "@material-ui/core/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import {
  FormInputDatePickerProps,
  FormInputFieldProps,
  FormInputPasswordFieldProps,
  FormInputSelectFieldProps,
  FormInputSelectFieldPropsEle,
} from "../../ts/FormType";
import { renderRequiredError } from "./FormValidator";
import { KeyboardDatePicker } from "@material-ui/pickers";

export const InputField: React.FC<FormInputFieldProps> = ({
  name,
  type,
  rows,
  multiline,
  className,
  value,
  lable,
  placeHolder,
  handleChange,
  isValidValue,
  isSubmit,
  requiredMessage,
  max,
  min,
  disabled = false,
  isCheckValidEmailValue = false,
  isCheckValidNoValue = false,
  isCheckValidPhoneValue = false,
  isCheckValidRID = false,
  isCheckValidAddress = false,
  disablePrevious = "",
}) => {
  return (
    <div className={className}>
      <TextField
        InputLabelProps={{ shrink: true }}
        fullWidth
        minRows={rows}
        multiline={multiline}
        type={type}
        label={lable}
        name={name}
        value={value}
        placeholder={placeHolder}
        onChange={handleChange}
        disabled={disabled}
        inputProps={{
          maxLength: max,
          minLength: min,
          min: disablePrevious !== "" ? disablePrevious : "",
          step: "any"
        }}
      />
      {!isValidValue && renderRequiredError(value, isSubmit, requiredMessage, isCheckValidEmailValue, isCheckValidNoValue, isCheckValidPhoneValue,
        isCheckValidRID, isCheckValidAddress)}
    </div>
  );
};

export const InputPasswordField: React.FC<FormInputPasswordFieldProps> = ({
  name,
  type,
  rows,
  multiline,
  className,
  value,
  lable,
  placeHolder,
  handleChange,
  isValidValue,
  isSubmit,
  requiredMessage,
  clickToShowPassowrd,
  hoverToPassword,
  showPassword,
  isCheckValidEmailValue = false,
  isCheckValidNoValue = false,
  isCheckValidPhoneValue = false,
  isCheckValidRID = false,
  isCheckValidAddress = false
}) => {
  return (
    <div className={className}>
      <TextField
        InputLabelProps={{ shrink: true }}
        fullWidth
        minRows={rows}
        multiline={multiline}
        type={showPassword ? "text" : type}
        label={lable}
        name={name}
        value={value}
        placeholder={placeHolder}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={clickToShowPassowrd}
                onMouseDown={hoverToPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      // disabled={disabled}
      />
      {!isValidValue && renderRequiredError(value, isSubmit, requiredMessage, isCheckValidEmailValue, isCheckValidNoValue, isCheckValidPhoneValue,
        isCheckValidRID, isCheckValidAddress)}
    </div>
  );
};

export const InputSelectField: React.FC<FormInputSelectFieldProps> = ({
  select,
  lable,
  name,
  className,
  value,
  options,
  handleChange,
  isValidValue,
  isSubmit,
  requiredMessage,
  disabled = false,
  isCheckValidEmailValue = false,
  isCheckValidNoValue = false,
  isCheckValidPhoneValue = false,
  isCheckValidRID = false,
  isCheckValidAddress = false
}) => {
  return (
    <div className={className}>
      <TextField
        InputLabelProps={{ shrink: true }}
        fullWidth
        select={select}
        SelectProps={{ native: true }}
        label={lable}
        name={name}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      >
        {options.map((option) => (
          <option className={option.name} key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </TextField>
      {!isValidValue && renderRequiredError(value, isSubmit, requiredMessage, isCheckValidEmailValue, isCheckValidNoValue, isCheckValidPhoneValue,
        isCheckValidRID, isCheckValidAddress)}
    </div>
  );
};

export const InputSelectFieldEle: React.FC<FormInputSelectFieldPropsEle> = ({
  select,
  lable,
  name,
  className,
  value,
  options,
  handleChange,
  isValidValue,
  isSubmit,
  requiredMessage,
  disabled = false,
  isCheckValidEmailValue = false,
  isCheckValidNoValue = false,
  isCheckValidPhoneValue = false,
  isCheckValidRID = false,
  isCheckValidAddress = false
}) => {
  return (
    <div className={className}>
      <TextField
        InputLabelProps={{ shrink: true }}
        fullWidth
        select={select}
        SelectProps={{ native: true }}
        label={lable}
        name={name}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      >
        {options.map((option) => (
          <option
            className={option.styleClass}
            key={option.id}
            value={option.id}
          >
            {option.name}
          </option>
        ))}
      </TextField>
      {!isValidValue && renderRequiredError(value, isSubmit, requiredMessage, isCheckValidEmailValue, isCheckValidNoValue, isCheckValidPhoneValue,
        isCheckValidRID, isCheckValidAddress)}
    </div>
  );
};

export const InputDatePicker: React.FC<FormInputDatePickerProps> = ({
  className,
  value,
  lable,
  handleChange,
  isValidValue,
  isSubmit,
  requiredMessage,
  disabled = false,
  isCheckValidEmailValue = false,
  isCheckValidNoValue = false,
  isCheckValidPhoneValue = false,
  isCheckValidRID = false,
  isCheckValidAddress = false,
  isCheckValidIPAddress = false,
  isCheckValidPassword = false,
  isCheckString = false,
  min,
}) => {
  return (
    <div className={className}>
      <Grid container>
        <KeyboardDatePicker
          InputLabelProps={{ shrink: true }}
          fullWidth
          variant="inline"
          openTo="date"
          views={["year", "month", "date"]}
          format="YYYY-MM-DD"
          label={lable}
          className="form-textfield-styles errorBottom"
          value={value}
          onChange={handleChange}
          placeholder="YYYY-MM-DD"
          minDate={min}
          disabled={disabled}
          KeyboardButtonProps={{ "aria-label": "change date" }}
        />
        {!isValidValue && renderRequiredError(value, isSubmit, requiredMessage, isCheckValidEmailValue, isCheckValidNoValue, isCheckValidPhoneValue, isCheckValidRID, isCheckValidAddress)}
      </Grid>
    </div>
  );
};
