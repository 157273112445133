import axios from "axios";
import { toast } from "react-toastify";
import { LIST_OF_LICENSE, LIST_OF_LICENSE_ERROR, SINGLE_LICENSE_ERROR, SINGLE_LICENSE_INFO } from "../ActionTypes";
import { LICENSE_LIST_API, PAGE, LIMIT, DELETE_LICENSE_API, ADD_LICENSE_API, SINGLE_LICENSE_API, UPDATE_LICENSE_API } from "../constants";

const token = sessionStorage.getItem("token");
const header = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization",
  },
};

type AddLicenseProps = {
  name: string;
  start_timestamp: string;
  description: string;
};

export const LicenseList = (
  page = PAGE,
  limit = LIMIT,
  token: string,
  order: string,
  search: string,
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${LICENSE_LIST_API}?page=${page}&limit=${limit}&orderBy=${order}&search=${search}`, header)
      .then((res) => {
        dispatch({
          type: LIST_OF_LICENSE,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_LICENSE_ERROR,
        });
        toast.error(error?.response?.data?.errorMsg);
      });
  };
};

export const addLicence = async (userObj: AddLicenseProps) => {
  const body = JSON.stringify(userObj);
  return await axios
    .post(ADD_LICENSE_API, body, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
      return error.response.data;
    });
};

export const deleteLicence = async (
  id = "",
  token: string
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .delete(
      `${DELETE_LICENSE_API}/${id}`,
      header
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
      return error.response.data;
    });
};

export const SingleLicenseData = (id = "", token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${SINGLE_LICENSE_API}/${id}`, header)
      .then((res) => {
        dispatch({
          type: SINGLE_LICENSE_INFO,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: SINGLE_LICENSE_ERROR,
        });
        toast.error(error.response.data.errorMsg);
        return error.response;
      });
  };
};

export const updateLicence = async (id = "", userObj: AddLicenseProps) => {
  const body = JSON.stringify(userObj);
  return await axios
    .put(`${UPDATE_LICENSE_API}/${id}`, body, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
      return error.response.data;
    });
};