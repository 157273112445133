import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CommonHeadPart from "../../../../common-components/CommonHeadPart";
import Box from "@material-ui/core/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ROUTES } from "../../../../constants/routes";
import SchoolIcon from "../../../../svg_icons/school.svg";
import Icon from "../../../../svg_icons/warn.png";
import "../../../Common.css";
import Profile from "./Profile/Profile";
import Switch from "@mui/material/Switch";
import Payments from "./Payment/Payment";
import Communication from "./Communication/Communicatin";
import * as Actions from "../../../../reducer-store/actions/schoolActions";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import SchoolOnBoarding from './SchoolOnBording/BordingSchool';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import { Tooltip, Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import { SCHOOL_DISABLED, SCHOOL_ENABLED } from "../../../../reducer-store/messages/actionMessages";
import { UPDATE_SCHOOL_API } from "../../../../reducer-store/constants";
import axios from "axios";
import { RiErrorWarningFill } from "react-icons/ri";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PlanDetails from "./PlanHistory/PlanDetails";
import ToastMessage from "../../../../common-components/ToastMessage";

const useStyles = makeStyles(() => ({
  tabFont: {
    fontFamily: 'Arial !important',
    textTransform: 'capitalize'
  },
  tabPanelStyle: {
    padding: "0 !important",
  },
  boxBorder: {
    boxShadow: "0px 0px 5px #8e8e8e",
    borderRadius: "5px",
  },
  divStyling: {
    padding: "15px 20px",
  },
  switchRight: {
    textAlign: "end",
  },
  dialogContent: {
    textAlign: "center",
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: "center"
  },
  dialogAction: {
    justifyContent: "center !important",
  },
  icon: {
    width: '30%',
    height: 'auto',
    color: "#000",
  },
  iconHover: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
    alignItems: "center !important",
    '&:hover': {
      cursor: "pointer"
    },
  },
  backText: {
    fontSize: "18px !important",
  },
  yesButton: {
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontFamily: 'Arial !important',
    fontSize: '15px',
    textTransform: 'capitalize'
  },
  noButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #000000 !important",
    color: "#000000 !important",
    padding: "0 35px !important",
    height: "40px !important",
    textTransform: 'capitalize',
    fontSize: '15px',
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DetailPage: React.FC = (props) => {
  const queryPara: any = useParams();
  const history = useHistory();
  const classes = useStyles();
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const [value, setValue] = React.useState("1");
  const [profileData, setProfileData] = React.useState<any>(null);
  const [schoolStatus, setSchoolStatus] = React.useState<any>("");
  const [open, setOpen] = React.useState(false);
  const [schoolId, setSchoolId] = useState("");
  const dispatch = useDispatch();
  const { SingleSchoolData } = bindActionCreators(Actions, dispatch);
  const { updateSchool } = Actions;

  const BreadCrumbSchool = [
    {
      labelText: "Home",
      linkUrl: ROUTES.home,
    },
    {
      labelText: "School",
      linkUrl: ROUTES.school
    },
    {
      labelText: `${history.location.state}`,
      linkUrl: ROUTES.school
    },
    {
      labelText: `${value === "1" ? "Profile" : value === "2" ? "Communication" : value === "3" ? "Payments" : value === "4" ? "Onboarding" : value === "5" ? "Plan Details" : "School"}`,
      linkUrl: "",
    },
  ];

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;
    const [name, setname] = useState("");

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} style={{ color: "#ffffff", backgroundColor: "#404040" }} {...other}>
        {children}
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  useEffect(() => {
    (async () => {
      setSchoolId(queryPara.id);
      const schoolInfo: any = await SingleSchoolData(schoolId, token);
      setProfileData(schoolInfo.data.data);
      setSchoolStatus(schoolInfo.data.data ? schoolInfo.data.data.status : "");
    })();
  }, [schoolStatus, open]);

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  const handleBack = () => {
    history.push(ROUTES.school);
  };

  const handleClickOpen = async () => {
    // setID(id)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDisable = async (id: string) => {
    if (schoolStatus) {
      let obj = {
        status: 0
      };
      const resData: any = await updateSchool(id, obj);
      if (!resData.error) {
        toast.success(SCHOOL_DISABLED);
        setTimeout(() => {
          setOpen(false);
        }, 2000);
      }
      return;
    }
    else {
      let obj = {
        status: 1
      };
      const resData: any = await updateSchool(id, obj);
      if (!resData.error) {
        toast.success(SCHOOL_ENABLED);
        setTimeout(() => {
          setOpen(false);
        }, 2000);
      }
      return;
    }
  }

  return (
    <>
      <ToastMessage />
      <CommonHeadPart
        componentText="School"
        Icon={SchoolIcon}
        buttonText=""
        BreadCrumbs={BreadCrumbSchool}
        urlLink=""
      />
      <div className={classes.iconHover} onClick={() => handleBack()}>
        <ArrowBackIosIcon fontSize="small" />
        <Typography className={classes.backText}>Back</Typography>
      </div>

      <Box className={classes.boxBorder}>
        <div className={classes.divStyling}>
          <TabContext value={value}>
            <Row>
              <Col lg="10" sm="12">
                <TabList
                  textColor="inherit"
                  TabIndicatorProps={{ style: { backgroundColor: '#000000' } }}
                  value={value}
                  onChange={(event, newValue) => handleChange(newValue)}
                  aria-label="Other options"
                  sx={{
                    "& button": {
                      font: 'normal normal normal 15px/17px Arial',
                      letterSpacing: '0.06px',
                      color: '#3A3A3A', textTransform: 'capitalize', marginRight: '10px', padding: '0px', minHeight: '48px'
                    },
                    // "& button:active": { color: '#967faf', fontWeight: 'bold', textTransform: 'capitalize', marginRight: '40px', padding: '0px', fontFamily: "Public Sans ,sans-serif", fontSize: '0.875rem' },
                    "& button.Mui-selected": {
                      font: 'normal normal bold 15px/17px Arial',
                      letterSpacing: '0.06px',
                      color: '#3A3A3A', textTransform: 'capitalize', marginRight: '10px', padding: '0px',
                    }
                  }}
                >
                  <Tab className={classes.tabFont} label="Profile" value="1" />
                  <Tab className={classes.tabFont} label="Communication" value="2" />
                  <Tab className={classes.tabFont} label="Payments" value="3" />
                  <Tab className={classes.tabFont} label="Onboarding" value="4" />
                  <Tab className={classes.tabFont} label="Plan Details" value="5" />
                </TabList>
              </Col>
              <Col lg="2" sm="12" className={classes.switchRight}>
                <div>
                  {value != "4" ?
                    <Tooltip title={schoolStatus ? "Disable School" : "Enable School"}>
                      <Switch
                        color={schoolStatus ? "success" : "default"}
                        checked={schoolStatus ? true : false}
                        onClick={() => handleClickOpen()}
                      />
                    </Tooltip>
                    : null}
                </div>

              </Col>
            </Row>
            <hr />
            <TabPanel value="1" className={classes.tabPanelStyle}>
              <Profile profileInfo={profileData} />
            </TabPanel>
            <TabPanel value="2" className={classes.tabPanelStyle}>
              <Communication />
            </TabPanel>
            <TabPanel value="3" className={classes.tabPanelStyle}>
              <Payments paymentInfo={profileData} />
            </TabPanel>
            <TabPanel value="4">
              <SchoolOnBoarding onBoarding={profileData} />
            </TabPanel>
            <TabPanel value="5">
              <PlanDetails onBoarding={profileData} />
            </TabPanel>
          </TabContext>
        </div>
      </Box>

      <BootstrapDialog
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        open={open}
      >

        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          School Enable/Disable
        </BootstrapDialogTitle>

        <DialogContent dividers className={classes.dialogContent} >
          <RiErrorWarningFill color="#FDB924" size={30} style={{ marginRight: 10 }} />
          <Typography variant="h6" component="div">
            Are you sure you want to {schoolStatus ? 'disable' : 'enable'} this school?
          </Typography>

        </DialogContent>
        <DialogActions className={classes.dialogAction} >
          <Button
            type="button"
            variant="contained"
            className={classes.noButton}
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            type="button"
            variant="contained"
            className={classes.yesButton}
            onClick={() => handleDisable(schoolId)}
          >
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default DetailPage;
