import axios from "axios";
import { toast } from "react-toastify";
import {
  COUNT_OF_UNREAD_COMMUNICATION,
  COUNT_OF_UNREAD_COMMUNICATION_ERROR,
  LIST_OF_COMMUNICATION,
  LIST_OF_COMMUNICATION_ERROR,
} from "../ActionTypes";
import {
  LIMIT,
  PAGE,
  SEND_MESSAGE,
  SAVE_MESSAGE,
  COMMUNICATION_LIST_API,
  SCHOOL_LIST_API,
  REPLY_MESSAGE,
  DELETE_MESSAGE_PERMENANT,
  READ_MESSAGE,
} from "../constants";

const token = sessionStorage.getItem("token");
const header = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization",
  },
};

const headers = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization",
  },
};

export const sendMessage = async (userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .post(SEND_MESSAGE, userObj, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
};

export const sendDraftMessage = async (userObj: any, token: string, id: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .post(`${SEND_MESSAGE}/${id}`, userObj, header)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error?.response?.data;
    });
};

export const saveMessage = async (userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .post(SAVE_MESSAGE, userObj, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
};

export const sendReplyMessage = async (id: string, userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .post(`${REPLY_MESSAGE}/${id}`, userObj, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
};

export const DropDownCommunicationList = async (
  page = PAGE,
  token: string,
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .get(
      `${SCHOOL_LIST_API}?page=${page}`,
      header
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
    });
};

export const GetReplyMessaage = async (
  id: string | number,
  token: string,
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .get(`${COMMUNICATION_LIST_API}/${id}`, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
};

export const UpdateDraftMessaage = async (
  id: string | number,
  token: string,
  obj: any,
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .put(`${COMMUNICATION_LIST_API}/${id}`, obj, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
};

export const CommunicationList = (
  page = PAGE,
  limit = LIMIT,
  token: string,
  order: string,
  status = "",
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${COMMUNICATION_LIST_API}?page=${page}&limit=${limit}&orderBy=${order}&status=${status}`, header)
      .then((res) => {
        dispatch({
          type: LIST_OF_COMMUNICATION,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_COMMUNICATION_ERROR,
        });
        return error?.response;
      });
  };
};

export const CommunicationListTagsAdded = (
  page = PAGE,
  limit = LIMIT,
  token: string,
  order: string,
  status = "",
  userId: any,
  archive: boolean
) => {
  header.headers.Authorization = `Bearer ${token}`;
  // ${status !== "6" || status == "4" ? `&tags=${userId}` : ""}
  return async (dispatch: any) => {
    return await axios
      .get(`${COMMUNICATION_LIST_API}?page=${page}&limit=${limit}&orderBy=${order}${status == "5" ? `&archive=${archive}` : `&status=${status}`}${status == "4" ? `&tags=${userId}` : archive && status !== "6" ? `&archived_by=${userId}` : ""}`, header)
      .then((res) => {
        dispatch({
          type: LIST_OF_COMMUNICATION,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_COMMUNICATION_ERROR,
        });
        return error?.response;
      });
  };
};

export const UnreadCommunication = (
  // page = PAGE,
  // limit = LIMIT,
  token: string,
  // order: string,
  // status = "",
  // search = '',
  // schoolId = ""
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${COMMUNICATION_LIST_API}?read=${false}`, header)
      .then((res) => {
        dispatch({
          type: COUNT_OF_UNREAD_COMMUNICATION,
          payload: res?.data,
        });
        return res?.data;
      })
      .catch((error) => {
        dispatch({
          type: COUNT_OF_UNREAD_COMMUNICATION_ERROR,
        });
        toast.error(error?.response);
      });
  };
};

export const updateMessage = async (url: string, data: any, token: string) => {
  headers.headers.Authorization = `Bearer ${token}`;
  const body = JSON.stringify(data);
  return await axios
    .patch(url, body, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
};

export const deletePermenant = async (id: string, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .delete(`${DELETE_MESSAGE_PERMENANT}/${id}`, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
};

export const readMessage = async (id: string, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .patch(`${READ_MESSAGE}/${id}`, {}, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
};