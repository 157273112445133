import { ActionProps } from "../../ts";
import { LIST_OF_INVOICE, LIST_OF_INVOICE_ERROR,SINGLE_INVOICE_INFO,SINGLE_INVOICE_ERROR } from "../ActionTypes";

const initialState = { invoices: null };

const invoiceStore = (state: any = initialState, action: ActionProps) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_OF_INVOICE: {
      return {
        ...state,
        invoices: payload,
        invoice_data: null,
      };
    }
    case LIST_OF_INVOICE_ERROR: {
      return {
        ...state,
        invoices: null,
        invoice_data: null,
      };
    }
    case SINGLE_INVOICE_INFO: {
      return {
        ...state,
        invoice_data: payload,
      };
    }
    case SINGLE_INVOICE_ERROR: {
      return {
        ...state,
        invoice_data: null,
      };
    }
    default:
      return state;
  }
};

export default invoiceStore;
