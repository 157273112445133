import { combineReducers } from "redux";
import AuthStore from "./authStore";
import schoolStore from "./schoolStore";
import planStore from "./planStore";
import programStore from "./programStore";
import modualStore from "./modualStore";
import courseStore from "./courseStore";
import topicStore from "./topicStore";
import schoolPaymentStore from "./schoolPaymentStore";
import communicationStore from "./communicationStore";
import licenseStore from "./licenseStore";
import invoiceStore from "./invoiceStore";
import dashboardStore from "./dashboardStore";
import notificationStore from "./notificationStore";
import taxStore from "./taxStore";

export default combineReducers({
  auth: AuthStore,
  school: schoolStore,
  plan: planStore,
  program: programStore,
  modual: modualStore,
  course: courseStore,
  topic: topicStore,
  payment: schoolPaymentStore,
  communication: communicationStore,
  license: licenseStore,
  invoice: invoiceStore,
  dashboard: dashboardStore,
  notification: notificationStore,
  tax: taxStore,
});
