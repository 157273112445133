import axios from "axios";
import { toast } from "react-toastify";
import { LIST_OF_INVOICE, LIST_OF_INVOICE_ERROR, SINGLE_INVOICE_INFO, SINGLE_INVOICE_ERROR } from "../ActionTypes";
import { INVOICE_LIST_API, INVOICE_DELETE_API, SAVE_INVOICE, SINGLE_INVOICE_API, SEND_INVOICE, PAGE, LIMIT, SCHOOL_LIST_API, PLAN_LIST_API, SEND_CUSTOM_INVOICE, DRAFT_INVOICE } from "../constants";

const token = sessionStorage.getItem("token");
const header = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization",
  },
};

// type AddInvoiceProps = {
//   school_name?: string;
//   owner_name?: string;
//   phone?: string;
//   mobile?: string;
//   email?: string;
//   invoice_email?: string;
//   plan_name?: string;
//   template?: string;
//   description: string;
//   created_by: string;
//   from_date: string;
//   to_date: string;
//   plan_id: string;
//   school_id: string;
//   addOns: {
//     name: string;
//     price: string;
//   }[];
// };

type AddInvoiceProps = {
  school_id: string;
  // from_date: string;
  // to_date: string;
  addons: {
    data: any;
  };
};

export const InvoiceList = (
  status: any,
  page = PAGE,
  limit = LIMIT,
  token: string,
  order: string,
  search: string,
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${INVOICE_LIST_API}?page=${page}&limit=${limit}&orderBy=${order}&search=${search}${status === true ? `&saved=${true}` : ""}`, header)
      .then((res) => {
        dispatch({
          type: LIST_OF_INVOICE,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_INVOICE_ERROR,
        });
        toast.error(error?.response?.data?.errorMsg);
        return error?.response;
      });
  };
};

export const saveInvoice = async (userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .post(DRAFT_INVOICE, userObj, header)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response;
    });
};

export const sendCustomInvoice = async (userObj: AddInvoiceProps, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  // const body = JSON.stringify(userObj);
  return await axios
    .post(SEND_CUSTOM_INVOICE, userObj, header)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response;
    });
};

export const sendDraftInvoice = async (id: string, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .post(`${SEND_INVOICE}/${id}`, {}, header)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response;
    });
};

export const deleteInvoice = async (
  id = "",
  token: string
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .delete(`${INVOICE_DELETE_API}/${id}`, header)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
      return error.response;
    });
};

export const SingleInvoiceData = async (id = "", token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  // return async (dispatch: any) => {
  return await axios
    .get(`${SINGLE_INVOICE_API}/${id}`, header)
    .then((res) => {
      // dispatch({
      //   type: SINGLE_INVOICE_INFO,
      //   payload: res.data,
      // });
      return res.data;
    })
    .catch((error) => {
      // dispatch({
      //   type: SINGLE_INVOICE_ERROR,
      // });
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
  // };
};

export const UpdateDraftInvoice = async (id = "", userObj: AddInvoiceProps, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .patch(`${SINGLE_INVOICE_API}/${id}`, userObj, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
};

export const DropDownSchoolList = async (
  page = PAGE,
  token: string
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .get(`${SCHOOL_LIST_API}?page=${page}`, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
    });
};

export const DropDownPlanList = async (
  page = PAGE,
  token: string
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .get(`${PLAN_LIST_API}?page=${page}`, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
    });
};

