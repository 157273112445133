import React, { useEffect, useState } from "react";
import { Box, TextField, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CommonHeadPart from "../../common-components/CommonHeadPart";
import FilterTabs from "../../common-components/FilterTabs";
import InvoiceIcon from "../../svg_icons/invoice.svg";
// import PaginationComponent from "../../common-components/PaginationComponent";
import { InnerPageRoutes, ROUTES } from "../../constants/routes";
import { GenerateInvoiceFilterTabs } from "../../constants/tabs";
import { BsFillArrowDownSquareFill, BsFillTrashFill, BsFillEyeFill } from "react-icons/bs";

import "../Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import * as Actions from "../../reducer-store/actions/invoiceAction";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { PAGE } from "../../reducer-store/constants";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "react-bootstrap";
import { Typography } from "@material-ui/core";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { INVOICE_SUCCESSFULLY_DELETED } from "../../reducer-store/ToastMessage";
import ToastMessage from "../../common-components/ToastMessage";
import { fileSignedURL } from "../../reducer-store/actions/authActions";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import { RiEyeFill } from "react-icons/ri";
import { SearchOutlined } from "@material-ui/icons";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import Html from 'react-pdf-html';
import { PDFDownloadLink, Page, Document, PDFViewer } from '@react-pdf/renderer';
import ReactDOMServer from 'react-dom/server';
import Logo from "../../svg_icons/logo/dsc-logo1.png";

const useStyles = makeStyles((theme) => ({
  divInsideMain: {
    boxShadow: "0 0 5px #8e8e8e",
    borderRadius: "3px",
  },
  iconSpace: {
    marginRight: "20px !important"
  },
  dialogContent: {
    textAlign: "center",
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: "center",
  },
  dialogAction: {
    justifyContent: "center !important",
  },
  icon: {
    width: '30%',
    height: 'auto',
    color: "#000",
  },
  searchBar: {
    top: 0,
    borderColor: "black",
    "& hover": {
      borderColor: "black"
    },
    "& focused": {
      borderColor: "black"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black"
    }
  },
  yesButton: {
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontFamily: 'Arial !important',
    fontSize: '15px',
    textTransform: 'capitalize'
  },
  noButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #000000 !important",
    color: "#000000 !important",
    padding: "0 35px !important",
    height: "40px !important",
    textTransform: 'capitalize',
    fontSize: '15px',
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  draftInvoice: {
    cursor: 'default',
  }
}));

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .css-1ygcj2i-MuiTableCell-root": {
            fontweight: "bold",
            backgroundColor: '#FFFFFF'
          },
          "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
            display: "-webkit-box"
          },
          "& .tss-1fbujeu-MUIDataTableHeadCell-toolButton": {
            backgroundColor: '#FFFFFF'
          },
          "& .css-14e01q-MuiTableCell-root": {
            borderBottomColor: '#000',
            borderBottomWidth: 'thin',
          },
        },
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          lineHeight: "1.30 !important"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
          fontSize: "15px",
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
          fontSize: "15px",
        },
        head: {
          backgroundColor: "#FFFFFF"
        },
        footer: {
          backgroundColor: "#FFFFFF"
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#E53D12',
          borderRadius: '4px',
          color: 'white',
          marginRight: '25px',
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: "#F4F4F4"
          },
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& .MuiTablePagination-displayedRows": {
            marginTop: "9%"
          },
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          fontFamily: "Arial",
          fontSize: "15px",
        }
      }
    }
  }
});

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const ref = React.createRef();

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} style={{ color: "#ffffff", backgroundColor: "#646464" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[100],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const GenerateInvoice: React.FC = () => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [page, setPage] = useState(PAGE);
  const [order, setOrder] = useState("desc");
  const [filter, setFilters] = useState("all");
  const dispatch = useDispatch();
  const { InvoiceList } = bindActionCreators(Actions, dispatch);
  const { deleteInvoice } = Actions
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const invoiceList = useSelector((state: any) => state.invoice);
  const { invoices } = invoiceList;
  const [open, setOpen] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [id, setID] = React.useState('');
  const [search, setSearch] = useState('');
  const [filterVal, setFilterVal] = useState('');
  const [searchApiData, setSearchApiData] = useState([]);
  const [data, setData] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [searchData, setSearchData] = useState(false);
  const [rowData, setRowData] = useState<any>({});

  const BreadCrumbInvoice = [
    {
      labelText: "Home",
      linkUrl: ROUTES.home,
    },
    {
      labelText: "Generate Invoice",
      linkUrl: ROUTES.generateInvoice,
    },
    {
      labelText: `${filter === "0" ? "Saved" : "All invoice"}`,
      linkUrl: "",
    },
  ];

  const handleFilter = (newValue: string) => {
    setFilters(newValue);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const HandleFilter = (e: any) => {
    if (e.target.value == "") {
      setData(searchApiData);
    } else {
      const filterData = searchApiData.filter((items: any) =>
        items.id.toString().includes(e.target.value.toString())
        || items.school_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items.school_id.toString().includes(e.target.value.toString())
        || items.registration_date.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items.status.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || (items.amount_paid.props.children[0] + items.amount_paid.props.children[2]).toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        || items.ref_id.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
      );
      if (filterData.length > 0) {
        setData(filterData);
        setSearchData(false);
      } else {
        setData([]);
        setSearchData(true);
      }
    }
    setFilterVal(e.target.value);
  };

  const columns = [
    {
      label: 'Sr No',
      name: "id",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link
            className={tableMeta.rowData[4] === "Sent" ? classes.draftInvoice : ""}
            to={{ pathname: tableMeta.rowData[4] === "Draft" ? `${InnerPageRoutes.sendDraftInvoice}/${tableMeta.rowData[0]}` : "" }}>
            {tableMeta.rowIndex + 1}
          </Link>
        )
      }
    },
    {
      label: 'School Name',
      name: "school_name",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link
            className={tableMeta.rowData[4] === "Sent" ? classes.draftInvoice : ""}
            to={{ pathname: tableMeta.rowData[4] === "Draft" ? `${InnerPageRoutes.sendDraftInvoice}/${tableMeta.rowData[0]}` : "" }}>
            {value}
          </Link>
        )
      }
    },
    {
      label: 'School ID',
      name: "school_id",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link
            className={tableMeta.rowData[4] === "Sent" ? classes.draftInvoice : ""}
            to={{ pathname: tableMeta.rowData[4] === "Draft" ? `${InnerPageRoutes.sendDraftInvoice}/${tableMeta.rowData[0]}` : "" }}>
            {value}
          </Link>
        )
      }
    },
    {
      label: "Registration Date",
      name: "registration_date",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link
            className={tableMeta.rowData[4] === "Sent" ? classes.draftInvoice : ""}
            to={{ pathname: tableMeta.rowData[4] === "Draft" ? `${InnerPageRoutes.sendDraftInvoice}/${tableMeta.rowData[0]}` : "" }}>
            {value}
          </Link>
        )
      }
    },
    {
      label: 'Status',
      name: "status",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link
            className={tableMeta.rowData[4] === "Sent" ? classes.draftInvoice : ""}
            to={{ pathname: tableMeta.rowData[4] === "Draft" ? `${InnerPageRoutes.sendDraftInvoice}/${tableMeta.rowData[0]}` : "" }}>
            {value}
          </Link>
        )
      }
    },
    {
      label: 'Amount Paid',
      name: "amount_paid",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link
            className={tableMeta.rowData[4] === "Sent" ? classes.draftInvoice : ""}
            to={{ pathname: tableMeta.rowData[4] === "Draft" ? `${InnerPageRoutes.sendDraftInvoice}/${tableMeta.rowData[0]}` : "" }}>
            {value}
          </Link>
        )
      }
    },
    {
      label: 'Ref ID',
      name: "ref_id",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link
            className={tableMeta.rowData[4] === "Sent" ? classes.draftInvoice : ""}
            to={{ pathname: tableMeta.rowData[4] === "Draft" ? `${InnerPageRoutes.sendDraftInvoice}/${tableMeta.rowData[0]}` : "" }}>
            {value}
          </Link>
        )
      }
    },
    {
      label: 'Action',
      name: "action",
      options: {
        sort: false
      }
    },

  ];

  const options = {
    responsive: "vertical",
    search: false,
    print: false,
    viewColumns: false,
    filter: false,
    download: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    selectableRows: "none",
    rowsPerPageOptions: [],
    fixedHeader: false,
    textLabels: {
      body: {
        noMatch: searchData || data?.length == 0 && invoices?.data?.length == 0 ?
          'Sorry, there is no matching data to display' :
          <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
            <CircularProgress sx={{ color: "#707070" }} />
          </Box >
        ,
      },
    },
    customToolbar: () => {
      return (<>
        <TextField
          variant="standard"
          placeholder="Search"
          onInput={(e: any) => HandleFilter(e)}
          value={filterVal}
          className={classes.searchBar}
          InputProps={{
            style: { color: "black" },
            endAdornment: (
              <IconButton>
                <SearchOutlined />
              </IconButton>
            ),
          }}
        />
      </>)
    },
  };

  useEffect(() => {
    (async () => {
      const invoiceList = await InvoiceList(filter, page, rowsPerPage, token, order, search);
      renderDataTable(invoiceList);
    })();
  }, [filter, page, order, search, open]);

  const MyDoc = (row: any) => (
    <Document>
      <Page size={"A4"}>
        <Html>
          {ReactDOMServer.renderToStaticMarkup(
            <html>
              <body style={{ height: '825px', padding: 10 }}>

                <table width="100%" style={{ border: 0 }} cellSpacing={0} cellPadding={0}>
                  <tr>
                    <td style={{ alignItems: 'center' }} ><img src={Logo} style={{ width: '180px' }} /></td>
                  </tr>
                  <tr style={{ fontSize: 12, marginTop: 10 }}>
                    <th style={{ alignItems: 'center' }} ><span style={{ fontWeight: 900 }}>HST Number: </span></th>
                  </tr>
                  <tr style={{ fontSize: 12 }}>
                    <th style={{ alignItems: 'center', fontWeight: 'bolder' }}>GST Number: </th>
                  </tr>
                </table>

                <hr />
                <div style={{ fontSize: 12, padding: '10px 20px' }}>
                  <div style={{ paddingBottom: '20px' }}>
                    <table style={{ fontSize: 12, borderStyle: 'none', borderWidth: '0px', width: '100%' }}>
                      <tbody>
                        <tr>
                          <td style={{ fontSize: 22 }}>Billing Information</td>
                          <td style={{ alignItems: 'flex-end' }}><a href="https://testdscbucket.s3.ca-central-1.amazonaws.com/T&C.pdf">Terms &amp; Conditions</a><a /></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <table style={{ alignContent: 'center' }}>
                      <tbody >
                        <tr>
                          <td ><span style={{ fontSize: 14 }}>Owner Name:</span><br />{`${row?.School?.title}${" "}${row?.School?.first_name}${" "}${row?.School?.middle_name}${" "}${row?.School?.last_name}`}</td>
                          <td ><span style={{ fontSize: 15 }}>Email ID:</span><br />{row?.School?.email}</td>
                          <td style={{ left: 40 }}><span style={{ fontSize: 15 }}>Order ID:</span><br />{row?.invoice_id}</td>
                        </tr>
                        <tr style={{ marginTop: 5 }}>
                          <td><span style={{ fontSize: 14 }}>School Name:</span><br />{row?.School?.name}</td>
                          <td><span style={{ fontSize: 14 }}>Invoice Email: </span><br />{row?.School?.email}</td>
                          <td style={{ left: 40 }}><span style={{ fontSize: 14 }}>Invoice Date: </span><br />{moment(row?.created_at).format("MMM DD, YYYY")}</td>
                        </tr>
                        <tr style={{ marginTop: 5 }}>
                          <td ><span style={{ fontSize: 14 }}>School ID: </span><br />{row?.School?.id}</td>
                          <td ><span style={{ fontSize: 14 }}>Phone Number: </span><br />{row?.School?.phone || ""}</td>
                          <td style={{ left: 40 }}><span style={{ fontSize: 14 }}>Mobile Number: </span><br />{row?.School?.mobile || ""}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div style={{ fontSize: 12, padding: '20px 20px' }}>
                  <div>
                    <table style={{ borderStyle: 'none', width: '100%' }}>
                      <tbody>
                        {row?.addons?.data?.map((d: any, index: number) => {
                          return (
                            <tr>
                              <td><span style={{ fontSize: 15 }}>{d?.name}</span></td>
                              <td style={{ alignItems: 'flex-end', marginTop: 4 }}>CAD {new Number(d?.price).toFixed(2)}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div style={{ fontSize: 12, padding: '20px 20px' }}>

                  <div>
                    <table style={{ borderStyle: 'none', width: '100%' }}>
                      <tbody>
                        <tr>
                          <td><span style={{ fontSize: 14 }}>Sub Total:</span></td>
                          <td style={{ alignItems: 'flex-end' }}>CAD {new Number(row?.sub_total).toFixed(2)}</td>
                        </tr>
                        <tr style={{ marginTop: 4 }}>
                          <td><span style={{ fontSize: 14 }}>Province Tax:</span></td>
                          <td style={{ alignItems: 'flex-end' }}>CAD {new Number(row?.tax1).toFixed(2)}</td>
                        </tr>
                        <tr style={{ marginTop: 4 }}>
                          <td><span style={{ fontSize: 14 }}>County Tax:</span></td>
                          <td style={{ alignItems: 'flex-end' }}>CAD {new Number(row?.tax2).toFixed(2)}</td>
                        </tr>
                        <tr style={{ marginTop: 4 }}>
                          <td> <span style={{ fontSize: 14, }}>Total Tax:</span></td>
                          <td style={{ alignItems: 'flex-end' }}>CAD {new Number(row?.tax_total).toFixed(2)}</td>
                        </tr>
                        <tr style={{ marginTop: 4 }}>
                          <td><span style={{ fontSize: 14 }}>Total:</span></td>
                          <td style={{ alignItems: 'flex-end' }}>CAD {new Number(row?.total).toFixed(2)}</td>
                        </tr>
                        <tr style={{ marginTop: 4 }}>
                          <td><span style={{ fontSize: 14 }}>Balance Due:</span></td>
                          <td style={{ alignItems: 'flex-end' }}>CAD {new Number(row?.balance_due || 0).toFixed(2)}</td>
                        </tr>
                        <tr style={{ backgroundColor: 'gold', marginTop: 4 }}>
                          <td><span style={{ fontSize: 14 }}>Grand Total:</span></td>
                          <td style={{ alignItems: 'flex-end' }}>CAD {new Number(row?.grand_total).toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div style={{ position: 'absolute', bottom: 0, width: '580px' }}>
                  <div style={{ alignItems: 'center', alignContent: 'center', textAlign: 'center', width: '100%', marginTop: '15px', marginBottom: '15px', color: '#00ac00' }}>
                    <strong>Thank you for part of Driving School Cloud!</strong>
                    <br />
                    <strong>Have a nice day.</strong>
                  </div>
                  <footer style={{ backgroundColor: '#b9b9b9', padding: '20px', fontSize: 10, alignItems: 'center', alignContent: 'center', textAlign: 'center' }}>
                    <div style={{ justifyContent: 'space-between' }}>
                      <span style={{ fontSize: 12 }}>+91 9876543210{"    "}</span>
                      <span style={{ fontSize: 12 }}>contact@drivingschoolcloud.com{"    "}</span>
                      <span style={{ fontSize: 12 }}>321 Clark Street, New York - 10013</span>
                    </div>
                  </footer>
                </div>
              </body >
            </html >
          )}
        </Html>
      </Page>
    </Document>
  )

  const renderDataTable = (invoiceList: any) => {
    const rowsDataTable = invoiceList && invoiceList.data.data.map((row: any) => {
      return {
        id: row ? row.id : "---",
        school_name: row ? row.school_name : "---",
        school_id: row.school_id ? row.school_id : "---",
        registration_date: row ? moment(row.created_at).format("YYYY-MM-DD") : "---",
        status: row.saved ? "Draft" : "Sent",
        amount_paid: <>{'CAD'}{" "}{row ? new Number(row?.total).toFixed(2) : "---"}</>,
        ref_id: row && (row?.invoice_id)?.toUpperCase() || "---",
        action: <div>
          {!row.saved ? <>
            <span className={classes.iconSpace}>
              <BsFillEyeFill style={{ cursor: 'pointer' }} color="#FFAF00" size={24} onClick={() => handleViewInvoice(row.invoice_url)} />
            </span>
            <span className={classes.iconSpace} onClick={() => navigateToExternalUrl(row.invoice_url, true)}>
              <BsFillArrowDownSquareFill style={{ cursor: 'pointer' }} color="green" size={20} />
            </span>
          </>
            : <>
              <span className={classes.iconSpace}>
                <BsFillEyeFill style={{ cursor: 'pointer' }} color="#FFAF00" size={24} onClick={() => handleLocalInvoice(row)} />
              </span>
              <span className={classes.iconSpace}>
                <PDFDownloadLink document={MyDoc(row)} fileName={`${row?.School?.name}_${row?.invoice_id}.pdf`}>
                  {({ blob, url, loading, error }) => (loading ? 'Loading Invoice...' :
                    <BsFillArrowDownSquareFill style={{ cursor: 'pointer' }} color="green" size={20} />)}
                </PDFDownloadLink>
              </span></>
          }
          <span onClick={() => handleClickOpen(row.id)}>
            <BsFillTrashFill style={{ cursor: 'pointer' }} size={20} color="black" />
          </span>
        </div>,
      };
    });
    setData(rowsDataTable);
    setSearchApiData(rowsDataTable);
  };

  const handleClickOpen = async (id: any) => {
    setID(id)
    setOpen(true);
  };

  const handleLocalInvoice = (row: any) => {
    setOpenView(true);
    setRowData(row);
  }

  const handleViewInvoice = async (url: any) => {
    // setID(id);
    navigateToExternalUrlPdfView(url)
  };

  const handleCloseView = () => {
    setPdfUrl("");
    setRowData({});
    setOpenView(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    let resData: any = await deleteInvoice(id, token);
    if (resData.status === 200) {
      toast.success(INVOICE_SUCCESSFULLY_DELETED);
      InvoiceList(filter, page, rowsPerPage, token, order, search);
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
  }

  const navigateToExternalUrlPdfView = async (urlName: string) => {
    if (urlName) {
      const newData: { url: string, msg: string, error: boolean } = await fileSignedURL(urlName && urlName, token);
      const { url, msg, error } = newData;
      setPdfUrl(newData.url);
      setOpenView(true);
      if (error) {
        toast.error(msg);
        return;
      }
    }
    // shouldOpenNewTab ? window.open(url, "_blank") : window.location.href = url;
    // return;
  }

  const navigateToExternalUrl = async (urlName: string, shouldOpenNewTab: boolean = true) => {
    const newData: { url: string, msg: string, error: boolean } = await fileSignedURL(urlName && urlName, token);
    const { url, msg, error } = newData;
    if (error) {
      toast.error(msg);
      return;
    }
    shouldOpenNewTab ? window.open(url, "_blank") : window.location.href = url;
    return;
  }

  return (
    <>
      <ToastMessage />
      <CommonHeadPart
        componentText="Generate Invoice"
        Icon={InvoiceIcon}
        buttonText="Generate New Invoice"
        BreadCrumbs={BreadCrumbInvoice}
        urlLink={InnerPageRoutes.generateInvoice}
      />
      <div className={classes.divInsideMain}>
        <ThemeProvider theme={theme}>
          <MUIDataTable
            title={<FilterTabs
              SelectedFilter={filter}
              Filters={GenerateInvoiceFilterTabs}
              HandleChange={handleFilter}
              isFilter={false}
              HandleSearch={handleSearch}
            />}
            data={data}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </div>

      <BootstrapDialog
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Delete Inovice
        </BootstrapDialogTitle>

        <DialogContent dividers className={classes.dialogContent} >
          <RiErrorWarningFill color="#FDB924" size={30} style={{ marginRight: 10 }} />
          <Typography variant="h6" component="div">
            Are you sure you want to delete this record?
          </Typography>
        </DialogContent>

        <DialogActions className={classes.dialogAction} >
          <Button
            type="button"
            variant="contained"
            className={classes.noButton}
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            type="button"
            variant="contained"
            className={classes.yesButton}
            onClick={handleDelete}
          >
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={handleCloseView}
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        open={openView}
      >

        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseView}>
          <RiEyeFill color="#FFFFFF" size={28} style={{ marginRight: 10, marginBottom: 4 }} />
          Preview of invoice
        </BootstrapDialogTitle>

        <DialogContent dividers>
          {pdfUrl ?
            <embed src={`${pdfUrl}#toolbar=0`} style={{ width: "100%", height: "60vh" }} />
            :
            <div style={{ width: "100%", height: "60vh" }}>
              <PDFViewer height="100%" width="100%" showToolbar={false}>
                {MyDoc(rowData)}
              </PDFViewer>
            </div>
          }

        </DialogContent>

      </BootstrapDialog>
    </>
  );
};

export default GenerateInvoice;
