import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Logo from "../svg_icons/logo/dsc_logo.svg";
import { ROUTES } from "../constants/routes";
import { IconButton } from "@mui/material";
import MenuIcon from "@material-ui/icons/Menu";
import { useDrawerContext } from "../contexts/drawer-context";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import PatternOutlinedIcon from '@mui/icons-material/PatternOutlined';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Logout from "@mui/icons-material/Logout";
import { Drawer, Button, DialogContent } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { SidebarRegister } from "./SidebarRegister";
import { useDispatch, useSelector } from "react-redux";
import { USER_LOGOUT } from "../reducer-store/ActionTypes";
import Badge from "@mui/material/Badge";
import { bindActionCreators } from "redux";
import * as Action from "../reducer-store/actions/notificationAction";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { fileSignedURL, logoutUser } from "../reducer-store/actions/authActions";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { MdLogout } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import { toast } from "react-toastify";
import { RELOAD_NOTIFICATION } from "../reducer-store/constants";

const drawerWidth = 370;

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: "#FFFFFF",
    // background: "#ececec",
    color: "#000",
    boxShadow: "none",
  },
  logoLink: {
    paddingTop: ".3125rem",
    paddingBottom: ".3125rem",
  },
  title: {
    margin: "auto",
  },
  header: {
    minHeight: "96px !important",
    width: '100%',
    marginLeft: "auto !important ",
    padding: "0px 15px 0px 15px !important",
    alignItems: "center !important",
    display: "flex !important",
    flexDirection: 'row',
    justifyContent: 'space-between !important',
  },
  Navbar: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#000000",
  },
  icon: {
    padding: "12px 20px 12px 28px",
    display: "none !important",
    '@media (max-width: 768px)': {
      display: "block !important",
    },
  },
  grow: {
    flexGrow: 1,
  },
  headerMenuButton: {
    paddingRight: "0px",
  },
  headerIcon: {
    fontSize: 28,
    color: "rgba(0, 0, 0, 1)",
    marginRight: "10px",
  },
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 0),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  changePassword: {
    display: "flex",
    '&:hover': {
      color: "#757575",
    },
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  dialogContent: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dialogAction: {
    justifyContent: "center !important",
  },
  yesButton: {
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontFamily: "Arial !important",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  noButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #000000 !important",
    color: "#000000 !important",
    padding: "0 35px !important",
    height: "40px !important",
    textTransform: "capitalize",
    fontSize: "15px",
  },
  imageParentDiv: {
    // height: "80px",
    /* border: "1px solid #707070", */
    borderRadius: "5%",
    // marginLeft: "45px",
  },
  avtar: {
    width: "100%",
    height: "100%",
    borderRadius: "5%",
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2 }}
      style={{ color: "#ffffff", backgroundColor: "#646464" }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[100],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Header = () => {
  const classes = useStyles();
  const { isOpened, toggleIsOpened } = useDrawerContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [notificaitonArray, setNewNotificationArray] = React.useState([]);
  const [newNotificationStatus, setNewNotificationStatus] = React.useState<any>(false);
  const [openNotification, setOpenNotification] = React.useState<any>(false);
  const [adminProfile, setAdminProfile] = useState("");
  const open = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openLogout, setOpenLogout] = React.useState<any>(false);

  const dispatch = useDispatch();
  const AuthToken = useSelector((state: any) => state.auth);
  const { user, token, userUpdated } = AuthToken;
  const notificationArr = useSelector((state: any) => state.notification);
  const { NotificationList } = bindActionCreators(Action, dispatch);

  const { ReadNotification } = Action;

  const loggedOutUser = async () => {
    const res: any = await logoutUser(token);
    if (!res?.error) {
      toast.success(res?.msg);
      setTimeout(() => {
        dispatch({ type: USER_LOGOUT });
      }, 1500)
    }
  };

  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];
  let timer: any;

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, () => clearTimeout(timer));
      });
      logoutAction();
    }, 240000);
  };

  const logoutAction = () => {
    setOpenLogout(true);
    timer = setTimeout(async () => {
      // clears any pending timer.
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      setOpenLogout(false);
      await logoutUser(token);
      // dispatch({ type: USER_LOGOUT });
      // window.location.reload();
    }, 60000);
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    if (token) {
      Object.values(events).forEach((item) => {
        window.addEventListener(item, () => {
          resetTimer();
          handleLogoutTimer();
        });
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (userUpdated) {
        if (userUpdated?.data?.profile_url) {
          const newData = await fileSignedURL(userUpdated && userUpdated?.data?.profile_url, token);
          setAdminProfile(newData?.url);
        }
      } else {
        if (user?.profile_url) {
          const newData = await fileSignedURL(user && user?.profile_url, token)
          setAdminProfile(newData?.url);
        }
      }
    })();
  }, [userUpdated, user]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const Noti: any = await NotificationList(user?.role_id, token);

      if (!Noti?.error) {
        setNewNotificationArray(Noti?.data);
      }
      var isNewNotification: any = Noti?.data?.some((value: any) => value?.status)

      if (isNewNotification) {
        setNewNotificationStatus(true);
      }
      else {
        setNewNotificationStatus(false);
      }
    }, RELOAD_NOTIFICATION);
    return () => clearInterval(interval);
  }, []);

  const handleClickDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    resetTimer();
    setAnchorEl(null);
    setOpenLogout(false);
  };

  const handleDrawerOpen = (notification: any, notificationDrawer: any) => {
    if (notificationDrawer) {
      handleDialogClose();
    } else {
      setOpenNotification(true);
    }

    notification?.map(async (data: any) => {
      if (data?.status) {
        await ReadNotification(data?.id, token);
      }
    })
  };

  const handleDrawerClose = () => {
    setOpenNotification(false)
  };

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.header}>
        <IconButton
          color="inherit"
          onClick={() => toggleIsOpened(!isOpened)}
          className={`${classes.icon} drawer-icon`}
        >
          <MenuIcon />
        </IconButton>

        {/* <Link className={classes.logoLink} to={ROUTES.dashboard}>
          <img src={Logo} />
        </Link> */}

        <div className={classes.imageParentDiv}>
          <Link className={classes.logoLink} to={ROUTES.dashboard}>
            <img
              className={classes.avtar}
              src={Logo}
            />
          </Link>
        </div>

        <div className={classes.grow} />
        <div className="icon2">
          <IconButton
            color="inherit"
            aria-haspopup="true"
            aria-controls="mail-menu"
            className={classes.headerMenuButton}
          >
            <Avatar alt="M" src={adminProfile} classes={{ root: classes.headerIcon }} />
            <Typography
              variant="subtitle1"
              className="IconTest"
              noWrap
              component="div"
              fontFamily="Arial"
            >
              {user && user.full_name}
            </Typography>
          </IconButton>

          <IconButton
            color={openNotification ? "success" : "inherit"}
            aria-haspopup="true"
            aria-controls="mail-menu"
            className={classes.headerMenuButton}
            onClick={() => handleDrawerOpen(notificaitonArray, openNotification)}
          >
            <Badge
              color={newNotificationStatus ? "warning" : "default"}
              classes={{ root: classes.headerIcon }}
              overlap="circular"
              badgeContent=" "
              variant="dot"
            >
              <NotificationsIcon color={openNotification ? "success" : "inherit"} />
            </Badge>
            <Typography
              variant="subtitle1"
              className="IconTest"
              noWrap
              component="div"
              fontFamily="Arial"
            >
              Notification
            </Typography>
          </IconButton>

          <IconButton
            onClick={handleClick}
            color="inherit"
            aria-haspopup="true"
            aria-controls="mail-menu"
            className={classes.headerMenuButton}
          >
            <SettingsIcon classes={{ root: classes.headerIcon }} />
            <Typography
              variant="subtitle1"
              className="IconTest"
              noWrap
              component="div"
              fontFamily="Arial"
            >
              {" "}
              Settings
            </Typography>
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >

            <Link to={ROUTES.profile}>
              <MenuItem>
                <Avatar src={adminProfile} /> Profile
              </MenuItem>
            </Link>

            <MenuItem>
              <Link to={ROUTES.changePassword} className={classes.changePassword}>
                <ListItemIcon>
                  <PatternOutlinedIcon fontSize="medium" />
                </ListItemIcon>
                Change Password
              </Link>
            </MenuItem>
            <Divider />
            {/* <MenuItem>
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              Add another account
            </MenuItem> */}
            <MenuItem onClick={() => handleClickDialogOpen()}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>

          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => handleDrawerClose()}
          >
            <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor="right"
              open={openNotification}
              classes={{ paper: classes.drawerPaper }}
              onClose={(_, reason) => reason === 'backdropClick' && console.log('close')}
            >
              <SidebarRegister notification={notificationArr.notifications} handleDrawerClose={handleDrawerClose} />
            </Drawer>
          </ClickAwayListener>

        </div>
      </Toolbar>

      <BootstrapDialog
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleDialogClose}
        >
          <FiLogOut color="#FFFFFF" size={22} style={{ marginRight: 10, marginBottom: 6 }} />
          Logout
        </BootstrapDialogTitle>

        <DialogContent dividers>
          Are you sure you want to logout?
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            variant="contained"
            onClick={handleDialogClose}
            className={classes.noButton}
          >
            No
          </Button>
          <Button
            type="button"
            variant="contained"
            className={classes.yesButton}
            onClick={loggedOutUser}
          >
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        open={openLogout}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Session Expire Warning
        </BootstrapDialogTitle>

        <DialogContent dividers className={classes.dialogContent}>
          <MdLogout color="#737373" size={30} style={{ marginRight: 10 }} />
          <Typography variant="inherit" component="div">
            Your session is about to expire. Do you need more time?
          </Typography>
        </DialogContent>

        <DialogActions className={classes.dialogAction}>
          <Button
            type="button"
            variant="contained"
            className={classes.noButton}
            onClick={loggedOutUser}
          >
            No
          </Button>
          <Button
            type="button"
            variant="contained"
            className={classes.yesButton}
            onClick={handleClose}
          >
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </AppBar >
  );
};

export default Header;
