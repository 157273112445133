import { ActionProps } from "../../ts";
import { LIST_OF_LICENSE, LIST_OF_LICENSE_ERROR, SINGLE_LICENSE_INFO, SINGLE_LICENSE_ERROR } from "../ActionTypes";

const initialState = { licenses: null };

const licenseStore = (state: any = initialState, action: ActionProps) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_OF_LICENSE: {
      return {
        ...state,
        licenses: payload,
        license_data: null,
      };
    }
    case LIST_OF_LICENSE_ERROR: {
      return {
        ...state,
        licenses: null,
        license_data: null,
      };
    }
    case SINGLE_LICENSE_INFO: {
      return {
        ...state,
        license_data: payload,
      };
    }
    case SINGLE_LICENSE_ERROR: {
      return {
        ...state,
        license_data: null,
      };
    }
    default:
      return state;
  }
};

export default licenseStore;
