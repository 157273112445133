import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CommonHeadPart from "../../common-components/CommonHeadPart";
import CommunicationIcon from "../../svg_icons/communication.svg";
import { ROUTES } from "../../constants/routes";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import PrintIcon from '@mui/icons-material/Print';
import { Button, Tooltip } from "@material-ui/core";
import DownloadIcon from '@mui/icons-material/Download';
import ReplyIcon from '@mui/icons-material/Reply';
import { InputField } from "../../common-components/FormComponent/FormFieldsComponent";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import { Clear } from "@material-ui/icons";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  DiscardButtonComponet,
  SendButtonComponet,
} from "../../common-components/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../reducer-store/actions/communicationAction";
import { isRequiredField } from "../../common-components/FormComponent/FormValidator";
import moment from "moment";
import { toast } from "react-toastify";
import { Box, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { ACCOUNT, OTHER, SALES, SUPPORT, TECHNICAL } from "../../reducer-store/constants";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  saveEmailButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #393939 !important",
    color: "#393939 !important",
    padding: "0 35px !important",
    height: "40px !important",
  },
  divInsideMain: {
    boxShadow: "0 0 5px #8e8e8e",
    borderRadius: "3px",
  },
  body_text: {
    marginTop: "10px",
  },
  attachement: {
    textAlign: "end",
  },
  attached_button: {
    textTransform: "none"
  },
  icon: {
    marginRight: "20px",
    cursor: "pointer",
  },
  button_save: {
    textAlign: "right",
  },
  linkStyle: {
    textDecoration: "none"
  },
  dialogContent: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const BreadCrumbComposeMessage = [
  {
    labelText: "Home",
    linkUrl: ROUTES.dashboard,
  },
  {
    labelText: "Communication",
    linkUrl: ROUTES.communication,
  },
  {
    labelText: "Message Reply",
    linkUrl: "",
  },

];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2 }}
      style={{ color: "#ffffff", backgroundColor: "#646464" }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[100],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const MessageReply: React.FC = () => {
  const param: any = useParams();
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState<any>([]);
  const [attachmentFileName, setAttachmentFileName] = useState<any>([]);
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const [receivedMsg, setReceivedMsg] = useState<any>(null);
  const [category, setCategory] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const { sendReplyMessage, GetReplyMessaage } = Actions;
  const history = useHistory();
  const dispatch = useDispatch();

  const manageCategory = (value: string) => {
    switch (value) {
      case "sales":
        return SALES;
      case "support":
        return SUPPORT;
      case "account":
        return ACCOUNT;
      case "technical":
        return TECHNICAL;
      case "other":
        return OTHER;
      default:
        return SALES;
    }
  };

  useEffect(() => {
    (async () => {
      const lastReplyMessage: any = await GetReplyMessaage(param.id, token);
      const { data } = lastReplyMessage;
      setCategory(data.category);
      setSubject(data.subject);
      setEmail(data.SentBy.email)
      setReceivedMsg(data);
    })();
  }, []);

  if (receivedMsg === null) {
    return (
      <Box style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
        <CircularProgress sx={{ color: '#707070' }} />
      </Box>
    );
  }

  const handleClose = () => {
    setOpenSuccess(false);
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    setAttachmentFileName([...attachmentFileName, file.name]);
    setAttachmentFile([...attachmentFile, file]);
  };

  const removeSelectedAttachment = async (id: number) => {
    const newArr = await attachmentFileName.filter((files: string, index: number) => {
      return id !== index;
    });
    const newFileArr = await attachmentFile.filter((files: any, index: number) => {
      return id !== index;
    });
    setAttachmentFileName(newArr);
    setAttachmentFile(newFileArr);
  };

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      isRequiredField(email) &&
      isRequiredField(category) &&
      isRequiredField(subject) &&
      isRequiredField(message)
    ) {
      setIsSubmit(false);
      const formData = new FormData();
      formData.append("category", category);
      formData.append("subject", subject);
      formData.append("body", message);
      formData.append("send_to", email);
      formData.append("fromAdmin", "1");
      {
        attachmentFile.map((filesData: any) => {
          formData.append("files", filesData);
        })
      }
      let resData: any = await sendReplyMessage(param.id, formData, token);
      if (!resData?.error) {
        toast.success("Reply sent successfully");
        // setOpenSuccess(true);
        setTimeout(() => {
          // setOpenSuccess(false);
          history.push(ROUTES.communication);
        }, 2000);
        return;
      }
      toast.error(resData?.errMsg);
      return;
    }
    setIsValidValues(false);
  };

  return (<>
    <CommonHeadPart
      componentText="Communication"
      Icon={CommunicationIcon}
      buttonText=""
      urlLink=""
      BreadCrumbs={BreadCrumbComposeMessage}
    />
    <Card>
      <CardContent>
        <Row>
          <Col lg="10" sm="12">
            <Typography variant="subtitle1">
              {receivedMsg && moment(receivedMsg.sent_timestamp).format("MMM D, YYYY")}
            </Typography>
          </Col>
          {/* <Col lg="1" sm="12" style={{ textAlign: "end" }}>
            <StarBorderIcon />
          </Col>
          <Col lg="1" sm="12">
            <PrintIcon />
          </Col> */}
        </Row>
        <Row>
          <Typography variant="h6">
            {receivedMsg && receivedMsg.subject}
          </Typography>
        </Row>
        <Row>
          <Col lg="6" sm="12">
            <Row>
              <Col lg="8" sm="12">
                <Typography variant="body2" className={classes.body_text}>
                  From: {receivedMsg && receivedMsg.SentBy?.full_name}
                </Typography>
              </Col>
              <Col lg="4" sm="12">
                <Typography variant="body2" className={classes.body_text}>
                  Categories: {receivedMsg && (manageCategory(receivedMsg?.category))}
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="hrline" />
        <Row>
          <Typography variant="body2" className={classes.body_text}>
            {receivedMsg && receivedMsg.threads[receivedMsg.threads.length - 1].body}
          </Typography>
        </Row>
        {/*
          <Row className={classes.attachement}>
            <Col lg="8" sm="12">
            </Col>
            <Col lg="4" sm="12">
              <Button className={classes.attached_button} >
                Download all attachments
                <DownloadIcon />
              </Button>
            </Col>
          </Row>
        */}
        <hr className="hrline" />
        <form onSubmit={SubmitHandler}>
          <Row>
            <Col lg="12" sm="12">
              <Typography>
                <ReplyIcon /> To: {receivedMsg && receivedMsg.School ? receivedMsg.School.name : ""} ({receivedMsg && receivedMsg.School ? receivedMsg.School.email : ""})
              </Typography>
            </Col>
            <Col lg="12" sm="12">
              <InputField
                type="text"
                lable=""
                name={message}
                rows={10}
                multiline={true}
                placeHolder="Reply"
                className="form-textfield-styles"
                value={message}
                handleChange={(e) => setMessage(e.target.value)}
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="Reply Message is required"
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col lg="6" sm="6">
              <div className="mb-2">
                <label htmlFor="file">
                  <Tooltip title="Attach files">
                    <AttachFileIcon className={classes.icon} />
                  </Tooltip>
                </label>
                <input
                  multiple
                  onChange={(e) => onChangeFile(e)}
                  type="file"
                  id="file"
                  style={{ display: "none" }}
                />
                {/* <InsertEmoticonIcon className={classes.icon} />
                <InsertLinkIcon className={classes.icon} /> */}
              </div>
              {
                attachmentFileName.map((fileName: string, index: number) => {
                  return <div>
                    {fileName}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => removeSelectedAttachment(index)}
                    >
                      <Clear className={classes.icon} />
                    </span>
                  </div>
                })
              }
            </Col>
            <Col lg="6" sm="6" className={classes.button_save}>
              <Link to={ROUTES.communication}>
                <DiscardButtonComponet />
              </Link>
              {" "}
              {/* <Button
                type="button"
                variant="contained"
                className={classes.saveEmailButton}
              >
                Save
              </Button>{" "} */}
              <SendButtonComponet />
            </Col>
          </Row>
        </form>
      </CardContent>
    </Card>

    <BootstrapDialog
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="customized-dialog-title"
      open={false}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
      >
        Message Sent
      </BootstrapDialogTitle>

      <DialogContent dividers className={classes.dialogContent}>
        <BsFillCheckCircleFill color="#00A651" size={40} />
        <Typography variant="h6" component="div">
          Thank you for your message, we aim to respond all queries within 24 working hours
        </Typography>
      </DialogContent>

      {/* <DialogActions className={classes.dialogAction}>
          <Button
            type="button"
            variant="contained"
            className={classes.noButton}
          // onClick={loggedOutUser}
          >
            No
          </Button>
          <Button
            type="button"
            variant="contained"
            className={classes.yesButton}
          // onClick={handleClose}
          >
            Yes
          </Button>
        </DialogActions> */}
    </BootstrapDialog>
  </>
  )
}

export default MessageReply;