import axios from "axios";
import { toast } from "react-toastify";
import { LIST_OF_PROVINCE_TAX, LIST_OF_PROVINCE_TAX_ERROR } from "../ActionTypes";
import { LIMIT, PAGE, PROVINCE_TAX } from "../constants";

const token = sessionStorage.getItem("token");
const header = {
    headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
        "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization",
    },
};

export const ProvinceTaxList = (
    token: string,
) => {
    header.headers.Authorization = `Bearer ${token}`;
    return async (dispatch: any) => {
        return await axios
            .get(
                `${PROVINCE_TAX}`, header)
            .then((res) => {
                dispatch({
                    type: LIST_OF_PROVINCE_TAX,
                    payload: res.data,
                });
                return res;
            })
            .catch((error) => {
                dispatch({
                    type: LIST_OF_PROVINCE_TAX_ERROR,
                });
                toast.error(error.response.data.errorMsg);
                return error.response;
            });
    };
};

export const ProvinceTaxUpdate = async (
    tax_id: string,
    token: string,
    obj: any
) => {
    header.headers.Authorization = `Bearer ${token}`;
    // return async (dispatch: any) => {
    return await axios
        .put(
            `${PROVINCE_TAX}/${tax_id}`, obj, header)
        .then((res) => {
            // dispatch({
            //     type: LIST_OF_PROVINCE_TAX,
            //     payload: res.data,
            // });
            return res;
        })
        .catch((error) => {
            // dispatch({
            //     type: LIST_OF_PROVINCE_TAX_ERROR,
            // });
            toast.error(error.response.data.errorMsg);
            return error.response;
        });
    // };
};