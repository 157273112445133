import axios from "axios";
import { Dispatch } from "react";
import { toast } from "react-toastify";
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  OTP_SENT,
  OTP_SENT_ERROR,
  OTP_VERIFY,
  OTP_VERIFY_ERROR,
  RESEND_OTP,
  RESEND_OTP_ERROR,
  SELECT_OTP_PLATFORM,
  RESET_OTP_SENT,
  RESET_OTP_SENT_ERROR,
  RESET_OTP_VERIFY,
  RESET_OTP_VERIFY_ERROR,
  UPDATE_RESET_PASSWORD,
  UPDATE_RESET_PASSWORD_ERROR,
  REGISTER_ANOTHER_ACCOUNT_FAIL,
  REGISTER_ANOTHER_ACCOUNT,
  GET_ADMIN_UPDATED_DATA,
  GET_ADMIN_UPDATED_DATA_ERROR,
  LIST_OF_PLAN_HISTORY,
  LIST_OF_PLAN_HISTORY_ERROR
} from "../ActionTypes";
import {
  ADMIN_LOGIN_API,
  ADMIN_RESEND_LOGIN_OTP_API,
  ADMIN_RESEND_LOGIN_OTP_API_PHONE,
  ADMIN_SEND_LOGIN_OTP_API,
  ADMIN_SEND_LOGIN_OTP_API_PHONE,
  ADMIN_VERIFY_LOGIN_OTP_API,
  ADMIN_SEND_FORGOT_PASSWORD_OTP_API,
  ADMIN_VERIFY_FORGOT_PASSWORD_OTP_API,
  ADMIN_UPDATE_FORGOT_PASSWORD,
  config,
  SIGNED_URL,
  ADD_NEW_USER_API,
  ALL_ADMIN_LIST,
  ADMIN_DETAILS_API,
  GET_PLAN_HISTORY,
  LOGOUT_API,
} from "../constants";

type AdminLoginProps = {
  email: string;
  password: string;
};

export const adminLogin = (userObj: AdminLoginProps) => {
  return async (dispatch: Dispatch<any>) => {
    const body = JSON.stringify(userObj);
    return await axios
      .post(ADMIN_LOGIN_API, body, config)
      .then((res) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LOGIN_FAIL
        });
        return error?.response?.data;
      });
  };
};

type SendOTPProps = {
  email?: string;
  phone?: string;
};

export const sentVerificationOTPCode = (
  userObj: SendOTPProps,
  type: string
) => {
  return async (dispatch: any) => {
    const API_URL =
      type === "email"
        ? ADMIN_SEND_LOGIN_OTP_API
        : ADMIN_SEND_LOGIN_OTP_API_PHONE;
    return await axios
      .post(API_URL, userObj)
      .then((res) => {
        dispatch({
          type: OTP_SENT,
          payload: res.data,
          otpType: type,
        });
        return res.data;
      })
      .catch((error) => {
        dispatch({
          type: OTP_SENT_ERROR,
        });
        toast.error(error?.response?.data?.errorMsg);
      });
  };
};

type VerifyOTPProps = {
  email?: string;
  mobile?: string;
  otp: string;
  password: string
};

export const verifyOTPCode = (userObj: VerifyOTPProps) => {
  return async (dispatch: any) => {
    const body = JSON.stringify(userObj);
    return await axios
      .post(ADMIN_VERIFY_LOGIN_OTP_API, body, config)
      .then((res) => {
        dispatch({
          type: OTP_VERIFY,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: OTP_VERIFY_ERROR,
        });
        toast.error(error?.response?.data?.errorMsg);
      });
  };
};

export const resendVerificationOTPCode = (
  userObj: SendOTPProps,
  type: string
) => {
  return async (dispatch: any) => {
    const API_URL =
      type === "email"
        ? ADMIN_RESEND_LOGIN_OTP_API
        : ADMIN_RESEND_LOGIN_OTP_API_PHONE;

    return await axios
      .post(API_URL, userObj)
      .then((res) => {
        dispatch({
          type: RESEND_OTP,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: RESEND_OTP_ERROR,
        });
        toast.error(error?.response?.data?.errorMsg);
      });
  };
};

export const updateOtpPlatFormValue = (otpType: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: SELECT_OTP_PLATFORM,
      payload: otpType,
    });
  };
};

export const sentForgotPasswordOTPCode = (email: string) => {
  return async (dispatch: any) => {
    return await axios
      .get(`${ADMIN_SEND_FORGOT_PASSWORD_OTP_API}/${email}`)
      .then((res) => {
        dispatch({
          type: RESET_OTP_SENT,
          payload: res.data,
        });
        return res.data;
      })
      .catch((error) => {
        dispatch({
          type: RESET_OTP_SENT_ERROR,
        });
        toast.error(error.response.data.errorMsg);
        return error?.response?.data;
      });
  };
};

export const verifyForgotPasswordOTPCode = (userObj: any) => {
  return async (dispatch: any) => {
    const body = JSON.stringify(userObj);
    return await axios
      .post(ADMIN_VERIFY_FORGOT_PASSWORD_OTP_API, body, config)
      .then((res) => {
        dispatch({
          type: RESET_OTP_VERIFY,
          payload: res.data,
        });
        return res.data;
      })
      .catch((error) => {
        dispatch({
          type: RESET_OTP_VERIFY_ERROR,
        });
        toast.error(error?.response?.data?.errorMsg);
        return error?.response?.data;
      });
  };
};

export const updateForgotPassword = (userObj: any) => {
  return async (dispatch: any) => {
    const body = JSON.stringify(userObj);
    return await axios
      .put(ADMIN_UPDATE_FORGOT_PASSWORD, body, config)
      .then((res) => {
        dispatch({
          type: UPDATE_RESET_PASSWORD,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: ADMIN_UPDATE_FORGOT_PASSWORD,
        });
        toast.error(error?.response?.data?.errorMsg);
      });
  };
};

const token = sessionStorage.getItem("token");
const header = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization",
  },
};


export const fileSignedURL = async (url: string, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .get(`${SIGNED_URL}?key=${url}`, header)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
};

export const allAdminList = async (role_id: string, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .get(`${ALL_ADMIN_LIST}?role_id=${role_id}`, header)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
};


export const addNewUser = (userObj: any) => {
  return async (dispatch: Dispatch<any>) => {
    const body = JSON.stringify(userObj);
    return await axios
      .post(ADD_NEW_USER_API, body, config)
      .then((res) => {
        dispatch({ type: REGISTER_ANOTHER_ACCOUNT, payload: res.data });
        return res;
      })
      .catch((error) => {
        dispatch({ type: REGISTER_ANOTHER_ACCOUNT_FAIL });
        toast.error(error?.response?.data?.errorMsg);
      });
  };
};

export const updateProfile = async (userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .patch(`${ADMIN_DETAILS_API}`, userObj, header)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response;
    });
};

export const SingleAdminUser = (token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${ADMIN_DETAILS_API}`, header)
      .then((res) => {
        dispatch({
          type: GET_ADMIN_UPDATED_DATA,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: GET_ADMIN_UPDATED_DATA_ERROR,
        });
        toast.error(error?.response?.data?.errorMsg);
        return error?.response;
      });
  };
};

export const planHistorySingle = (
  // status = "0",
  // page = PAGE,
  // limit = LIMIT,
  token: string,
  schoolId: string,
  // search: string
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${GET_PLAN_HISTORY}?schoolId=${schoolId}`, header)
      .then((res) => {
        dispatch({
          type: LIST_OF_PLAN_HISTORY,
          payload: res.data,
        });
        return res.data;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_PLAN_HISTORY_ERROR,
        });
        toast.error(error?.response?.data?.errorMsg);
      });
  };
};

export const logoutUser = async (token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .patch(LOGOUT_API, {}, header)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      return error?.response?.data;
    });
};
