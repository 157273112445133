import React, { useState } from 'react'
import { useHistory } from 'react-router';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Input
} from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForever from "@mui/icons-material/DeleteForever";
import CommonHeadPart from '../../common-components/CommonHeadPart';
import { makeStyles } from "@material-ui/core/styles";
import PlatformIcon from "../../svg_icons/platform.svg"
import "../Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { InnerPageRoutes, ROUTES } from '../../constants/routes';
import ToastMessage from '../../common-components/ToastMessage';
import { BsFillTrashFill } from 'react-icons/bs';

const useStyles = makeStyles(() => ({
  divInsideMain: {
    boxShadow: "0 0 5px #8e8e8e",
    borderRadius: "3px"
  }
}));

const BreadCrumbPlatform = [{
  labelText: "Home",
  linkUrl: ROUTES.home
}, {
  labelText: "Platform feature toggle",
  linkUrl: ""
}]

const PlatformFeatureToggle: React.FC = () => {
  const classes = useStyles();
  const [plateFromList, setPlateFromList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [dataFrom, setDateFrom] = useState(0);
  const [dataTo, setDataTo] = useState(5);
  const [filterByStatus, setFilterByStatus] = useState("all");
  const [search, setSearch] = useState("");
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();

  return (
    <>
      <ToastMessage />
      <CommonHeadPart
        componentText="Platform Feature Toggle"
        Icon={PlatformIcon}
        buttonText="Add new toggle"
        BreadCrumbs={BreadCrumbPlatform}
        urlLink={InnerPageRoutes.addNewToggle}
      />
      <div className={classes.divInsideMain}>
        <Table className="communication-table" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>User name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>School Name and ID</TableCell>
              <TableCell>Status + Date</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow >
              <TableCell component="td" scope="row" className="important-icon">
                Test <i className={`fa ${"yes" === "yes" ? "fa-star" : "fa-star-o"}`} aria-hidden="true" />
              </TableCell>
              <TableCell component="td" scope="row">
                Test
              </TableCell>
              <TableCell component="td" scope="row" className="Test">
                Test
              </TableCell>
              <TableCell component="td" scope="row">
                Test
                <br />
                <small>DSC - 712345678</small>
              </TableCell>
              <TableCell component="td" scope="row">
                Dec 14, 2021
                <br />
                <small>Test</small>
              </TableCell>
              <TableCell component="td" scope="row">
                Test
                <br />
                <small>09:04 am, Jun 21, 2021</small>
              </TableCell>
              <TableCell component="td" scope="row">
                {"deleted" !== "deleted"
                  ? <BsFillTrashFill size={20} color="black" />
                  : <DeleteForever titleAccess="Delete" className="delete-icon" />
                }
              </TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="td" scope="row" className="important-icon">
                Test <i className={`fa ${"yes" === "yes" ? "fa-star" : "fa-star-o"}`} aria-hidden="true" />
              </TableCell>
              <TableCell component="td" scope="row">
                Test
              </TableCell>
              <TableCell component="td" scope="row" className="Test">
                Test
              </TableCell>
              <TableCell component="td" scope="row">
                Test
                <br />
                <small>DSC - 712345678</small>
              </TableCell>
              <TableCell component="td" scope="row">
                Dec 14, 2021
                <br />
                <small>Test</small>
              </TableCell>
              <TableCell component="td" scope="row">
                Test
                <br />
                <small>09:04 am, Jun 21, 2021</small>
              </TableCell>
              <TableCell component="td" scope="row">
                {"deleted" !== "deleted"
                  ? <DeleteIcon titleAccess="Move to trash" className="delete-icon" />
                  : <DeleteForever titleAccess="Delete" className="delete-icon" />
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  )
}

export default PlatformFeatureToggle
