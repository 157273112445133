import axios from "axios";
import { toast } from "react-toastify";
import { LIST_OF_PLAN, LIST_OF_PLAN_ERROR } from "../ActionTypes";
import { PLAN_LIST_API, SINGLE_PLAN_API, UPDATE_PLAN_API } from "../constants";

const token = sessionStorage.getItem("token");
const header = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization",
  },
};

type UpdatePlanProps = {
  name: string;
  price: string;
  staff: string;
  instructor: string;
  admin: string;
  students: string;
};

export const PlanList = (page = 1, limit = 5, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${PLAN_LIST_API}?page=${page}&limit=${limit}`, header)
      .then((res) => {
        dispatch({
          type: LIST_OF_PLAN,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_PLAN_ERROR,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};

export const SinglePlanData = (id = 1, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${SINGLE_PLAN_API}/${id}`, header)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_PLAN_ERROR,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};

export const updatePlan = async (id = 1, userObj: UpdatePlanProps) => {
  const body = JSON.stringify(userObj);
  return await axios
    .put(`${UPDATE_PLAN_API}/${id}`, userObj, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
      return error.response.data;
    });
};
