import { ActionProps } from "../../ts";
import { LIST_OF_PROVINCE_TAX, LIST_OF_PROVINCE_TAX_ERROR } from "../ActionTypes";

const initialState = { taxes: null };

const licenseStore = (state: any = initialState, action: ActionProps) => {
    const { type, payload } = action;
    switch (type) {
        case LIST_OF_PROVINCE_TAX: {
            return {
                ...state,
                taxes: payload,
                taxes_data: null,
            };
        }
        case LIST_OF_PROVINCE_TAX_ERROR: {
            return {
                ...state,
                taxes: null,
                taxes_data: null,
            };
        }
        default:
            return state;
    }
};

export default licenseStore;