export const SCHOOL_SUCCESSFULLY_CREATED = "School Created Successfully";
export const SCHOOL_SUCCESSFULLY_UPDATED = "School Updated Successfully";

export const PROGRAM_SUCCESSFULLY_CREATED = "Program Created Successfully";
export const PROGRAM_SUCCESSFULLY_UPDATED = "Program Updated Successfully";

export const MODUAL_SUCCESSFULLY_CREATED = "Module Created Successfully";
export const MODUAL_SUCCESSFULLY_UPDATED = "Module Updated Successfully";

export const COURSE_SUCCESSFULLY_CREATED = "Course Created Successfully";
export const COURSE_SUCCESSFULLY_UPDATED = "Course Updated Successfully";

export const TOPIC_SUCCESSFULLY_CREATED = "Topic Created Successfully";
export const TOPIC_SUCCESSFULLY_UPDATED = "Topic Updated Successfully";
export const PLAN_SUCCESSFULLY_UPDATED = "Plan Updated Successfully";

export const COMMUNICATION_MESSAGE_SUCCESSFULLY_SENT = "Message Sent Successfully";

export const COMMUNICATION_MESSAGE_SUCCESSFULLY_SAVE = "Message Saved Successfully";

export const COMMUNICATION_MESSAGE_SUCCESSFULLY_REPLY_SENT = "Reply Sent Successfully";

export const LICENSE_SUCCESSFULLY_CREATED = "License Created Successfully";
export const LICENSE_SUCCESSFULLY_DELETED = "License Deleted Successfully";
export const LICENSE_SUCCESSFULLY_UPDATED = "License Updated Successfully";

export const INVOICE_SUCCESSFULLY_SAVE = "Invoice Saved Successfully";
export const INVOICE_SUCCESSFULLY_UPDATED = "Invoice Updated Successfully";
export const INVOICE_SUCCESSFULLY_SEND = "Invoice Sent Successfully";
export const INVOICE_SUCCESSFULLY_DELETED = "Invoice Deleted Successfully";

export const REFUND_SUCCESSFULLY_CREATED = "Payment Refunded Successfully";

export const ADD_NEW_USER_SUCCESSFULLY = "New User Added Successfully";

export const UPDATE_ADMIN_SUCCESSFULLY = "Admin Updated Successfully";