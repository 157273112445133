import React, { useEffect, useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "../../Common.css";
import { Button, Col, Row } from "react-bootstrap";
import ToastMessage from "../../../common-components/ToastMessage";
import { ROUTES } from "../../../constants/routes";
import { InputField } from "../../../common-components/FormComponent/FormFieldsComponent";
import moment from "moment";
import { isRequiredField } from "../../../common-components/FormComponent/FormValidator";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    divInsideMain: {
        boxShadow: "0 0 5px #8e8e8e",
        borderRadius: "3px",
        marginTop: "30px",
    },
    valueStyle: {
        fontFamily: "Arial",
        fontWeight: 900,
        letterSpacing: '0.06px',
        color: '#3A3A3A',
        fontSize: '18px'
    },
    tabPanelStyle: {
        padding: "0 !important",
    },
    boxBorderCurrent: {
        boxShadow: "0px 0px 5px #8e8e8e",
        backgroundColor: "#F9F9F9",
        borderRadius: "5px",
        padding: "10px",
    },
    boxBorderHistory: {
        boxShadow: "0px 0px 5px #8e8e8e",
        backgroundColor: "#DFDFDF",
        borderRadius: "5px",
    },
    divStyling: {
        padding: "15px 20px",
    },
    switchRight: {
        textAlign: "right",
    },
    colStyling: {
        marginTop: 10,
        marginBottom: 6,
    },
    saveEmailButton: {
        borderRadius: "40px !important",
        backgroundColor: "#00A651 !important",
        border: "1px solid #00A651 !important",
        color: "#ffffff !important",
        padding: "0 35px !important",
        height: "40px !important",
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        fontWeight: 500,
        fontSize: "16px !important",
        textTransform: 'capitalize',
    },
    saveEmailButtonDisabled: {
        borderRadius: "40px !important",
        backgroundColor: "#E5E5E5 !important",
        border: "1px solid #8E8E8E !important",
        color: "#959595 !important",
        padding: "0 35px !important",
        boxShadow: 'none',
        height: "40px !important",
        fontWeight: 500,
        fontSize: "16px !important",
        textTransform: 'capitalize',
        cursor: 'default !important',
        '&:hover': {
            boxShadow: 'none'
        },
        '&:focus': {
            boxShadow: 'none'
        },
        '&:active': {
            boxShadow: 'none'
        }
    },
    iconHover: {
        display: "flex",
        flexDirection: "row",
        // marginBottom: "10px",
        alignItems: "center !important",
        '&:hover': {
            cursor: "pointer"
        },
    },
    button1234: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "40px",
        height: "40px",
    },
    popRow: {
        paddingTop: 10,
    },
    popRow1: {
        paddingTop: 20,
    },
    lockicon: {
        '& svg': {
            fontSize: "18px"
        },
    },
    saveButton: {
        borderRadius: "40px !important",
        backgroundColor: "#00A651 !important",
        border: "1px solid #00A651 !important",
        color: "#ffffff !important",
        padding: "0 35px !important",
        height: "40px !important",
        float: "right"
    },
    dialogContent: {
        textAlign: "center",
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: "center"
    },
    dialogAction: {
        justifyContent: "center !important",
    },
    icon: {
        width: '30%',
        height: 'auto',
        color: "#000",
    },
    yesButton: {
        borderRadius: "40px !important",
        backgroundColor: "#00A651 !important",
        border: "1px solid #00A651 !important",
        color: "#ffffff !important",
        padding: "0 35px !important",
        height: "40px !important",
        fontFamily: 'Arial !important',
        fontSize: '15px',
        textTransform: 'capitalize',
        float: 'right',
    },
    noButton: {
        borderRadius: "40px !important",
        backgroundColor: "#ffffff !important",
        border: "1px solid #000000 !important",
        color: "#000000 !important",
        padding: "0 35px !important",
        height: "40px !important",
        textTransform: 'capitalize',
        fontSize: '15px',
    }
}));

const BreadCrumbProgram = [
    {
        labelText: "Home",
        linkUrl: ROUTES.home,
    },
    {
        labelText: "My Plan",
        linkUrl: "",
    },
];

const PaymentKey: React.FC = () => {
    const classes = useStyles();
    const AuthToken = useSelector((state: any) => state.auth);
    const { token } = AuthToken;

    const [keyId, setKeyId] = useState<any>('');
    const [clientKey, setClientKey] = useState<any>('');
    const [secretKey, setSecretKey] = useState<any>('');
    const [webHookKey, setWebHookKey] = useState<any>('');
    const [updatedOn, setUpdatedOn] = useState<any>('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [isValidValues, setIsValidValues] = useState(true);
    const [update, setUpdate] = useState(false);


    return (
        <>
            <ToastMessage />
            <Paper className={classes.boxBorderCurrent}>
                <form>
                    <Row>
                        <Col lg='6' className={classes.colStyling} >
                            <InputField
                                // disabled
                                type="text"
                                lable={'Stripe Client Key :'}
                                name="Stripe Client Key"
                                rows={1}
                                multiline={false}
                                placeHolder="Enter Stripe Client Key"
                                className="form-textfield-styles"
                                value={clientKey}
                                handleChange={(e) => setClientKey(e.target.value)}
                                isValidValue={isValidValues}
                                isSubmit={isSubmit}
                                requiredMessage="Stripe Client Key field is required"
                            />
                        </Col>
                        <Col lg='6' className={classes.colStyling} >
                            <InputField
                                // disabled
                                type="text"
                                lable={'Stripe Secret Key :'}
                                name="Stripe Secret Key"
                                rows={1}
                                multiline={false}
                                placeHolder="Enter Stripe Secret Key"
                                className="form-textfield-styles"
                                value={secretKey}
                                handleChange={(e) => setSecretKey(e.target.value)}
                                isValidValue={isValidValues}
                                isSubmit={isSubmit}
                                requiredMessage="Stripe Secret Key field is required"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='6' className={classes.colStyling} >
                            <InputField
                                // disabled
                                type="text"
                                lable={'Stripe Webhook Key :'}
                                name="Stripe Webhook Key"
                                rows={1}
                                multiline={false}
                                placeHolder="Enter Stripe Webhook Key"
                                className="form-textfield-styles"
                                value={webHookKey}
                                handleChange={(e) => setWebHookKey(e.target.value)}
                                isValidValue={isValidValues}
                                isSubmit={isSubmit}
                                requiredMessage="Stripe Webhook Key field is required"
                            />
                        </Col>
                        <Col lg='6' className={classes.colStyling} >
                            <InputField
                                disabled
                                type="text"
                                lable={'Stripe Currency :'}
                                name="Stripe Currency"
                                rows={1}
                                multiline={false}
                                placeHolder="Enter Stripe Currency"
                                className="form-textfield-styles"
                                value={"CAD"}
                                handleChange={(e) => { }}
                                isValidValue={false}
                                isSubmit={false}
                                requiredMessage="Stripe Currency field is required"
                                isCheckValidNoValue={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='6' className={classes.colStyling} >
                            <Typography variant="subtitle1">
                                Updated On : {updatedOn ? moment(updatedOn).format("DD/MM/YYYY hh:mm:ss A") : "-"}
                            </Typography>
                        </Col>
                        <Col lg='6' className={classes.colStyling} >
                            <Button type="submit" variant="contained" className={classes.yesButton}>
                                {update ? "Save " : "Update"}
                            </Button>
                        </Col>
                    </Row>
                </form>
            </Paper>
        </>
    );
};

export default PaymentKey;
